import api from 'api';
import i18n from 'i18n';
import { userAdminURL } from './utils';
import { httpErrorTypes } from 'utility/error';
export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';
export const GET_ACTIVE_USERS_REQUEST = 'GET_ACTIVE_USERS_REQUEST';
export const GET_ACTIVE_USERS_SUCCESS = 'GET_ACTIVE_USERS_SUCCESS';
export const GET_ACTIVE_USERS_FAILURE = 'GET_ACTIVE_USERS_FAILURE';
export const GET_USERS_FULL_PORTAL_REQUEST = 'GET_USERS_FULL_PORTAL_REQUEST';
export const GET_USERS_FULL_PORTAL_SUCCESS = 'GET_USERS_FULL_PORTAL_SUCCESS';
export const GET_USERS_FULL_PORTAL_FAILURE = 'GET_USERS_FULL_PORTAL_FAILURE';
export const SERVICE_ENTITLEMENTS_REQUEST = 'SERVICE_ENTITLEMENTS_REQUEST';
export const SERVICE_ENTITLEMENTS_SUCCESS = 'SERVICE_ENTITLEMENTS_SUCCESS';
export const SERVICE_ENTITLEMENTS_FAILURE = 'SERVICE_ENTITLEMENTS_FAILURE';

export const createUserErrorTypes = {
  CREATE_USER_SETUP: 'CREATE_USER_SETUP',
  EXPRESS_USER_SETUP: 'EXPRESS_USER_SETUP',
  GET_ACTIVE_USERS: 'GET_ACTIVE_USERS',
  GET_USERS_FULL_PORTAL: 'GET_USERS_FULL_PORTAL'
};

export const createUser = (userSetupInfo, t = i18n.t) => {
  return {
    types: [CREATE_USER_REQUEST, CREATE_USER_SUCCESS, CREATE_USER_FAILURE],
    callAPI: (state, options) => api.save(userAdminURL('createuser'), userSetupInfo),
    httpErrorTypes,
    errorDisplayType: 'modal',
    errorTitle: t('administration.actionMessages.createUser')
  };
};

export const getActiveUsers = () => {
  return {
    types: [GET_ACTIVE_USERS_REQUEST, GET_ACTIVE_USERS_SUCCESS, GET_ACTIVE_USERS_FAILURE],
    callAPI: (state, options) => api.fetch(userAdminURL('view/activeusers')),
    httpErrorTypes,
    errorDisplayType: 'modal'
  };
};

export const getServiceEntitlements = hasAssignedDMPrograms => {
  return {
    types: [SERVICE_ENTITLEMENTS_REQUEST, SERVICE_ENTITLEMENTS_SUCCESS, SERVICE_ENTITLEMENTS_FAILURE],
    callAPI: (state, options) => {
      return hasAssignedDMPrograms == null
        ? api.fetch(userAdminURL(`createuser/serviceentitlements`))
        : api.fetch(userAdminURL(`createuser/serviceentitlements?hasAssignedDMPrograms=${hasAssignedDMPrograms}`));
    },
    httpErrorTypes,
    errorDisplayType: 'modal'
  };
};

export const getUsersFullPortal = () => {
  return {
    types: [GET_USERS_FULL_PORTAL_REQUEST, GET_USERS_FULL_PORTAL_SUCCESS, GET_USERS_FULL_PORTAL_FAILURE],
    callAPI: (state, options) => api.fetch(userAdminURL('createuser/accessusers')),
    httpErrorTypes
  };
};
