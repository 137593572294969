import isNil from 'lodash/isNil';
import isNull from 'lodash/isNull';
import isFinite from 'lodash/isFinite';
import reduce from 'lodash/reduce';
import findLastKey from 'lodash/findLastKey';

export const generateExportPayload = (listName, selectAll, payload, selectedItemIdList) =>
  selectAll
    ? {
        applyFilter: selectAll,
        exportAll: selectAll,
        ...payload
      }
    : {
        [listName]: selectedItemIdList,
        exportAll: false,
        applyFilter: false
      };

export const mapEmptyListToFullList = (payload, payloadKeys, serverKeys, serverData, allOptionsFlags, mapList) => {
  const tempPayload = { ...payload };
  payloadKeys.map((payloadKey, index) => {
    if (!(payloadKey in tempPayload)) {
      tempPayload[payloadKey] = flattenByPropOrPreserveObject(
        serverData[serverKeys[index]] ? serverData[serverKeys[index]] : [{}],
        mapList?.[payloadKey]
      );
    }
    tempPayload[allOptionsFlags[index]] =
      serverData[serverKeys[index]]?.length <= tempPayload[payloadKey].length ? true : undefined;
  });
  return tempPayload;
};

const flattenByPropOrPreserveObject = (value, prop) => {
  if (value instanceof Array) {
    return value.map(item =>
      prop && prop === 'object'
        ? item
        : prop
        ? item[prop]
        : item.id || isFinite(item.id)
        ? item.id
        : item.userId || item
    );
  } else {
    return isNull(value?.id)
      ? undefined
      : prop && prop === 'object'
      ? value
      : prop
      ? value[prop]
      : value?.id || value?.id === 0
      ? value.id
      : value;
  }
};

export const mapValuesToPayload = (payload, propMapCollection = {}) => {
  return reduce(
    payload,
    (acc, valueOfTypeObjOrObjectArray, key) => {
      const value = flattenByPropOrPreserveObject(valueOfTypeObjOrObjectArray, propMapCollection[key]);
      return !isNil(value)
        ? {
            ...acc,
            [key]: value
          }
        : acc;
    },
    {}
  );
};

export const payloadToPathParams = payload => {
  const pathParams = reduce(
    payload,
    (acc, value, key) => `${acc}${key}=${value}${findLastKey(payload) !== key ? '&' : ''}`,
    ''
  );
  return pathParams ? `?${pathParams}` : '';
};
