import api from 'api';
import { httpErrorTypes } from 'utility/error';
import { handleDownloadFile, getFileName } from 'utility/downloadUtility';
import { CONTENT_TYPE } from 'Constants/headers';
import { originalAdviceDefaultFileName } from 'Constants/defaultFileNames';
import i18n from 'i18n';

export const LOAD_ORIGINAL_ADVICE_REQUEST = 'LOAD_ORIGINAL_ADVICE_REQUEST';
export const LOAD_ORIGINAL_ADVICE_SUCCESS = 'LOAD_ORIGINAL_ADVICE_SUCCESS';
export const LOAD_ORIGINAL_ADVICE_FAILURE = 'LOAD_ORIGINAL_ADVICE_FAILURE';
export const CLEAR_DOWNLOAD_ORIGINAL_ADVICE_EMAIL_STATE = 'CLEAR_DOWNLOAD_ORIGINAL_ADVICE_EMAIL_STATE';
export const DOWNLOAD_ORIGINAL_ADVICE_EMAIL_REQUEST = 'DOWNLOAD_ORIGINAL_ADVICE_EMAIL_REQUEST';
export const DOWNLOAD_ORIGINAL_ADVICE_EMAIL_SUCCESS = 'DOWNLOAD_ORIGINAL_ADVICE_EMAIL_SUCCESS';
export const DOWNLOAD_ORIGINAL_ADVICE_EMAIL_FAILURE = 'DOWNLOAD_ORIGINAL_ADVICE_EMAIL_FAILURE';

const handleDownloadOriginalAdviceEmail = () => (dispatch, getState) => {
  const exportData = getState()?.originalAdvice?.exportData;
  if (exportData?.data) {
    const type = exportData.request.getResponseHeader(CONTENT_TYPE);
    const fileName = getFileName(exportData, originalAdviceDefaultFileName);
    handleDownloadFile(exportData.data, fileName, type);
    return dispatch(clearDownloadOriginalAdviceEmailState());
  }
};

export const clearDownloadOriginalAdviceEmailState = () => ({ type: CLEAR_DOWNLOAD_ORIGINAL_ADVICE_EMAIL_STATE });

export const loadOriginalAdvice = emailQueueID => ({
  types: [LOAD_ORIGINAL_ADVICE_REQUEST, LOAD_ORIGINAL_ADVICE_SUCCESS, LOAD_ORIGINAL_ADVICE_FAILURE],
  callAPI: (state, { customerId, userId }) =>
    api.fetch(
      `remittanceadvicesvc/v0/cust/${customerId}/user/${userId}/original-remittance-advice/${emailQueueID}/get-email`
    ),
  httpErrorTypes
});

export const downloadOriginalAdviceEmail = emailQueueID => ({
  types: [
    DOWNLOAD_ORIGINAL_ADVICE_EMAIL_REQUEST,
    DOWNLOAD_ORIGINAL_ADVICE_EMAIL_SUCCESS,
    DOWNLOAD_ORIGINAL_ADVICE_EMAIL_FAILURE
  ],
  callAPI: (state, { customerId, userId }) =>
    api.fetchFileWithGet(
      `remittanceadvicesvc/v0/cust/${customerId}/user/${userId}/original-remittance-advice/${emailQueueID}/get-email-attachments`
    ),
  dispatchOnSuccess: [handleDownloadOriginalAdviceEmail],
  httpErrorTypes,
  errorDisplayType: 'modal',
  errorTitle: i18n.t('originalAdvice.errors.unableToExport')
});
