import { statusCodeToMessage } from 'utility/error';

import {
  LOAD_PAYMENT_INFORMATION_REQUEST,
  LOAD_PAYMENT_INFORMATION_SUCCESS,
  LOAD_PAYMENT_INFORMATION_FAILURE,
  LOAD_REMITTANCE_INFORMATION_REQUEST,
  LOAD_REMITTANCE_INFORMATION_SUCCESS,
  LOAD_REMITTANCE_INFORMATION_FAILURE,
  LOAD_TRANSACTIONDETAILS_REQUEST,
  LOAD_TRANSACTIONDETAILS_SUCCESS,
  LOAD_TRANSACTIONDETAILS_FAILURE,
  LOAD_WORKFLOW_DETAILS_REQUEST,
  LOAD_WORKFLOW_DETAILS_SUCCESS,
  LOAD_WORKFLOW_DETAILS_FAILURE,
  LOAD_WORKFLOW_DETAILS_FORM_REQUEST,
  LOAD_WORKFLOW_DETAILS_FORM_SUCCESS,
  LOAD_WORKFLOW_DETAILS_FORM_FAILURE,
  ADD_ADVICES_TO_TRANSACTION_REQUEST,
  ADD_ADVICES_TO_TRANSACTION_SUCCESS,
  ADD_ADVICES_TO_TRANSACTION_FAILURE,
  ADD_INVOICES_TO_TRANSACTION_REQUEST,
  ADD_INVOICES_TO_TRANSACTION_SUCCESS,
  ADD_INVOICES_TO_TRANSACTION_FAILURE,
  ASSIGN_TRANSACTION_REQUEST,
  ASSIGN_TRANSACTION_SUCCESS,
  ASSIGN_TRANSACTION_FAILURE,
  LOAD_OPEN_ADVICES_AND_INVOICES_REQUEST,
  LOAD_OPEN_ADVICES_AND_INVOICES_SUCCESS,
  LOAD_OPEN_ADVICES_AND_INVOICES_FAILURE,
  RESET_TRANSACTIONDETAILS,
  LOAD_TRANSACTION_NOTES_REQUEST,
  LOAD_TRANSACTION_NOTES_SUCCESS,
  LOAD_TRANSACTION_NOTES_FAILURE,
  UPDATE_TRANSACTION_NOTE_REQUEST,
  UPDATE_TRANSACTION_NOTE_SUCCESS,
  UPDATE_TRANSACTION_NOTE_FAILURE,
  UPDATE_WORKFLOW_DETAILS_REQUEST,
  UPDATE_WORKFLOW_DETAILS_SUCCESS,
  UPDATE_WORKFLOW_DETAILS_FAILURE,
  UPLOAD_REMITTANCE_FILE_REQUEST,
  UPLOAD_REMITTANCE_FILE_SUCCESS,
  UPLOAD_REMITTANCE_FILE_FAILURE,
  DOWNLOAD_TEMPLATE_FILE_REQUEST,
  DOWNLOAD_TEMPLATE_FILE_SUCCESS,
  DOWNLOAD_TEMPLATE_FILE_FAILURE,
  SAVE_DATA_REQUEST,
  SAVE_DATA_SUCCESS,
  SAVE_DATA_FAILURE,
  COMPLETE_DATA_FAILURE,
  TAKE_OVER_TRANSACTION,
  CHECK_TRANSACTION_IN_PROGRESS_REQUEST,
  CHECK_TRANSACTION_IN_PROGRESS_SUCCESS,
  CHECK_TRANSACTION_IN_PROGRESS_FAILURE,
  CANCEL_EDIT_TRANSACTION_REQUEST,
  CANCEL_EDIT_TRANSACTION_SUCCESS,
  CANCEL_EDIT_TRANSACTION_FAILURE,
  GPX_ACTION_ITEM_REQUEST,
  GPX_ACTION_ITEM_SUCCESS,
  GPX_ACTION_ITEM_FAILURE,
  DELETE_TRANSACTION_NOTE_REQUEST,
  DELETE_TRANSACTION_NOTE_SUCCESS,
  DELETE_TRANSACTION_NOTE_FAILURE,
  DISASSOCIATE_ADVICES_REQUEST,
  DISASSOCIATE_ADVICES_SUCCESS,
  DISASSOCIATE_ADVICES_FAILURE,
  LOAD_INVOICE_FIELDS_REQUEST,
  LOAD_INVOICE_FIELDS_SUCCESS,
  LOAD_INVOICE_FIELDS_FAILURE,
  LOAD_INVOICES_REQUEST,
  LOAD_INVOICES_SUCCESS,
  LOAD_INVOICES_FAILURE,
  RESET_OPEN_INVOICES,
  DEACTIVATE_INVOICES_REQUEST,
  DEACTIVATE_INVOICES_SUCCESS,
  DEACTIVATE_INVOICES_FAILURE,
  UPDATE_TYPE_CODE_REQUEST,
  UPDATE_TYPE_CODE_SUCCESS,
  UPDATE_TYPE_CODE_FAILURE
} from './transactionDetailsActions';

export const initialState = {
  data: {},
  assignTransactionState: {},
  openAdvicesAndInvoicesState: {},
  openInvoicesState: {},
  paymentInformationRequestState: {},
  remittanceInformationRequestState: {},
  workflowDetailsState: {},
  workflowDetailsFormState: {},
  updateWorkflowDetailsState: {}
};

const assignTransactionRequest = (state, action) => ({
  ...state,
  assignTransactionState: {
    assigningTransaction: true,
    errorAssigningTransaction: undefined
  }
});

const assignTransactionSuccess = (state, action) => ({
  ...state,
  assignTransactionState: {
    ...action.response.data,
    assigningTransaction: false,
    errorAssigningTransaction: undefined
  }
});

const assignTransactionFailure = (state, action) => ({
  ...state,
  assignTransactionState: {
    assigningTransaction: false,
    errorAssigningTransaction: action.error
  }
});

const loadPaymentInformationRequest = (state, action) => {
  return {
    ...state,
    paymentInformationRequestState: {
      isFetchingPaymentInformation: true,
      paymentInformationError: undefined
    }
  };
};

const loadPaymentInformationSuccess = (state, action) => {
  return {
    ...state,
    paymentInformationRequestState: {
      isFetchingPaymentInformation: false,
      paymentInformationError: undefined
    },
    data: {
      ...state.data,
      paymentInformation: {
        ...action.response.data?.paymentInformation
      }
    }
  };
};

const loadPaymentInformationFailure = (state, action) => {
  return {
    ...state,
    paymentInformationRequestState: {
      isFetchingPaymentInformation: false,
      paymentInformationError: action.error
    }
  };
};

const loadRemittanceInformationRequest = (state, action) => {
  return {
    ...state,
    isProcessing: true,
    remittanceInformationRequestState: {
      isFetchingRemittanceInformation: true,
      remittanceInformationError: undefined
    }
  };
};

const loadRemittanceInformationSuccess = (state, action) => {
  return {
    ...state,
    isProcessing: false,
    remittanceInformationRequestState: {
      isFetchingRemittanceInformation: false,
      remittanceInformationError: undefined
    },
    data: {
      ...state.data,
      transactionDetails: {
        ...state.data.transactionDetails,
        lastUpdatedBy: action.response.data.lastUpdatedBy,
        lastUpdatedOn: action.response.data.lastUpdatedOn,
        canSaveDocTypeCode: action.response.data.canSaveDocTypeCode
      },
      remittanceDetails: {
        ...state.data.remittanceDetails,
        ...action.response.data,
        displayLastUpdateInfo: action.response.data.displayLastUpdateInfo,
        invoiceStatusHistRows: action.response.data.invoiceStatusHistRows
      }
    }
  };
};

const loadRemittanceInformationFailure = (state, action) => {
  return {
    ...state,
    remittanceInformationRequestState: {
      isFetchingRemittanceInformation: false,
      remittanceInformationError: action.error
    },
    isProcessing: false
  };
};

const loadTransactionDetailsRequest = (state, action) => {
  return {
    ...state,
    isFetching: true
  };
};

const loadTransactionDetailsSuccess = (state, action) => {
  return {
    ...state,
    ...action.response,
    ...action.params,
    isFetching: false
  };
};

const loadTransactionDetailsFailure = (state, action) => {
  return {
    ...state,
    ...action,
    isFetching: false
  };
};

const loadWorkflowDetailsRequest = (state, action) => ({
  ...state,
  workflowDetailsState: {
    fetchingWorkflowDetails: true,
    errorFetchingWorkflowDetails: undefined
  }
});

const loadWorkflowDetailsSuccess = (state, action) => ({
  ...state,
  workflowDetailsState: {
    ...action.response.data,
    fetchingWorkflowDetails: false,
    errorFetchingWorkflowDetails: undefined
  }
});

const loadWorkflowDetailsFailure = (state, action) => ({
  ...state,
  workflowDetailsState: {
    fetchingWorkflowDetails: false,
    errorFetchingWorkflowDetails: action.error
  }
});

const loadWorkflowDetailsFormRequest = (state, action) => ({
  ...state,
  workflowDetailsFormState: {
    fetchingWorkflowDetailsForm: true,
    errorFetchingWorkflowDetailsForm: undefined
  }
});

const loadWorkflowDetailsFormSuccess = (state, action) => ({
  ...state,
  workflowDetailsFormState: {
    ...action.response.data,
    fetchingWorkflowDetailsForm: false,
    errorFetchingWorkflowDetailsForm: undefined
  }
});

const loadWorkflowDetailsFormFailure = (state, action) => ({
  ...state,
  workflowDetailsFormState: {
    fetchingWorkflowDetailsForm: false,
    errorFetchingWorkflowDetailsForm: action.error
  }
});

const updateWorkflowDetailsRequest = (state, action) => ({
  ...state,
  updateWorkflowDetailsState: {
    updatingWorkflowDetails: true,
    errorUpdatingWorkflowDetails: undefined
  }
});

const updateWorkflowDetailsSuccess = (state, action) => ({
  ...state,
  updateWorkflowDetailsState: {
    ...action.response.data,
    updatingWorkflowDetails: false,
    errorUpdatingWorkflowDetails: undefined
  }
});

const updateWorkflowDetailsFailure = (state, action) => ({
  ...state,
  updateWorkflowDetailsState: {
    updatingWorkflowDetails: false,
    errorUpdatingWorkflowDetails: action.error
  }
});

const resetTransactionDetails = (state, action) => {
  return {
    ...initialState
  };
};

const loadTransactionNotesRequest = (state, action) => {
  return {
    ...state,
    isFetchingNotes: true
  };
};

const loadTransactionNotesSuccess = (state, action) => {
  return {
    ...state,
    ...action.response.data,
    isFetchingNotes: false
  };
};

const loadTransactionsNotesFailure = (state, action) => {
  return {
    ...state,
    transactionNotesErrors: action.error,
    isFetchingNotes: false
  };
};

const addAdvicesToTransactionRequest = (state, action) => ({
  ...state,
  isProcessing: true,
  remittanceInformationRequestState: {
    isFetchingRemittanceInformation: true,
    remittanceInformationError: undefined
  },
  isAssociatingAdvices: true,
  associatedAdvicesMsg: undefined,
  associatedAdvicesError: undefined
});

const addAdvicesToTransactionSuccess = (state, action) => ({
  ...state,
  isProcessing: false,
  remittanceInformationRequestState: {
    isFetchingRemittanceInformation: false,
    remittanceInformationError: undefined
  },
  isAssociatingAdvices: false,
  associatedAdvicesError: undefined,
  associatedAdvicesMsg: action.response
});

const addAdvicesToTransactionFailure = (state, action) => ({
  ...state,
  isProcessing: false,
  remittanceInformationRequestState: {
    isFetchingRemittanceInformation: false,
    remittanceInformationError: undefined
  },
  isAssociatingAdvices: false,
  associatedAdvicesMsg: undefined,
  associatedAdvicesError: action.error
});

const addInvoicesToTransactionRequest = (state, action) => ({
  ...state,
  isAssociatingAdvices: true,
  associatedAdvicesMsg: undefined,
  associatedAdvicesError: undefined
});

const addInvoicesToTransactionSuccess = (state, action) => ({
  ...state,
  isAssociatingAdvices: false,
  associatedAdvicesError: undefined,
  associatedAdvicesMsg: action.response
});

const addInvoicesToTransactionFailure = (state, action) => ({
  ...state,
  isAssociatingAdvices: false,
  associatedAdvicesMsg: undefined,
  associatedAdvicesError: action.error
});

const updateTransactionNoteRequest = (state, action) => {
  return {
    ...state,
    isUpdatingNotes: true
  };
};

const updateTransactionNoteSuccess = (state, action) => {
  return {
    ...state,
    ...action.response.data,
    isUpdatingNotes: false
  };
};

const updateTransactionNoteFailure = (state, action) => {
  return {
    ...state,
    isUpdatingNotes: false
  };
};

const uploadRemittanceFileRequest = (state, action) => {
  return {
    ...state,
    isProcessing: true,
    uploadData: {}
  };
};

const uploadRemittanceFileSuccess = (state, action) => {
  return {
    ...state,
    uploadData: { ...action.response.data },
    uploadDataFlag: true,
    irSaveDataFlag: false,
    isProcessing: false
  };
};

const uploadRemittanceFileFailure = (state, action) => {
  return {
    ...state,
    uploadDataFlag: false,
    isProcessing: false
  };
};

const downloadTemplateFileRequest = (state, action) => {
  return {
    ...state,
    isFetchingTemplate: true,
    templateData: ''
  };
};

const downloadTemplateFileSuccess = (state, action) => {
  return {
    ...state,
    isFetchingTemplate: false,
    templateData: action.response
  };
};

const downloadTemplateFileFailure = (state, action) => {
  return {
    ...state,
    isFetchingTemplate: false,
    templateData: ''
  };
};

const saveDataRequest = (state, action) => {
  return {
    ...state,
    dataSaveFlag: false,
    isProcessing: true,
    uploadDataFlag: false,
    irSaveDataFlag: false
  };
};

const saveDataSuccess = (state, action) => {
  const payload = JSON.parse(action.response.config.data);
  const irEnabled = state.data.transactionDetails.irEnabled;
  return {
    ...state,
    isProcessing: false,
    dataSaveFlag: (payload.action === 'complete' && irEnabled) || (payload.action && !irEnabled),
    uploadData: irEnabled && { ...action.response.data },
    irSaveDataFlag: irEnabled,
    data: {
      ...state.data,
      transactionDetails: {
        ...state.data.transactionDetails,
        lastUpdatedBy: action.response.data.lastUpdatedBy,
        lastUpdatedOn: action.response.data.lastUpdatedOn,
        canSaveDocTypeCode: action.response.data.canSaveDocTypeCode,
        supplementalDataEntryStatus: action.response.data.supplementalDataEntryStatus
      },
      remittanceDetails: {
        ...state.data.remittanceDetails,
        displayLastUpdateInfo: action.response.data.displayLastUpdateInfo,
        deactivatedTotal: action.response.data.deactivatedTotal,
        forceBalanceRowNumber: action.response.data.forceBalanceRowNumber,
        remittancesList: action.response.data.remittancesList,
        invoiceStatusHistRows: action.response.data.invoiceStatusHistRows
      }
    }
  };
};

const saveDataFailure = (state, action) => {
  return {
    ...state,
    ...action,
    dataSaveFlag: false,
    isProcessing: false
  };
};

const completeDataFailure = (state, action) => {
  return {
    ...state,
    ...action,
    isProcessing: false
  };
};

const takeOverTransaction = (state, action) => {
  return {
    ...state,
    transactionProgressRes: {}
  };
};

const checkTransactionInProgressRequest = (state, action) => {
  return {
    ...state,
    isProcessing: true,
    transactionProgressRes: {}
  };
};

const loadOpenAdvicesAndInvoicesRequest = (state, action) => ({
  ...state,
  openAdvicesAndInvoicesState: {
    openAdvicesAndInvoicesErrors: false,
    openAdvicesAndInvoices: undefined,
    isFetchingOpenAdvicesAndInvoices: true
  }
});

const loadOpenAdvicesAndInvoicesSuccess = (state, action) => {
  return {
    ...state,
    openAdvicesAndInvoicesState: {
      ...action.response.data,
      openAdvicesAndInvoicesErrors: false,
      isFetchingOpenAdvicesAndInvoices: false
    }
  };
};

const loadOpenAdvicesAndInvoicesFailure = (state, action) => ({
  ...state,
  errors: { ...action.error },
  openAdvicesAndInvoicesState: {
    openAdvicesAndInvoicesErrors: action.error,
    isFetchingOpenAdvicesAndInvoices: false
  }
});

const checkTransactionInProgressSuccess = (state, action) => {
  const response = action.response.data;
  const supplementalDataEntryStatus = Number(response)
    ? '' + response
    : state.data.transactionDetails.supplementalDataEntryStatus;
  return {
    ...state,
    isProcessing: false,
    data: { ...state.data, transactionDetails: { ...state.data.transactionDetails, supplementalDataEntryStatus } },
    transactionProgressRes: Number(response) ? '' : response
  };
};

const checkTransactionInProgressFailure = (state, { error }) => {
  return {
    ...state,
    isProcessing: false
  };
};

const cancelEditTransactionRequest = (state, action) => {
  return {
    ...state,
    isProcessing: true,
    remittanceInformationRequestState: {
      isFetchingRemittanceInformation: true,
      remittanceInformationError: undefined
    }
  };
};

const cancelEditTransactionSuccess = (state, action) => {
  return {
    ...state,
    isProcessing: false,
    remittanceInformationRequestState: {
      isFetchingRemittanceInformation: false,
      remittanceInformationError: undefined
    },
    data: {
      ...state.data,
      transactionDetails: {
        ...state.data.transactionDetails,
        lastUpdatedBy: action.response.data.lastUpdatedBy,
        lastUpdatedOn: action.response.data.lastUpdatedOn,
        supplementalDataEntryStatus: action.response.data.supplementalDataEntryStatus
      },
      remittanceDetails: {
        ...state.data.remittanceDetails,
        deactivatedTotal: action.response.data.deactivatedTotal,
        displayLastUpdateInfo: action.response.data.displayLastUpdateInfo,
        displayIrRefItems: action.response.data.displayIrRefItems,
        displayLegacyInvoiceMatching: action.response.data.displayLegacyInvoiceMatching,
        errorMessage: action.response.data.errorMessage,
        forceBalanceRowNumber: action.response.data.forceBalanceRowNumber,
        remittancesList: action.response.data.remittancesList,
        invoiceStatusHistRows: action.response.data.invoiceStatusHistRows
      }
    }
  };
};

const cancelEditTransactionFailure = (state, action) => {
  return {
    ...state,
    remittanceInformationRequestState: {
      isFetchingRemittanceInformation: false,
      remittanceInformationError: action.error
    },
    isProcessing: false
  };
};

const gpxActionItemRequest = (state, action) => {
  return {
    ...state,
    gpxActionLoading: true
  };
};
const gpxActionItemSuccess = (state, action) => {
  return {
    ...state,
    gpxActionLoading: false
  };
};
const gpxActionItemFailure = (state, { error }) => {
  return {
    ...state,
    gpxActionLoading: false
  };
};

const deleteTransactionNoteRequest = (state, action) => {
  return {
    ...state
  };
};
const deleteTransactionNoteSuccess = (state, action) => {
  return {
    ...state
  };
};
const deleteTransactionNoteFailure = (state, { error }) => {
  return {
    ...state,
    errors: { ...error }
  };
};

const disassociateAdvicesRequest = (state, action) => {
  return {
    ...state,
    isProcessing: true,
    remittanceInformationRequestState: {
      isFetchingRemittanceInformation: true,
      remittanceInformationError: undefined
    },
    isDisassociateFetching: true
  };
};
const disassociateAdvicesSuccess = (state, action) => {
  return {
    ...state,
    isProcessing: false,
    remittanceInformationRequestState: {
      isFetchingRemittanceInformation: false,
      remittanceInformationError: undefined
    },
    isDisassociateFetching: false
  };
};
const disassociateAdvicesFailure = (state, action) => {
  return {
    ...state,
    errors: { ...action.error },
    isProcessing: false,
    remittanceInformationRequestState: {
      isFetchingRemittanceInformation: false,
      remittanceInformationError: undefined
    },
    isDisassociateFetching: false
  };
};

const loadInvoiceFieldsRequest = (state, action) => {
  return {
    ...state,
    invoicesFields: false,
    isInvoicesFieldsFetching: true
  };
};
const loadInvoiceFieldsSuccess = (state, action) => {
  return {
    ...state,
    invoicesFields: action.response.data,
    isInvoicesFieldsFetching: false
  };
};
const loadInvoiceFieldsFailure = (state, action) => {
  return {
    ...state,
    invoicesFields: false,
    errors: { ...action.error },
    isInvoicesFieldsFetching: false
  };
};

const loadInvoicesRequest = (state, action) => ({
  ...state,
  openInvoicesState: {
    invoicesErrors: false,
    openAdvicesAndInvoices: undefined,
    isFetchingOpenInvoices: true
  }
});
const loadInvoicesSuccess = (state, action) => {
  const { grandTotal, invoicesData } = action.response.data;
  return {
    ...state,
    openInvoicesState: {
      ...action.response.data,
      invoicesErrors: false,
      isFetchingOpenInvoices: false
    }
  };
};
const loadInvoicesFailure = (state, action) => {
  return {
    ...state,
    errors: { ...action.error },
    openInvoicesState: {
      invoicesErrors: action.error,
      isFetchingOpenInvoices: false
    }
  };
};

const deactivateInvoicesRequest = (state, action) => ({
  ...state,
  isDisassociateFetching: true
});
const deactivateInvoicesSuccess = (state, action) => {
  return {
    ...state,
    isDisassociateFetching: false
  };
};
const deactivateInvoicesFailure = (state, action) => {
  return {
    ...state,
    errors: { ...action.error },
    isDisassociateFetching: false
  };
};

const updateCodeRequest = (state, action) => ({
  ...state,
  isDocCodeTypeSuccess: false,
  isFetchingCode: true
});
const updateCodeSuccess = (state, action) => {
  return {
    ...state,
    isDocCodeTypeSuccess: true,
    isFetchingCode: false
  };
};
const updateCodeFailure = (state, action) => {
  return {
    ...state,
    isDocCodeTypeSuccess: false,
    errors: { ...action.error },
    isFetchingCode: false
  };
};

const resetOpenInvoicesData = (state, action) => {
  return {
    ...state,
    openInvoicesState: {}
  };
};

export default function transactionDetailsReducer(state = initialState, action) {
  const handlers = {
    [LOAD_PAYMENT_INFORMATION_REQUEST]: loadPaymentInformationRequest,
    [LOAD_PAYMENT_INFORMATION_SUCCESS]: loadPaymentInformationSuccess,
    [LOAD_PAYMENT_INFORMATION_FAILURE]: loadPaymentInformationFailure,
    [LOAD_REMITTANCE_INFORMATION_REQUEST]: loadRemittanceInformationRequest,
    [LOAD_REMITTANCE_INFORMATION_SUCCESS]: loadRemittanceInformationSuccess,
    [LOAD_REMITTANCE_INFORMATION_FAILURE]: loadRemittanceInformationFailure,
    [LOAD_TRANSACTIONDETAILS_REQUEST]: loadTransactionDetailsRequest,
    [LOAD_TRANSACTIONDETAILS_SUCCESS]: loadTransactionDetailsSuccess,
    [LOAD_TRANSACTIONDETAILS_FAILURE]: loadTransactionDetailsFailure,
    [LOAD_WORKFLOW_DETAILS_REQUEST]: loadWorkflowDetailsRequest,
    [LOAD_WORKFLOW_DETAILS_SUCCESS]: loadWorkflowDetailsSuccess,
    [LOAD_WORKFLOW_DETAILS_FAILURE]: loadWorkflowDetailsFailure,
    [LOAD_WORKFLOW_DETAILS_FORM_REQUEST]: loadWorkflowDetailsFormRequest,
    [LOAD_WORKFLOW_DETAILS_FORM_SUCCESS]: loadWorkflowDetailsFormSuccess,
    [LOAD_WORKFLOW_DETAILS_FORM_FAILURE]: loadWorkflowDetailsFormFailure,
    [RESET_TRANSACTIONDETAILS]: resetTransactionDetails,
    [ADD_ADVICES_TO_TRANSACTION_REQUEST]: addAdvicesToTransactionRequest,
    [ADD_ADVICES_TO_TRANSACTION_SUCCESS]: addAdvicesToTransactionSuccess,
    [ADD_ADVICES_TO_TRANSACTION_FAILURE]: addAdvicesToTransactionFailure,
    [ADD_INVOICES_TO_TRANSACTION_REQUEST]: addInvoicesToTransactionRequest,
    [ADD_INVOICES_TO_TRANSACTION_SUCCESS]: addInvoicesToTransactionSuccess,
    [ADD_INVOICES_TO_TRANSACTION_FAILURE]: addInvoicesToTransactionFailure,
    [ASSIGN_TRANSACTION_REQUEST]: assignTransactionRequest,
    [ASSIGN_TRANSACTION_SUCCESS]: assignTransactionSuccess,
    [ASSIGN_TRANSACTION_FAILURE]: assignTransactionFailure,
    [LOAD_TRANSACTION_NOTES_REQUEST]: loadTransactionNotesRequest,
    [LOAD_TRANSACTION_NOTES_SUCCESS]: loadTransactionNotesSuccess,
    [LOAD_TRANSACTION_NOTES_FAILURE]: loadTransactionsNotesFailure,
    [LOAD_OPEN_ADVICES_AND_INVOICES_REQUEST]: loadOpenAdvicesAndInvoicesRequest,
    [LOAD_OPEN_ADVICES_AND_INVOICES_SUCCESS]: loadOpenAdvicesAndInvoicesSuccess,
    [LOAD_OPEN_ADVICES_AND_INVOICES_FAILURE]: loadOpenAdvicesAndInvoicesFailure,
    [UPDATE_TRANSACTION_NOTE_REQUEST]: updateTransactionNoteRequest,
    [UPDATE_TRANSACTION_NOTE_SUCCESS]: updateTransactionNoteSuccess,
    [UPDATE_TRANSACTION_NOTE_FAILURE]: updateTransactionNoteFailure,
    [UPLOAD_REMITTANCE_FILE_REQUEST]: uploadRemittanceFileRequest,
    [UPLOAD_REMITTANCE_FILE_SUCCESS]: uploadRemittanceFileSuccess,
    [UPLOAD_REMITTANCE_FILE_FAILURE]: uploadRemittanceFileFailure,
    [UPDATE_WORKFLOW_DETAILS_REQUEST]: updateWorkflowDetailsRequest,
    [UPDATE_WORKFLOW_DETAILS_SUCCESS]: updateWorkflowDetailsSuccess,
    [UPDATE_WORKFLOW_DETAILS_FAILURE]: updateWorkflowDetailsFailure,
    [DOWNLOAD_TEMPLATE_FILE_REQUEST]: downloadTemplateFileRequest,
    [DOWNLOAD_TEMPLATE_FILE_SUCCESS]: downloadTemplateFileSuccess,
    [DOWNLOAD_TEMPLATE_FILE_FAILURE]: downloadTemplateFileFailure,
    [SAVE_DATA_REQUEST]: saveDataRequest,
    [SAVE_DATA_SUCCESS]: saveDataSuccess,
    [SAVE_DATA_FAILURE]: saveDataFailure,
    [COMPLETE_DATA_FAILURE]: completeDataFailure,
    [TAKE_OVER_TRANSACTION]: takeOverTransaction,
    [CHECK_TRANSACTION_IN_PROGRESS_REQUEST]: checkTransactionInProgressRequest,
    [CHECK_TRANSACTION_IN_PROGRESS_SUCCESS]: checkTransactionInProgressSuccess,
    [CHECK_TRANSACTION_IN_PROGRESS_FAILURE]: checkTransactionInProgressFailure,
    [CANCEL_EDIT_TRANSACTION_REQUEST]: cancelEditTransactionRequest,
    [CANCEL_EDIT_TRANSACTION_SUCCESS]: cancelEditTransactionSuccess,
    [CANCEL_EDIT_TRANSACTION_FAILURE]: cancelEditTransactionFailure,
    [GPX_ACTION_ITEM_REQUEST]: gpxActionItemRequest,
    [GPX_ACTION_ITEM_SUCCESS]: gpxActionItemSuccess,
    [GPX_ACTION_ITEM_FAILURE]: gpxActionItemFailure,
    [DELETE_TRANSACTION_NOTE_REQUEST]: deleteTransactionNoteRequest,
    [DELETE_TRANSACTION_NOTE_SUCCESS]: deleteTransactionNoteSuccess,
    [DELETE_TRANSACTION_NOTE_FAILURE]: deleteTransactionNoteFailure,
    [DISASSOCIATE_ADVICES_REQUEST]: disassociateAdvicesRequest,
    [DISASSOCIATE_ADVICES_SUCCESS]: disassociateAdvicesSuccess,
    [DISASSOCIATE_ADVICES_FAILURE]: disassociateAdvicesFailure,
    [LOAD_INVOICE_FIELDS_REQUEST]: loadInvoiceFieldsRequest,
    [LOAD_INVOICE_FIELDS_SUCCESS]: loadInvoiceFieldsSuccess,
    [LOAD_INVOICE_FIELDS_FAILURE]: loadInvoiceFieldsFailure,
    [LOAD_INVOICES_REQUEST]: loadInvoicesRequest,
    [LOAD_INVOICES_SUCCESS]: loadInvoicesSuccess,
    [LOAD_INVOICES_FAILURE]: loadInvoicesFailure,
    [DEACTIVATE_INVOICES_REQUEST]: deactivateInvoicesRequest,
    [DEACTIVATE_INVOICES_SUCCESS]: deactivateInvoicesSuccess,
    [DEACTIVATE_INVOICES_FAILURE]: deactivateInvoicesFailure,
    [UPDATE_TYPE_CODE_REQUEST]: updateCodeRequest,
    [UPDATE_TYPE_CODE_SUCCESS]: updateCodeSuccess,
    [UPDATE_TYPE_CODE_FAILURE]: updateCodeFailure,
    [RESET_OPEN_INVOICES]: resetOpenInvoicesData
  };
  return handlers.hasOwnProperty(action.type) ? handlers[action.type](state, action) : state;
}
