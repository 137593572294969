import {
  USER_BATCH_OWNER_REQUEST,
  USER_BATCH_OWNER_SUCCESS,
  USER_BATCH_OWNER_FAILURE,
  LOAD_CAPTUREDETAILS_REQUEST,
  LOAD_CAPTUREDETAILS_SUCCESS,
  RESET_CAPTUREDETAILS,
  LOAD_CAPTUREDETAILS_FAILURE,
  LOAD_ITEMDETAIL_REQUEST,
  LOAD_ITEMDETAIL_SUCCESS,
  LOAD_ITEMDETAIL_FAILURE,
  SAVE_ITEMDETAIL_REQUEST,
  SAVE_ITEMDETAIL_SUCCESS,
  SAVE_ITEMDETAIL_FAILURE,
  UPDATE_BALANCE_REQUEST,
  UPDATE_BALANCE_SUCCESS,
  UPDATE_BALANCE_FAILURE,
  SUBMIT_BATCHES_DETAIL_REQUEST,
  SUBMIT_BATCHES_DETAIL_SUCCESS,
  SUBMIT_BATCHES_DETAIL_FAILURE,
  DELETE_TRANSACTION_REQUEST,
  DELETE_TRANSACTION_SUCCESS,
  DELETE_TRANSACTION_FAILURE,
  CLEAR_DELETE_TRANSACTION_ITEM,
  DOWNLOAD_PDF_REQUEST,
  DOWNLOAD_PDF_SUCCESS,
  DOWNLOAD_PDF_FAILURE,
  CLEAR_CAPTURE_DETAIL_ERROR,
  CLEAR_PDF_DATA,
  CLEAR_SUBMIT_BATCH,
  HANDLE_SCAN_OPERATION,
  SET_CAN_SCAN,
  RESCAN_ITEM_REQUEST,
  RESCAN_ITEM_SUCCESS,
  RESCAN_ITEM_FAILURE,
  CLEAR_SCAN_RESPONSE,
  SCAN_MORE_FAILURE,
  SCAN_MORE_REQUEST,
  SCAN_MORE_SUCCESS,
  CONVERT_TRANSACTION_ITEM_REQUEST,
  CONVERT_TRANSACTION_ITEM_SUCCESS,
  CONVERT_TRANSACTION_ITEM_FAIILURE,
  MARK_AS_NEW_TRANSACTION_REQUEST,
  MARK_AS_NEW_TRANSACTION_SUCCESS,
  MARK_AS_NEW_TRANSACTION_FAILURE,
  UNMARK_AS_NEW_TRANSACTION_REQUEST,
  UNMARK_AS_NEW_TRANSACTION_SUCCESS,
  UNMARK_AS_NEW_TRANSACTION_FAILURE,
  SET_CAPTURE_DETAILS_ERROR,
  captureDetailsErrorTypes
} from '../actions/capturedetailActions';

export const initialState = {
  deletedTransactionItem: {},
  error: {},
  itemDetails: {},
  transactionlistResponse: {},
  submittedBatch: false,
  canScan: true,
  scanEvent: {},
  scanResponse: {},
  completedScan: false,
  shouldLoadItemDetails: true,
  loadItemCallsWaiting: 0
};

const DOCUMENT_TYPE = 'Document';

function userBatchOwnerRequest(state, action) {
  return state;
}
function userBatchOwnerSuccess(state, action) {
  return state;
}
function userBatchOwnerFailure(state, action) {
  return Object.assign({}, state, { error: action.error, scanEvent: {} });
}
function captureDetailsRequest(state, action) {
  return { ...state, isFetching: true, shouldLoadItemDetails: true };
}
function captureDetailsSuccess(state, action) {
  action.response.data.transactionList = action.response.data.transactionList.map(transaction => {
    if (transaction.type === DOCUMENT_TYPE) {
      const { amount, ...rest } = transaction;
      return rest;
    }
    return transaction;
  });
  return Object.assign(
    {},
    state,
    { transactionlistResponse: action.response.data, itemDetails: {} },
    { isFetching: false }
  );
}
function captureDetailsFailure(state, action) {
  return Object.assign({}, state, { error: action.error });
}
function resetCaptureDetails(state, action) {
  return initialState;
}

function loadItemDetailRequest(state, action) {
  return Object.assign({}, state, {
    actionedUponItemId: undefined,
    itemDetails: { isFetching: true },
    multiError: undefined,
    checkRefMultiError: undefined,
    loadItemCallsWaiting: state.loadItemCallsWaiting + 1
  });
}
function loadItemDetailSuccess(state, action) {
  const isFetching = state.loadItemCallsWaiting > 1 ? true : false;

  return {
    ...state,
    itemDetails: { ...action.response.data, isFetching: isFetching },
    loadItemCallsWaiting: state.loadItemCallsWaiting - 1
  };
}
function loadItemDetailFailure(state, action) {
  const isFetching = state.loadItemCallsWaiting > 1 ? true : false;
  return Object.assign({}, state, {
    ...initialState,
    transactionlistResponse: state.transactionlistResponse,
    itemDetails: { isFetching: isFetching, unableToLoadDetails: true },
    error: action.error,
    loadItemCallsWaiting: state.loadItemCallsWaiting - 1
  });
}

function updateBalanceRequest(state, action) {
  return Object.assign({}, state, {
    balancing: { balancing: null, isFetching: true }
  });
}
function updateBalanceSuccess(state, action) {
  return Object.assign({}, state, {
    balancing: { balancing: action.response.data, isFetching: false }
  });
}
function updateBalanceFailure(state, action) {
  return Object.assign({}, state, {
    error: action.error,
    balancing: { isFetching: false }
  });
}

function deleteTransactionRequest(state, action) {
  return Object.assign({}, state, {});
}

function deleteTransactionSuccess(state, action) {
  return Object.assign({}, state, {
    deletedTransactionItem: { data: action.response.data, isFetching: false }
  });
}

function deleteTransactionFailure(state, action) {
  return Object.assign({}, state, { error: action.error });
}

function submitBatchDetailRequest(state, action) {
  return Object.assign({}, state, { submitBatch: null });
}
function submitBatchDetailSuccess(state, action) {
  return Object.assign({}, state, {
    submitBatch: action.response.data,
    submittedBatch: true
  });
}

function submitBatchDetailFailure(state, action) {
  return Object.assign({}, state, { error: action.error });
}

function downloadPdfRequest(state, action) {
  return Object.assign({}, state, {
    isProcessing: true,
    pdfError: null,
    pdfData: null
  });
}
function downloadPdfSuccess(state, action) {
  return Object.assign({}, state, {
    pdfData: action.pdfData,
    isProcessing: false
  });
}
function downloadPdfFailure(state, action) {
  return Object.assign({}, state, {
    error: action.error,
    isProcessing: false
  });
}

function saveItemDetailRequest(state, action) {
  return Object.assign({}, state, {
    saveItemDetailError: null,
    saveItemDetailResponse: null,
    multiError: {},
    checkRefMultiError: undefined
  });
}
function saveItemDetailSuccess(state, action) {
  return Object.assign({}, state, {
    saveItemDetailResponse: action.response.data,
    multiError: {},
    checkRefMultiError: undefined
  });
}
function saveItemDetailFailure(state, action) {
  if (action.error?.data?.errorMessage) {
    return Object.assign({}, state, {
      multiError: action.error.data.errorMessage,
      checkRefMultiError: action.error.data.checkRefErrorMessage
    });
  }

  return Object.assign({}, state, {
    error: action.error
  });
}
function clearCaptureDetailError(state, action) {
  return Object.assign({}, state, { error: {} });
}
function clearDeleteTransactionItem(state, action) {
  return Object.assign({}, state, { deletedTransactionItem: {} });
}

function clearPdfData(state, action) {
  return Object.assign({}, state, { pdfData: {} });
}

function clearSubmitBatch(state, action) {
  return Object.assign({}, state, { submitBatch: {} });
}
function handleScanOperation(state, action) {
  return Object.assign({}, state, {
    scanEvent: { action: action.scanOperation },
    ...action.args
  });
}
function rescanItemRequest(state, action) {
  return Object.assign({}, state, { shouldLoadItemDetails: false });
}
function rescanItemSuccess(state, action) {
  return Object.assign({}, state, {
    scanResponse: action.response.data,
    completedScan: true
  });
}
function rescanItemFailure(state, action) {
  return Object.assign({}, state, { error: action.error, completedScan: true });
}

function scanMoreRequest(state, action) {
  return Object.assign({}, state, { shouldLoadItemDetails: false });
}
function scanMoreSuccess(state, action) {
  return Object.assign({}, state, {
    scanResponse: action.response.data,
    completedScan: true
  });
}
function scanMorefailure(state, action) {
  return Object.assign({}, state, { error: action.error, completedScan: true });
}

function setCanScan(state, action) {
  return Object.assign({}, state, { canScan: action.canScan });
}
function clearScanResponse(state, action) {
  return Object.assign({}, state, { scanResponse: {}, completedScan: false });
}
function convertTransactionItemRequest(state, action) {
  return { ...state, actionedUponItemId: action.actionedUponItemId, shouldLoadItemDetails: false, isFetching: true };
}
function convertTransactionItemSuccess(state, action) {
  return { ...state, isFetching: false };
}
function convertTransactionItemFailure(state, action) {
  return {
    ...state,
    isFetching: false,
    shouldLoadItemDetails: action.error.type === captureDetailsErrorTypes.TRY_AGAIN,
    error: action.error
  };
}
function markAsNewTransactionRequest(state, action) {
  return { ...state, shouldLoadItemDetails: false, actionedUponItemId: action.actionedUponItemId, isFetching: true };
}
function markAsNewTransactionSuccess(state, action) {
  return { ...state, isFetching: false };
}
function markASNewTransactionFailure(state, action) {
  return {
    ...state,
    isFetching: false,
    shouldLoadItemDetails: action.error.type === captureDetailsErrorTypes.TRY_AGAIN,
    error: action.error
  };
}
function unmarkAsNewTransactionRequest(state, action) {
  return { ...state, shouldLoadItemDetails: false, actionedUponItemId: action.actionedUponItemId, isFetching: true };
}
function unmarkAsNewTransactionSuccess(state, action) {
  return { ...state, isFetching: false };
}
function unmarkASNewTransactionFailure(state, action) {
  return {
    ...state,
    isFetching: false,
    shouldLoadItemDetails: action.error.type === captureDetailsErrorTypes.TRY_AGAIN,
    error: action.error
  };
}
function setCaptureDetailsError(state, action) {
  return {
    ...state,
    error: action.error
  };
}

export default function batchReducer(state = initialState, action) {
  const handlers = {
    [USER_BATCH_OWNER_REQUEST]: userBatchOwnerRequest,
    [USER_BATCH_OWNER_SUCCESS]: userBatchOwnerSuccess,
    [USER_BATCH_OWNER_FAILURE]: userBatchOwnerFailure,
    [LOAD_CAPTUREDETAILS_REQUEST]: captureDetailsRequest,
    [LOAD_CAPTUREDETAILS_SUCCESS]: captureDetailsSuccess,
    [LOAD_CAPTUREDETAILS_FAILURE]: captureDetailsFailure,
    [RESET_CAPTUREDETAILS]: resetCaptureDetails,
    [LOAD_ITEMDETAIL_REQUEST]: loadItemDetailRequest,
    [LOAD_ITEMDETAIL_SUCCESS]: loadItemDetailSuccess,
    [LOAD_ITEMDETAIL_FAILURE]: loadItemDetailFailure,
    [UPDATE_BALANCE_REQUEST]: updateBalanceRequest,
    [UPDATE_BALANCE_SUCCESS]: updateBalanceSuccess,
    [UPDATE_BALANCE_FAILURE]: updateBalanceFailure,
    [DELETE_TRANSACTION_REQUEST]: deleteTransactionRequest,
    [DELETE_TRANSACTION_SUCCESS]: deleteTransactionSuccess,
    [DELETE_TRANSACTION_FAILURE]: deleteTransactionFailure,
    [SUBMIT_BATCHES_DETAIL_REQUEST]: submitBatchDetailRequest,
    [SUBMIT_BATCHES_DETAIL_SUCCESS]: submitBatchDetailSuccess,
    [SUBMIT_BATCHES_DETAIL_FAILURE]: submitBatchDetailFailure,
    [RESCAN_ITEM_REQUEST]: rescanItemRequest,
    [RESCAN_ITEM_SUCCESS]: rescanItemSuccess,
    [RESCAN_ITEM_FAILURE]: rescanItemFailure,
    [SCAN_MORE_REQUEST]: scanMoreRequest,
    [SCAN_MORE_SUCCESS]: scanMoreSuccess,
    [SCAN_MORE_FAILURE]: scanMorefailure,
    [CLEAR_SCAN_RESPONSE]: clearScanResponse,
    [DOWNLOAD_PDF_REQUEST]: downloadPdfRequest,
    [DOWNLOAD_PDF_SUCCESS]: downloadPdfSuccess,
    [DOWNLOAD_PDF_FAILURE]: downloadPdfFailure,
    [SAVE_ITEMDETAIL_REQUEST]: saveItemDetailRequest,
    [SAVE_ITEMDETAIL_SUCCESS]: saveItemDetailSuccess,
    [SAVE_ITEMDETAIL_FAILURE]: saveItemDetailFailure,
    [CLEAR_CAPTURE_DETAIL_ERROR]: clearCaptureDetailError,
    [CLEAR_DELETE_TRANSACTION_ITEM]: clearDeleteTransactionItem,
    [CLEAR_PDF_DATA]: clearPdfData,
    [CLEAR_SUBMIT_BATCH]: clearSubmitBatch,
    [SET_CAN_SCAN]: setCanScan,
    [HANDLE_SCAN_OPERATION]: handleScanOperation,
    [CONVERT_TRANSACTION_ITEM_REQUEST]: convertTransactionItemRequest,
    [CONVERT_TRANSACTION_ITEM_SUCCESS]: convertTransactionItemSuccess,
    [CONVERT_TRANSACTION_ITEM_FAIILURE]: convertTransactionItemFailure,
    [MARK_AS_NEW_TRANSACTION_REQUEST]: markAsNewTransactionRequest,
    [MARK_AS_NEW_TRANSACTION_SUCCESS]: markAsNewTransactionSuccess,
    [MARK_AS_NEW_TRANSACTION_FAILURE]: markASNewTransactionFailure,
    [UNMARK_AS_NEW_TRANSACTION_REQUEST]: unmarkAsNewTransactionRequest,
    [UNMARK_AS_NEW_TRANSACTION_SUCCESS]: unmarkAsNewTransactionSuccess,
    [UNMARK_AS_NEW_TRANSACTION_FAILURE]: unmarkASNewTransactionFailure,
    [SET_CAPTURE_DETAILS_ERROR]: setCaptureDetailsError
  };
  if (handlers.hasOwnProperty(action.type)) {
    return handlers[action.type](state, action);
  } else {
    return state;
  }
}
