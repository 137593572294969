import axios from 'axios';
import { HEALTH_CHECK_URL } from 'Constants/healthcheck';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import uuid from 'uuid';

const TOKEN_NAME = 'x-xsrf-token';
const TOKEN_MISMATCH_STATUS_CODE = 401;
const IS_TEST = process.env.JEST_WORKER_ID !== undefined || process.env.NODE_ENV === 'test';
const DEFAULT_RETRY = IS_TEST ? 0 : 2;

export const getApiOrHealthCheckUrl = url => {
  const apiHost = window.API_HOST || '';
  if (url === HEALTH_CHECK_URL) {
    return apiHost + window.API_NAMESPACE + '/alertssvc/v0/' + HEALTH_CHECK_URL;
  }
  return apiHost + window.API_NAMESPACE + '/' + url;
};

const isLogin = url => url === '/api/ronline/v0/authsvc/v0/auth/login';

export default {
  csrf_token: '',
  sm_user: '',
  setCSRFToken(token) {
    this.csrf_token = token;
    window.sessionStorage.setItem('sessionToken', token);
  },
  getCSRFToken() {
    this.csrf_token = window.sessionStorage.getItem('sessionToken');
    return this.csrf_token;
  },
  fetch(url, options = {}) {
    return this.ajaxPromise(url, {
      method: 'GET',
      token: options?.token || undefined
    });
  },
  fetchPdf(url, data, options = {}) {
    return this.ajaxPromise(url, {
      method: 'POST',
      data: JSON.stringify(data),
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/pdf'
      },
      token: options?.token
    });
  },
  fetchFileWithGet(url) {
    const options = {
      method: 'GET',
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/pdf, application/zip'
      }
    };
    return this.ajaxPromise(url, options);
  },
  // TODO:TECHDEBT: 2019-06-14: seems dead: delete soon if not needed
  // - this forces client code to package the parameters into a query string
  // - axios supports passing in a params option
  // - SEE: https://stackoverflow.com/questions/53709142/best-way-to-pass-query-parameters-to-url-using-axios-in-vue/53709218
  query(url, queryParam) {
    return this.ajaxPromise(url + '?' + queryParam, { method: 'GET' });
  },
  update(url, data) {
    return this.ajaxPromise(url, { method: 'PUT', data: JSON.stringify(data) });
  },
  save(url, data, options = {}) {
    return this.ajaxPromise(url, {
      method: 'POST',
      data: JSON.stringify(data),
      token: options?.token || undefined,
      ...options
    });
  },
  saveWithLogging(url, data) {
    return this.ajaxPromise(url, {
      method: 'POST',
      data: JSON.stringify(data),
      logSimulationHeaders: true
    });
  },
  postWithFile(url, data) {
    return this.ajaxPromise(url, {
      method: 'POST',
      data: data
    });
  },
  delete(url, data) {
    return this.ajaxPromise(url, {
      method: 'DELETE',
      data: JSON.stringify(data)
    });
  },
  setUserHeader(userName) {
    this.sm_user = userName;
    window.sessionStorage.setItem('userheader', userName);
  },
  getUserHeader() {
    this.sm_user = window.sessionStorage.getItem('userheader');
    return this.sm_user;
  },
  getRequestUniqueIdHeader() {
    return uuid.v4();
  },
  checkForSimulationMode() {
    this.simulateMode = JSON.parse(window.sessionStorage.getItem('simulation'));
    return this.simulateMode;
  },
  replaceToken() {
    return this.ajaxPromise('gatewaysvc/refreshToken', {
      method: 'GET'
    });
  },
  ajaxPromise(url, options = {}) {
    const self = this;
    const retries = isNil(options.retries) ? DEFAULT_RETRY : options.retries;
    options.data = options.data || {};
    options.headers = options.headers || {};

    /**
     * In Simulation Mode, Send User Name and Customer Name in Headers to log it into Database in security logs for login and loggout.
     */
    if (options.logSimulationHeaders) {
      const userinfo = JSON.parse(window.sessionStorage.getItem('user'));
      options.headers['USER_NAME'] = userinfo.userFullName;
      options.headers['CUSTOMER_NAME'] = userinfo.userCustomer.name;
    }
    options.headers['Pragma'] = options.headers['Pragma'] || 'no-cache';
    options.headers['Cache-Control'] = options.headers['Cache-Control'] || 'no-cache';
    if (options.method !== 'GET') {
      options.headers['Content-Type'] = 'application/json';
    }

    options.url = getApiOrHealthCheckUrl(url);
    options.withCredentials = true;

    options.headers['Correlation-Id'] = self.getRequestUniqueIdHeader();

    const storedToken = self.getCSRFToken();
    if (storedToken) {
      options.headers['sessionToken'] = storedToken;
    }
    const storedSM = self.getUserHeader();
    if (storedSM) {
      options.headers['USER'] = storedSM;
    }
    const simulateMode = self.checkForSimulationMode();
    if (!isEmpty(simulateMode)) {
      options.headers['simulatee-id'] = simulateMode.simulateeId;
      options.headers['simulator-id'] = simulateMode.simulatorId;
      options.headers['simulation-mode'] = Boolean(simulateMode.simulateMode);
    }

    options.cancelToken = options.token;

    options.onDownloadProgress = e => {
      options.customOnDownloadProgress?.();
      const currentToken = self.getCSRFToken();
      const latestToken = e.currentTarget?.getResponseHeader?.(TOKEN_NAME);
      if (latestToken && currentToken !== latestToken) {
        window.sessionStorage.setItem('sessionToken', latestToken);
      }
    };
    document.dispatchEvent(new Event('apiEvent'));
    let retry = 0;

    const saveAttempt = options =>
      axios(options)
        .then(response => {
          const token = response.headers[TOKEN_NAME];
          if (token) {
            self.setCSRFToken(token);
          }
          return response;
        })
        .catch(async errorResponse => {
          if (errorResponse.response) {
            const token = errorResponse.response.headers[TOKEN_NAME];
            if (token) {
              self.setCSRFToken(token);
              options.token = token;
              options.headers.sessionToken = token;
            } else if (
              options.method === 'POST' &&
              errorResponse.response.status == TOKEN_MISMATCH_STATUS_CODE &&
              !isLogin(errorResponse.config.url) &&
              isEmpty(token)
            ) {
              try {
                const response = await self.replaceToken();
                const updatedToken = response.headers[TOKEN_NAME];
                self.setCSRFToken(updatedToken);
                options.token = updatedToken;
                options.headers.sessionToken = updatedToken;
              } catch (error) {
                throw error;
              }
            }
          }
          if (errorResponse?.message === 'CANCEL_AND_KEEP_STATE') {
            throw errorResponse;
          }
          if (retry < retries && errorResponse.response.status === TOKEN_MISMATCH_STATUS_CODE) {
            retry++;
            return saveAttempt(options);
          } else {
            throw errorResponse.response;
          }
        });
    return saveAttempt(options);
  }
};
