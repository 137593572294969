import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { withStyles } from '@jpmuitk/theme';

import { DataGrid as DefaultDataGrid } from '@jpmuitk/data-grid';
import { getConfigGridAndCols } from 'hooks/useGrid';
import { gridStyles } from 'Components/PageWithGrid/styles';
import i18n from 'i18n';

const headerHeight = 30;
const rowHeight = 24;
const tableWidth = 402;

const COLUMN_HEADER = [
  {
    headerName: i18n.t('webIdSelection.label.customerName'),
    field: 'userCustomer.name',
    suppressMenu: true
  },
  {
    headerName: i18n.t('webIdSelection.label.customerId'),
    field: 'userCustomer.custWebId',
    suppressMenu: true
  }
];

const ROW_SELECTION_TYPE = 'single';
const DataGrid = withStyles(gridStyles)(DefaultDataGrid);

export default class WebIdSelectionComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { isGridReady: false };
  }

  onGridReady = ({ api }) => {
    this.gridApi = api;
    this.setState({ isGridReady: true });
  };

  render() {
    const classes = classNames('web-id-selection-border', this.props.className);
    const height = rowHeight * this.props.userInfoList.length + headerHeight;

    return (
      <DataGrid
        onGridReady={this.onGridReady}
        columnDefs={COLUMN_HEADER}
        rowData={this.props.userInfoList}
        rowSelection={ROW_SELECTION_TYPE}
        onSelectionChanged={this.props.onSelectionChanged}
        width={tableWidth}
        rowHeight={rowHeight}
        {...getConfigGridAndCols({ gridProps: { suppressRowClickSelection: false } })}
      />
    );
  }
}
