import {
  CLEAR_SCAN_FORM_ERROR,
  LOAD_LOCKBOXES_REQUEST,
  LOAD_LOCKBOXES_SUCCESS,
  LOAD_LOCKBOXES_FAILURE,
  RESET_LOCKBOXES,
  LOAD_LOCKBOX_REQUEST,
  LOAD_LOCKBOX_SUCCESS,
  LOAD_LOCKBOX_FAILURE,
  SET_SCAN_FORM_ERROR
} from 'actions/scanActions';
import { PROCESSING_LAST_ITEM_FAILURE } from 'actions/scanControlServiceActions/processScanItemsActions';
const initialState = {
  isFetching: false
};

const loadLockboxesRequest = state => {
  return { ...state, errors: undefined, isFetching: true };
};

const loadLockboxesSuccess = (state, action) => {
  return { ...state, lockboxes: action.response.data.lockboxes, isFetching: false };
};

const loadLockboxesFailure = (state, action) => {
  return { ...state, errors: action.error, isFetching: false };
};

const resetLockboxes = state => {
  return initialState;
};

const loadLockboxRequest = state => {
  return { ...state, errors: undefined, isFetching: true };
};

const loadLockboxSuccess = (state, action) => {
  return {
    ...state,
    lockboxDetail: {
      ...action.response.data.lockboxDetail,
      lockboxCaptureTypes: action.response.data.lockboxDetail.lockboxCaptureTypes.filter(({ type }) => Boolean(type))
    },
    isFetching: false
  };
};

const loadLockboxFailure = (state, action) => {
  return { ...state, errors: action.error, isFetching: false };
};

const processLastItemFailure = (state, action) => {
  return { ...state, errors: { type: 'PROCESSING_LAST_ITEM', message: action.message } };
};

const setScanFormError = (state, action) => {
  return { ...state, errors: action.error };
};
const clearScanFormError = (state, action) => {
  return { ...state, errors: undefined };
};

export default function scanReducer(state = initialState, action) {
  const handlers = {
    [LOAD_LOCKBOXES_REQUEST]: loadLockboxesRequest,
    [LOAD_LOCKBOXES_SUCCESS]: loadLockboxesSuccess,
    [LOAD_LOCKBOXES_FAILURE]: loadLockboxesFailure,
    [RESET_LOCKBOXES]: resetLockboxes,
    [LOAD_LOCKBOX_REQUEST]: loadLockboxRequest,
    [LOAD_LOCKBOX_SUCCESS]: loadLockboxSuccess,
    [LOAD_LOCKBOX_FAILURE]: loadLockboxFailure,
    [PROCESSING_LAST_ITEM_FAILURE]: processLastItemFailure,
    [SET_SCAN_FORM_ERROR]: setScanFormError,
    [CLEAR_SCAN_FORM_ERROR]: clearScanFormError
  };
  return handlers.hasOwnProperty(action.type) ? handlers[action.type](state, action) : state;
}
