import {
  LOAD_SCANNERS_REQUEST,
  LOAD_SCANNERS_SUCCESS,
  LOAD_SCANNERS_FAILURE,
  LOAD_USER_SETTINGS_REQUEST,
  LOAD_USER_SETTINGS_FAILURE,
  LOAD_USER_SETTINGS_SUCCESS,
  SAVE_SELECTED_SCANNER_REQUEST,
  SAVE_SELECTED_SCANNER_SUCCESS,
  SET_FULL_PORTAL,
  SET_SIMULATION_MODE,
  SET_USER_CUSTOMER,
  SAVE_SETTINGS_SUCCESS
} from './settingsActions';
import { sessionUser } from 'utility/sessionStorageHelper';

const FULL_PORTAL_TYPE_CODE = 1;

const setSettings = (state, { values }) => ({
  ...state,
  userSettings: { ...state.userSettings, ...values }
});

export const getInitialState = () => {
  const loggedInUser = sessionUser.getLoggedInUser();
  return {
    isFetching: false,
    isFullPortal: loggedInUser.isEmpty() ? false : loggedInUser.userCustomer.portalTypeCode === FULL_PORTAL_TYPE_CODE,
    scanners: [],
    settingsError: null,
    userCustomer: loggedInUser.isEmpty() ? {} : loggedInUser.userCustomer,
    userSettings: loggedInUser.isEmpty() ? {} : loggedInUser
  };
};

function loadScannerRequest(state, action) {
  return {
    ...state,
    isFetching: true,
    scannersError: false,
    saveSelectedScanner: null
  };
}

function loadScannerSuccess(state, action) {
  return { ...state, scanners: action.scanners, isFetching: false };
}

function loadScannersFailure(state, action) {
  return { ...state, scannersError: true, scanners: [], isFetching: false };
}

function saveSelectedScannerRequest(state, action) {
  return { ...state, isFetching: true, saveSelectedScanner: null };
}

function saveSelectedScannerSuccess(state, action) {
  return {
    ...state,
    ...action.response,
    isFetching: false,
    saveSelectedScanner: true
  };
}

function loadUserSettingsRequest(state, action) {
  return { ...state, isFetching: true };
}

function loadUserSettingsSuccess(state, action) {
  if (!action.userSettings.data.hasOwnProperty('userPreferedScannerCode')) {
    return { ...state, ...action.userSettings.data, userPreferedScannerCode: null, isFetching: false };
  }
  return { ...state, ...action.userSettings.data, isFetching: false };
}

function loadUserSettingsFailure(state, action) {
  return { ...state, settingsError: action.error, isFetching: false };
}

const setFullPortal = (state, { isFullPortal }) => ({ ...state, isFullPortal });
const setUserCustomerConfig = (state, action) => {
  return { ...state, userCustomer: action.userCustomer, userSettings: action.userInfo };
};
const setSimulationMode = (state, { type, simulateMode }) => ({ ...state, simulateMode });

export default (state = getInitialState(), action) => {
  const handlers = {
    [LOAD_SCANNERS_REQUEST]: loadScannerRequest,
    [LOAD_SCANNERS_SUCCESS]: loadScannerSuccess,
    [LOAD_SCANNERS_FAILURE]: loadScannersFailure,
    [LOAD_USER_SETTINGS_REQUEST]: loadUserSettingsRequest,
    [LOAD_USER_SETTINGS_SUCCESS]: loadUserSettingsSuccess,
    [LOAD_USER_SETTINGS_FAILURE]: loadUserSettingsFailure,
    [SAVE_SELECTED_SCANNER_REQUEST]: saveSelectedScannerRequest,
    [SAVE_SELECTED_SCANNER_SUCCESS]: saveSelectedScannerSuccess,
    [SET_FULL_PORTAL]: setFullPortal,
    [SET_SIMULATION_MODE]: setSimulationMode,
    [SET_USER_CUSTOMER]: setUserCustomerConfig,
    [SAVE_SETTINGS_SUCCESS]: setSettings
  };

  return handlers.hasOwnProperty(action.type) ? handlers[action.type](state, action) : state;
};
