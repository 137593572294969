import api from 'api';

export const LOAD_LOCKBOXES_REQUEST = 'LOAD_LOCKBOXES_REQUEST';
export const LOAD_LOCKBOXES_SUCCESS = 'LOAD_LOCKBOXES_SUCCESS';
export const LOAD_LOCKBOXES_FAILURE = 'LOAD_LOCKBOXES_FAILURE';
export const RESET_LOCKBOXES = 'RESET_LOCKBOXES';
export const LOAD_LOCKBOX_REQUEST = 'LOAD_LOCKBOX_REQUEST';
export const LOAD_LOCKBOX_SUCCESS = 'LOAD_LOCKBOX_SUCCESS';
export const LOAD_LOCKBOX_FAILURE = 'LOAD_LOCKBOX_FAILURE';
export const SET_SCAN_FORM_ERROR = 'SET_SCAN_FORM_ERROR';
export const CLEAR_SCAN_FORM_ERROR = 'CLEAR_SCAN_FORM_ERROR';

export const scanErrorTypes = {
  RELOAD_PAGE: 'RELOAD_PAGE',
  TRY_AGAIN: 'TRY_AGAIN',
  LOAD_PROGRAMS: 'LOAD_PROGRAMS'
};

export const loadLockboxes = () => {
  return {
    types: [LOAD_LOCKBOXES_REQUEST, LOAD_LOCKBOXES_SUCCESS, LOAD_LOCKBOXES_FAILURE],
    callAPI: (state, { customerId: custId, userId }) => api.fetch(`lockboxsvc/v0/cust/${custId}/user/${userId}/vr`),
    httpErrorTypes: { DEFAULT: scanErrorTypes.LOAD_PROGRAMS }
  };
};

export function resetLockboxes(lockboxes) {
  return { type: RESET_LOCKBOXES };
}

export const loadLockbox = (lockboxId, platform) => {
  return {
    types: [LOAD_LOCKBOX_REQUEST, LOAD_LOCKBOX_SUCCESS, LOAD_LOCKBOX_FAILURE],
    callAPI: (state, { customerId, userId }) =>
      api.fetch(`lockboxsvc/v0/cust/${customerId}/user/${userId}/vr/${lockboxId}/capturetypes?platform=${platform}`),
    httpErrorTypes: { DEFAULT: scanErrorTypes.TRY_AGAIN }
  };
};

export const setScanFormError = error => {
  return { type: SET_SCAN_FORM_ERROR, error };
};

export const clearScanFormError = () => {
  return { type: CLEAR_SCAN_FORM_ERROR };
};
