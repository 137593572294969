import React from 'react';
import { getIn } from 'final-form';
import map from 'lodash/map';
import set from 'lodash/set';
import values from 'lodash/values';
import sortBy from 'lodash/sortBy';
import cloneDeep from 'lodash/cloneDeep';
import startCase from 'lodash/startCase';
import isPlainObject from 'lodash/isPlainObject';

import { formatAmount } from 'utility/amountFormatter';
import * as V from 'utility/validation';
import { USD_REGEX } from 'Constants/regex';

import { InputBuilder } from 'Components/Forms/Input';
import { DropdownBuilder } from 'Components/Forms/Dropdown';
import RangeBuilder from 'Components/Forms/Input/RangeBuilder';
import { rangeCalendarProps } from 'Components/Forms/DatePicker/utils';
import { getArchivalOrDefaultDateRange } from 'config/forms/utils';
import { app } from 'config';
import { SingleDatePickerBuilder, DatePickerBuilder } from 'Components/Forms/DatePicker';

export const columnLayoutItemSizes = (columns = 1) => ({
  xs: 12,
  sm: 6 * columns,
  md: columns === 2 ? 12 : 4 * columns,
  lg: 3 * columns
});

const dynamicColumnLayoutItemSizes = wide => (wide ? columnLayoutItemSizes() : { xs: 12 });

export const getDefaultFieldProps = wide => ({
  noSuffix: true,
  useColumnLayout: true,
  columnLayoutProps: { ...dynamicColumnLayoutItemSizes(wide) }
});

export const getField = ({ field, props }) => {
  const { label: defaultLabel, type, source } = field;

  if (type === 'Date') {
    return (
      <SingleDatePickerBuilder
        field={{ ...field, label: dateFormat => `${defaultLabel} (${dateFormat})` }}
        noSuffix
        {...props}
      />
    );
  } else if (type === 'daterange') {
    return (
      <DatePickerBuilder
        field={{ ...field, label: dateFormat => `${defaultLabel} (${dateFormat})` }}
        noSuffix
        {...props}
      />
    );
  } else if (type === 'dropdown' || type === 'list') {
    return (
      <DropdownBuilder
        field={{
          defaultValue: field?.dropdownSource?.find(item => item.defaultIndicator)?.lookUpValue || undefined,
          ...field,
          type: type === 'list' ? 'typeahead' : type
        }}
        key={defaultLabel}
        noSuffix
        {...props}
      />
    );
  } else if (type === 'Numeric') {
    return <RangeBuilder field={field} noSuffix {...props} />;
  } else {
    return <InputBuilder field={field} noSuffix {...props} />;
  }
};

// Providing a static id list is an antipattern for a dynamic
// Field generators
export const serializeFormBuilder = (idList = [], fields = []) =>
  fields.map(({ id, value, label, required, length, regex, errorRegex, errMsg }, index) => {
    const fieldRegex = idList[index] === 'amount' ? USD_REGEX.source : regex;
    return {
      id: id || idList[index],
      label,
      required,
      inputProps: { maxLength: length },
      regex: fieldRegex,
      errorOnLoad: errMsg ? ({ pristine }) => (pristine ? { type: 'error', msg: errMsg } : undefined) : undefined,
      validation: ({ isRequired }) => [V.isRequired(isRequired), V.isValidWithRegex(errorRegex, fieldRegex)]
    };
  });

// Generator for Transaction Filter
export const serializeFilterFieldListToFormBuilder = ({ title, groupName, validation }) => ({
  referenceId,
  id = `${groupName}.${referenceId}Field`,
  headerName: label,
  referenceType,
  type = referenceType === 'Date' ? 'daterange' : referenceType,
  ...rest
}) => ({
  id,
  label,
  type,
  payloadId: `${groupName}.${referenceId}`,
  referenceId,
  referenceType,
  referencePart: groupName,
  validation: type === 'Alpha' ? () => validation : undefined
});

// generator for Transaction Details manual invoice association
export const serializeFilterFieldListToFormBuilder1 = ({ title, groupName }, t) => ({
  referenceId,
  id = `${groupName}.${referenceId}Field`,
  headerName: label,
  referenceType,
  type = referenceType === 'Date' ? 'daterange' : referenceType,
  irLabel,
  irRefTypeDesc,
  domain,
  irRefTypeId,
  ...rest
}) => ({
  id,
  label,
  type,
  payloadId: `${groupName}.${referenceId}`,
  referenceId,
  referenceType,
  referencePart: groupName,
  validation: type === 'Alpha' ? () => [V.maxWildCards(title, 1, t)] : undefined,
  maxRange: referenceId === '9005' ? app(t).common.search.maxRange : undefined,
  CalendarProps: rangeCalendarProps(getArchivalOrDefaultDateRange(true), app(t).common.search.maxFutureValue.default),
  irLabel,
  irRefTypeDesc,
  domain,
  irRefTypeId
});

export const deserializeReferenceGroupCallback = values => referenceItem => {
  const value = getIn(values, referenceItem.id);
  const type = typeof value === 'string' ? 'searchText' : 'searchRange';
  if (value) {
    const { referenceId, referenceType, referencePart, irLabel, irRefTypeDesc, domain, irRefTypeId } = referenceItem;
    set(values, referenceItem.payloadId, {
      [type]: value,
      referenceId,
      referenceType,
      referencePart,
      irLabel,
      irRefTypeDesc,
      domain,
      irRefTypeId
    });
    set(values, referenceItem.id, undefined);
  }
};

export const deserializeReferenceCallback = values => referenceItem => {
  const value = getIn(values, referenceItem.id);
  if (value) {
    const { referenceId } = referenceItem;
    set(values, referenceId, value);
  }
};

export const serializeFieldListToValues = (fieldList = []) =>
  fieldList
    .filter(({ defaultValue, type }) => defaultValue && type === 'dropdown')
    .reduce((acc, { id, defaultValue }) => ({ ...acc, [id]: { ...defaultValue } }), {});

export const serializeDownloadFilterFieldListToFormBuilder = fieldList =>
  !fieldList
    ? undefined
    : map(
        sortBy(
          fieldList.map(
            ({
              defaultOptionLabel: optionLabel,
              queryLabel: id,
              orderSeq: order,
              label,
              type,
              data: source,
              required,
              itemToString = item => (item ? item.optionLabel : ''),
              selectAll
            }) => ({
              order,
              id: id ? id : label.replace(/ /g, '_').toLowerCase(),
              label,
              type,
              // This assumes all sources without ids require only label data
              // A pattern should be documented and discussed
              source: (source || []).map(item => ({ id: item.labelId || item.optionLabel, ...item })),
              itemToString,
              required,
              defaultValue: optionLabel ? { id: null, optionLabel } : undefined,
              validation: ({ isRequired }) => [V.isRequired(isRequired)],
              selectAll: selectAll?.label
            }),
            ['order']
          ),
          values
        )
      );

export const serializeInitialValues = (idList, fields = []) => {
  const initialValues = fields
    .map(({ value }, index) => ({ [idList[index]]: value ? value : '' }))
    .reduce((initialValues, value) => {
      return { ...initialValues, ...value };
    }, {});
  return initialValues;
};

export const serializeSelectedDropdownItemToValueWithFormValues = (values, id = 'id') => {
  let newValues = cloneDeep(values);

  Object.values(values).forEach((v, index) => {
    if (!v) {
      return;
    }
    const { [id]: value } = v;
    const key = Object.keys(values)[index];
    if ((value || value === 0) && value !== -1) {
      // hack to adapt to old contract
      if (key === 'countByReferenceType') {
        newValues[key].description = newValues[key].optionLabel;
      } else {
        newValues[key] = value;
      }
    } else if (value === -1) {
      delete newValues[key];
    }
  });

  return newValues;
};
