import axios from 'axios';

const CHECK_AT_POLL_COUNT = 10;
const PANINI_VISION_X = 'X';
const PANINI_IDEAL = 'Y';
const scannersToPollAgst = new Set([PANINI_IDEAL, PANINI_VISION_X]);

export const shouldPoll = userPreferredScannerCode => {
  return scannersToPollAgst.has(userPreferredScannerCode);
};

export default {
  poll(
    callback,
    shouldCancelPoll,
    shouldContinueAtTimeout,
    userPreferredScannerCode,
    timeout = 30000,
    interval = 2000
  ) {
    if (!shouldPoll(userPreferredScannerCode)) {
      return callback();
    } else {
      let pollCount = 0;
      const endTime = Number(new Date()) + timeout;
      const hasTimedOut = () => Number(new Date()) > endTime;
      const checkCondition = (resolve, reject) => {
        const options = {
          onSuccess: response => {
            const shouldContinue = shouldContinueAtTimeout(response);
            if (shouldCancelPoll(response)) {
              resolve(response);
            } else if (
              !hasTimedOut() ||
              (hasTimedOut() && shouldContinue) ||
              (pollCount >= CHECK_AT_POLL_COUNT && shouldContinue)
            ) {
              setTimeout(checkCondition, interval, resolve, reject);
            } else {
              reject(new Error(response));
            }
          },
          onFailure: err => {
            reject(err);
          }
        };
        pollCount++;
        callback(options);
      };

      return new Promise(checkCondition);
    }
  },
  fetch(url, options) {
    return {
      cancelToken: options.cancelToken,
      timeout: options.timeout,
      onFailure: options.onFailure,
      onSuccess: options.onSuccess,
      type: 'GET',
      url
    };
  },
  query(url, queryParam, options) {
    return {
      cancelToken: options.cancelToken,
      timeout: options.timeout,
      type: 'GET',
      url: url + '?' + queryParam
    };
  },
  update(url, data, options) {
    return {
      cancelToken: options.cancelToken,
      data: JSON.stringify(data),
      timeout: options.timeout,
      type: 'PUT',
      url
    };
  },
  post(url, options) {
    return {
      cancelToken: options.cancelToken,
      data: JSON.stringify(options.data),
      onFailure: options.onFailure,
      onSuccess: options.onSuccess,
      timeout: options.timeout,
      type: 'POST',
      url
    };
  },
  call(action) {
    const self = this;
    const ONE_MINUTE = 60000;
    const timeout = action.timeout ? action.timeout : ONE_MINUTE;
    if (action.type !== 'GET') {
      action.contentType = 'application/json';
    }
    return axios({
      method: action.type,
      url: (SCANNER_HOST || '') + SCANNER_NAMESPACE + '/' + action.url,
      data: action.data ? action.data : {},
      headers: { 'content-type': 'application/json' },
      timeout: timeout,
      cancelToken: action.cancelToken,
      contentType: action.contentType
    });
  }
};
