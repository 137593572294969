import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withStyles } from '@jpmuitk/theme';
import { app, FEATURE_FLAGS } from 'config';
import { DATE_RANGE } from 'config/forms/fields';

import { useSelector } from 'react-redux';
import { useField, Field } from 'react-final-form';
import { useInteractiveProps, useErrorProps } from 'Components/Forms/Hooks';
import { useId } from '@jpmuitk/utils';
import { useTranslation } from 'react-i18next';

import { FormField } from '@jpmuitk/form-field';
import { ColumnLayout } from '@jpmuitk/column-layout';
import { DateRangePicker, RangePickerPanel } from '@jpmuitk/date-picker';

import ConditionalWrapper from 'Components/ConditionalWrapper';
import Fieldset from 'Components/Forms/Fieldset';

import { composeValidators, processError } from 'utility/validation';
import { rangeStyles } from './styles';
import 'react-dates/lib/css/_datepicker.css';

const renderPanel = ({
  classes,
  userDateFormat,
  focusedInput,
  maxRange,
  CalendarProps,
  ListProps,
  YearDropdownProps
}) => props => {
  const { startDate, endDate } = props;

  return (
    <RangePickerPanel
      {...props}
      classes={{
        listWrapper: classes.list
      }}
      ListProps={ListProps}
      CalendarProps={CalendarProps ? CalendarProps({ focusedInput, maxRange, startDate }) : undefined}
      YearDropdownProps={YearDropdownProps}
    />
  );
};

const Builder = props => {
  const [focusedInput, setFocusedInput] = useState(null);
  const {
    // Common
    classes,
    helperText,
    helperTextPlacement,
    hide,
    id,
    isRequired,
    label,
    noPrefix,
    validation,
    validateOnLoad,
    // DateRange
    defaultHelperText,
    maxRange,
    CalendarProps,
    ListProps,
    YearDropdownProps,
    // Layout
    columnLayoutProps,
    useColumnLayout
  } = useInteractiveProps(props);
  const { t } = useTranslation();

  const {
    userDateFormat: { description: userDateFormat }
  } = useSelector(state => state.settings.userSettings);

  // value, onChange, onFocus causes keyboard issues if passed to input
  const {
    input: { onChange: startDateOnChange, value: startDateValue, onFocus: removeStartDateFocus, ...startDateInput },
    meta: startDateMeta
  } = useField(`${id}.${DATE_RANGE(t).START.id}`, {
    parse: momentDate => momentDate?.format(app(t).server.dateFormat),
    format: date => (date ? moment(date, app(t).server.dateFormat) : ''),
    validate: composeValidators([
      ...DATE_RANGE(t).START.validation({
        format: userDateFormat,
        label: DATE_RANGE(t).START.label,
        requiredRange: true,
        required: isRequired
      }),
      ...(validation.from?.({ format: userDateFormat }) || [])
    ])
  });

  const {
    input: { onChange: endDateOnChange, value: endDateValue, onFocus: removeEndDateFocus, ...endDateInput },
    meta: endDateMeta
  } = useField(`${id}.${DATE_RANGE(t).END.id}`, {
    parse: momentDate => momentDate?.format(app(t).server.dateFormat),
    format: date => (date ? moment(date, app(t).server.dateFormat) : ''),
    validate: composeValidators([
      ...DATE_RANGE(t).END.validation({
        format: userDateFormat,
        label: DATE_RANGE(t).END.label,
        requiredRange: true,
        required: isRequired
      }),
      ...(validation.to?.({ format: userDateFormat }) || [])
    ])
  });

  const displayFormatter = (date, value) => {
    const formattedDate = moment(date?._i, userDateFormat);
    return formattedDate.isValid() ? formattedDate.format(userDateFormat) : value;
  };

  const { type: startDateMsgType, msg: startDateMsg, ...rest } = useErrorProps({ meta: startDateMeta });
  const { type: endDateMsgType, msg: endDateMsg } = useErrorProps({ meta: endDateMeta });
  const labelId = `ui-toolkit-form-field-label-${Math.floor(Math.random() * 10)}`;
  const helperTextId = useId();

  return hide ? null : (
    <ConditionalWrapper
      condition={useColumnLayout}
      wrapper={children => (
        <ColumnLayout item {...columnLayoutProps}>
          {children}
        </ColumnLayout>
      )}
    >
      <div role="group" aria-labelledby={labelId}>
        {' '}
        <FormField
          {...{
            LabelProps: {
              // Disables the "for" attribute from the label for accessibility reasons.
              // See UITK-1380 for more details: https://jiradc-other.jpmchase.net/browse/UITK-1380
              // htmlFor: undefined,
              id: labelId,
              NecessityIndicator: () => (isRequired ? <i>&nbsp;{t('necessity.required')}</i> : '')
            },
            label: label({ format: userDateFormat }),
            labelPlacement: 'top',
            'aria-labelledby': labelId,
            classes,
            validationState: startDateMsgType || endDateMsgType,
            helperText: startDateMsg || endDateMsg || defaultHelperText,
            HelperTextProps: {
              id: helperTextId
            }
          }}
        >
          {/* const { id, helperTextId = `${id}-helper-text` } = getChildProps(); */}
          <DateRangePicker
            PopperProps={{ modifiers: { flip: { enabled: true } } }}
            defaultStartDate={startDateValue || undefined}
            defaultEndDate={endDateValue || undefined}
            dateFormat={[userDateFormat]}
            StartInputProps={{
              ...startDateInput,
              placeholder: DATE_RANGE(t).START.label,
              displayFormatter
            }}
            startInputProps={{
              'aria-invalid': startDateMsgType === 'error',
              'aria-describedby': (startDateMsg || endDateMsg || defaultHelperText) && helperTextId,
              'aria-label': t('form.range.startDate', { dateFormat: userDateFormat })
              // 'aria-labelledby': `${labelId}`
            }}
            EndInputProps={{
              ...endDateInput,
              placeholder: DATE_RANGE(t).END.label,
              displayFormatter
            }}
            endInputProps={{
              'aria-invalid': endDateMsgType === 'error',
              'aria-describedby': (startDateMsg || endDateMsg || defaultHelperText) && helperTextId,
              'aria-label': t('form.range.endDate', { dateFormat: userDateFormat })
              // 'aria-labelledby': `${labelId}`,
            }}
            onDatesChange={({ startDate, endDate }) => {
              startDateOnChange(startDate);
              endDateOnChange(endDate);
            }}
            onFocusChange={focusedInput => setFocusedInput(focusedInput)}
            renderPanel={renderPanel({
              classes,
              userDateFormat,
              focusedInput,
              maxRange,
              CalendarProps,
              ListProps,
              YearDropdownProps
            })}
          />
        </FormField>
      </div>
    </ConditionalWrapper>
  );
};

Builder.propTypes = {
  field: PropTypes.object.isRequired
};

export default withStyles(rangeStyles)(Builder);
