import React from 'react';
import { useDispatch } from 'react-redux';
import { withStyles } from '@jpmuitk/theme';
import { ColumnLayout } from '@jpmuitk/column-layout';
import { Spinner } from '@jpmuitk/spinner';
import { Panel } from '@jpmuitk/panel';
import PropTypes from 'prop-types';

import { PageTitle, PageRoot, PageContent } from 'Components/Page';
import WebIdSelectionComponent from './WebIdSelectionComponent';
import { logUserLogin } from 'actions/userinfoActions';
import isEmpty from 'lodash/isEmpty';
import { clearCustomerWebId, setUserConfig, setFullPortalStatus } from 'routes/Settings/settingsActions';
import './WebIdSelection.scss';
import i18n from 'i18n';

const PAGE_TITLE = i18n.t('webIdSelection.pageTitle');
const styleColumnLayout = {
  container: {
    height: '100%'
  }
};

const FullHeightColumnLayout = withStyles(styleColumnLayout)(ColumnLayout);

export function WebIdSelection(props) {
  const dispatch = useDispatch();
  const simulationMode = React.useMemo(() => {
    return {
      simulateeId: props.userinfo.headers['simulatee-id'],
      simulatorId: props.userinfo.headers['simulator-id'],
      simulateMode: props.userinfo.headers['simulation-mode']
    };
  }, [props.userinfo]);

  const onSelectionChanged = React.useCallback(
    event => {
      const selectedUserId = event.api
        .getSelectedNodes()
        .map(item => item.data.userId)
        .reduce((accumulator, item) => item);
      const userConfig = props.userinfo.list.find(user => user.userId === selectedUserId);
      dispatch(setUserConfig(userConfig, simulationMode));
      dispatch(logUserLogin(userConfig.userCustomer.id, userConfig.userId));
    },
    [dispatch, simulationMode, props.userinfo]
  );

  React.useEffect(function autoClose() {
    if (props.userinfo.list.length > 0) {
      if (props.userinfo.list.length === 1) {
        const userinfo = props.userinfo.list[0];
        dispatch(setUserConfig(userinfo, simulationMode));
        dispatch(logUserLogin(userinfo.userCustomer.id, userinfo.userId));
      } else {
        dispatch(setFullPortalStatus(props.userinfo.list[0]));
      }
    }
  }, []);

  React.useEffect(
    function handleError() {
      if (!isEmpty(props.userinfo.errorMessage)) {
        dispatch(clearCustomerWebId());
      }
    },
    [props.userinfo.errorMessage]
  );

  return (
    <PageRoot narrow>
      <PageTitle errorMessage={props.userinfo.errorMessage}>{PAGE_TITLE}</PageTitle>
      <PageContent noFooter noWrapper>
        <p>{i18n.t('webIdSelection.messages.accessLinks')}</p>
        <p>{i18n.t('webIdSelection.messages.selectAccount')}</p>
        <WebIdSelectionComponent userInfoList={props.userinfo.list} onSelectionChanged={onSelectionChanged} />
      </PageContent>
    </PageRoot>
  );
}

WebIdSelection.defaultProps = {
  userinfo: {
    list: []
  }
};

WebIdSelection.propTypes = {
  userinfo: PropTypes.shape({
    list: PropTypes.array
  })
};

export default WebIdSelection;
