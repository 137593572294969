import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter, Prompt } from 'react-router-dom';
import { Form, FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { PageTitle, PageFooter, PageContent } from 'Components/Page';
import { ButtonBar } from '@salt-ds/lab';
import { ColumnLayout } from '@jpmuitk/column-layout';
import Button from 'Components/Button/ActionButton';
import { InputBuilder } from 'Components/Forms/Input';
import { DropdownBuilder } from 'Components/Forms/Dropdown';
import Spinner from 'Components/Spinner';
import TextWithIcon from 'Components/TextWithIcon';
import FormManager from './FormManager';
import { openDialog } from 'actions/modalActions';
import {
  clearInvoicePatternState,
  createInvoicePattern,
  loadInvoicePrograms,
  loadInvoiceReferences,
  updateInvoicePattern
} from './actions';
import IPDF from './invoicePatternDetailsFields';
import { booleanDropdown, IPD_PAGES } from './utils';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import {
  CREATE_INVOICE_PATTERN,
  CLONE_INVOICE_PATTERN,
  EDIT_INVOICE_PATTERN,
  INVOICE_IDENTIFICATION_PATTERNS
} from 'Constants/routes';

const InvoicePatternDetails = props => {
  const { classes = {}, history, initialValues } = props || {};
  const { errorFetchingSources, fetchingSources, programs, invoiceReferences } = useSelector(
    state => state.invoicePattern.partialSourcesState
  );
  const [enablePrompt, setEnablePrompt] = useState(true);
  const [nextLocation, setNextLocation] = useState(undefined);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const source = {
    programs,
    invoiceReferences,
    searchPaymentNumber: booleanDropdown,
    removeSpaces: booleanDropdown,
    removeSpecialCharacters: booleanDropdown
  };
  const responsiveFormStyle = isEmpty(source?.invoiceReferences) ? 3 : 4;

  useEffect(() => {
    if (isEmpty(sessionStorage.getItem('currentInitialValues'))) {
      sessionStorage.setItem('currentInitialValues', JSON.stringify(initialValues));
    }
    if (initialValues?.program?.id && props.location.pathname !== CREATE_INVOICE_PATTERN) {
      dispatch(loadInvoicePrograms(initialValues.program.id));
    } else {
      dispatch(loadInvoicePrograms());
    }

    return () => {
      if (!IPD_PAGES[history.location.pathname]) {
        sessionStorage.removeItem('currentInitialValues');
        dispatch(clearInvoicePatternState());
      }
    };
  }, []);

  useEffect(() => {
    if (nextLocation && history) {
      history.push(nextLocation.pathname);
    }
  }, [nextLocation, history]);

  const handleBlockedNavigation = location => {
    if (enablePrompt && !IPD_PAGES[location.pathname] && !location.state?.disableNavigationPromptOnSubmission) {
      setEnablePrompt(false);
      dispatch(
        openDialog({
          title: t('invoicePattern.dialog.navigation.title'),
          state: 'warning',
          message: t('invoicePattern.dialog.navigation.message'),
          reverseCloseActionInCallAll: true,
          btnOverride: true,
          btnContent: [
            {
              ['data-test-id']: 'cancelNavigationBtn',
              actionButton: false,
              name: t('invoicePattern.dialog.button.no'),
              onClick: () => setEnablePrompt(true)
            },
            {
              ['data-test-id']: 'proceedNavigationBtn',
              actionButton: false,
              name: t('invoicePattern.dialog.button.yes'),
              variant: 'cta',
              onClick: () => {
                if (location) {
                  setNextLocation(location);
                }
              }
            }
          ]
        })
      );
      return false;
    }
    return true;
  };

  const referenceFieldMutator = (args, state, utils) => {
    if (
      state.formState.values.program.id &&
      state.formState.initialValues.program?.id !== state.formState.values.program.id
    ) {
      utils.changeValue(state, IPDF.REFERENCE_FIELD.id, () => args[0]);
      sessionStorage.setItem('currentInitialValues', JSON.stringify(state.formState.values));
      dispatch(loadInvoiceReferences(state.formState.values.program.id));
    }
  };

  const onSubmit = async values => {
    const payload = {};
    let response;
    payload.programId = values.program?.id;
    payload.minLength = values.minLength;
    payload.maxLength = values.maxLength;
    payload.invoiceRefFieldFormat = values.invoiceNumberFormat;
    payload.invoiceRefTypeId = values.invoiceReference?.id;
    payload.searchPaymentNumIn = values.searchPaymentNumber?.id === booleanDropdown[0].id ? true : false;
    payload.removeSpacesIn = values.removeSpaces?.id === booleanDropdown[0].id ? true : false;
    payload.removeSpecialCharactersIn = values.removeSpecialCharacters?.id === booleanDropdown[0].id ? true : false;
    payload.truncateReferenceLeading = values.truncateReferenceLeading;
    payload.truncateReferenceTrailing = values.truncateReferenceTrailing;

    if (values.invoicePatternId) {
      payload.invoiceIdPatternId = values.invoicePatternId;
      response = await dispatch(
        updateInvoicePattern(payload, [
          () => (dispatch, getState) => {
            history.push({
              pathname: INVOICE_IDENTIFICATION_PATTERNS,
              state: {
                lastModifiedPatternId: values.invoicePatternId,
                disableNavigationPromptOnSubmission: true,
                successToast: {
                  title: t('invoicePattern.successToast.updateTitle'),
                  message: t('invoicePattern.successToast.updateMessage')
                }
              }
            });
          }
        ])
      );
      return response;
    } else {
      response = await dispatch(
        createInvoicePattern(payload, [
          () => (dispatch, getState) => {
            const createdPatternId = getState().invoicePattern?.createPatternState?.createdPatternId;
            history.push({
              pathname: INVOICE_IDENTIFICATION_PATTERNS,
              state: {
                lastModifiedPatternId: createdPatternId,
                disableNavigationPromptOnSubmission: true,
                successToast: {
                  title: t('invoicePattern.successToast.createTitle'),
                  message: t('invoicePattern.successToast.createMessage')
                }
              }
            });
          }
        ])
      );
      return response;
    }
  };

  const renderMessage = (message, type = 'error') => {
    return (
      <TextWithIcon className={classes.textWithIcon} role="note" type={type} icon={type}>
        {message}
      </TextWithIcon>
    );
  };

  const currentFormValues = sessionStorage.getItem('currentInitialValues');
  const currentInitialValues = !isEmpty(currentFormValues) ? JSON.parse(currentFormValues) : initialValues;

  return (
    <>
      <Prompt when={true} message={handleBlockedNavigation} />
      <PageTitle />
      {fetchingSources ? (
        <Spinner className="default" announceMessage="Loading pattern details..." />
      ) : (
        <Form
          initialValues={currentInitialValues}
          initialValuesEqual={isEqual}
          mutators={{ referenceFieldMutator }}
          subscription={{ submitting: true }}
          onSubmit={onSubmit}
          render={({ handleSubmit, errors, form: { reset, mutators }, submitting }) => (
            <>
              <PageContent>
                <h2>{t('invoicePattern.content.defaultTitle')}</h2>
                <span>{t('invoicePattern.content.defaultDescription')}</span>
                {!isEmpty(errorFetchingSources) ? (
                  renderMessage(errorFetchingSources.message)
                ) : (
                  <>
                    {submitting ? (
                      <Spinner
                        className="default"
                        announceMessage={t('invoicePattern.submittingInvoicePatternDetails')}
                      />
                    ) : (
                      <>
                        <ColumnLayout container spacing={3}>
                          <ColumnLayout item xs={12} md={6} lg={6}>
                            <DropdownBuilder
                              tabIndex={0}
                              field={{ ...IPDF.PROGRAM, onChange: item => mutators.referenceFieldMutator(null) }}
                              source={source.programs}
                              rowsToDisplay={4}
                              noSuffix
                            />
                          </ColumnLayout>
                          <ColumnLayout item xs={12} md={3} lg={3} className={classes.inputBuilderOverride}>
                            <InputBuilder field={IPDF.MIN_LENGTH} noSuffix />
                          </ColumnLayout>
                          <ColumnLayout item xs={12} md={3} lg={3} className={classes.inputBuilderOverride}>
                            <InputBuilder field={IPDF.MAX_LENGTH} noSuffix />
                          </ColumnLayout>
                          <ColumnLayout item xs={12} md={6} lg={6} className={classes.inputBuilderOverride}>
                            <InputBuilder field={IPDF.INVOICE_NUMBER_FORMAT} noSuffix />
                          </ColumnLayout>
                          {!isEmpty(source?.invoiceReferences) && (
                            <ColumnLayout item xs={12} md={6} lg={6}>
                              <DropdownBuilder
                                tabIndex={0}
                                field={IPDF.REFERENCE_FIELD}
                                source={source.invoiceReferences}
                                rowsToDisplay={4}
                                noSuffix
                              />
                            </ColumnLayout>
                          )}
                          <ColumnLayout item xs={12} md={responsiveFormStyle} lg={responsiveFormStyle}>
                            <DropdownBuilder
                              tabIndex={0}
                              field={IPDF.SEARCH_PAYMENT_NUMBER}
                              source={source.searchPaymentNumber}
                              noSuffix
                            />
                          </ColumnLayout>
                          <ColumnLayout item xs={12} md={responsiveFormStyle} lg={responsiveFormStyle}>
                            <DropdownBuilder
                              tabIndex={0}
                              field={IPDF.REMOVE_SPACES}
                              source={source.removeSpaces}
                              noSuffix
                            />
                          </ColumnLayout>
                          <ColumnLayout item xs={12} md={4} lg={4}>
                            <DropdownBuilder
                              tabIndex={0}
                              field={IPDF.REMOVE_SPECIAL_CHARACTERS}
                              source={source.removeSpecialCharacters}
                              noSuffix
                            />
                          </ColumnLayout>
                          <ColumnLayout item xs={12} md={4} lg={4} className={classes.inputBuilderOverride}>
                            <InputBuilder field={IPDF.TRUNCATE_REF_LEADING} noSuffix />
                          </ColumnLayout>
                          <ColumnLayout item xs={12} md={4} lg={4} className={classes.inputBuilderOverride}>
                            <InputBuilder field={IPDF.TRUNCATE_REF_TRAILING} noSuffix />
                          </ColumnLayout>
                        </ColumnLayout>
                        <FormManager history={history} />
                      </>
                    )}
                  </>
                )}
              </PageContent>
              <PageFooter>
                <FormSpy subscription={{ errors: true }}>
                  {({ errors }) => (
                    <ButtonBar alignLeft sortAlignLeft="asc" stackAtBreakpoint={0}>
                      <Button
                        ordered
                        alignLeftOrder={0}
                        actionButton={true}
                        onClick={handleSubmit}
                        variant="cta"
                        data-test-id={'submitBtn'}
                        disabled={!isEmpty(errorFetchingSources) || !isEmpty(errors) || submitting}
                      >
                        {t('invoicePattern.footerButtons.submit')}
                      </Button>
                      <Button
                        ordered
                        alignLeftOrder={1}
                        onClick={() => {
                          history.push(INVOICE_IDENTIFICATION_PATTERNS);
                        }}
                        data-test-id={'cancelBtn'}
                      >
                        {t('invoicePattern.footerButtons.cancel')}
                      </Button>
                    </ButtonBar>
                  )}
                </FormSpy>
              </PageFooter>
            </>
          )}
        />
      )}
    </>
  );
};

export default withRouter(InvoicePatternDetails);
