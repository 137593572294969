import { useRef, useState } from 'react';
import { useId } from '@jpmuitk/utils';
import { withTranslation } from 'react-i18next';
import { Button, SaltProvider } from '@salt-ds/core';
import { ButtonBar } from '@salt-ds/lab';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@jpmuitk/dialog';
import { Spinner } from '@jpmuitk/spinner';
import { RadioButton, RadioButtonGroup } from '@jpmuitk/radio-button';
import TextWithIcon from 'Components/TextWithIcon';
import { makeStyles } from '@jpmuitk/theme';
import isEmpty from 'lodash/isEmpty';
import './ExportDialog.scss';

import cssVariables from 'Constants/_variables.module.scss';

const useDialogStyles = makeStyles(theme => {
  return {
    msgWithIcon: {
      marginBottom: cssVariables.space3
    }
  };
});

const ExportDialog = props => {
  const titleRef = useRef(null);
  const classes = useDialogStyles();
  const [state, setState] = useState({
    selectedValue: props.defaultValue || props.dialogContent.defaultValue || 'data'
  });
  const titleId = useId();
  const handleClose = () => {
    setState({ selectedValue: props.dialogContent.defaultValue || 'data' });
    props.dialogContent.onClose();
  };

  const renderExportDialogMessage = (message, type) => {
    return (
      <TextWithIcon className={classes.msgWithIcon} type={type} icon={type}>
        {message}
      </TextWithIcon>
    );
  };

  const { openDialog, isExportAll, page, options, isLoading, error, errorType, infoMsg } = props.dialogContent;
  const title = isExportAll
    ? props.t('transactions.exportDialog.exportall', { page })
    : props.t('transactions.exportDialog.export');

  return (
    <Dialog
      autoFocusRef={titleRef}
      id={props.id}
      open={openDialog === true}
      onClose={handleClose}
      aria-labelledby={titleId}
      aria-describedby={null}
    >
      <SaltProvider>
        <DialogTitle disableAria onClose={handleClose}>
          <span aria-level="2" role="heading" ref={titleRef} tabIndex={-1} id={titleId}>
            {title}
          </span>
        </DialogTitle>
        {error && !isLoading && renderExportDialogMessage(error, 'error')}
        <DialogContent>
          {isLoading ? (
            <Spinner className="ExportDialog-Spinner" />
          ) : (
            <>
              {infoMsg && renderExportDialogMessage(infoMsg, 'info')}
              {!isEmpty(options) && (
                <RadioButtonGroup
                  defaultValue={state.selectedValue}
                  onChange={event => setState({ selectedValue: event.target.value })}
                  key={state.counter}
                  legend={props.t('transactions.exportDialog.legend')}
                >
                  {options.map((option, index) => (
                    <RadioButton
                      key={option.key}
                      label={option.key}
                      className="export-dialog-radio-btn"
                      value={option.value}
                      disabled={!!(option.value === 'images' && error) || errorType === 'data'}
                    />
                  ))}
                </RadioButtonGroup>
              )}
            </>
          )}
        </DialogContent>
        {!isLoading && (
          <DialogActions className="export-dialog-btn">
            <ButtonBar>
              <Button
                key="Export"
                id="Export"
                variant="cta"
                disabled={isLoading || state.selectedValue === errorType}
                onClick={() => props.dialogContent.exportFunction(state.selectedValue)}
              >
                {errorType === 'data'
                  ? props.t('transactions.exportDialog.close')
                  : props.t('transactions.exportDialog.export')}
              </Button>
            </ButtonBar>
          </DialogActions>
        )}
      </SaltProvider>
    </Dialog>
  );
};

export default withTranslation()(ExportDialog);
