import App from 'config/app';
import moment from 'moment';
import { amountUnformatter } from 'hooks/useFieldFormatter/amountFormatter';
import { dateUnformatter, dateTimeUnformatter } from 'hooks/useFieldFormatter/dateFormatter';
import i18n from 'i18n';

export const dateTimeComparator = userSettings => (valueA, valueB) => {
  const momentA = dateTimeUnformatter(valueA, userSettings);
  const momentB = dateTimeUnformatter(valueB, userSettings);

  return momentA.isValid() && momentB.isValid()
    ? momentA - momentB
    : momentA.isValid()
    ? 1
    : momentB.isValid()
    ? -1
    : 0;
};

export const dateComparator = userSettings => (valueA, valueB) => {
  const unformatedA = dateUnformatter(valueA, userSettings);
  const unformatedB = dateUnformatter(valueB, userSettings);
  const momentA = moment(unformatedA, App(i18n.t).server.dateFormat);
  const momentB = moment(unformatedB, App(i18n.t).server.dateFormat);

  return momentA.isValid() && momentB.isValid()
    ? momentA - momentB
    : momentA.isValid()
    ? 1
    : momentB.isValid()
    ? -1
    : 0;
};

export const amountComparator = userSettings => (
  valueA,
  valueB,
  nodeA,
  nodeB,
  isInverted,
  withoutDollarSignValueA = valueA?.replace?.(/\$/g, ''),
  withoutDollarSignValueB = valueB?.replace?.(/\$/g, '')
) => {
  return (
    amountUnformatter(withoutDollarSignValueA, userSettings) - amountUnformatter(withoutDollarSignValueB, userSettings)
  );
};

export const numberNameComparator = userSettings => (valueA, valueB, nodeA, nodeB, isInverted) => {
  const valueNameA = valueA && valueA.name ? valueA.name : '';
  const valueNumberA = valueA && valueA.number ? valueA.number + ' - ' : '';
  const valueToRenderA = valueNumberA + valueNameA;

  const valueNameB = valueB && valueB.name ? valueB.name : '';
  const valueNumberB = valueB && valueB.number ? valueB.number + ' - ' : '';
  const valueToRenderB = valueNumberB + valueNameB;

  return valueToRenderA.localeCompare(valueToRenderB);
};
