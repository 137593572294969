import api from 'api';
import { httpErrorTypes, errorActions } from 'utility/error';
import { handleDownloadFile, getFileName } from 'utility/downloadUtility';
import { transactionURL } from '../Transactions/utils';
import { CONTENT_TYPE } from 'Constants/headers';
import { rduTemplate } from 'Constants/defaultFileNames';

export const CLEAR_UPLOAD_WORKFLOW_NOTES = 'CLEAR_UPLOAD_WORKFLOW_NOTES';
export const DOWNLOAD_UPLOAD_WORKFLOW_NOTES_TEMPLATE_REQUEST = 'DOWNLOAD_UPLOAD_WORKFLOW_NOTES_TEMPLATE_REQUEST';
export const DOWNLOAD_UPLOAD_WORKFLOW_NOTES_TEMPLATE_SUCCESS = 'DOWNLOAD_UPLOAD_WORKFLOW_NOTES_TEMPLATE_SUCCESS';
export const DOWNLOAD_UPLOAD_WORKFLOW_NOTES_TEMPLATE_FAILURE = 'DOWNLOAD_UPLOAD_WORKFLOW_NOTES_TEMPLATE_FAILURE';
export const CLEAR_DOWNLOAD_UPLOAD_WORKFLOW_NOTES_TEMPLATE_STATE =
  'CLEAR_DOWNLOAD_UPLOAD_WORKFLOW_NOTES_TEMPLATE_STATE';
export const UPLOAD_WORKFLOW_NOTES_REQUEST = 'UPLOAD_WORKFLOW_NOTES_REQUEST';
export const UPLOAD_WORKFLOW_NOTES_SUCCESS = 'UPLOAD_WORKFLOW_NOTES_SUCCESS';
export const UPLOAD_WORKFLOW_NOTES_FAILURE = 'UPLOAD_WORKFLOW_NOTES_FAILURE';
export const SAVE_WORKFLOW_NOTES_REQUEST = 'SAVE_WORKFLOW_NOTES_REQUEST';
export const SAVE_WORKFLOW_NOTES_SUCCESS = 'SAVE_WORKFLOW_NOTES_SUCCESS';
export const SAVE_WORKFLOW_NOTES_FAILURE = 'SAVE_WORKFLOW_NOTES_FAILURE';

const handleDownloadTemplate = () => (dispatch, getState) => {
  const templateData = getState()?.uploadWorkflowNotes?.templateData;
  if (templateData?.data) {
    const type = templateData.request.getResponseHeader(CONTENT_TYPE);
    const fileName = getFileName(templateData, rduTemplate);
    handleDownloadFile(templateData.data, fileName, type);
    return dispatch(clearDownloadUploadWorkflowNotesTemplateState());
  }
};

export const clearDownloadUploadWorkflowNotesTemplateState = () => ({
  type: CLEAR_DOWNLOAD_UPLOAD_WORKFLOW_NOTES_TEMPLATE_STATE
});

export const downloadUploadWorkflowNotesTemplate = t => ({
  types: [
    DOWNLOAD_UPLOAD_WORKFLOW_NOTES_TEMPLATE_REQUEST,
    DOWNLOAD_UPLOAD_WORKFLOW_NOTES_TEMPLATE_SUCCESS,
    DOWNLOAD_UPLOAD_WORKFLOW_NOTES_TEMPLATE_FAILURE
  ],
  callAPI: (state, { customerId, userId }) => api.fetch(transactionURL('uploadworkflownotes/download-template')),
  dispatchOnSuccess: [handleDownloadTemplate],
  httpErrorTypes,
  errorDisplayType: 'modal',
  errorTitle: t('uploadworkflownotes.downloadTemplate.error')
});

export const clearUploadWorkflowNotes = () => ({
  type: CLEAR_UPLOAD_WORKFLOW_NOTES
});

export const uploadWorkflowNotesFile = file => ({
  types: [UPLOAD_WORKFLOW_NOTES_REQUEST, UPLOAD_WORKFLOW_NOTES_SUCCESS, UPLOAD_WORKFLOW_NOTES_FAILURE],
  callAPI: (state, { customerId, userId }) => api.postWithFile(transactionURL('validate-workflow-notes'), file),
  httpErrorTypes
});

export const saveWorkflowNotes = (data, t) => ({
  types: [SAVE_WORKFLOW_NOTES_REQUEST, SAVE_WORKFLOW_NOTES_SUCCESS, SAVE_WORKFLOW_NOTES_FAILURE],
  callAPI: (state, { customerId, userId }) => api.save(transactionURL('save-uploaded-workflow-notes'), data),
  httpErrorTypes,
  errorDisplayType: 'modal',
  errorTitle: t('uploadworkflownotes.saveFile.error')
});
