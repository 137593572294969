import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@jpmuitk/theme';

import { Spinner } from '@jpmuitk/spinner';
import ConditionalWrapper from 'Components/ConditionalWrapper';
import { useFormContext } from './Context';

import { usePageStyles } from 'styles';
import { centerStyles } from 'Components/Spinner/styles';

const CenterSpinner = withStyles(centerStyles)(Spinner);

const PageContent = ({ children, noWrapper = false, noFooter = false, loading = false }) => {
  const { narrow } = useFormContext();
  const classes = usePageStyles({ narrow });

  return (
    <div className={classNames(classes.content, { [classes.noFooter]: noFooter, [classes.loading]: loading })}>
      <ConditionalWrapper
        condition={!noWrapper}
        wrapper={children => <div className={classes.contentWrapper}>{children}</div>}
        children={loading ? <CenterSpinner size="large" /> : children}
      />
    </div>
  );
};

PageContent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired
};

export default PageContent;
