import React from 'react';
import { withStyles } from '@jpmuitk/theme';
import { useField } from 'react-final-form';
import { composeValidators } from 'utility/validation';
import { useTranslation } from 'react-i18next';

import { FormField as DefaultFormField } from '@jpmuitk/form-field';
import { RadioButtonGroup as DefaultRadioButtonGroup, RadioButton } from '@jpmuitk/radio-button';

import { ColumnLayout } from '@jpmuitk/column-layout';
import { useInteractiveProps, useErrorProps } from 'Components/Forms/Hooks';
import ConditionalWrapper from 'Components/ConditionalWrapper';

const styles = {
  activationIndicator: {
    display: 'none'
  },
  activationIndicatorIcon: {
    left: 0,
    bottom: 'calc(1em * -1.4 / 2)'
  },
  helperText: {
    paddingLeft: '1em'
  },
  contentArea: {
    paddingBottom: '0.25rem'
  }
};

const radioStyles = () => ({
  legend: {
    fontSize: 11
  }
});

const FormField = withStyles(styles)(DefaultFormField);
const RadioButtonGroup = withStyles(radioStyles)(DefaultRadioButtonGroup);

const RadioButtonGroupBuilder = ({ children, legend, ...radioButtonGroupProps }) => {
  const { field } = radioButtonGroupProps;
  const {
    helperText,
    id,
    isRequired,
    isValidationDisabled,
    label,
    noSuffix,
    onChange,
    validation,
    validateOnLoad,
    columnLayoutProps,
    useColumnLayout
  } = useInteractiveProps(radioButtonGroupProps);
  const { t } = useTranslation();
  const {
    input: { onChange: inputOnChange, ...input },
    meta
  } = useField(`${id}${noSuffix ? '' : 'Field'}`, {
    key: isRequired ? 1 : 0,
    validate: composeValidators(validation({ isRequired })),
    type: 'radio'
  });
  const { type: errorType, msg } = useErrorProps({ meta, validateOnLoad, isValidationDisabled, helperText });

  return (
    <ConditionalWrapper
      condition={useColumnLayout}
      wrapper={children => (
        <ColumnLayout item {...columnLayoutProps}>
          {children}
        </ColumnLayout>
      )}
    >
      <FormField validationState={errorType} helperText={msg} label={label() || legend}>
        <RadioButtonGroup
          {...field}
          {...input}
          legend={label() || legend}
          onChange={value => {
            inputOnChange(value);
            onChange && onChange(value);
          }}
        >
          {children}
        </RadioButtonGroup>
      </FormField>
    </ConditionalWrapper>
  );
};

export default RadioButtonGroupBuilder;
