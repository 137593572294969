import isArray from 'lodash/isArray';
import isBoolean from 'lodash/isBoolean';
import isEmpty from 'lodash/isEmpty';
import Scanner, { shouldPoll } from 'api/scanner';
import { SCAN, CAPTURE_DETAILS } from 'Constants/routes';
import {
  isSelectedScannerCaptureOne,
  isSelectedScannerSimulateMode,
  scanningErrorTypes,
  HANDLE_SCAN_ERROR,
  twainDriverErrorMessage,
  twainDriverActionMessage,
  enqueue
} from './index';
import { selectTwainScanSource, getTwainScannerSources } from './getAndSelectScannerActions';
import i18n from 'i18next';

export const SETUP_CANCELLATION_TOKEN = 'SETUP_CANCELLATION_TOKEN';
export const INIT_SCAN_SERVICE_SUCCESS = 'INIT_SCAN_SERVICE_SUCCESS';
export const STOP_SCANNING_SESSION = 'STOP_SCANNING_SESSION';
export const START_TWAIN_SESSION_FINISHED = 'START_TWAIN_SESSION_FINISHED';

export const namedTwainDriverActionMessage = scannerDriverName =>
  i18n.t('scanner.messages.scannerDriverNotFound', { scannerDriverName: scannerDriverName });
export const twainDriverRefreshMessage = i18n.t('scanner.messages.pleaseRefreshAfterInstallingDriver');
export const twainDriverActionHref =
  'https://www.jpmorgan.com/solutions/treasury-payments/remote-capture-resource-center';
export const startScanErrorMessage = i18n.t('scanner.messages.scanSessionNotStarted');
export const startScanActionMessage = i18n.t('scanner.messages.scanSessionFailure');
export const startScanNamedScannerActionMessage = scannerDriverName =>
  i18n.t('scanner.messages.scanSessionDriverFailure', { scannerDriverName: scannerDriverName });
export const startScanRefreshMessage = i18n.t('scanner.messages.pleaseRefreshAfterInstallingSoftware');

export const applicationData = {
  manufacturer: 'JPMorgan Chase & Co.',
  productFamily: 'Receivables Online',
  version: 'V0',
  application: i18n.t('metadata.capture')
};

const FIVE_SECONDS = 5000;
const FORCE_UPGRADE_SCANNER = 1;

export function getScannerNameFromScannerCode(getState) {
  const { userPreferedScannerCode, scanners } = getState().settings;
  const scanner = isArray(scanners) ? scanners.find(scanner => scanner.scannerCode === userPreferedScannerCode) : null;
  return scanner ? scanner.scannerDriverName : null;
}

export const connectToScannerAndTwainDriver = () => async (dispatch, getState) => {
  if (!isEmpty(getState().scanControl.sessionId)) {
    await dispatch(stopScanningSession());
  }
  if (window.FORCE_SCAN_SOFTWARE_UPGRADE === FORCE_UPGRADE_SCANNER) {
    await dispatch(getScannerVersion());
  } else {
    await dispatch(connectToScanner());
  }
};

export const getScannerVersion = () => (dispatch, getState) => {
  const error = {
    type: HANDLE_SCAN_ERROR,
    error: {
      errorType: scanningErrorTypes.INIT,
      errorMessage: '',
      actionMessage: i18n.t('scanner.messages.newerVersion'),
      actionLinkHref: '',
      actionLinkMessage: i18n.t('scanner.messages.downloadScanSoftware'),
      preventScanning: true
    }
  };
  const onSuccess = response => {
    if (response.data !== window.SCAN_SOFTWARE_VERSION) {
      dispatch(error);
    } else {
      dispatch(connectToScanner());
    }
  };
  const onFailure = err => {
    dispatch(error);
  };
  dispatch(enqueue(Scanner.fetch('GetVersion', { onSuccess, onFailure })));
};

export const connectToScanner = () => (dispatch, getState) => {
  const data = {
    ...applicationData,
    isCaptureOne: isSelectedScannerCaptureOne(getState),
    userData: null
  };
  const onSuccess = response => {
    dispatch({ type: INIT_SCAN_SERVICE_SUCCESS, sessionId: response.data });
    const userPreferedScannerCode = getState().settings.userPreferedScannerCode;
    const scanners = getState().settings.scanners;
    const prevSelectedScanner = getState().scanControl.selectedTwainScanSource;
    const nextAction = () => {
      dispatch(isTwainDriverAvailable());
    };
    if (userPreferedScannerCode && Array.isArray(scanners)) {
      const selectedScanner = scanners.find(scanner => scanner.scannerCode === userPreferedScannerCode);
      dispatch(selectTwainScanSource(selectedScanner.scannerDriverName, nextAction));
    } else if (!isEmpty(prevSelectedScanner)) {
      dispatch(selectTwainScanSource(prevSelectedScanner, nextAction));
    } else {
      nextAction();
    }
  };
  const onFailure = err => {
    dispatch({
      type: HANDLE_SCAN_ERROR,
      error: {
        errorType: scanningErrorTypes.INIT,
        errorMessage: i18n.t('scanner.messages.softwareNotFound'),
        actionMessage: i18n.t('scanner.messages.scanSoftwareNotInstalled'),
        actionLinkHref: '',
        actionLinkMessage: i18n.t('scanner.messages.downloadScanSoftware'),
        preventScanning: true
      }
    });
  };
  dispatch(enqueue(Scanner.post('Init', { data, timeout: 8000, onSuccess, onFailure })));
};

export const isTwainDriverAvailable = () => (dispatch, getState) => {
  const data = {
    id: getState().scanControl.sessionId,
    userData: null,
    isCaptureOne: isSelectedScannerCaptureOne(getState)
  };
  const scannerDriverName = getScannerNameFromScannerCode(getState);
  const onFailure = error => {
    dispatch(twainDriverError(scannerDriverName));
  };
  const onSuccess = response => {
    const availabilityStatus = response.data;
    if (availabilityStatus === true) {
      dispatch(startTwainSession());
    } else {
      onFailure();
    }
  };
  const selectedScanner = getState().settings.userPreferedScannerCode;
  if (!isSelectedScannerSimulateMode(getState)) {
    if (shouldPoll(selectedScanner)) {
      Scanner.poll(
        options => dispatch(enqueue(Scanner.post('IsSourceAvailable', { data, ...options }))),
        response => response.data === true || (!isBoolean(response.data) && !getState().scanControl.stopScanCalled),
        response => false,
        getState().settings.userPreferedScannerCode
      )
        .then(response => {
          onSuccess(response);
        })
        .catch(err => {
          onFailure(err);
        });
    } else {
      dispatch(enqueue(Scanner.post('IsSourceAvailable', { data, onSuccess, onFailure })));
    }
  }
};

export const startTwainSession = () => (dispatch, getState) => {
  const data = {
    id: getState().scanControl.sessionId,
    userData: null,
    isCaptureOne: isSelectedScannerCaptureOne(getState)
  };
  const scannerDriverName = getScannerNameFromScannerCode(getState);
  const error = {
    type: HANDLE_SCAN_ERROR,
    error: {
      errorType: scanningErrorTypes.START,
      errorMessage: startScanErrorMessage,
      actionMessage: scannerDriverName ? startScanNamedScannerActionMessage(scannerDriverName) : startScanActionMessage,
      actionLinkHref: '',
      actionLinkMessage: '',
      preventScanning: true
    }
  };
  const onSuccess = response => {
    if (response.data) {
      dispatch(error);
    } else {
      dispatch({ type: START_TWAIN_SESSION_FINISHED });
    }
  };
  const onFailure = err => {
    dispatch(error);
  };
  if (!isSelectedScannerSimulateMode(getState)) {
    dispatch(enqueue(Scanner.post('StartSource', { data, onSuccess, onFailure })));
  } else {
    dispatch(selectTwainScanSource(scannerDriverName));
  }
};

export const twainDriverError = scannerDriverName => {
  return {
    type: HANDLE_SCAN_ERROR,
    error: {
      errorType: scanningErrorTypes.TWAIN_DRIVER,
      errorMessage: twainDriverErrorMessage,
      actionMessage: scannerDriverName ? namedTwainDriverActionMessage(scannerDriverName) : twainDriverActionMessage,
      actionLinkHref: twainDriverActionHref,
      actionLinkMessage: i18n.t('support.supportPage.resourceCenter'),
      preventScanning: true
    }
  };
};

export const stopScanningSession = () => (dispatch, getState) => {
  const data = {
    id: getState().scanControl.sessionId,
    userData: null,
    isCaptureOne: isSelectedScannerCaptureOne(getState)
  };
  const onSuccess = () => {
    dispatch({ type: STOP_SCANNING_SESSION });
  };
  const onFailure = () => {
    dispatch({ type: STOP_SCANNING_SESSION });
  };
  dispatch(enqueue(Scanner.post('StopScan', { data, onSuccess, onFailure })));
};
