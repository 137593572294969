import { SaltProvider } from '@salt-ds/core';

import { ColumnLayout } from '@jpmuitk/column-layout';
import { ButtonBar, OrderedButton } from '@jpmuitk/button-bar';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@jpmuitk/dialog';

import { Form, FormSpy } from 'react-final-form';

import ConfirmationDialog from 'Components/Dialogs/ConfirmationDialog';
import EmptyCellRenderer from 'Components/DataGrid/Renderers/EmptyCellRenderer';
import PageWithGrid from 'Components/PageWithGrid';
import StopGoFilter from './Components/Dialogs/StopGoFilter';
import UploadFileZone from 'Components/PageWithUpload/UploadFileZone';
import { FilterDialog } from 'Components/Dialogs/FilterDialog';
import { RadioButtonGroupBuilder } from 'Components/Forms/RadioButton';

import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useId } from '@jpmuitk/utils';
import { useTranslation } from 'react-i18next';

import isEqual from 'lodash/isEqual';
import stopGoColumnDefs from './stopGoDefs';
import { clearUpload, loadStopGo, uploadFileAction, SaveStopGo } from './actions';
import { feature } from './feature';
import { getField } from 'Components/Forms/helpers';
import { makeStyles } from '@jpmuitk/theme';
import { mapValuesToPayload } from '../utils/payload';
import { openDialog } from '../../actions/modalActions';

import './stopGo.scss';

const RF = t => {
  return {
    UPLOAD_TYPE: {
      id: 'uploadType',
      radios: [
        { label: t('stopGo.labels.replace'), value: 'Replace' },
        { label: t('stopGo.labels.append'), value: 'Append' }
      ],
      label: t('stopGo.labels.uploadType')
    }
  };
};

export const reload = (dispatch, t) => {
  let sessionValue = {};
  const sessionKey = 'filters';
  try {
    const item = sessionStorage.getItem(sessionKey);
    sessionValue = item ? JSON.parse(item) : {};
  } catch (error) {
    return {};
  }
  const {
    [sessionKey]: {
      [feature.pages.stopGo.currentFilter]: sessionStorageValues = {},
      [feature.pages.stopGo.currentFilterPayload]: sessionStoragePayload = {}
    } = {}
  } = sessionValue;
  const formatPayload = mapValuesToPayload(sessionStoragePayload);
  dispatch(loadStopGo(formatPayload, t));
};

const StopGo = props => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const titleId = useId();
  const [uploadModal, setUploadModal] = useState(false);
  const [uploadType, setUploadType] = useState(false);
  const [allPrograms, setAllPrograms] = useState(null);
  const [saveStart, setSaveStart] = useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const {
    error = {},
    isFetching = false,
    rowData: rows = [],
    isLoading = false,
    uploadErrors = undefined,
    successToast = null,
    saveData = null,
    isSaving = false,
    saveError = null
  } = useSelector(state => state.stopgo, isEqual);
  const uploadSuccess = useSelector(state => {
    return state.stopgo.uploadSuccess?.message ? state.stopgo.uploadSuccess.message : false;
  });
  const filedData = useSelector(state => state.stopgo.uploadSuccess);
  const { userSettings } = useSelector(state => state.settings);

  const frameworkComponents = {
    emptyCellRenderer: EmptyCellRenderer
  };
  const fileErrorMessage =
    uploadErrors?.message || uploadErrors?.errorDetails
      ? uploadErrors.message || t(`uploadentity.uploadFile.defaultErrorMsg`)
      : false;
  const uploadResult =
    isLoading || isSaving
      ? 'loading'
      : successToast
      ? 'default'
      : fileErrorMessage
      ? 'error'
      : uploadSuccess
      ? 'success'
      : 'default';
  let submit;
  const programkey = allPrograms ? allPrograms.id : 'programId';
  const programdefaultVal = allPrograms ? allPrograms.defaultValue : '';

  const buttonsContent = [
    {
      name: t('stopGo.labels.upload', { hatText: t('stopGo.single') }),
      actionButton: false,
      variant: 'primary',
      disabled: false,
      onClick: () => {
        setUploadModal(true);
      }
    }
  ];

  useEffect(() => {
    if (isSaving === false && saveData !== null && saveStart === true) {
      setUploadModal(false);
      dispatch(
        openDialog({
          state: 'success',
          title: t('stopGo.labels.success'),
          message: t('stopGo.messages.uploadSuccess'),
          btnOverride: true,
          btnContent: [
            {
              name: t('stopGo.labels.close'),
              actionButton: false,
              hideCount: true,
              variant: 'primary',
              disabled: false,
              ariaLabel: t('button.close'),
              onClick: () => {
                reload(dispatch, t);
              }
            }
          ],
          closeOverride: true,
          closeAction: () => {
            reload(dispatch, t);
          }
        })
      );
    }
  }, [saveData, isSaving, saveStart]);

  useEffect(() => {
    if (isSaving === false && saveError !== null && saveStart === true) {
      setErrorDialogOpen(true);
    }
  }, [saveError, isSaving, saveStart]);

  const updatePrograms = data => {
    const newData = {
      ...data,
      defaultValue: { id: null, optionLabel: t('stopGo.labels.selectValue') },
      required: true
    };
    setAllPrograms(newData);
  };

  const handleUploadClose = () => {
    setUploadModal(false);
    dispatch(clearUpload());
  };

  const handleFileUplaod = (form, val = null) => {
    setSelectedFile(form.get('file'));
    return uploadFileAction(form, val, t);
  };

  const handleSubmit = value => {
    const payload = new FormData();

    payload.append('file', selectedFile);
    payload.append('uploadType', value.uploadType);
    payload.append('programId', value.programId?.id);
    setSaveStart(true);
    dispatch(SaveStopGo(payload, value));
  };

  const getDialogContent = () => {
    return {
      dialogContent: {
        message: saveError.message,
        dialogTitle: t('stopGo.labels.error'),
        dialogState: 'warning',
        handleClose: () => {
          setErrorDialogOpen(false);
        },
        openDialog: errorDialogOpen,
        btnContent: [
          {
            name: t('stopGo.labels.close'),
            onClick: () => {
              setErrorDialogOpen(false);
            },
            variant: 'cta'
          }
        ]
      }
    };
  };

  const renderFilter = props => (
    <StopGoFilter {...props} pageSettings={feature.pages.stopGo} updatePrograms={updatePrograms} />
  );

  const renderUploadForm = () => {
    return (
      <>
        <Form
          key={'upload-form'}
          initialValues={{ uploadType: 'Append', ...{ [programkey]: programdefaultVal } }}
          subscription={{ submitting: true }}
          onSubmit={handleSubmit}
          render={({ handleSubmit, errors, form: { reset }, submitting, values }) => {
            submit = handleSubmit;
            return (
              <form
                onSubmit={e => {
                  e.preventDefault();
                  return handleSubmit();
                }}
              >
                <ColumnLayout container alignItems="flex-end" alignContent="flex-start" spacing={3}>
                  {allPrograms
                    ? getField({
                        field: allPrograms,
                        props: { useColumnLayout: true, columnLayoutProps: { xs: 12 } }
                      })
                    : null}
                  <ColumnLayout item xs={12}>
                    <RadioButtonGroupBuilder field={RF(t).UPLOAD_TYPE} noSuffix />
                  </ColumnLayout>
                  <ColumnLayout item xs={12}>
                    <FormSpy subscription={{ values: true }}>
                      {({ values }) => (
                        <UploadFileZone
                          disabled={values.programId?.id === null}
                          uploadFile={file => handleFileUplaod(file, values[allPrograms?.id]?.id)}
                          uploadStatus={uploadResult}
                          uploadSuccess={uploadSuccess}
                          fileErrorMessage={fileErrorMessage}
                        />
                      )}
                    </FormSpy>
                  </ColumnLayout>
                </ColumnLayout>
              </form>
            );
          }}
        />
      </>
    );
  };

  const renderUploadDialog = () => {
    return (
      <Dialog
        width={500}
        id="UploadDialog"
        open={uploadModal}
        onClose={handleUploadClose}
        aria-describedby={null}
        aria-labelledby={titleId}
      >
        <SaltProvider>
          <DialogTitle disableAria onClose={handleUploadClose}>
            <span aria-level="2" role="heading" id={titleId}>
              {t('stopGo.labels.fileUpload')}
            </span>
          </DialogTitle>
          <DialogContent className="UploadDialog-Content">
            {renderUploadForm()}
            {saveError && saveError.message ? <ConfirmationDialog {...getDialogContent()} /> : null}
          </DialogContent>
          <ButtonBar>
            <OrderedButton variant="cta" onClick={e => submit()} disabled={!uploadSuccess || isSaving}>
              {t('stopGo.labels.submit')}
            </OrderedButton>
          </ButtonBar>
        </SaltProvider>
      </Dialog>
    );
  };

  return (
    <>
      <FilterDialog render={renderFilter} />
      <PageWithGrid
        className="stopgo-grid"
        isLoading={isFetching}
        frameworkComponents={frameworkComponents}
        errors={error.message}
        rows={rows}
        columnDefs={stopGoColumnDefs(t, userSettings)}
        buttonsContent={buttonsContent}
        suppressRowSelection
      />
      {uploadModal && renderUploadDialog()}
    </>
  );
};

export default StopGo;
