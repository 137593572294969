import {
  LOAD_STOPGO_REQUEST,
  LOAD_STOPGO_SUCCESS,
  LOAD_STOPGO_FAILURE,
  UPLOAD_STOPGO_FILE_REQUEST,
  UPLOAD_STOPGO_FILE_SUCCESS,
  UPLOAD_STOPGO_FILE_FAILURE,
  SAVE_STOPGO_REQUEST,
  SAVE_STOPGO_FAILURE,
  SAVE_STOPGO_SUCCESS,
  CLEAR_UPLOAD
} from './actions';

export const initialState = {
  error: {},
  isFetching: false,
  rowData: [],
  isLoading: false,
  uploadSuccess: undefined,
  uploadErrors: undefined,
  successToast: null,
  isSaving: false,
  saveData: null,
  saveError: null
};

const loadStopGoRequest = state => {
  return {
    ...state,
    error: {},
    isFetching: true,
    rowData: []
  };
};

const loadStopGoSuccess = (state, action) => {
  const response = action.response;
  return {
    ...state,
    error: {},
    isFetching: false,
    rowData: response.data.stopGoFiles
  };
};

const loadStopGoFailure = (state, action) => {
  return {
    ...state,
    error: action.error ? { ...action.error } : null,
    isFetching: false,
    rowData: []
  };
};

const upoadFileRequest = (state, action) => {
  return Object.assign({}, state, {
    uploadSuccess: undefined,
    uploadErrors: undefined,
    successToast: null,
    isLoading: true
  });
};
const upoadFileSuccess = (state, action) => {
  const data = action.response.data;
  const errors = data.errors;
  const result = !errors
    ? { uploadSuccess: { message: '--', ...data } }
    : {
        uploadErrors: {
          message: data.fileErrorMessage,
          errorDetails: data.errorDetails
        }
      };
  return Object.assign({}, state, {
    ...state,
    ...result,
    //errors: errors,
    isLoading: false
  });
};
const upoadFileFailure = (state, action) => {
  return {
    ...state,
    uploadErrors: { ...action.error },
    isLoading: false
  };
};
const saveStopgoSuccess = (state, action) => {
  return {
    ...state,
    isSaving: false,
    saveData: action.response.data
  };
};
const saveStopgoFailure = (state, action) => {
  return {
    ...state,
    isSaving: false,
    saveError: action?.error
  };
};

const saveStopgoRequest = (state, action) => {
  return {
    ...state,
    isSaving: true,
    saveData: null,
    saveError: null
  };
};
const clearUpload = (state, action) => {
  return {
    ...state,
    uploadErrors: undefined,
    uploadSuccess: undefined,
    isLoading: false
  };
};

export default function stopGoReducer(state = initialState, action) {
  const handlers = {
    [LOAD_STOPGO_REQUEST]: loadStopGoRequest,
    [LOAD_STOPGO_SUCCESS]: loadStopGoSuccess,
    [LOAD_STOPGO_FAILURE]: loadStopGoFailure,
    [UPLOAD_STOPGO_FILE_REQUEST]: upoadFileRequest,
    [UPLOAD_STOPGO_FILE_SUCCESS]: upoadFileSuccess,
    [UPLOAD_STOPGO_FILE_FAILURE]: upoadFileFailure,
    [SAVE_STOPGO_REQUEST]: saveStopgoRequest,
    [SAVE_STOPGO_SUCCESS]: saveStopgoSuccess,
    [SAVE_STOPGO_FAILURE]: saveStopgoFailure,
    [CLEAR_UPLOAD]: clearUpload
  };

  return handlers.hasOwnProperty(action.type) ? handlers[action.type](state, action) : state;
}
