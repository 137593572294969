import i18n from 'i18n';

export const shouldBeDisabled = ({ data, gridButton: { field, value, name } }) =>
  data.some(item => {
    if (item.data.payerEntityId && item.data[field] === i18n.t('decisionManager.labels.new') && name === 'activate') {
      return false;
    }
    return item.data[field] !== value;
  });

export const disableGridButtonsObjectFactory = (data = []) => {
  const gridButtons = [
    {
      name: 'submit',
      field: 'status',
      value: i18n.t('pageWithGrid.label.readyToSubmit')
    },
    {
      name: 'deactivate',
      field: 'status',
      value: i18n.t('pageWithGrid.label.active')
    },
    {
      name: 'activate',
      field: 'status',
      value: i18n.t('pageWithGrid.label.inactive')
    }
  ];

  return gridButtons.reduce(
    (gridButtonObject, gridButton) => ({
      ...gridButtonObject,
      [gridButton.name]: shouldBeDisabled({
        data,
        gridButton
      })
    }),
    {}
  );
};
