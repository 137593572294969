import moment from 'moment';
import isNil from 'lodash/isNil';
import App from 'config/app';
import i18n from 'i18n';

export const dateUnformatter = (date, { userDateFormat }) => {
  if (isNil(date)) {
    return date;
  }
  return moment(date, userDateFormat.description).format(App(i18n.t).server.dateFormat);
};

export const dateTimeUnformatter = (dateTime, { userDateFormat, userTimeFormat }) => {
  if (userTimeFormat.description === '24 Hr') {
    const [date, time] = dateTime.split(' ');
    if (isNil(dateTime)) {
      return dateTime;
    }
    return moment(`${date} ${time}`, `${userDateFormat.description} HH:mm`);
  } else {
    const [date, time, ampm] = dateTime.split(' ');
    if (isNil(dateTime)) {
      return dateTime;
    }
    return moment(`${date} ${time} ${ampm}`, `${userDateFormat.description} hh:mm A`);
  }
};
