import i18n from 'i18n';
import {
  CREATE_USER_FAILURE,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  GET_ACTIVE_USERS_FAILURE,
  GET_ACTIVE_USERS_REQUEST,
  GET_ACTIVE_USERS_SUCCESS,
  GET_USERS_FULL_PORTAL_FAILURE,
  GET_USERS_FULL_PORTAL_REQUEST,
  GET_USERS_FULL_PORTAL_SUCCESS,
  SERVICE_ENTITLEMENTS_FAILURE,
  SERVICE_ENTITLEMENTS_REQUEST,
  SERVICE_ENTITLEMENTS_SUCCESS
} from 'routes/UserAdmin/userAdminActions';

const createUserRequest = state => {
  return { ...state, isFetching: true };
};

const createUserSuccess = (state, action) => {
  return {
    ...state,
    errors: {},
    isFetching: false,
    userAccount: {
      userCreation: action.userCreation ? action.userCreation : action.response.data
    }
  };
};

const createUsersFailure = (state, action) => {
  return { ...state, errors: action.errors ? action.errors : 'error', isFetching: false };
};

const getActiveUsersRequest = state => {
  return { ...state, isFetching: true };
};

const getActiveUsersSuccess = (state, action) => {
  return { ...state, activeUsers: action.users ? action.users : action.response.data, isFetching: false };
};

const getActiveUsersFailure = (state, action) => {
  return { ...state, errors: action.errors, isFetching: false };
};

const getUsersFullPortalRequest = state => {
  return { ...state, isFetching: true };
};

const getUsersFullPortalSuccess = (state, action) => {
  if (action.response.data.length === 0) {
    return {
      ...state,
      errors: { message: i18n.t('administration.actionMessages.unableCreateUser') },
      isFetching: false,
      fullPortalUsers: []
    };
  }
  return { ...state, fullPortalUsers: action.users ? action.users : action.response.data, isFetching: false };
};

const getUsersFullPortalFailure = (state, action) => {
  return {
    ...state,
    errors: action.error,
    isFetching: false,
    fullPortalUsers: []
  };
};

const serviceEntitlementsRequest = state => {
  return { ...state, isFetching: true };
};

const serviceEntitlementsSuccess = (state, action) => {
  return {
    ...state,
    errors: {},
    isFetching: false,
    userAccount: {
      ...state.userAccount,
      entitlementResponse: action.entitlementResponse ? action.entitlementResponse : action.response.data
    }
  };
};

const serviceEntitlementsFailure = (state, action) => {
  return { ...state, errors: action.errors, isFetching: false };
};

export const createUserReducer = {
  [CREATE_USER_REQUEST]: createUserRequest,
  [CREATE_USER_SUCCESS]: createUserSuccess,
  [CREATE_USER_FAILURE]: createUsersFailure,
  [GET_ACTIVE_USERS_REQUEST]: getActiveUsersRequest,
  [GET_ACTIVE_USERS_SUCCESS]: getActiveUsersSuccess,
  [GET_ACTIVE_USERS_FAILURE]: getActiveUsersFailure,
  [GET_USERS_FULL_PORTAL_REQUEST]: getUsersFullPortalRequest,
  [GET_USERS_FULL_PORTAL_SUCCESS]: getUsersFullPortalSuccess,
  [GET_USERS_FULL_PORTAL_FAILURE]: getUsersFullPortalFailure,
  [SERVICE_ENTITLEMENTS_REQUEST]: serviceEntitlementsRequest,
  [SERVICE_ENTITLEMENTS_SUCCESS]: serviceEntitlementsSuccess,
  [SERVICE_ENTITLEMENTS_FAILURE]: serviceEntitlementsFailure
};
