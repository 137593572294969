import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import isObject from 'lodash/isObject';
import cloneDeep from 'lodash/cloneDeep';
import { useTranslation } from 'react-i18next';

import { Form, FormSpy } from 'react-final-form';
import { ButtonBar } from '@salt-ds/lab';

import { Panel } from '@jpmuitk/panel';
import { Button } from '@salt-ds/core';
import { Spinner } from '@jpmuitk/spinner';
import { ColumnLayout } from '@jpmuitk/column-layout';

import { useSessionStorage } from 'hooks/useSessionStorage';
import useApi from 'hooks/useApi';

import { toggleSidePanelWide } from 'actions/sidePanelsActions';

import { loadStopGo } from '../../actions';

import TextWithIcon from 'Components/TextWithIcon';
import ButtonRenderer from 'Components/Forms/ButtonRenderer';

import { stopGoURL } from 'routes/Stopgo/utils';

import { getSourceByKey } from 'utility/formFieldHelper';
import { getCurrentOrNextBusinessDay } from 'Components/Forms/DatePicker/utils';
import {
  getField,
  serializeDownloadFilterFieldListToFormBuilder,
  serializeFieldListToValues
} from 'Components/Forms/helpers';

import { updateFormState } from 'Components/Dialogs/FilterDialog/filterReducer';
import { mapValuesToPayload } from '../../../utils/payload';

const StopGoFilter = props => {
  const {
    classes,
    pageSettings: { currentFilter, currentFilterPayload }
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    state: { isLoading = true, isError = false, data },
    fieldList = serializeDownloadFilterFieldListToFormBuilder(data?.filters),
    defaultValues = serializeFieldListToValues(fieldList)
  } = useApi(stopGoURL('stopgofilters'));
  const [key, updateKey] = useState(0);
  const [resetFlag, setResetFlag] = useState(false);
  const resetButtonRef = useRef();

  const sessionKey = 'filters';
  const [sessionValue = {}, setValue, removeKey] = useSessionStorage(sessionKey);
  const {
    [sessionKey]: {
      [currentFilter]: sessionStorageValues = defaultValues,
      [currentFilterPayload]: sessionStoragePayload = defaultValues
    } = {}
  } = sessionValue;
  const [initialValues, setInitialValues] = useState(sessionStorageValues);

  useEffect(() => {
    if (resetFlag) {
      resetButtonRef?.current?.focus();
      setResetFlag(false);
    }
  }, [key]);

  useEffect(() => {
    if (isObject(initialValues)) {
      updateKey(key + 1);

      setValue({
        ...sessionValue,
        [sessionKey]: { [currentFilter]: sessionStorageValues, [currentFilterPayload]: sessionStoragePayload }
      });

      const formatPayload = mapValuesToPayload(sessionStoragePayload);
      dispatch(loadStopGo(formatPayload, t));
    }
  }, []);

  useEffect(() => {
    if (!isLoading) {
      if (typeof props.updatePrograms !== 'undefined') {
        const allPrograms = fieldList?.find(field => field.id === 'programId');
        if (allPrograms) {
          props.updatePrograms(allPrograms);
        }
      }

      setValue({
        ...sessionValue,
        [sessionKey]: { [currentFilter]: sessionStorageValues, [currentFilterPayload]: sessionStoragePayload }
      });

      dispatch(updateFormState(currentFilter, sessionStorageValues));
      dispatch({ type: 'FILTER_LOADING_SUCCESS', form: currentFilter, payload: sessionStorageValues });
    }
  }, [isLoading]);

  const onSubmit = async values => {
    const copy = cloneDeep(values);
    setValue({ ...sessionValue, [sessionKey]: { [currentFilter]: values, [currentFilterPayload]: copy } });
    dispatch(updateFormState(currentFilter, values));
    const newPayload = mapValuesToPayload(copy);
    const response = await dispatch(loadStopGo(newPayload, t));
    return response;
  };

  return isLoading ? (
    <ColumnLayout
      component={Panel}
      className={classNames(classes.columnLayoutContainer, classes.loading)}
      container
      spacing={3}
    >
      <ColumnLayout item xs={12}>
        <Spinner classes={{ root: 'spinner' }} />
      </ColumnLayout>
    </ColumnLayout>
  ) : isError ? (
    <ColumnLayout component={Panel} className={classes.columnLayoutContainer} container spacing={3}>
      <ColumnLayout item xs={12}>
        <TextWithIcon type="error" icon="error" iconSize={20}>
          {data.message}
        </TextWithIcon>
      </ColumnLayout>
    </ColumnLayout>
  ) : (
    <Form
      key={key}
      initialValues={initialValues}
      subscription={{ submitting: true }}
      onSubmit={onSubmit}
      render={({ handleSubmit, errors, form: { reset }, submitting }) => (
        <form
          className={classes.form}
          onSubmit={e => {
            e.preventDefault();
            return handleSubmit();
          }}
        >
          <ColumnLayout
            component={Panel}
            className={classes.columnLayoutContainer}
            container
            alignItems="flex-end"
            alignContent="flex-start"
            spacing={3}
          >
            {fieldList?.map(field => {
              return getField({
                field,
                props: { useColumnLayout: true, columnLayoutProps: { xs: 12 } }
              });
            })}
            <ColumnLayout item xs={12} className={classes.formPadding} />
          </ColumnLayout>
          <ButtonRenderer
            containerAriaLabel={t('stopGo.labels.actionButtons')}
            containerClassName={classes.filterButtons}
            render={() => (
              <ButtonBar alignLeft>
                <FormSpy subscription={{ errors: true }}>
                  {({ errors }) => (
                    <Button
                      variant="cta"
                      data-test-id="applyFilterButton"
                      type="submit"
                      disabled={Object.values(errors).length || submitting}
                    >
                      {t('button.apply', { hatText: t('filterDialog.title') })}
                    </Button>
                  )}
                </FormSpy>
                <Button
                  type="button"
                  data-test-id="clearFilterButton"
                  ref={resetButtonRef}
                  onClick={() => {
                    setResetFlag(true);
                    setInitialValues(defaultValues);
                    updateKey(key + 1);
                  }}
                >
                  {t('button.reset', { hatText: t('filterDialog.title') })}
                </Button>
              </ButtonBar>
            )}
          />
        </form>
      )}
    />
  );
};

export default StopGoFilter;
