import { CONTENT_DISPOSITION, CONTENT_TYPE } from 'Constants/headers';
import { FILENAME_REGEX } from 'Constants/regex';

const BINARY_DATA_TYPES = {
  arrayBuffer: 'arrayBuffer',
  base64: 'base64'
};

export const handleOpenInIframe = ({ response, source }) => {
  if (source) {
    const html = `<!DOCTYPE html>
      <html>
        <head>
          <title>Receivables Online - JP Morgan</title>
        </head>
        <body>
          <img src=${source} width="1100px" />
        </body>
      </html>`;

    const printWindow = window.open(
      'https://accesspte3.jpmorgan.com/',
      '',
      'width=1100, height=500, scrollbars=yes, resizable=yes'
    );

    const printDoc = printWindow.document;
    printDoc.write(html);
    printDoc.close();

    printWindow.print();
  } else {
    const type = response.request.getResponseHeader(CONTENT_TYPE);
    const blob = new Blob([response.data], { type });
    const blobURL = window.URL.createObjectURL(blob);

    const widthHeightStyle = 'height: 100%; width: 100%;';

    const html = `<!DOCTYPE html>
      <html style="${widthHeightStyle}">
        <head>
          <title>Receivables Online - JP Morgan</title>
        </head>
        <body style="${widthHeightStyle} margin: 0;">
        </body>
      </html>`;

    const printWindow = window.open('https://accesspte3.jpmorgan.com/', '', 'width=1100, height=500, resizable=yes');
    const printDoc = printWindow.document;
    printDoc.write(html);

    const iframe = printDoc.createElement('IFRAME');
    iframe.id = 'print-frame-id';
    iframe.src = blobURL;
    iframe.type = type;
    iframe.style = `${widthHeightStyle} margin-bottom:-5px; border:none;`;
    iframe.onload = () => {
      iframe.contentWindow.print();
    };

    printDoc.body.appendChild(iframe);
    printDoc.close();
  }
};

const base64ToArrayBuffer = base64 => {
  const binary_string = window.atob(base64);
  const len = binary_string.length;
  const bytes = new Uint8Array(len);

  for (let i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }

  return bytes.buffer;
};
export const handleDownloadNamedFile = (response, fileType, defaultFileName) => {
  const fileName = getFileName(response, defaultFileName);
  handleDownloadFile(response.data, fileName, fileType);
};

export function handleDownloadFile(data, fileName, imageType, binaryDataType = BINARY_DATA_TYPES.arrayBuffer) {
  const imageArrayBuffer =
    binaryDataType === BINARY_DATA_TYPES.arrayBuffer
      ? data
      : base64ToArrayBuffer(data.replace(/^data:image\/(png|jpeg|jpg);base64,/, ''));
  const blob = new Blob([imageArrayBuffer], { imageType });
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    window.navigator.msSaveBlob(blob, fileName);
  } else {
    const blobURL = window.URL.createObjectURL(blob);

    const tempLink = document.createElement('a');
    tempLink.href = blobURL;
    tempLink.setAttribute('download', fileName);
    tempLink.style.opacity = 0;
    tempLink.style.clip = 'rect(0, 0, 0, 0)';
    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank');
    }
    document.body.appendChild(tempLink);

    tempLink.addEventListener('click', () => {
      setTimeout(() => {
        document.body.removeChild(tempLink);
        window.URL.revokeObjectURL(blobURL);
      }, 1000);
    });
    tempLink.click();
  }
}

export const getFileName = (response, defaultFileName) => {
  const disposition = response.request.getResponseHeader(CONTENT_DISPOSITION);
  const matches = FILENAME_REGEX.exec(disposition);
  if (matches !== null && matches[1]) {
    return matches[1].replace(/['"]/g, '');
  } else {
    return defaultFileName;
  }
};

export const getFileType = data => {
  return data.request.getResponseHeader(CONTENT_TYPE);
};
