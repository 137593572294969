import api from 'api/axios-helper';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import cloneDeep from 'lodash/cloneDeep';
import sessionHelper, { sessionUser } from 'utility/sessionStorageHelper';
import { connectToScannerAndTwainDriver } from 'actions/scanControlServiceActions';
import { SCANNABLES } from 'Constants/captureTypes';
import i18n from 'i18next';

export const USER_BATCH_OWNER_REQUEST = 'USER_BATCH_OWNER_REQUEST';
export const USER_BATCH_OWNER_SUCCESS = 'USER_BATCH_OWNER_SUCCESS';
export const USER_BATCH_OWNER_FAILURE = 'USER_BATCH_OWNER_FAILURE';
export const LOAD_CAPTUREDETAILS_REQUEST = 'LOAD_CAPTUREDETAILS_REQUEST';
export const LOAD_CAPTUREDETAILS_SUCCESS = 'LOAD_CAPTUREDETAILS_SUCCESS';
export const LOAD_CAPTUREDETAILS_FAILURE = 'LOAD_CAPTUREDETAILS_FAILURE';
export const RESET_CAPTUREDETAILS = 'RESET_CAPTUREDETAILS';
export const LOAD_ITEMDETAIL_REQUEST = 'LOAD_ITEMDETAIL_REQUEST';
export const LOAD_ITEMDETAIL_SUCCESS = 'LOAD_ITEMDETAIL_SUCCESS';
export const LOAD_ITEMDETAIL_FAILURE = 'LOAD_ITEMDETAIL_FAILURE';
export const SAVE_ITEMDETAIL_REQUEST = 'SAVE_ITEMDETAIL_REQUEST';
export const SAVE_ITEMDETAIL_SUCCESS = 'SAVE_ITEMDETAIL_SUCCESS';
export const SAVE_ITEMDETAIL_FAILURE = 'SAVE_ITEMDETAIL_FAILURE';
export const UPDATE_BALANCE_REQUEST = 'UPDATE_BALANCE_REQUEST';
export const UPDATE_BALANCE_SUCCESS = 'UPDATE_BALANCE_SUCCESS';
export const UPDATE_BALANCE_FAILURE = 'UPDATE_BALANCE_FAILURE';
export const SUBMIT_BATCHES_DETAIL_REQUEST = `SUBMIT_BATCHES_DETAIL_REQUEST`;
export const SUBMIT_BATCHES_DETAIL_SUCCESS = `SUBMIT_BATCHES_DETAIL_SUCCESS`;
export const SUBMIT_BATCHES_DETAIL_FAILURE = 'SUBMIT_BATCHES_DETAIL_FAILURE';
export const DELETE_TRANSACTION_REQUEST = 'DELETE_TRANSACTION_REQUEST';
export const DELETE_TRANSACTION_SUCCESS = 'DELETE_TRANSACTION_SUCCESS';
export const DELETE_TRANSACTION_FAILURE = 'DELETE_TRANSACTION_FAILURE';
export const RESCAN_ITEM_REQUEST = 'RESCAN_ITEM_REQUEST';
export const RESCAN_ITEM_SUCCESS = 'RESCAN_ITEM_SUCCESS';
export const RESCAN_ITEM_FAILURE = 'RESCAN_ITEM_FAILURE';
export const SCAN_MORE_REQUEST = 'SCAN_MORE_REQUEST';
export const SCAN_MORE_SUCCESS = 'SCAN_MORE_SUCCESS';
export const SCAN_MORE_FAILURE = 'SCAN_MORE_FAILURE';
export const DOWNLOAD_PDF_REQUEST = 'DOWNLOAD_PDF_REQUEST';
export const DOWNLOAD_PDF_SUCCESS = 'DOWNLOAD_PDF_SUCCESS';
export const DOWNLOAD_PDF_FAILURE = 'DOWNLOAD_PDF_FAILURE';
export const CLEAR_CAPTURE_DETAIL_ERROR = 'CLEAR_CAPTURE_DETAIL_ERROR';
export const CLEAR_DELETE_TRANSACTION_ITEM = 'CLEAR_DELETE_TRANSACTION_ITEM';
export const CLEAR_PDF_DATA = 'CLEAR_PDF_DATA';
export const CLEAR_SUBMIT_BATCH = 'CLEAR_SUBMIT_BATCH';
export const HANDLE_SCAN_OPERATION = 'HANDLE_SCAN_OPERATION';
export const SET_CAN_SCAN = 'SET_CAN_SCAN';
export const CLEAR_SCAN_RESPONSE = 'CLEAR_SCAN_RESPONSE';
export const CONVERT_TRANSACTION_ITEM_REQUEST = 'CONVERT_TRANSACTION_ITEM_REQUEST';
export const CONVERT_TRANSACTION_ITEM_SUCCESS = 'CONVERT_TRANSACTION_ITEM_SUCCESS';
export const CONVERT_TRANSACTION_ITEM_FAIILURE = 'CONVERT_TRANSACTION_ITEM_FAIILURE';
export const MARK_AS_NEW_TRANSACTION_REQUEST = 'MARK_AS_NEW_TRANSACTION_REQUEST';
export const MARK_AS_NEW_TRANSACTION_SUCCESS = 'MARK_AS_NEW_TRANSACTION_SUCCESS';
export const MARK_AS_NEW_TRANSACTION_FAILURE = 'MARK_AS_NEW_TRANSACTION_FAILURE';
export const UNMARK_AS_NEW_TRANSACTION_REQUEST = 'UNMARK_AS_NEW_TRANSACTION_REQUEST';
export const UNMARK_AS_NEW_TRANSACTION_SUCCESS = 'UNMARK_AS_NEW_TRANSACTION_SUCCESS';
export const UNMARK_AS_NEW_TRANSACTION_FAILURE = 'UNMARK_AS_NEW_TRANSACTION_FAILURE';
export const SET_CAPTURE_DETAILS_ERROR = 'SET_CAPTURE_DETAILS_ERROR';

export const captureDetailsErrorTypes = {
  DEFAULT_ERROR: 'DEFAULT_ERROR',
  FETCH_CAPTURED_BATCH_DETAILS: 'FETCH_CAPTURED_BATCH_DETAILS',
  RELOAD_PAGE: 'RELOAD_PAGE',
  TRY_AGAIN: 'TRY_AGAIN',
  GO_BACK: 'GO_BACK'
};

export function loadCapturedBatchDetails() {
  const bUniqueId = sessionHelper.getItem('batchUniqueId');
  let batchNumber = Number(sessionHelper.getItem('batchNumber'));
  batchNumber = isNaN(batchNumber) ? 0 : batchNumber;
  return {
    types: [LOAD_CAPTUREDETAILS_REQUEST, LOAD_CAPTUREDETAILS_SUCCESS, LOAD_CAPTUREDETAILS_FAILURE],
    callAPI: (state, { customerId, userId }) =>
      api.fetch(
        `remotesvc/v0/capture/cust/${customerId}/user/${userId}/capturedetails?batchUniqueId=${bUniqueId}&batchNum=${batchNumber}`
      ),
    httpErrorTypes: {
      DEFAULT: captureDetailsErrorTypes.FETCH_CAPTURED_BATCH_DETAILS
    },
    errorDisplayType: 'modal',
    dispatchOnFailure: [
      () => {
        window.history.back();
      }
    ]
  };
}
export function clearCaptureDetailError() {
  return { type: CLEAR_CAPTURE_DETAIL_ERROR };
}
export function clearDeleteTransactionItem() {
  return { type: CLEAR_DELETE_TRANSACTION_ITEM };
}

export function clearPdfData() {
  return { type: CLEAR_PDF_DATA };
}

export function clearSubmitBatch() {
  return { type: CLEAR_SUBMIT_BATCH };
}

export function resetCaptureDetails() {
  return { type: RESET_CAPTUREDETAILS };
}

export const isUserBatchOwner = () => {
  return {
    types: [USER_BATCH_OWNER_REQUEST, USER_BATCH_OWNER_SUCCESS, USER_BATCH_OWNER_FAILURE],
    callAPI: (state, { customerId, userId }) =>
      api.fetch(
        `remotesvc/v0/capture/cust/${customerId}/user/${userId}/capturedetails/isCurrentUserBatchOwner?batchUniqueId=${sessionHelper.getItem(
          'batchUniqueId'
        )}`
      ),
    httpErrorTypes: {
      DEFAULT: captureDetailsErrorTypes.TRY_AGAIN,
      404: captureDetailsErrorTypes.RELOAD_PAGE,
      401: captureDetailsErrorTypes.RELOAD_PAGE,
      403: captureDetailsErrorTypes.RELOAD_PAGE
    },
    successCase: response => response.data === true,
    successCaseNotMet: response => {
      return {
        type: captureDetailsErrorTypes.RELOAD_PAGE,
        message: i18n.t('captureDetails.errorMessages.regainOwnership')
      };
    },
    dispatchOnSuccess: [connectToScannerAndTwainDriver]
  };
};

export const getItemTypeEndpt = type => (type === 'Coupon' ? 'Document' : type);

export const loadItemDetails = (customerId, userId, { transactionId, transactionDetailsId, type }, platform) => {
  return {
    types: [LOAD_ITEMDETAIL_REQUEST, LOAD_ITEMDETAIL_SUCCESS, LOAD_ITEMDETAIL_FAILURE],
    shouldCallAPI: state => state.capturedetail.shouldLoadItemDetails === true,
    callAPI: () =>
      api.fetch(
        `remotesvc/v0/capture/cust/${customerId}/user/${userId}/capturedetails/${getItemTypeEndpt(
          type
        ).toLowerCase()}-detail/${transactionDetailsId}?platform=${platform}`
      ),
    httpErrorTypes: {
      DEFAULT: captureDetailsErrorTypes.TRY_AGAIN,
      404: captureDetailsErrorTypes.RELOAD_PAGE,
      401: captureDetailsErrorTypes.RELOAD_PAGE,
      403: captureDetailsErrorTypes.RELOAD_PAGE
    }
  };
};

export const saveItemDetails = ({ transactionId, transactionDetailsId, type }, newDetails) => {
  const data = cloneDeep(newDetails);
  return {
    types: [SAVE_ITEMDETAIL_REQUEST, SAVE_ITEMDETAIL_SUCCESS, SAVE_ITEMDETAIL_FAILURE],
    callAPI: (state, { customerId, userId }) =>
      api.save(
        `remotesvc/v0/capture/cust/${customerId}/user/${userId}/capturedetails/transaction/${transactionId}/detail/${transactionDetailsId}/update${getItemTypeEndpt(
          type
        )}`,
        data
      ),
    httpErrorTypes: {
      DEFAULT: captureDetailsErrorTypes.TRY_AGAIN,
      401: captureDetailsErrorTypes.RELOAD_PAGE,
      403: captureDetailsErrorTypes.RELOAD_PAGE,
      404: captureDetailsErrorTypes.RELOAD_PAGE
    },
    dispatchOnSuccess: [loadCapturedBatchDetails]
  };
};

export function balanceBatch(payload) {
  const bUniqueId = sessionHelper.getItem('batchUniqueId');
  return {
    types: [UPDATE_BALANCE_REQUEST, UPDATE_BALANCE_SUCCESS, UPDATE_BALANCE_FAILURE],
    callAPI: (state, { customerId, userId }) =>
      api.save(
        `remotesvc/v0/capture/cust/${customerId}/user/${userId}/capturedetails/update?batchUniqueId=${bUniqueId}`,
        payload
      ),
    httpErrorTypes: {
      DEFAULT: captureDetailsErrorTypes.TRY_AGAIN,
      400: captureDetailsErrorTypes.TRY_AGAIN,
      401: captureDetailsErrorTypes.RELOAD_PAGE,
      403: captureDetailsErrorTypes.RELOAD_PAGE,
      404: captureDetailsErrorTypes.GO_BACK
    },
    dispatchOnSuccess: [loadCapturedBatchDetails]
  };
}

export function submitBatchesDetail() {
  const errorMessageReducer = (errorMsg, { invalid, duplicate, deleted, submitted }, i) => {
    const error = invalid || duplicate || deleted || submitted;
    return i === 0 ? error : `${errorMsg} ${error}`;
  };
  const errorAction = {
    invalid: captureDetailsErrorTypes.TRY_AGAIN,
    duplicate: captureDetailsErrorTypes.TRY_AGAIN,
    deleted: captureDetailsErrorTypes.GO_BACK,
    submitted: captureDetailsErrorTypes.GO_BACK
  };
  const successCaseNotMetAction = response => {
    let actionType;
    if (isArray(response.data.errorMessage) && response.data.errorMessage.length > 0) {
      actionType = Object.keys(response.data.errorMessage[0])[0];
    }
    return actionType ? errorAction[actionType] : captureDetailsErrorTypes.TRY_AGAIN;
  };

  const batchUniqueId = sessionHelper.getItem('batchUniqueId');
  const batch = { batchDetails: [{ batchUniqueId: batchUniqueId }] };

  return {
    types: [SUBMIT_BATCHES_DETAIL_REQUEST, SUBMIT_BATCHES_DETAIL_SUCCESS, SUBMIT_BATCHES_DETAIL_FAILURE],
    callAPI: (state, { customerId, userId }) =>
      api.save(`remotesvc/v0/capture/cust/${customerId}/user/${userId}/capturedetails/submit`, batch),
    successCase: response =>
      !response.data.errorMessage || (isArray(response.data.errorMessage) && response.data.errorMessage.length === 0),
    successCaseNotMet: response => {
      const message = response.data.errorMessage.reduce(errorMessageReducer, '');
      return {
        type: successCaseNotMetAction(response),
        message
      };
    },
    httpErrorTypes: {
      DEFAULT: captureDetailsErrorTypes.TRY_AGAIN,
      401: captureDetailsErrorTypes.RELOAD_PAGE,
      403: captureDetailsErrorTypes.RELOAD_PAGE,
      404: captureDetailsErrorTypes.GO_BACK
    }
  };
}

export const deleteTransactionItem = ({ transactionId, transactionDetailsId }, override) => {
  return {
    types: [DELETE_TRANSACTION_REQUEST, DELETE_TRANSACTION_SUCCESS, DELETE_TRANSACTION_FAILURE],
    callAPI: (state, { customerId, userId }) =>
      api.save(
        `remotesvc/v0/capture/cust/${customerId}/user/${userId}/capturedetails/transaction/${transactionId}/detail/${transactionDetailsId}/delete?override=${override}`
      ),
    httpErrorTypes: {
      DEFAULT: captureDetailsErrorTypes.TRY_AGAIN,
      401: captureDetailsErrorTypes.RELOAD_PAGE,
      403: captureDetailsErrorTypes.RELOAD_PAGE,
      404: captureDetailsErrorTypes.RELOAD_PAGE
    }
  };
};

export const convertTransactionItemType = ({ type, transactionId, transactionDetailsId }, captureType) => {
  const action =
    type === 'Check' ? (captureType === SCANNABLES ? 'convertToCoupon' : 'convertToDocument') : 'convertToCheck';
  return {
    types: [CONVERT_TRANSACTION_ITEM_REQUEST, CONVERT_TRANSACTION_ITEM_SUCCESS, CONVERT_TRANSACTION_ITEM_FAIILURE],
    params: { actionedUponItemId: transactionDetailsId },
    callAPI: (state, { customerId, userId }) =>
      api.save(
        `remotesvc/v0/capture/cust/${customerId}/user/${userId}/capturedetails/transaction/${transactionId}/detail/${transactionDetailsId}/${action}`
      ),
    httpErrorTypes: {
      DEFAULT: captureDetailsErrorTypes.TRY_AGAIN,
      401: captureDetailsErrorTypes.RELOAD_PAGE,
      403: captureDetailsErrorTypes.RELOAD_PAGE,
      404: captureDetailsErrorTypes.RELOAD_PAGE
    },
    dispatchOnSuccess: [loadCapturedBatchDetails]
  };
};

export function markAsNewTransction({ transactionId, transactionDetailsId }) {
  return {
    types: [MARK_AS_NEW_TRANSACTION_REQUEST, MARK_AS_NEW_TRANSACTION_SUCCESS, MARK_AS_NEW_TRANSACTION_FAILURE],
    params: { actionedUponItemId: transactionDetailsId },
    callAPI: (state, { customerId, userId }) =>
      api.save(
        `remotesvc/v0/capture/cust/${customerId}/user/${userId}/capturedetails/transaction/${transactionId}/detail/${transactionDetailsId}/markAsNewTransaction`
      ),
    httpErrorTypes: {
      DEFAULT: captureDetailsErrorTypes.TRY_AGAIN,
      401: captureDetailsErrorTypes.RELOAD_PAGE,
      403: captureDetailsErrorTypes.RELOAD_PAGE,
      404: captureDetailsErrorTypes.RELOAD_PAGE
    },
    dispatchOnSuccess: [loadCapturedBatchDetails]
  };
}

export const unMarkAsNewTransction = ({ transactionId, transactionDetailsId }) => {
  return {
    types: [UNMARK_AS_NEW_TRANSACTION_REQUEST, UNMARK_AS_NEW_TRANSACTION_SUCCESS, UNMARK_AS_NEW_TRANSACTION_FAILURE],
    params: { actionedUponItemId: transactionDetailsId },
    callAPI: (state, { customerId, userId }) =>
      api.save(
        `remotesvc/v0/capture/cust/${customerId}/user/${userId}/capturedetails/transaction/${transactionId}/detail/${transactionDetailsId}/unmarkATransaction`
      ),
    httpErrorTypes: {
      DEFAULT: captureDetailsErrorTypes.TRY_AGAIN,
      401: captureDetailsErrorTypes.RELOAD_PAGE,
      403: captureDetailsErrorTypes.RELOAD_PAGE,
      404: captureDetailsErrorTypes.RELOAD_PAGE
    },
    dispatchOnSuccess: [loadCapturedBatchDetails]
  };
};

export const scanMore = (payload, scanInfoObject) => {
  return {
    types: [SCAN_MORE_REQUEST, SCAN_MORE_SUCCESS, SCAN_MORE_FAILURE],
    successCase: response => isEmpty(response.data.errorMessage),
    successCaseNotMet: response => {
      return { type: captureDetailsErrorTypes.RELOAD_PAGE, message: response.data.errorMessage };
    },
    callAPI: (state, { customerId, userId }) =>
      api.save(
        `remotesvc/v0/capture/cust/${customerId}/user/${userId}/job/${scanInfoObject.jobNumber}/scanmore`,
        payload
      ),
    httpErrorTypes: {
      DEFAULT: captureDetailsErrorTypes.TRY_AGAIN,
      401: captureDetailsErrorTypes.RELOAD_PAGE,
      403: captureDetailsErrorTypes.RELOAD_PAGE,
      404: captureDetailsErrorTypes.GO_BACK
    },
    dispatchOnSuccess: [loadCapturedBatchDetails]
  };
};

export function reScanItem(rescanInfoObject, payload) {
  return {
    types: [RESCAN_ITEM_REQUEST, RESCAN_ITEM_SUCCESS, RESCAN_ITEM_FAILURE],
    callAPI: (state, { customerId, userId }) =>
      api.save(
        `remotesvc/v0/capture/cust/${customerId}/user/${userId}/capturedetails/transaction/${rescanInfoObject.transactionId}/detail/${rescanInfoObject.transactionDetailId}/job/${rescanInfoObject.jobNumber}/rescan`,
        payload
      ),
    httpErrorTypes: {
      DEFAULT: captureDetailsErrorTypes.TRY_AGAIN,
      401: captureDetailsErrorTypes.RELOAD_PAGE,
      403: captureDetailsErrorTypes.RELOAD_PAGE,
      404: captureDetailsErrorTypes.RELOAD_PAGE
    },
    dispatchOnSuccess: [loadCapturedBatchDetails]
  };
}

export function clearScanResponse() {
  return { type: CLEAR_SCAN_RESPONSE };
}

export function downloadPDFRequest() {
  return { type: DOWNLOAD_PDF_REQUEST };
}
export function downloadPDFSuccess(pdfData) {
  return { type: DOWNLOAD_PDF_SUCCESS, pdfData };
}
export function downloadPDFFailure(pdfError) {
  return {
    type: DOWNLOAD_PDF_FAILURE,
    error: {
      type: captureDetailsErrorTypes.TRY_AGAIN,
      message: i18n.t('captureDetails.errorMessages.unableToProcess')
    }
  };
}
export function downloadPDF(batches) {
  const batchesToDownload = !isEmpty(batches) ? batches : { batchUniqueIds: [sessionHelper.getItem('batchUniqueId')] };
  const { customerId, userId } = sessionUser.getLoggedInUser().getId();
  return dispatch => {
    return api
      .fetchPdf(`remotesvc/v0/capture/cust/${customerId}/user/${userId}/batch/submissionreport`, batchesToDownload)
      .then(pdfResponse => {
        dispatch(downloadPDFSuccess(pdfResponse));
      })
      .catch(pdfError => {
        dispatch(downloadPDFFailure(pdfError));
      });
  };
}
export function setScanEvent(scanOperation, args) {
  return { type: HANDLE_SCAN_OPERATION, scanOperation, args };
}
export function setCanScan(canScan) {
  return { type: SET_CAN_SCAN, canScan };
}
export function setCaptureDetailsError(error) {
  return { type: SET_CAPTURE_DETAILS_ERROR, error };
}
