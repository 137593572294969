import isNull from 'lodash/isNull';
import isEmpty from 'lodash/isEmpty';

export const USER_SESSION_KEY = 'user';
const BROADCAST_FLAG = 'broadcastFlag';
const ONLINE_REPORTS_KEY = 'onlineReports';
const FULL_PORTAL = 1;

const createCustomer = ({ id = -1, name = '', portalTypeCode = -1 } = {}) => ({
  id,
  name,
  portalTypeCode
});

const createUser = ({
  userId = -1,
  userFullName = '',
  email1 = '',
  email2 = '',
  geUserId = '',
  lastLogonDate = '',
  portalSsoName = '',
  extendedTimeoutIndicator = -1,
  userCulture = {},
  userCurrencyFormat = {},
  userDateFormat = {},
  userPreferedScannerCode = '',
  userStartPage = {},
  userTimezone = {},
  userTimeFormat = {},
  userCustomer = {},
  userScanner = {},
  userAlertDestination = {}
} = {}) => ({
  userId,
  userFullName,
  email1,
  email2,
  geUserId,
  lastLogonDate,
  portalSsoName,
  extendedTimeoutIndicator,
  userCulture,
  userCurrencyFormat,
  userDateFormat,
  userPreferedScannerCode,
  userStartPage,
  userTimezone,
  userTimeFormat,
  userCustomer,
  userScanner,
  userAlertDestination,

  isEmpty: () => {
    return userId === -1;
  },
  getId: () => {
    const customer = createCustomer(userCustomer);

    return {
      userId,
      customerId: customer.id
    };
  },
  isFullPortal: () => {
    return createCustomer(userCustomer).portalTypeCode === FULL_PORTAL;
  }
});

const sessionUser = {
  setLoggedInUser(user) {
    if (isEmpty(user)) {
      sessionStorage.removeItem(USER_SESSION_KEY);
    } else {
      sessionStorage.setItem(USER_SESSION_KEY, JSON.stringify(user));
    }
  },
  getLoggedInUser() {
    const userdata = JSON.parse(sessionStorage.getItem(USER_SESSION_KEY)) || {};

    return createUser(userdata);
  },
  isSimulationMode() {
    return !isEmpty(JSON.parse(sessionStorage.getItem('simulation')));
  }
};

const sessionBroadcast = {
  setBroadcastFlag(flag) {
    return sessionStorage.setItem(BROADCAST_FLAG, flag);
  },
  getBroadcastFlag() {
    return sessionStorage.getItem(BROADCAST_FLAG) || false;
  }
};

const sessionOnlineReports = {
  clearSelection() {
    sessionStorage.removeItem(ONLINE_REPORTS_KEY);
  },
  getSelection() {
    return JSON.parse(sessionStorage.getItem(ONLINE_REPORTS_KEY)) || {};
  },
  setSelection(selectedCriteria = [], initialValues, hideFilter = false) {
    sessionStorage.setItem(ONLINE_REPORTS_KEY, JSON.stringify({ selectedCriteria, initialValues, hideFilter }));
  }
};

export { sessionBroadcast, sessionOnlineReports, sessionUser };

export default {
  setItem(itemName, itemValue) {
    sessionStorage.setItem(itemName, itemValue);
  },
  getItem(itemName, parse = false) {
    const payload = sessionStorage.getItem(itemName);
    if (!parse) {
      return payload;
    } else {
      return !isNull(payload) ? JSON.parse(payload) : undefined;
    }
  },
  removeItem(itemName) {
    sessionStorage.removeItem(itemName);
  }
};
