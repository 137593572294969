import axios from 'axios';

import {
  SETUP_CANCELLATION_TOKEN,
  INIT_SCAN_SERVICE_SUCCESS,
  HANDLE_SCAN_ERROR,
  MORE_THAN_ONE_AVAIL_SCAN_SOURCE,
  ONE_AVAIL_SCAN_SOURCE,
  SELECT_TWAIN_SCAN_SOURCE,
  IS_SCANNING,
  STOP_SCANNING_SESSION,
  UPDATE_SCANNING_STATUS,
  PROCESSING_ITEM_NUM,
  CLEAR_BATCH_RESPONSE,
  CLEAR_FINISHED_PROCESSING,
  FINISHED_PROCESSING,
  INIT_PROCESSED_COUNT,
  CLEAR_SCAN_ERROR,
  CLEAR_SCAN_SOURCE,
  START_TWAIN_SESSION_FINISHED,
  SELECT_TWAIN_SCAN_SOURCE_SUCCESS,
  DEQUEUE_SCAN_ACTION,
  ENQUEUE_SCAN_ACTION,
  STOP_CALLED
} from '../actions/scanControlServiceActions';

export const initialState = {
  error: undefined,
  startTwainSessionFinished: false,
  twainScanSources: [],
  selectedTwainScanSource: null,
  scanningStatus: {},
  batchResponse: {},
  finishedProcessing: false,
  sourceConfirmed: false,
  queue: [],
  stopScanCalled: false
};

function setupCancellationToken(state, action) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  return {
    ...state,
    cancelToken: source
  };
}

function initScanServiceSuccess(state, action) {
  return {
    ...state,
    ...initialState,
    selectedTwainScanSource: state.selectedTwainScanSource,
    sessionId: action.sessionId
  };
}

function handleScanError(state, action) {
  return {
    ...state,
    error: action.error
  };
}
function handleMoreThanOneAvailScanSource(state, action) {
  return {
    ...state,
    twainScanSources: action.twainScanSources
  };
}

function handleOneAvailScanSource(state, action) {
  return {
    ...state,
    twainScanSources: action.twainScanSources,
    selectedTwainScanSource: action.selectedTwainScanSource
  };
}

function handleStopScanningSession(state, action) {
  return {
    ...state,
    scanningStatus: {},
    sessionId: '',
    error: undefined,
    startTwainSessionFinished: false
  };
}

function handleSelectionOfTwainScanSource(state, action) {
  return {
    ...state,
    selectedTwainScanSource: action.selectedSource,
    sourceConfirmed: true
  };
}

function handleClearTwainScanSource(state, action) {
  return {
    ...state,
    selectedTwainScanSource: null
  };
}

function handleIsScanning(state, action) {
  return {
    ...state,
    scanningStatus: {
      currentPage: -1,
      isScanning: true,
      pageCount: -1,
      selectedSource: '',
      twainError: { code: -1, message: '' }
    },
    error: undefined,
    finishedProcessing: false
  };
}
function handleUpdateScanningStatus(state, action) {
  return {
    ...state,
    scanningStatus: action.scanningStatus
  };
}
function handleProcessingItemNum(state, action) {
  return {
    ...state,
    scanningStatus: {
      ...state.scanningStatus,
      processedCount: action.processedCount
    }
  };
}
function handleFinishedProcessing(state, action) {
  return {
    ...state,
    scanningStatus: {
      ...state.scanningStatus,
      isProcessingImages: false,
      processedCount: 0
    },
    batchResponse: action.batchResponse,
    finishedProcessing: true
  };
}

function handleClearFinishProcessing(state, action) {
  return {
    ...state,
    finishedProcessing: false
  };
}

function handleInitProcessedCount(state, action) {
  return {
    ...state,
    scanningStatus: { ...state.scanningStatus, isProcessingImages: true, processedCount: 0 }
  };
}

function handleClearBatchResponse(state, action) {
  return {
    ...state,
    batchResponse: {}
  };
}
function handleClearScanError(state, action) {
  return {
    ...state,
    error: undefined,
    scanningStatus: {}
  };
}
function handleStartTwainSession(state, action) {
  return {
    ...state,
    startTwainSessionFinished: true
  };
}

function handleEnqueue(state, action) {
  const { queue } = state;
  return {
    ...state,
    queue: [...queue, action.action]
  };
}
function handleDequeue(state, action) {
  const { queue } = state;
  return {
    ...state,
    queue: queue.slice(1)
  };
}
function handleStopCalled(state, action) {
  return {
    ...state,
    stopScanCalled: action.value
  };
}

export default function scanControlReducer(state = initialState, action) {
  const handlers = {
    [CLEAR_BATCH_RESPONSE]: handleClearBatchResponse,
    [CLEAR_FINISHED_PROCESSING]: handleClearFinishProcessing,
    [CLEAR_SCAN_ERROR]: handleClearScanError,
    [CLEAR_SCAN_SOURCE]: handleClearTwainScanSource,
    [DEQUEUE_SCAN_ACTION]: handleDequeue,
    [ENQUEUE_SCAN_ACTION]: handleEnqueue,
    [FINISHED_PROCESSING]: handleFinishedProcessing,
    [HANDLE_SCAN_ERROR]: handleScanError,
    [INIT_PROCESSED_COUNT]: handleInitProcessedCount,
    [INIT_SCAN_SERVICE_SUCCESS]: initScanServiceSuccess,
    [IS_SCANNING]: handleIsScanning,
    [MORE_THAN_ONE_AVAIL_SCAN_SOURCE]: handleMoreThanOneAvailScanSource,
    [ONE_AVAIL_SCAN_SOURCE]: handleOneAvailScanSource,
    [PROCESSING_ITEM_NUM]: handleProcessingItemNum,
    [SELECT_TWAIN_SCAN_SOURCE]: handleSelectionOfTwainScanSource,
    [SETUP_CANCELLATION_TOKEN]: setupCancellationToken,
    [START_TWAIN_SESSION_FINISHED]: handleStartTwainSession,
    [STOP_CALLED]: handleStopCalled,
    [STOP_SCANNING_SESSION]: handleStopScanningSession,
    [UPDATE_SCANNING_STATUS]: handleUpdateScanningStatus
  };

  if (handlers.hasOwnProperty(action.type)) {
    return handlers[action.type](state, action);
  } else {
    return state;
  }
}
