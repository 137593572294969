import { OPEN_DIALOG, CLOSE_DIALOG, UPDATE_CONTENT } from '../actions/modalActions';

export const initialState = {
  isOpen: false,
  title: '',
  state: '',
  message: '',
  action: ''
};

function openDialog(state, action) {
  return { ...action.config, isOpen: true };
}

function closeDialog(state, action) {
  return { ...state, isOpen: false };
}

function updateContent(state, action) {
  return { ...state, ...action.config, isOpen: state.isOpen };
}

export default function modalReducer(state = initialState, action) {
  const handlers = {
    [OPEN_DIALOG]: openDialog,
    [CLOSE_DIALOG]: closeDialog,
    [UPDATE_CONTENT]: updateContent
  };
  return handlers.hasOwnProperty(action.type) ? handlers[action.type](state, action) : state;
}
