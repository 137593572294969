import { useAriaAnnouncer } from '@jpmuitk/aria-announcer';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

import config from 'config/app';
import { useTranslation } from 'react-i18next';

const useGridErrorAnnouncement = (props, route) => {
  const { announce } = useAriaAnnouncer();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (props.totalRows > config(t).grid[route]?.maxRows) {
      announce(t('pageWithGrid.messages.maxRowsExceeded', { maxRows: config(t).grid[route]?.maxRows }));
    } else if (props.errors) {
      const msg = typeof props.errors === 'object' ? props.errors.message : props.errors;
      announce(msg);
    }
  }, [props.totalRows, props.errors]);
};

export default useGridErrorAnnouncement;
