import { reducer, initialState as initialStateReducer } from './reducer';
import { inboxAlertReducer, initialState as initialStateForInbox } from './inboxAlertReducer';

const initialState = {
  ...initialStateReducer,
  ...initialStateForInbox,
  isFetchingAlertCount: false,
  countErrors: {},
  alertCount: 0,
  isFetchingAlertTypes: false,
  alertTypes: [],
  isFetchingAlertCriteria: false,
  alertCriteria: {},
  saveMessage: '',
  isFetchingAlertDetails: false,
  alertDetails: {},
  isFetchingSaveAlert: false,
  updateMessage: ''
};

export default function alertsReducer(state = initialState, action) {
  const handlers = {
    ...reducer,
    ...inboxAlertReducer
  };

  return handlers.hasOwnProperty(action.type) ? handlers[action.type](state, action) : state;
}
