import cssVariables from 'Constants/_variables.module.scss';

export const styles = ({ palette }) => {
  return {
    root: {
      display: 'flex',
      alignItems: 'baseline'
    },
    error: {
      color: palette.toolkit.red500
    },
    warning: {
      color: palette.toolkit.orange700
    },
    info: {
      color: palette.toolkit.blue500
    },
    page: {
      '&:first-child': {
        marginTop: 0
      },
      margin: `${cssVariables.space1} 0`,
      padding: `0 ${cssVariables.space1}`
    },
    panel: {
      padding: `${cssVariables.panelGutter / 2}px ${cssVariables.panelGutter}px`
    },
    sidepanel: {
      padding: `${cssVariables.sidepanelPadding / 2}px ${cssVariables.sidepanelPadding}px`
    },
    strong: {
      fontWeight: 600
    },
    icon: {
      paddingRight: `${cssVariables.panelGutter / 2}px`
    }
  };
};
