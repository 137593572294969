import React from 'react';
import { withStyles } from '@jpmuitk/theme';
import { ColumnLayout } from '@jpmuitk/column-layout';
import { useTranslation } from 'react-i18next';
import { grandItemCountStyles } from './styles';
import isNumber from 'lodash/isNumber';

const GrandItemCount = props => {
  const { selectedRowCount, totalRowCount, classes } = props;
  const { t } = useTranslation();

  return (
    <>
      {isNumber(totalRowCount) && (
        <span className={classes.grandItem} id={'totalRowCount'} data-test-id={'totalRowCount'}>
          {t('pageWithGrid.label.itemTotal', { count: totalRowCount })}
        </span>
      )}
      {isNumber(selectedRowCount) && (
        <span className={classes.grandItem} id={'selectedRowCount'} data-test-id={'selectedRowCount'}>
          {t('pageWithGrid.label.selectedTotal', {
            count: selectedRowCount,
            hatText: t('pageWithGrid.label.row', { count: selectedRowCount })
          })}
        </span>
      )}
    </>
  );
};

export default withStyles(grandItemCountStyles, { name: 'GrandItemCount' })(GrandItemCount);
