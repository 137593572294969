import api from 'api';
import { sessionUser } from 'utility/sessionStorageHelper';

export const LOAD_SUPPORT_PAGE_REQUEST = 'LOAD_SUPPORT_PAGE_REQUEST';
export const LOAD_SUPPORT_PAGE_SUCCESS = 'LOAD_SUPPORT_PAGE_SUCCESS';
export const LOAD_SUPPORT_PAGE_FAILURE = 'LOAD_SUPPORT_PAGE_FAILURE';
export const RESET_SUPPORT_PAGE = 'RESET_SUPPORT_PAGE';
export const DOWNLOAD_PRODUCT_GUIDE_REQUEST = 'DOWNLOAD_PRODUCT_GUIDE_REQUEST';
export const DOWNLOAD_PRODUCT_GUIDE_SUCCESS = 'DOWNLOAD_PRODUCT_GUIDE_SUCCESS';
export const DOWNLOAD_PRODUCT_GUIDE_FAILURE = 'DOWNLOAD_PRODUCT_GUIDE_FAILURE';

export const supportErrors = {
  LOAD_SUPPORT_PAGE: 'LOAD_SUPPORT_PAGE',
  DOWNLOAD_PRODUCT_GUIDE: 'DOWNLOAD_PRODUCT_GUIDE'
};

export const loadSupportPageInfo = () => {
  const userId = sessionUser.getLoggedInUser().getId().userId;
  const custId = sessionUser.getLoggedInUser().userCustomer.id;

  return {
    types: [LOAD_SUPPORT_PAGE_REQUEST, LOAD_SUPPORT_PAGE_SUCCESS, LOAD_SUPPORT_PAGE_FAILURE],
    callAPI: () => api.fetch(`authsvc/v0/auth/system/cust/${custId}/user/${userId}/support-page`),
    httpErrorTypes: {
      DEFAULT: supportErrors.LOAD_SUPPORT_PAGE
    }
  };
};

export const downloadProductGuide = productGuideId => {
  const userinfo = sessionUser.getLoggedInUser();
  const { userId, customerId } = userinfo.getId();
  return {
    types: [DOWNLOAD_PRODUCT_GUIDE_REQUEST, DOWNLOAD_PRODUCT_GUIDE_SUCCESS, DOWNLOAD_PRODUCT_GUIDE_FAILURE],
    callAPI: () =>
      api.fetchFileWithGet(`usersvc/v0/cust/${customerId}/user/${userId}/productguide/${productGuideId}/download`),
    httpErrorTypes: {
      DEFAULT: supportErrors.DOWNLOAD_PRODUCT_GUIDE
    }
  };
};

export const resetSupportPage = () => ({
  type: RESET_SUPPORT_PAGE
});
