import i18n from 'i18n';

export const NUMERIC_FIELD_LIST = ['amount'];
export const RENDERER_TYPE = 'renderer';

export const defaultColDef = {
  suppressMenu: true,
  suppressMovable: true,
  resizable: false,
  sortable: true
};

export const checkboxColDefs = {
  headerName: i18n.t('sde.label.checkbox'),
  field: 'checkbox',
  maxWidth: 30,
  checkboxSelection: ({ columnApi }) => columnApi.getRowGroupColumns().length === 0,
  headerCheckboxSelection: ({ columnApi }) => columnApi.getRowGroupColumns().length === 0,
  sortable: false
};
