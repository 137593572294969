import api from 'api';
import { httpErrorTypes, errorActions } from 'utility/error';
import { handleDownloadFile, getFileName } from 'utility/downloadUtility';
import { CONTENT_TYPE } from 'Constants/headers';
import { sdeTemplate } from 'Constants/defaultFileNames';
import isEmpty from 'lodash/isEmpty';
import i18n from 'i18n';

export const CLEAR_REMITTANCE_ADVICE_DETAILS = 'CLEAR_REMITTANCE_ADVICE_DETAILS';
export const LOAD_REMITTANCE_DETAILS_REQUEST = 'LOAD_REMITTANCE_DETAILS_REQUEST';
export const LOAD_REMITTANCE_DETAILS_SUCCESS = 'LOAD_REMITTANCE_DETAILS_SUCCESS';
export const LOAD_REMITTANCE_DETAILS_FAILURE = 'LOAD_REMITTANCE_DETAILS_FAILURE';
export const LOAD_PROGRAM_CURRENCIES_REQUEST = 'LOAD_PROGRAM_CURRENCIES_REQUEST';
export const LOAD_PROGRAM_CURRENCIES_SUCCESS = 'LOAD_PROGRAM_CURRENCIES_SUCCESS';
export const LOAD_PROGRAM_CURRENCIES_FAILURE = 'LOAD_PROGRAM_CURRENCIES_FAILURE';
export const GET_ADVICE_DETAILS_FOR_CREATION_REQUEST = 'GET_ADVICE_DETAILS_FOR_CREATION_REQUEST';
export const GET_ADVICE_DETAILS_FOR_CREATION_SUCCESS = 'GET_ADVICE_DETAILS_FOR_CREATION_SUCCESS';
export const GET_ADVICE_DETAILS_FOR_CREATION_FAILURE = 'GET_ADVICE_DETAILS_FOR_CREATION_FAILURE';
export const SAVE_REMITTANCE_ADVICE_DETAILS_REQUEST = 'SAVE_REMITTANCE_ADVICE_DETIALS_REQUEST';
export const SAVE_REMITTANCE_ADVICE_DETAILS_SUCCESS = 'SAVE_REMITTANCE_ADVICE_DETAILS_SUCCESS';
export const SAVE_REMITTANCE_ADVICE_DETAILS_FAILURE = 'SAVE_REMITTANCE_ADVICE_DETAILS_FAILURE';
export const CREATE_REMITTANCE_ADVICE_DETAILS_REQUEST = 'CREATE_REMITTANCE_ADVICE_DETAILS_REQUEST';
export const CREATE_REMITTANCE_ADVICE_DETAILS_SUCCESS = 'CREATE_REMITTANCE_ADVICE_DETAILS_SUCCESS';
export const CREATE_REMITTANCE_ADVICE_DETAILS_FAILURE = 'CREATE_REMITTANCE_ADVICE_DETAILS_FAILURE';
export const DOWNLOAD_REMITTANCE_ADVICE_TEMPLATE_FILE_REQUEST = 'DOWNLOAD_REMITTANCE_ADVICE_TEMPLATE_FILE_REQUEST';
export const DOWNLOAD_REMITTANCE_ADVICE_TEMPLATE_FILE_SUCCESS = 'DOWNLOAD_REMITTANCE_ADVICE_TEMPLATE_FILE_SUCCESS';
export const DOWNLOAD_REMITTANCE_ADVICE_TEMPLATE_FILE_FAILURE = 'DOWNLOAD_REMITTANCE_ADVICE_TEMPLATE_FILE_FAILURE';
export const RELOAD_REFERENCES_REM_ADV_REQUEST = 'RELOAD_REFERENCES_REM_ADV_REQUEST';
export const RELOAD_REFERENCES_REM_ADV_SUCCESS = 'RELOAD_REFERENCES_REM_ADV_SUCCESS';
export const RELOAD_REFERENCES_REM_ADV_FAILURE = 'RELOAD_REFERENCES_REM_ADV_FAILURE';
export const UPLOAD_REMITTANCE_ADVICE_DETAILS_REQUEST = 'UPLOAD_REMITTANCE_ADVICE_DETAILS_REQUEST';
export const UPLOAD_REMITTANCE_ADVICE_DETAILS_SUCCESS = 'UPLOAD_REMITTANCE_ADVICE_DETAILS_SUCCESS';
export const UPLOAD_REMITTANCE_ADVICE_DETAILS_FAILURE = 'UPLOAD_REMITTANCE_ADVICE_DETAILS_FAILURE';
export const DELETE_REMITTANCE_DETAILS_REQUEST = 'DELETE_REMITTANCE_DETAILS_REQUEST';
export const DELETE_REMITTANCE_DETAILS_SUCCESS = 'DELETE_REMITTANCE_DETAILS_SUCCESS';
export const DELETE_REMITTANCE_DETAILS_FAILURE = 'DELETE_REMITTANCE_DETAILS_FAILURE';

const handleDownloadTemplate = () => (dispatch, getState) => {
  const state = getState();
  const templateData = state.adviceDetails.templateData;
  const type = templateData.request.getResponseHeader(CONTENT_TYPE);
  const fileName = getFileName(templateData, sdeTemplate);
  handleDownloadFile(templateData.data, fileName, type);
};

export const loadRemittanceDetails = adviceId => ({
  types: [LOAD_REMITTANCE_DETAILS_REQUEST, LOAD_REMITTANCE_DETAILS_SUCCESS, LOAD_REMITTANCE_DETAILS_FAILURE],
  successCase: ({ data: { invoiceDetails, ...rest } }) =>
    !isEmpty(invoiceDetails) && !isEmpty(invoiceDetails.remittancesColumnList),
  successCaseNotMet: { errorType: errorActions.GO_BACK, message: i18n.t('remittanceAdviceDetails.errorMessages.unableToLoad') },
  callAPI: (state, { customerId, userId }) =>
    api.fetch(
      `remittanceadvicesvc/v0/cust/${customerId}/user/${userId}/rem/${adviceId}/remittance-advice-details/details`
    ),
  modalActions: { DEFAULT: errorActions.GO_BACK },
  httpErrorTypes
});

export const loadProgramCurrencies = () => ({
  types: [LOAD_PROGRAM_CURRENCIES_REQUEST, LOAD_PROGRAM_CURRENCIES_SUCCESS, LOAD_PROGRAM_CURRENCIES_FAILURE],
  callAPI: (state, { customerId, userId }) =>
    api.fetch(`lockboxsvc/v0/cust/${customerId}/user/${userId}/programs/program-currency`),
  httpErrorTypes,
  errorDisplayType: 'modal'
});

export const saveRemittanceAdvice = (adviceId, payload) => ({
  types: [
    SAVE_REMITTANCE_ADVICE_DETAILS_REQUEST,
    SAVE_REMITTANCE_ADVICE_DETAILS_SUCCESS,
    SAVE_REMITTANCE_ADVICE_DETAILS_FAILURE
  ],
  callAPI: (state, { customerId, userId }) =>
    api.save(
      `remittanceadvicesvc/v0/cust/${customerId}/user/${userId}/rem/${adviceId}/save-remittance-advice-details`,
      payload
    ),
  httpErrorTypes,
  errorDisplayType: 'modal'
});

export const downloadRemittanceAdviceTemplate = (adviceId, program, t) => (dispatch, getState) => {
  const remittanceId = adviceId ? `remittanceId=${adviceId}` : '';
  const programNumber = program?.number ? `programNumber=${program.number}` : 'programNumber=All';
  const programId = program?.id ? `programId=${program.id}` : '';
  const sign1 = remittanceId.length > 0 && programId.length > 0 ? '&' : '';
  const sign2 = programId.length > 0 || remittanceId.length > 0 ? '&' : '';
  const endPoint = remittanceId + sign1 + programId + sign2 + programNumber;
  return dispatch({
    types: [
      DOWNLOAD_REMITTANCE_ADVICE_TEMPLATE_FILE_REQUEST,
      DOWNLOAD_REMITTANCE_ADVICE_TEMPLATE_FILE_SUCCESS,
      DOWNLOAD_REMITTANCE_ADVICE_TEMPLATE_FILE_FAILURE
    ],
    callAPI: (state, { customerId, userId }) =>
      api.fetch(`remittanceadvicesvc/v0/cust/${customerId}/user/${userId}/remittance/download-template?${endPoint}`),
    dispatchOnSuccess: [() => handleDownloadTemplate()],
    httpErrorTypes,
    errorDisplayType: 'modal',
    errorTitle: t('remittanceAdviceDetails.errorMessages.downloadRemittanceAdviceTemplate')
  });
};

export const createRemittanceAdvice = payload => ({
  types: [
    CREATE_REMITTANCE_ADVICE_DETAILS_REQUEST,
    CREATE_REMITTANCE_ADVICE_DETAILS_SUCCESS,
    CREATE_REMITTANCE_ADVICE_DETAILS_FAILURE
  ],
  callAPI: (state, { customerId, userId }) =>
    api.save(`remittanceadvicesvc/v0/cust/${customerId}/user/${userId}/create-remittance-advice`, payload),
  httpErrorTypes,
  errorDisplayType: 'modal'
});

export const getAdviceDetailsForCreation = () => ({
  types: [
    GET_ADVICE_DETAILS_FOR_CREATION_REQUEST,
    GET_ADVICE_DETAILS_FOR_CREATION_SUCCESS,
    GET_ADVICE_DETAILS_FOR_CREATION_FAILURE
  ],
  callAPI: (state, { customerId, userId }) =>
    api.fetch(`remittanceadvicesvc/v0/cust/${customerId}/user/${userId}/rem/get-details-for-advice-creation`),
  httpErrorTypes,
  errorDisplayType: 'modal',
  modalActions: { DEFAULT: errorActions.GO_BACK }
});

export const uploadRemittanceData = (file, adviceId, program, t) => {
  const remittanceId = adviceId ? `remittanceId=${adviceId}` : '';
  const programId = program?.number !== 'All' && program.id ? `programId=${program.id}` : '';
  const allProgram = program.number === 'All' && program.number ? 'allProgramsIndicator=true' : '';
  const sign = remittanceId.length > 0 ? '&' : '';
  return {
    types: [
      UPLOAD_REMITTANCE_ADVICE_DETAILS_REQUEST,
      UPLOAD_REMITTANCE_ADVICE_DETAILS_SUCCESS,
      UPLOAD_REMITTANCE_ADVICE_DETAILS_FAILURE
    ],
    callAPI: (state, { customerId, userId }) =>
      api.postWithFile(
        `remittanceadvicesvc/v0/cust/${customerId}/user/${userId}/remittance/upload-remittance-details?` +
          remittanceId +
          sign +
          programId +
          allProgram,
        file
      ),
    httpErrorTypes,
    errorDisplayType: 'modal',
    errorTitle: t('remittanceAdviceDetails.errorMessages.uploadRemittanceData')
  };
};

export const clearRemittanceAdviceDetails = () => ({ type: CLEAR_REMITTANCE_ADVICE_DETAILS });

export const reloadReferenceColumnsForProgram = (programId, adviceId) => ({
  types: [RELOAD_REFERENCES_REM_ADV_REQUEST, RELOAD_REFERENCES_REM_ADV_SUCCESS, RELOAD_REFERENCES_REM_ADV_FAILURE],
  callAPI: (state, { customerId, userId }) => {
    let endpt = `remittanceadvicesvc/v0/cust/${customerId}/user/${userId}/remittance-advice-reference-headers?programId=${programId}&allProgramIn=${programId ===
      0}`;
    endpt += adviceId ? `&remAdvId=${adviceId}` : '';
    return api.fetch(endpt);
  },
  httpErrorTypes,
  errorDisplayType: 'modal'
});

export const deleteRemittanceAdvice = adviceId => ({
  types: [DELETE_REMITTANCE_DETAILS_REQUEST, DELETE_REMITTANCE_DETAILS_SUCCESS, DELETE_REMITTANCE_DETAILS_FAILURE],
  callAPI: (state, { customerId, userId }) =>
    api.save(
      `remittanceadvicesvc/v0/cust/${customerId}/user/${userId}/rem/${adviceId}/delete-remittance-advice-details`
    ),
  errorDisplayType: 'modal',
  httpErrorTypes
});
