import React from 'react';
import isEmpty from 'lodash/isEmpty';
const HTML_TAG_REGEX = /<(.*?)>/g;
const HTML_SPACE_CHARACTER_REGEX = /&nbsp;/g;
const WHTIESPACE_CHARACTERS_REGEX = /\s/g;

export const isMessageNonEmpty = broadcastalerts => {
  if (isEmpty(broadcastalerts)) {
    return false;
  }
  const messages = [];
  broadcastalerts.onlineWelcomeMessageList?.forEach(item =>
    messages.push(
      item.messageText
        .replace(HTML_TAG_REGEX, '')
        .replace(HTML_SPACE_CHARACTER_REGEX, '')
        .replace(WHTIESPACE_CHARACTERS_REGEX, '')
    )
  );
  broadcastalerts.publicMessageList?.forEach(item =>
    messages.push(
      item.messageText
        .replace(HTML_TAG_REGEX, '')
        .replace(HTML_SPACE_CHARACTER_REGEX, '')
        .replace(WHTIESPACE_CHARACTERS_REGEX, '')
    )
  );
  return messages.some(item => item.length > 0);
};

export function parseBroadcastMessages(message) {
  const parsedMessage = [];
  if (message) {
    message.onlineWelcomeMessageList?.forEach(msg => parsedMessage.push([<hr />, parseHTML(msg.messageText)]));
    message.publicMessageList?.forEach(msg => parsedMessage.push([<hr />, parseHTML(msg.messageText)]));
    return parsedMessage;
  }
  return parsedMessage;
}

export function parseHTML(html) {
  if (typeof html === 'string') {
    const wrapper = document.createElement('div');
    let index = 0;
    wrapper.innerHTML = html;
    const children = Array.prototype.slice.call(wrapper.childNodes);
    return children.map(child => {
      if (child.nodeName.toLowerCase() === '#text') {
        return child.textContent;
      } else {
        const props = parseAttributes(child.attributes);
        const HTML_ELEMENT = child.nodeName.toLowerCase();
        index++;
        return (
          <HTML_ELEMENT {...props} key={index + HTML_ELEMENT}>
            {child.childNodes.length !== 0 ? parseHTML(child.innerHTML) : null}
          </HTML_ELEMENT>
        );
      }
    });
  }
  return [];
}

function parseAttributes(attributes) {
  const props = {};

  for (let x = 0; x < attributes.length; x++) {
    props[attributes[x].name] = attributes[x].value;
  }
  if (props.hasOwnProperty('class')) {
    const className = props['class'];
    delete props['class'];
    props.className = className;
  }
  if (props.hasOwnProperty('for')) {
    const htmlFor = props['for'];
    delete props['for'];
    props.htmlFor = htmlFor;
  }
  if (props.hasOwnProperty('style')) {
    props['style'] = parseStyle(props['style']);
  }
  return props;
}

function parseStyle(styles) {
  const styleObject = {};
  const styleStrings = styles.split(';');
  styleStrings.map(styleString => {
    if (styleString !== '') {
      const styleParts = styleString.split(':');
      const key = styleParts[0].trim();
      const value = styleParts[1].trim();
      styleObject[parseHyphens(key)] = value;
    }
  });
  return styleObject;
}

function parseHyphens(name) {
  const names = name.split('-');
  const firstName = names.splice(0, 1)[0].toLowerCase();
  const theRest = names.map(part => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase());

  return [firstName, ...theRest].reduce((final, string) => final + string);
}
