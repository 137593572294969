import i18n from 'i18n';

import {
  CLEAR_USER_DETAILS,
  DEACTIVATE_USER_REQUEST,
  DEACTIVATE_USER_SUCCESS,
  DEACTIVATE_USER_FAILURE,
  GET_USER_DETAILS_REQUEST,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_FAILURE,
  REACTIVATE_USER_REQUEST,
  REACTIVATE_USER_SUCCESS,
  REACTIVATE_USER_FAILURE,
  RESET_PW_REQUEST,
  RESET_PW_SUCCESS,
  RESET_PW_FAILURE,
  RETRIEVE_ACTIVATION_CODE_REQUEST,
  RETRIEVE_ACTIVATION_CODE_SUCCESS,
  RETRIEVE_ACTIVATION_CODE_FAILURE,
  UNLOCK_USER_REQUEST,
  UNLOCK_USER_SUCCESS,
  UNLOCK_USER_FAILURE,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  SYNC_SELECTED_USER_ACCESS_EMAIL_REQUEST,
  SYNC_SELECTED_USER_ACCESS_EMAIL_SUCCESS,
  SYNC_SELECTED_USER_ACCESS_EMAIL_FAILURE,
  editUserErrorTypes,
  viewUserDetailsErrorTypes
} from 'routes/UserAdmin/userAdminActions';

const clearUserDetails = state => {
  return { ...state, userDetails: {} };
};

const deactivateUserRequest = (state, action) => {
  return { ...state, isFetching: true, userAction: { type: action.type } };
};

const deactivateUserSuccess = (state, action) => {
  return {
    ...state,
    isFetching: false,
    userAction: {
      type: action.type,
      completed: true,
      message: action.data.message
    },
    errors: {}
  };
};

const deactivateUserFailure = (state, action) => {
  return {
    ...state,
    isFetching: false,
    userAction: {
      type: action.type,
      completed: false
    },
    errors: action.errors
  };
};

const getUserDetailsRequest = state => {
  return { ...state, isFetching: true, userDetails: {} };
};

const getUserDetailsFailure = (state, action) => {
  return {
    ...state,
    errors: { type: editUserErrorTypes.GET_USERS, ...action.errors },
    isFetching: false,
    userDetails: {}
  };
};

const getUserDetailsSuccess = (state, action) => {
  return {
    ...state,
    errors: {},
    isFetching: false,
    userDetails: action.response.data,
    userAccount: { ...state.userAccount }
  };
};

const reactivateUserRequest = (state, action) => {
  return { ...state, isFetching: true };
};

const reactivateUserSuccess = (state, action) => {
  return {
    ...state,
    isFetching: false,
    userAccount: { ...state.userAccount, editUserSuccess: true, message: action.response.data.message }
  };
};

const reactivateUserFailure = (state, action) => {
  return {
    ...state,
    isFetching: false,
    errors: action.errors
  };
};

const resetPasswordRequest = (state, action) => {
  return { ...state, isFetching: true, userAction: { type: action.type } };
};

const resetPasswordSuccess = (state, action) => {
  return {
    ...state,
    isFetching: false,
    userAction: {
      type: action.type,
      completed: true,
      value: action.password,
      label: i18n.t('administration.labels.password')
    },
    errors: {}
  };
};

const resetPasswordFailure = (state, action) => {
  return {
    ...state,
    isFetching: false,
    userAction: {
      type: action.type,
      completed: false
    },
    errors: action.errors
  };
};

const retrieveActivationCodeRequest = (state, action) => {
  return { ...state, isFetching: true, userAction: { type: action.type } };
};

const retrieveActivationCodeSuccess = (state, action) => {
  return {
    ...state,
    isFetching: false,
    userAction: {
      type: action.type,
      completed: true,
      value: action.activationCode,
      label: i18n.t('administration.labels.activationCode')
    },
    errors: {}
  };
};

const retrieveActivationCodeFailure = (state, action) => {
  return {
    ...state,
    isFetching: false,
    userAction: {
      type: action.type,
      completed: false
    },
    errors: action.errors
  };
};

const unlockUserRequest = (state, action) => {
  return { ...state, isFetching: true, userAction: { type: action.type } };
};

const unlockUserSuccess = (state, action) => {
  return {
    ...state,
    isFetching: false,
    userAction: {
      type: action.type,
      completed: true
    },
    errors: {}
  };
};

const unlockUserFailure = (state, action) => {
  return {
    ...state,
    isFetching: false,
    userAction: {
      type: action.type,
      completed: false
    },
    errors: action.errors
  };
};

const deleteUserRequest = (state, action) => {
  return { ...state, isFetching: true, userAction: { type: action.type } };
};

const deleteUserSuccess = (state, action) => {
  return {
    ...state,
    isFetching: false,
    userAction: {
      type: action.response.type,
      completed: true,
      message: action.response.data.message
    },
    errors: {}
  };
};

const deleteUserFailure = (state, action) => {
  return {
    ...state,
    isFetching: false,
    userAction: {
      type: action.type,
      completed: false
    },
    errors: { ...action.error, type: viewUserDetailsErrorTypes.DELETE_USER }
  };
};

const getSelectedUserAccessEmailRequest = state => {
  return {
    ...state,
    isFetching: true
  };
};

const getSelectedUserAccessEmailSuccess = (state, action) => {
  const updatedEmail = action.response?.data?.emailAddress;

  return {
    ...state,
    isFetching: false,
    userDetails: {
      ...state.userDetails,
      userPersonalInfo: {
        ...state.userDetails?.userPersonalInfo,
        emailAddress: updatedEmail
      }
    }
  };
};

const getSelectedUserAccessEmailFailure = (state, action) => {
  return { ...state, isFetching: false, errors: action.error };
};

export const viewUserDetailsReducer = {
  [CLEAR_USER_DETAILS]: clearUserDetails,
  [DEACTIVATE_USER_REQUEST]: deactivateUserRequest,
  [DEACTIVATE_USER_SUCCESS]: deactivateUserSuccess,
  [DEACTIVATE_USER_FAILURE]: deactivateUserFailure,
  [GET_USER_DETAILS_REQUEST]: getUserDetailsRequest,
  [GET_USER_DETAILS_SUCCESS]: getUserDetailsSuccess,
  [GET_USER_DETAILS_FAILURE]: getUserDetailsFailure,
  [REACTIVATE_USER_REQUEST]: reactivateUserRequest,
  [REACTIVATE_USER_SUCCESS]: reactivateUserSuccess,
  [REACTIVATE_USER_FAILURE]: reactivateUserFailure,
  [RESET_PW_REQUEST]: resetPasswordRequest,
  [RESET_PW_SUCCESS]: resetPasswordSuccess,
  [RESET_PW_FAILURE]: resetPasswordFailure,
  [RETRIEVE_ACTIVATION_CODE_REQUEST]: retrieveActivationCodeRequest,
  [RETRIEVE_ACTIVATION_CODE_SUCCESS]: retrieveActivationCodeSuccess,
  [RETRIEVE_ACTIVATION_CODE_FAILURE]: retrieveActivationCodeFailure,
  [UNLOCK_USER_REQUEST]: unlockUserRequest,
  [UNLOCK_USER_SUCCESS]: unlockUserSuccess,
  [UNLOCK_USER_FAILURE]: unlockUserFailure,
  [DELETE_USER_REQUEST]: deleteUserRequest,
  [DELETE_USER_SUCCESS]: deleteUserSuccess,
  [DELETE_USER_FAILURE]: deleteUserFailure,
  [SYNC_SELECTED_USER_ACCESS_EMAIL_REQUEST]: getSelectedUserAccessEmailRequest,
  [SYNC_SELECTED_USER_ACCESS_EMAIL_SUCCESS]: getSelectedUserAccessEmailSuccess,
  [SYNC_SELECTED_USER_ACCESS_EMAIL_FAILURE]: getSelectedUserAccessEmailFailure
};
