import api from 'api';

export const VALIDATE_BATCH_REQUEST = 'VALIDATE_BATCH_REQUEST';
export const VALIDATE_BATCH_SUCCESS = 'VALIDATE_BATCH_SUCCESS';
export const VALIDATE_BATCH_FAILURE = 'VALIDATE_BATCH_FAILURE';
export const GENERATE_BATCH_REQUEST = 'GENERATE_BATCH_REQUEST';
export const GENERATE_BATCH_SUCCESS = 'GENERATE_BATCH_SUCCESS';
export const GENERATE_BATCH_FAILURE = 'GENERATE_BATCH_FAILURE';

export function loadValidateBatchRequest(payload) {
  return { type: VALIDATE_BATCH_REQUEST, payload };
}

export function loadValidateBatchSuccess(response) {
  return { type: VALIDATE_BATCH_SUCCESS, response };
}

export function loadValidateBatch(payload) {
  return dispatch => {
    return api
      .save('remotesvc/v0/capture/batch/validate', payload)
      .then(data => {
        dispatch(loadValidateBatchSuccess(data.data));
      })
      .catch(error => {
        throw error;
      });
  };
}

export function generateBatchRequest() {
  return { type: GENERATE_BATCH_REQUEST };
}

export function generateBatchSuccess(response) {
  return { type: GENERATE_BATCH_SUCCESS, response };
}

export function generateBatch(payload) {
  return dispatch => {
    return api
      .fetch('remotesvc/v0/capture/batch/generate')
      .then(data => {
        dispatch(generateBatchSuccess(data.data));
      })
      .catch(error => {
        throw error;
      });
  };
}
