import moment from 'moment-business-days';

export const yearList = [
  moment().subtract(10, 'years'),
  moment().subtract(9, 'years'),
  moment().subtract(8, 'years'),
  moment().subtract(7, 'years'),
  moment().subtract(6, 'years'),
  moment().subtract(5, 'years'),
  moment().subtract(4, 'years'),
  moment().subtract(3, 'years'),
  moment().subtract(2, 'years'),
  moment().subtract(1, 'years'),
  moment()
].map((date, id) => ({ value: date.year(), text: date.year().toString(), id }));

export const generateYearList = (
  pastYears,
  futureYears = 0,
  currentYear = 1,
  yearRange = pastYears + currentYear + futureYears
) => ({
  source: Array(yearRange)
    .fill()
    .map((_, i) => moment().subtract(pastYears - i, 'years'))
    .map((date, id) => ({ value: date.year(), text: date.year().toString(), id }))
});

export const getCurrentOrNextBusinessDay = dateFormat =>
  (moment().isBusinessDay() ? moment() : moment().nextBusinessDay()).format(dateFormat);

export const rangeCalendarProps = (minPastValue, maxFutureValue) => ({ focusedInput, maxRange, startDate }) => ({
  isOutsideRange: day => {
    const today = moment();

    if (
      day.isBefore(today.clone().subtract(...minPastValue), 'day') ||
      day.isAfter(maxFutureValue ? today.clone().add(...maxFutureValue) : today.clone(), 'day')
    ) {
      return true;
    }
    if (
      startDate &&
      focusedInput === 'endDate' &&
      day.isAfter(maxRange ? startDate.clone().add(maxRange, 'day') : today.clone(), 'day')
    ) {
      return true;
    }
    return false;
  },
  minimumNights: 0
});
