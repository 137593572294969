export { default as Dropdown } from './Dropdown';
export { default as Form } from './Form';
export { default as FormGroup } from './FormGroup';
export { default as FormContainer } from './FormContainer';
export { default as FormFooter } from './FormFooter';
export { default as FormSection } from './FormSection';
export { default as Input } from './Input';

import { useForm, useFormState } from 'react-final-form';

export const ClearErrorsHelper = ({ when, then, operand, condition = value => value === operand }) => {
  const {
    mutators: { setFieldData }
  } = useForm();

  useFormState({
    subscription: { values: true },
    onChange: ({ values }) => {
      const { [when]: value } = values;
      if (condition(value)) {
        setFieldData(then, { ignoreErr: false });
      } else {
        setFieldData(then, { ignoreErr: true });
      }
    }
  });

  return null;
};
