import Scanner, { shouldPoll } from 'api/scanner';
import {
  isSelectedScannerCaptureOne,
  HANDLE_SCAN_ERROR,
  scanningErrorTypes,
  SCAN_TYPE,
  noDocumentsFoundErrorMessage,
  noDocumentsFoundActionMessage,
  enqueue
} from './index';
import {
  getAndStreamAllScannedItems,
  performProcessingOnAnyScannedItemsBeforeScanError
} from './processScanItemsActions';
import { twainDriverActionHref } from './connectToScannerDriverActions';
import i18n from 'i18n';

export const UPDATE_SCANNING_STATUS = 'UPDATE_SCANNING_STATUS';
export const IS_SCANNING = 'IS_SCANNING';
export const ABORTED_SCAN_SESSION = 'ABORTED_SCAN_SESSION';

export const generalErrorMessage = i18n.t('scanPage.messages.errorDuringScanning');

const TWO_MINUTES = 120000;
const moment = require('moment-timezone');

export const invalidBatchNumber = t => {
  return dispatch => {
    dispatch({
      type: HANDLE_SCAN_ERROR,
      error: {
        errorType: scanningErrorTypes.INVALID_BATCH_NUM,
        errorMessage: t('scanPage.messages.invalidBatchNumber'),
        actionMessage: t('scanPage.messages.batchNotUnique'),
        actionLinkHref: twainDriverActionHref,
        actionLinkMessage: '',
        preventScanning: false
      }
    });
  };
};

export const generalErrorModal = (errorTitle, errorMessage) => {
  return dispatch => {
    dispatch({
      type: HANDLE_SCAN_ERROR,
      error: {
        errorType: scanningErrorTypes.GET_STATUS,
        errorMessage: errorTitle,
        actionMessage: errorMessage,
        actionLinkHref: '',
        actionLinkMessage: '',
        preventScanning: false
      }
    });
    dispatch({ type: UPDATE_SCANNING_STATUS, scanningStatus: {} });
  };
};

const handleNoDoccumentsInScanner = scanningStatus => {
  return dispatch => {
    dispatch({
      type: HANDLE_SCAN_ERROR,
      error: {
        errorType: scanningErrorTypes.GET_STATUS,
        errorMessage: noDocumentsFoundErrorMessage,
        actionMessage: noDocumentsFoundActionMessage,
        actionLinkHref: '',
        actionLinkMessage: '',
        preventScanning: false
      }
    });
    dispatch({
      type: UPDATE_SCANNING_STATUS,
      scanningStatus: { ...scanningStatus, isProcessingImages: false }
    });
  };
};

export const acquire = (
  lockboxDetail,
  numItemsAlreadyInBatch,
  existingBatchUniqueId,
  scanType = SCAN_TYPE.INITIATE_SCAN,
  scanInfoObject,
  cancelToken
) => (dispatch, getState) => {
  dispatch({ type: IS_SCANNING });
  const twainInterfaceFlagNoneValue = 0;
  const data = {
    flags: twainInterfaceFlagNoneValue,
    id: getState().scanControl.sessionId,
    userData: null,
    isCaptureOne: isSelectedScannerCaptureOne(getState)
  };
  const captureDateTime = moment()
    .tz('America/Chicago')
    .format('MM-DD-YYYY hh:mm:ss a');

  const nextAction = () => {
    dispatch(
      getAndStreamAllScannedItems(
        lockboxDetail,
        captureDateTime,
        numItemsAlreadyInBatch,
        existingBatchUniqueId,
        scanType,
        scanInfoObject,
        cancelToken
      )
    );
  };

  const onSuccess = response => {
    dispatch(getStatus(cancelToken, nextAction));
  };
  const onFailure = error => {
    dispatch(abortScanSession(cancelToken, nextAction));
  };

  return dispatch(
    enqueue(Scanner.post('AcquireImages', { data, timeout: TWO_MINUTES, cancelToken, onSuccess, onFailure }))
  );
};

export const abortScanSession = (cancelToken, nextAction) => (dispatch, getState) => {
  const data = {
    id: getState().scanControl.sessionId,
    userData: null,
    isCaptureOne: isSelectedScannerCaptureOne(getState)
  };
  const onSuccess = response => {
    dispatch(getStatus(cancelToken, nextAction));
  };
  const onFailure = err => {
    dispatch(getStatus(cancelToken, nextAction));
  };
  dispatch(enqueue(Scanner.post('AbortAcquire', { data, onSuccess, onFailure })));
};

export const getStatus = (cancelToken, onSuccess = () => {}, onFailure = () => {}) => (dispatch, getState) => {
  const data = {
    id: getState().scanControl.sessionId,
    userData: null,
    isCaptureOne: isSelectedScannerCaptureOne(getState)
  };
  const selectedScanner = getState().settings.userPreferedScannerCode;

  const handleGetStatusSuccess = response => {
    const scanningStatus = response.data;
    if (scanningStatus.pagesCount > 0) {
      dispatch({
        type: UPDATE_SCANNING_STATUS,
        scanningStatus: { ...scanningStatus, isProcessingImages: true, processedCount: 0 }
      });
      onSuccess();
    } else {
      dispatch(handleNoDoccumentsInScanner(scanningStatus));
    }
  };

  const handleGetStatusFailure = err => {
    if (err.message !== 'CANCEL') {
      dispatch({
        type: HANDLE_SCAN_ERROR,
        error: {
          errorType: scanningErrorTypes.GET_STATUS,
          errorMessage: generalErrorMessage,
          actionMessage: noDocumentsFoundActionMessage,
          actionLinkHref: '',
          actionLinkMessage: '',
          preventScanning: false
        }
      });
      dispatch({ type: UPDATE_SCANNING_STATUS, scanningStatus: {} });
      onFailure();
    }
  };

  if (shouldPoll(selectedScanner)) {
    return Scanner.poll(
      options => dispatch(enqueue(Scanner.post('GetScanStatus', { data, cancelToken, ...options }))),
      response => response.data.isScanning === false,
      response => response.data.pagesCount !== 0,
      getState().settings.userPreferedScannerCode
    )
      .then(response => {
        handleGetStatusSuccess(response);
      })
      .catch(err => {
        handleGetStatusFailure(err);
      });
  } else {
    dispatch(
      enqueue(
        Scanner.post('GetScanStatus', {
          data,
          cancelToken,
          onSuccess: handleGetStatusSuccess,
          onFailure: handleGetStatusFailure
        })
      )
    );
  }
};
