import { makeStyles } from '@jpmuitk/theme';

export const useStyles = makeStyles({
  root: {
    maxWidth: '900px',
    maxHeight: '850px'
  },
  errorList: {
    marginTop: '0 !important'
  }
});
