import React from 'react';
import {
  CLEAR_INVOICES_ERR,
  CLEAR_INVOICES_LIST,
  LOAD_INVOICES_REQUEST,
  LOAD_INVOICES_SUCCESS,
  LOAD_INVOICES_FAILURE,
  LOAD_INVOICE_DETAILS_REQUEST,
  LOAD_INVOICE_DETAILS_SUCCESS,
  LOAD_INVOICE_DETAILS_FAILURE,
  EXPORT_INVOICE_REQUEST,
  EXPORT_INVOICE_SUCCESS,
  EXPORT_INVOICE_FAILURE
} from './InvoiceSearchActions';

export const initialState = {
  invoicesErrors: {},
  dataList: []
};

const loadInvoicesRequest = (state, action) => ({
  ...state,
  ...initialState,
  isFetching: true,
  invoicesErrors: {}
});
const loadInvoicesSuccess = (state, action) => {
  const { grandTotal, invoiceSearchRows, exceededMaxIn, errorMessage } = action.response.data;
  return {
    ...state,
    grandTotal,
    exceededMaxIn,
    dataList: invoiceSearchRows,
    invoicesErrors: exceededMaxIn ? { status: 200, data: { message: errorMessage } } : {},
    isFetching: false
  };
};
const loadInvoicesFailure = (state, action) => {
  return {
    ...state,
    invoicesErrors: { ...action.error, isGridError: true },
    isFetching: false
  };
};

const loadInvoiceDetailsRequest = (state, action) => ({
  ...state,
  ...initialState,
  isFetching: true,
  invoicesErrors: {}
});
const loadInvoiceDetailsSuccess = (state, action) => {
  const { grandTotal, invoiceSearchRows, exceededMaxIn, errorMessage } = action.response.data;
  return {
    ...state,
    invoiceDetails: action.response.data,
    invoicesErrors: {},
    isFetching: false
  };
};
const loadInvoiceDetailsFailure = (state, action) => {
  return {
    ...state,
    invoicesErrors: { ...action.error, isGridError: true },
    isFetching: false
  };
};

function exportInvoiceRequest(state, action) {
  return Object.assign({}, state, {
    isFetching: true,
    exportError: null,
    exportData: null
  });
}
function exportInvoiceSuccess(state, action) {
  return Object.assign({}, state, {
    exportData: action.response,
    isFetching: false
  });
}
const exportInvoiceFailure = (state, action) => {
  return {
    ...state,
    exportError: action.error,
    isFetching: false
  };
};

const clearInvoicesList = (state, action) => ({ ...state, ...initialState });
const clearInvoicesErr = (state, action) => ({ ...state, invoicesErrors: {} });

export default function invoicesReducer(state = initialState, action) {
  const handlers = {
    [LOAD_INVOICES_REQUEST]: loadInvoicesRequest,
    [LOAD_INVOICES_SUCCESS]: loadInvoicesSuccess,
    [LOAD_INVOICES_FAILURE]: loadInvoicesFailure,
    [LOAD_INVOICE_DETAILS_REQUEST]: loadInvoiceDetailsRequest,
    [LOAD_INVOICE_DETAILS_SUCCESS]: loadInvoiceDetailsSuccess,
    [LOAD_INVOICE_DETAILS_FAILURE]: loadInvoiceDetailsFailure,
    [EXPORT_INVOICE_REQUEST]: exportInvoiceRequest,
    [EXPORT_INVOICE_SUCCESS]: exportInvoiceSuccess,
    [EXPORT_INVOICE_FAILURE]: exportInvoiceFailure,
    [CLEAR_INVOICES_LIST]: clearInvoicesList,
    [CLEAR_INVOICES_ERR]: clearInvoicesErr
  };
  if (handlers.hasOwnProperty(action.type)) {
    return handlers[action.type](state, action);
  } else {
    return state;
  }
}
