import {
  CLEAR_USER_ACCOUNT,
  EDIT_USER_REQUEST,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAILURE,
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  GET_USERS_FAILURE,
  LOAD_USER_SERVICE_ENTITLEMENTS_REQUEST,
  LOAD_USER_SERVICE_ENTITLEMENTS_SUCCESS,
  LOAD_USER_SERVICE_ENTITLEMENTS_FAILURE
} from 'routes/UserAdmin/userAdminActions';

const clearUserAccount = state => {
  return { ...state, userAccount: {} };
};

const editUserRequest = state => {
  return { ...state, isFetching: true };
};

const editUserSuccess = (state, action) => {
  return {
    ...state,
    isFetching: false,
    userAccount: { ...state.userAccount, editUserSuccess: true, message: action.response.data.message }
  };
};

const editUserFailure = (state, action) => {
  return { ...state, isFetching: false, errors: action.error };
};

const getUsersRequest = state => {
  return { ...state, isFetching: true };
};

const getUsersSuccess = (state, action) => {
  return { ...state, users: action.response.data.users, isFetching: false };
};

const getUsersFailure = (state, action) => {
  return { ...state, errors: action.error, isFetching: false };
};

const loadUserServiceEntitlementsRequest = state => {
  return { ...state, isFetching: true };
};

const loadUserServiceEntitlementsSuccess = (state, action) => {
  return {
    ...state,
    isFetching: false,
    errors: {},
    userAccount: {
      ...state.userAccount,
      entitlementResponse: action.response.data
    }
  };
};

const loadUserServiceEntitlementsFailure = (state, action) => {
  return {
    ...state,
    isFetching: false,
    errors: action.error
  };
};

export const editUserReducer = {
  [CLEAR_USER_ACCOUNT]: clearUserAccount,
  [EDIT_USER_REQUEST]: editUserRequest,
  [EDIT_USER_SUCCESS]: editUserSuccess,
  [EDIT_USER_FAILURE]: editUserFailure,
  [GET_USERS_REQUEST]: getUsersRequest,
  [GET_USERS_SUCCESS]: getUsersSuccess,
  [GET_USERS_FAILURE]: getUsersFailure,
  [LOAD_USER_SERVICE_ENTITLEMENTS_REQUEST]: loadUserServiceEntitlementsRequest,
  [LOAD_USER_SERVICE_ENTITLEMENTS_SUCCESS]: loadUserServiceEntitlementsSuccess,
  [LOAD_USER_SERVICE_ENTITLEMENTS_FAILURE]: loadUserServiceEntitlementsFailure
};
