import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import htmr from 'htmr';
import isUndefined from 'lodash/isUndefined';

import translationEN from 'i18n/en/translation.json';
import translationBR from 'i18n/br/translation.json';
import translationCN from 'i18n/cn/translation.json';
import translationDE from 'i18n/de/translation.json';
import translationES from 'i18n/es/translation.json';
import translationFR from 'i18n/fr/translation.json';
import translationIT from 'i18n/it/translation.json';
import translationJP from 'i18n/jp/translation.json';
import translationRU from 'i18n/ru/translation.json';
import translationTW from 'i18n/tw/translation.json';

const resources = {
  br: {
    translation: translationBR
  },
  cn: {
    translation: translationCN
  },
  de: {
    translation: translationDE
  },
  en: {
    translation: translationEN
  },
  es: {
    translation: translationES
  },
  fr: {
    translation: translationFR
  },
  it: {
    translation: translationIT
  },
  jp: {
    translation: translationJP
  },
  ru: {
    translation: translationRU
  },
  tw: {
    translation: translationTW
  }
};

i18n
  // load translation using xhr -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-xhr-backend
  // Investigate
  // .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // Investigate
  // .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .use({
    type: 'postProcessor',
    name: 'formatted-text',
    process: (value, key, options, { language }) => {
      if (key.includes('ariaLabel')) {
        return language !== 'en' ? (value = '') : value;
      }
      if (value.match(/^html:/)) {
        return htmr(value.replace(/^html:/, ''));
      }
      if (value.match(/\&nbsp\;/)) {
        return htmr(value);
      }
      return value;
    }
  })
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    postProcess: 'formatted-text',
    resources,
    lng: 'en',
    fallbackLng: 'en',
    // Investigate
    // saveMissing: true,
    debug: process.env.NODE_ENV === 'development' ? true : false,
    interpolation: {
      escapeValue: false,
      format: function(value, format, lng) {
        let newValue = value;
        let hatFlag = false;
        format.split(', ').map(format => {
          // Checking for 0 removes empty maxAllowed selections
          if (newValue && newValue?.[0] !== '0') {
            if (format === 'parenthesis') {
              newValue = `(${newValue})`;
            }
            if (format === 'comma') {
              newValue += ',';
            }
            if (format === 'preSpace') {
              newValue = `&nbsp;${newValue}`;
            }
            if (format === 'postSpace') {
              newValue += ' ';
            }
            if (format === 'uppercase') {
              newValue = newValue.toUpperCase();
            }
            if (format === 'hatWrapper') {
              hatFlag = true;
            }
          } else {
            newValue = '';
          }
        });

        if (newValue && hatFlag) {
          return lng === 'en' ? `<span className="visually-hidden"> ${newValue}</span>` : '';
        }
        if (format === 'test') {
          return `${newValue}_test`;
        }

        return newValue;
      }
    }
  });

export default i18n;
