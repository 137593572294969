import { SET_ANNOUNCEMENT, CLEAR_ANNOUNCEMENT } from '../actions/announcementActions';

export const initialState = {
  message: '',
  ariaLive: 'polite'
};

function setAnnouncement(state, action) {
  return { message: action.message, ariaLive: action.ariaLive };
}

function clearAnnouncement(state, action) {
  return { ...state, message: '', ariaLive: '' };
}

export default function announcementReducer(state = initialState, action) {
  const handlers = {
    [SET_ANNOUNCEMENT]: setAnnouncement,
    [CLEAR_ANNOUNCEMENT]: clearAnnouncement
  };
  return handlers.hasOwnProperty(action.type) ? handlers[action.type](state, action) : state;
}
