import {
  DECISION_MANAGER_HISTORY,
  DECISION_MANAGER_DETAILS,
  DECISIONS_REQUIRING_ACTION_DETAILS
} from 'Constants/routes';

import moment from 'moment-business-days';

import sessionHelper, { sessionUser } from 'utility/sessionStorageHelper';

export const decisionManagerURL = endpoint => {
  const { customerId, userId } = sessionUser.getLoggedInUser().getId();
  return `txndecisionmanagersvc/v0/cust/${customerId}/user/${userId}/${endpoint}`;
};

export const populateDefaultDate = payload => {
  if (!payload.expirationDateRange && !payload.processDateRange && !payload.decisionDateRange) {
    payload.processDateRange = {
      from: moment()
        .subtract(30, 'days')
        .format('YYYY-MM-DD'),
      to: moment()
        .add(15, 'days')
        .format('YYYY-MM-DD')
    };
  }
  return payload;
};
