import { useAriaAnnouncer } from '@jpmuitk/aria-announcer';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { useGridStyles } from 'Components/PageWithGrid/styles';
import { useLanguage } from 'LanguageContextProvider';
import { usePrevious } from 'hooks/usePrevious';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import useGridErrorAnnouncement from 'hooks/useGridErrorAnnouncement';
import classNames from 'classnames';
import noop from 'lodash/noop';
import isEmpty from 'lodash/isEmpty';
import { PAGE_TITLES } from 'Constants/pages';
import { SIDEPANEL_FILTER } from 'Constants/sidePanel';

import { openSidePanel, closeSidePanel } from 'actions/sidePanelsActions';
import sessionHelper from 'utility/sessionStorageHelper';
import { getConfigGridAndCols } from 'hooks/useGrid';

import { Icon } from '@jpmuitk/icon';
import { Panel } from '@jpmuitk/panel';
import { Button } from '@salt-ds/core';
import { Spinner } from '@jpmuitk/spinner';
import { DataGrid } from '@jpmuitk/data-grid';
import TextWithIcon from 'Components/TextWithIcon';
import GridBottom from 'Components/PageWithGrid/GridBottom';
import ExportDialog from 'Components/Dialogs/ExportDialog';
import ConfirmationDialog from 'Components/Dialogs/ConfirmationDialog';
import { PageTitle, PageRoot, PageContent, PageFooter } from 'Components/Page';
import config from 'config/app';
import { disableGridButtonsObjectFactory } from 'Components/PageWithGrid/gridPageHelper.js';
import { MAX_ROWS_EXCEEDED } from 'Constants/grid';
import cssVariables from 'Constants/_variables.module.scss';
import { sessionBroadcast } from 'utility/sessionStorageHelper';

const NUM_BATCH = 20;

const PageWithGrid = props => {
  const {
    rows,
    gridKey,
    isLoading,
    dialogContent,
    exportDialogContent,
    history,
    hideFilter,
    hideFilterBtn,
    requestData,
    errors,
    suppressRowSelection = false,
    shouldNotRestrictMaxRowCount = false
  } = props;
  const { announce } = useAriaAnnouncer();
  const lang = useLanguage();
  const gridClasses = useGridStyles();
  const dispatch = useDispatch();
  const [selectedRowCount, setSelectedRowCount] = useState(suppressRowSelection ? undefined : 0);
  const [focusOnTitle, setFocusOnTitle] = useState(sessionBroadcast.getBroadcastFlag());
  const totalRows = props.totalRows || rows?.length;
  const { displaying, wide: isExpanded, disabled = displaying === SIDEPANEL_FILTER } = useSelector(
    state => state.sidePanels
  );
  const filterButtonRef = useRef();
  useGridErrorAnnouncement(props, history.location.pathname);
  const prevDisplaying = usePrevious(displaying);
  const { t } = useTranslation();
  let gridApi;

  useEffect(() => {
    return () => {
      dispatch(closeSidePanel(SIDEPANEL_FILTER));
    };
  }, [dispatch]);

  useEffect(() => {
    if (prevDisplaying === SIDEPANEL_FILTER && displaying !== SIDEPANEL_FILTER) {
      setFocusOnTitle(false);
      if (!JSON.parse(sessionBroadcast.getBroadcastFlag())) {
        filterButtonRef.current?.focus();
      }
    }
  }, [displaying, prevDisplaying]);

  const onGridReady = params => {
    if (props.onGridReady) {
      props.onGridReady(params);
    }

    gridApi = params.api;

    // announce grid description
    const route = history.location.pathname;
    gridApi.setGridAriaProperty('label', PAGE_TITLES(t)[route]);

    if (!suppressRowSelection) {
      setSelectedRowCount(params.api.getSelectedNodes().length);
    }
  };

  const renderTitle = () => {
    const prevPageArray = sessionHelper.getItem('prevPageArray')?.split(',');

    const filterBtn =
      !hideFilter && !hideFilterBtn ? (
        <Button
          type="button"
          ref={filterButtonRef}
          id="filterButton"
          className="filter-icon"
          variant="secondary"
          aria-label={t('pageWithGrid.buttons.filter.hat')}
          disabled={disabled}
          style={{ opacity: 1 }}
          onClick={() => dispatch(openSidePanel(SIDEPANEL_FILTER))}
        >
          <Icon name="filter" size={16} style={{ color: cssVariables.blue600 }} />
        </Button>
      ) : null;

    return (
      <PageTitle
        backButton={prevPageArray?.length > 1}
        options={classes => <div className={classes.cta}>{filterBtn}</div>}
        focusOnTitle={focusOnTitle}
        hidden={isExpanded}
      />
    );
  };

  const renderGridMessage = gridMessage => {
    return typeof gridMessage === 'object' ? (
      <TextWithIcon type={gridMessage.status} icon={gridMessage.status} iconSize={20}>
        {gridMessage.message}
      </TextWithIcon>
    ) : (
      <TextWithIcon type="warning" icon="warning" iconSize={20}>
        {gridMessage}
      </TextWithIcon>
    );
  };

  const onSelectionChanged = ({ api: gridApi }) => {
    if (!gridApi) {
      return;
    }

    const selectedRows = gridApi.getSelectedNodes();
    const selectedRowCount = selectedRows?.length;
    const disableGridButtons = disableGridButtonsObjectFactory(selectedRows);

    setSelectedRowCount(selectedRowCount);
    props.handleSelectionChanges({ ...disableGridButtons, regular: selectedRowCount === 0 });
  };

  /**
   * Callback function to be called after you sort the grid (eg. by clicking on a column header).
   * This calls onSelectionChanged so that the selected rows in state have the same sort order as
   * is displayed on the UI.
   */
  const onSortChanged = event => {
    const gridApi = event?.api;
    if (gridApi) onSelectionChanged({ api: gridApi });
  };

  const renderGrid = () => {
    const route = history.location.pathname;
    if (isLoading) {
      announce(t('pageWithGrid.messages.loadingData', { page: PAGE_TITLES(t)[route] }));
      return <Spinner className="spinner" />;
    }

    return rows?.length ? (
      <DataGrid
        key={gridKey}
        localeText={lang?.languages[lang?.userLanguage]}
        context={props.context}
        className={classNames(props.className, {
          'hide-grid': props.errors
        })}
        classes={gridClasses}
        columnDefs={props.columnDefs}
        rowData={
          !shouldNotRestrictMaxRowCount && rows && rows?.length > config(t).grid[route]?.maxRows
            ? rows.slice(0, config(t).grid[route].maxRows)
            : rows
        }
        onGridReady={onGridReady}
        pagination={rows && rows?.length > NUM_BATCH}
        frameworkComponents={props.frameworkComponents}
        getRowNodeId={props.getRowNodeId}
        onSelectionChanged={onSelectionChanged}
        onSortChanged={onSortChanged}
        rowSelection="multiple"
        paginationPageSize={NUM_BATCH}
        rowHeight={30}
        getRowHeight={props.getRowHeight}
        getRowStyle={props.getRowStyle}
        enableRangeSelection
        {...getConfigGridAndCols({ defaultColDefProps: props.defaultColDef })}
      />
    ) : null;
  };

  const renderConfirmationDialog = () =>
    !dialogContent ? null : <ConfirmationDialog id="ConfirmationDialog" dialogContent={dialogContent} />;

  const renderExportDialog = () =>
    !exportDialogContent ? null : <ExportDialog id="ExportDialog" dialogContent={exportDialogContent} />;

  const route = history.location.pathname;
  const gridInformation =
    !shouldNotRestrictMaxRowCount && props.totalRows > config(t).grid[route]?.maxRows
      ? renderGridMessage(MAX_ROWS_EXCEEDED(route))
      : !isEmpty(errors) && renderGridMessage(errors);

  return (
    <>
      <Panel
        className="page-content"
        style={{
          display: !isExpanded ? 'flex' : 'none',
          flexGrow: 1,
          flexDirection: 'column',
          padding: 0
        }}
      >
        <PageRoot>
          {renderTitle()}
          <>
            <PageContent
              noWrapper
              noFooter={!props.buttonsContent?.length && !props.grandTotal?.length}
              loading={isLoading}
            >
              {gridInformation}
              {renderGrid()}
            </PageContent>
            {(rows || props.buttonsContent?.length > 0) && (
              <PageFooter>
                <GridBottom
                  buttonsContent={props.buttonsContent}
                  grandTotal={props.grandTotal}
                  selectedRowCount={selectedRowCount}
                  totalRows={totalRows}
                  isLoading={isLoading}
                />
              </PageFooter>
            )}
          </>
        </PageRoot>
      </Panel>
      {renderExportDialog()}
      {renderConfirmationDialog()}
    </>
  );
};

PageWithGrid.defaultProps = {
  handleSelectionChanges: noop
};

export default withRouter(PageWithGrid);
