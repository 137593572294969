import { combineReducers } from 'redux';
import alertsReducer from 'routes/Alerts/alertsReducer';
import userinfoReducer from './userinfoReducer';
import navigationReducer from './navigationReducer';
import captureresultsReducer from 'routes/CaptureResults/reducer';
import capturedetailReducer from './capturedetailReducer';
import transactionsReducer from 'routes/Transactions/transactionsReducer';
import transactiondetailsReducer from 'routes/TransactionDetail/transactiondetailsReducer';
import reasonCodeReducer from 'routes/ReasonCodes/reducers';
import scanReducer from './scanReducer';
import batchReducer from './batchReducer';
import scanControlReducer from './scanControlReducer';
import broadcastalertReducer from './broadcastalertReducer';
import userAdminReducer from 'routes/UserAdmin/userAdminReducer';
import settingsReducer from 'routes/Settings/settingsReducer';
import announcementReducer from './announcementReducer';
import sidePanelsReducer from './sidePanelReducer';
import reportsReducer from 'routes/Reports/reportsReducer';
import customReportReducer from 'routes/Reports/CustomReports/customReportReducer';
import supportReducer from 'routes/Support/supportReducer';
import originalAdviceReducer from 'routes/RemittanceAdviceDetails/OriginalAdvice/originalAdviceReducer';
import uploadRemittanceAdvicesReducer from 'routes/UploadRemittanceAdvices/uploadRemittanceAdvicesReducer';
import downloadCenterReducer from 'routes/DownloadCenter/reducer';
import stopGoReducer from 'routes/Stopgo/reducer';
import entityReducer from 'routes/Entity/reducer';
import imageViewerReducer from 'Components/Image/Viewer/reducer';
import imageViewerCacheReducer from 'Components/Image/Viewer/imageViewerCacheReducer';
import invoicePatternReducer from 'routes/InvoicePatternDetails/reducer';
import invoicePatternsReducer from 'routes/InvoiceIdentificationPatterns/reducer';
import invoicesReducer from 'routes/InvoiceSearch/InvoiceSearchReducers';
import filterReducer from 'Components/Dialogs/FilterDialog/filterReducer';
import RemittanceAdviceListReducer from 'routes/RemittanceAdviceList/RemittanceAdviceListReducer';
import decisionManagerReducer from 'routes/DecisionManager/decisionManagerReducer';
import decisionManagerDetailsReducer from 'routes/DecisionDetails/reducer';
import RemittanceAdviceDetailsReducer from 'routes/RemittanceAdviceDetails/RemittanceAdviceDetailsReducer';
import uploadWorkflowNotes from 'routes/UploadWorkFlowNotes/uploadWorkflowNotesReducer';
import { LOGOFF_USER_SUCCESS } from 'actions/userinfoActions';
import modalReducer from './modalReducer';

const appReducer = combineReducers({
  adviceDetails: RemittanceAdviceDetailsReducer,
  alerts: alertsReducer,
  announcement: announcementReducer,
  batch: batchReducer,
  broadcastalerts: broadcastalertReducer,
  capturedetail: capturedetailReducer,
  captureresults: captureresultsReducer,
  customReports: customReportReducer,
  downloadCenter: downloadCenterReducer,
  stopgo: stopGoReducer,
  decisionManager: decisionManagerReducer,
  decisionDetails: decisionManagerDetailsReducer,
  entity: entityReducer,
  filters: filterReducer,
  imageViewerCache: imageViewerCacheReducer,
  imageViewerInfo: imageViewerReducer,
  invoicePattern: invoicePatternReducer,
  invoicePatterns: invoicePatternsReducer,
  invoiceSearch: invoicesReducer,
  modals: modalReducer,
  navigation: navigationReducer,
  originalAdvice: originalAdviceReducer,
  remittanceAdviceList: RemittanceAdviceListReducer,
  reasoncodes: reasonCodeReducer,
  reports: reportsReducer,
  scan: scanReducer,
  scanControl: scanControlReducer,
  settings: settingsReducer,
  sidePanels: sidePanelsReducer,
  support: supportReducer,
  transactiondetails: transactiondetailsReducer,
  transactions: transactionsReducer,
  uploadRemittanceAdvices: uploadRemittanceAdvicesReducer,
  uploadWorkflowNotes: uploadWorkflowNotes,
  userAdmin: userAdminReducer,
  users: userinfoReducer
});

const rootReducer = (state, action) => {
  if (action.type === LOGOFF_USER_SUCCESS) {
    const logoffUrl = action.isFullPortal ? window.LOGGOFF_FULLPORTAL : window.LOGGOFF_DIRECTEDGE;
    window.location.href = logoffUrl + '?time=' + new Date().getTime();
    window.sessionStorage.clear();
    return {};
  }
  return appReducer(state, action);
};

export default rootReducer;
