import api from 'api';
import axios from 'axios';
import i18n from 'i18n';

export const CANCEL_AND_KEEP_STATE = 'CANCEL_AND_KEEP_STATE';
export const LOAD_PAYMENT_INFORMATION_REQUEST = 'LOAD_PAYMENT_INFORMATION_REQUEST';
export const LOAD_PAYMENT_INFORMATION_SUCCESS = 'LOAD_PAYMENT_INFORMATION_SUCCESS';
export const LOAD_PAYMENT_INFORMATION_FAILURE = 'LOAD_PAYMENT_INFORMATION_FAILURE';
export const LOAD_REMITTANCE_INFORMATION_REQUEST = 'LOAD_REMITTANCE_INFORMATION_REQUEST';
export const LOAD_REMITTANCE_INFORMATION_SUCCESS = 'LOAD_REMITTANCE_INFORMATION_SUCCESS';
export const LOAD_REMITTANCE_INFORMATION_FAILURE = 'LOAD_REMITTANCET_INFORMATION_FAILURE';
export const LOAD_TRANSACTIONDETAILS_REQUEST = 'LOAD_TRANSACTIONDETAILS_REQUEST';
export const LOAD_TRANSACTIONDETAILS_SUCCESS = 'LOAD_TRANSACTIONDETAILS_SUCCESS';
export const LOAD_TRANSACTIONDETAILS_FAILURE = 'LOAD_TRANSACTIONDETAILS_FAILURE';
export const LOAD_WORKFLOW_DETAILS_REQUEST = 'LOAD_WORKFLOW_DETAILS_REQUEST';
export const LOAD_WORKFLOW_DETAILS_SUCCESS = 'LOAD_WORKFLOW_DETAILS_SUCCESS';
export const LOAD_WORKFLOW_DETAILS_FAILURE = 'LOAD_WORKFLOW_DETAILS_FAILURE';
export const LOAD_WORKFLOW_DETAILS_FORM_REQUEST = 'LOAD_WORKFLOW_DETAILS_FORM_REQUEST';
export const LOAD_WORKFLOW_DETAILS_FORM_SUCCESS = 'LOAD_WORKFLOW_DETAILS_FORM_SUCCESS';
export const LOAD_WORKFLOW_DETAILS_FORM_FAILURE = 'LOAD_WORKFLOW_DETAILS_FORM_FAILURE';
export const ADD_ADVICES_TO_TRANSACTION_REQUEST = 'ADD_ADVICES_TO_TRANSACTION_REQUEST';
export const ADD_ADVICES_TO_TRANSACTION_SUCCESS = 'ADD_ADVICES_TO_TRANSACTION_SUCCESS';
export const ADD_ADVICES_TO_TRANSACTION_FAILURE = 'ADD_ADVICES_TO_TRANSACTION_FAILURE';
export const ADD_INVOICES_TO_TRANSACTION_REQUEST = 'ADD_INVOICES_TO_TRANSACTION_REQUEST';
export const ADD_INVOICES_TO_TRANSACTION_SUCCESS = 'ADD_INVOICES_TO_TRANSACTION_SUCCESS';
export const ADD_INVOICES_TO_TRANSACTION_FAILURE = 'ADD_INVOICES_TO_TRANSACTION_FAILURE';
export const ASSIGN_TRANSACTION_REQUEST = 'ASSIGN_TRANSACTION_REQUEST';
export const ASSIGN_TRANSACTION_SUCCESS = 'ASSIGN_TRANSACTION_SUCCESS';
export const ASSIGN_TRANSACTION_FAILURE = 'ASSIGN_TRANSACTION_FAILURE';
export const LOAD_OPEN_ADVICES_AND_INVOICES_REQUEST = 'LOAD_OPEN_ADVICES_AND_INVOICES_REQUEST';
export const LOAD_OPEN_ADVICES_AND_INVOICES_SUCCESS = 'LOAD_OPEN_ADVICES_AND_INVOICES_SUCCESS';
export const LOAD_OPEN_ADVICES_AND_INVOICES_FAILURE = 'LOAD_OPEN_ADVICES_AND_INVOICES_FAILURE';
export const RESET_TRANSACTIONDETAILS = 'RESET_TRANSACTIONDETAILS';
export const LOAD_TRANSACTION_NOTES_REQUEST = 'LOAD_TRANSACTION_NOTES_REQUEST';
export const LOAD_TRANSACTION_NOTES_SUCCESS = 'LOAD_TRANSACTION_NOTES_SUCCESS';
export const LOAD_TRANSACTION_NOTES_FAILURE = 'LOAD_TRANSACTION_NOTES_FAILURE';
export const GPX_ACTION_ITEM_REQUEST = 'GPX_ACTION_ITEM_REQUEST';
export const GPX_ACTION_ITEM_SUCCESS = 'GPX_ACTION_ITEM_SUCCESS';
export const GPX_ACTION_ITEM_FAILURE = 'GPX_ACTION_ITEM_FAILURE';
export const UPDATE_TRANSACTION_NOTE_REQUEST = 'UPDATE_TRANSACTION_NOTE_REQUEST';
export const UPDATE_TRANSACTION_NOTE_SUCCESS = 'UPDATE_TRANSACTION_NOTE_SUCCESS';
export const UPDATE_TRANSACTION_NOTE_FAILURE = 'UDATE_TRANSACTION_NOTE_FAILURE';
export const DELETE_TRANSACTION_NOTE_REQUEST = 'DELETE_TRANSACTION_NOTE_REQUEST';
export const DELETE_TRANSACTION_NOTE_SUCCESS = 'DELETE_TRANSACTION_NOTE_SUCCESS';
export const DELETE_TRANSACTION_NOTE_FAILURE = 'DELETE_TRANSACTION_NOTE_FAILURE';
export const UPLOAD_REMITTANCE_FILE_REQUEST = 'UPLOAD_REMITTANCE_FILE_REQUEST';
export const UPLOAD_REMITTANCE_FILE_SUCCESS = 'UPLOAD_REMITTANCE_FILE_SUCCESS';
export const UPLOAD_REMITTANCE_FILE_FAILURE = 'UPLOAD_REMITTANCE_FILE_FAILURE';
export const UPDATE_WORKFLOW_DETAILS_REQUEST = 'UPDATE_WORKFLOW_DETAILS_REQUEST';
export const UPDATE_WORKFLOW_DETAILS_SUCCESS = 'UPDATE_WORKFLOW_DETAILS_SUCCESS';
export const UPDATE_WORKFLOW_DETAILS_FAILURE = 'UPDATE_WORKFLOW_DETAILS_FAILURE';
export const DOWNLOAD_TEMPLATE_FILE_REQUEST = 'DOWNLOAD_TEMPLATE_FILE_REQUEST';
export const DOWNLOAD_TEMPLATE_FILE_SUCCESS = 'DOWNLOAD_TEMPLATE_FILE_SUCCESS';
export const DOWNLOAD_TEMPLATE_FILE_FAILURE = 'DOWNLOAD_TEMPLATE_FILE_FAILURE';
export const SAVE_DATA_REQUEST = 'SAVE_DATA_REQUEST';
export const SAVE_DATA_SUCCESS = 'SAVE_DATA_SUCCESS';
export const SAVE_DATA_FAILURE = 'SAVE_DATA_FAILURE';
export const COMPLETE_DATA_FAILURE = 'COMPLETE_DATA_FAILURE';
export const TAKE_OVER_TRANSACTION = 'TAKE_OVER_TRANSACTION';
export const CHECK_TRANSACTION_IN_PROGRESS_REQUEST = 'CHECK_TRANSACTION_IN_PROGRESS_REQUEST';
export const CHECK_TRANSACTION_IN_PROGRESS_SUCCESS = 'CHECK_TRANSACTION_IN_PROGRESS_SUCCESS';
export const CHECK_TRANSACTION_IN_PROGRESS_FAILURE = 'CHECK_TRANSACTION_IN_PROGRESS_FAILURE';
export const CANCEL_EDIT_TRANSACTION_REQUEST = 'CANCEL_EDIT_TRANSACTION_REQUEST';
export const CANCEL_EDIT_TRANSACTION_SUCCESS = 'CANCEL_EDIT_TRANSACTION_SUCCESS';
export const CANCEL_EDIT_TRANSACTION_FAILURE = 'CANCEL_EDIT_TRANSACTION_FAILURE';
export const DISASSOCIATE_ADVICES_REQUEST = 'DISASSOCIATE_ADVICES_REQUEST';
export const DISASSOCIATE_ADVICES_SUCCESS = 'DISASSOCIATE_ADVICES_SUCCESS';
export const DISASSOCIATE_ADVICES_FAILURE = 'DISASSOCIATE_ADVICES_FAILURE';
export const LOAD_INVOICE_FIELDS_REQUEST = 'LOAD_INVOICE_FIELDS_REQUEST';
export const LOAD_INVOICE_FIELDS_SUCCESS = 'LOAD_INVOICE_FIELDS_SUCCESS';
export const LOAD_INVOICE_FIELDS_FAILURE = 'LOAD_INVOICE_FIELDS_FAILURE';
export const LOAD_INVOICES_REQUEST = 'LOAD_INVOICES_REQUEST';
export const LOAD_INVOICES_SUCCESS = 'LOAD_INVOICES_SUCCESS';
export const LOAD_INVOICES_FAILURE = 'LOAD_INVOICES_FAILURE';
export const RESET_OPEN_INVOICES = 'RESET_OPEN_INVOICES';
export const UPDATE_TYPE_CODE_REQUEST = 'UPDATE_TYPE_CODE_REQUEST';
export const UPDATE_TYPE_CODE_SUCCESS = 'UPDATE_TYPE_CODE_SUCCESS';
export const UPDATE_TYPE_CODE_FAILURE = 'UPDATE_TYPE_CODE_FAILURE';
export const DEACTIVATE_INVOICES_REQUEST = 'DEACTIVATE_INVOICES_REQUEST';
export const DEACTIVATE_INVOICES_SUCCESS = 'DEACTIVATE_INVOICES_SUCCESS';
export const DEACTIVATE_INVOICES_FAILURE = 'DEACTIVATE_INVOICES_FAILURE';

import { FIELD_TYPES, unformatPayload } from 'hooks/useFieldFormatter';
import cloneDeep from 'lodash/cloneDeep';
import { transactionURL } from '../Transactions/utils';
import { sdeTemplate } from 'Constants/defaultFileNames';
import { CONTENT_TYPE } from 'Constants/headers';
import { FILENAME_REGEX } from 'Constants/regex';
import { handleDownloadFile, getFileName } from 'utility/downloadUtility';
import { httpErrorTypes, errorActions } from 'utility/error';
import { createPayload } from './Accordions/utils';
import { disassociateAdvicePayload } from './utils';
import sessionHelper, { sessionUser } from 'utility/sessionStorageHelper';

const handleDownloadTemplate = () => (dispatch, getState) => {
  const state = getState();
  const templateData = state.transactiondetails.templateData;
  const type = templateData.request.getResponseHeader(CONTENT_TYPE);
  const fileName = getFileName(templateData, sdeTemplate);
  handleDownloadFile(templateData.data, fileName, type);
};

export function resetTransactionDetails() {
  return { type: RESET_TRANSACTIONDETAILS };
}

export function resetOpenInvoices() {
  return { type: RESET_OPEN_INVOICES };
}

export const loadPaymentInformation = (transactionId, platformId) => {
  return {
    types: [
      LOAD_PAYMENT_INFORMATION_REQUEST,
      LOAD_PAYMENT_INFORMATION_SUCCESS,
      LOAD_PAYMENT_INFORMATION_FAILURE,
      CANCEL_AND_KEEP_STATE
    ],
    callAPI: (state, options) =>
      api.fetch(transactionURL(`transaction/${transactionId}/payment?platformId=${platformId}`), {}),
    httpErrorTypes
  };
};

export const loadRemittanceInformation = (transactionId, programId, displayLegacyInvoiceMatching = false) => {
  return {
    types: [
      LOAD_REMITTANCE_INFORMATION_REQUEST,
      LOAD_REMITTANCE_INFORMATION_SUCCESS,
      LOAD_REMITTANCE_INFORMATION_FAILURE,
      CANCEL_AND_KEEP_STATE
    ],
    callAPI: (state, options) =>
      api.save(
        transactionURL(
          `program/${programId}/transaction/${transactionId}/remittance-detail?withRequiredValidationRules=true&displayLegacyInvoiceMatching=${displayLegacyInvoiceMatching}`
        ),
        {}
      ),
    httpErrorTypes
  };
};

export const loadTransactionDetails = (transactionId, page, t) => {
  const customerId = sessionUser.getLoggedInUser().getId().customerId;
  const userId = sessionUser.getLoggedInUser().getId().userId;
  return {
    types: [LOAD_TRANSACTIONDETAILS_REQUEST, LOAD_TRANSACTIONDETAILS_SUCCESS, LOAD_TRANSACTIONDETAILS_FAILURE],
    callAPI: (state, options) => api.fetch(transactionURL(`transaction/${transactionId}?page=${page}`)),
    params: { params: { transactionIds: [transactionId], customerId, userId } },
    httpErrorTypes,
    errorDisplayType: 'modal',
    errorTitle: t('transactionDetails.errorMessages.loadTransactionDetailsFailure')
  };
};

export const loadTransactionNotes = transactionId => {
  return {
    types: [LOAD_TRANSACTION_NOTES_REQUEST, LOAD_TRANSACTION_NOTES_SUCCESS, LOAD_TRANSACTION_NOTES_FAILURE],
    callAPI: (state, options) => api.fetch(transactionURL(`transaction/${transactionId}/transaction-notes`)),
    httpErrorTypes
  };
};

export const addTransactionNote = (transactionId, noteText, t) => {
  return {
    types: [UPDATE_TRANSACTION_NOTE_REQUEST, UPDATE_TRANSACTION_NOTE_SUCCESS, UPDATE_TRANSACTION_NOTE_FAILURE],
    callAPI: (state, options) =>
      api.save(transactionURL(`transaction/${transactionId}/add-transaction-notes`), { noteText }),
    dispatchOnSuccess: [
      () => loadTransactionNotes(transactionId),
      () => loadWorkflowDetails(transactionId),
      ({ announce, t }) => announce(t('transactionDetails.messages.addNoteSuccess'))
    ],
    httpErrorTypes,
    errorDisplayType: 'modal',
    errorTitle: t('transactionDetails.errorMessages.updateTransactionNoteFailure')
  };
};

export const updateTransactionNote = (transactionId, noteTextId, noteText, t) => {
  return {
    types: [UPDATE_TRANSACTION_NOTE_REQUEST, UPDATE_TRANSACTION_NOTE_SUCCESS, UPDATE_TRANSACTION_NOTE_FAILURE],
    callAPI: (state, options) => api.save(transactionURL(`note/${noteTextId}/update-transaction-notes`), { noteText }),
    dispatchOnSuccess: [
      () => loadTransactionNotes(transactionId),
      ({ announce, t }) => onUpdateTransactionNoteSuccess(announce, t)
    ],
    httpErrorTypes,
    errorDisplayType: 'modal',
    errorTitle: t('transactionDetails.errorMessages.updateTransactionNoteFailure')
  };
};

export const onUpdateTransactionNoteSuccess = (announce, t) => (dispatch, getState) => {
  announce(
    t('transactionDetails.messages.updateNoteSuccess', {
      timestamp: getState().transactiondetails.transactionNoteList[0].lastUpdateDate
    })
  );
};

export const addAdvicesToTransaction = (payload, successActionsList) => ({
  types: [ADD_ADVICES_TO_TRANSACTION_REQUEST, ADD_ADVICES_TO_TRANSACTION_SUCCESS, ADD_ADVICES_TO_TRANSACTION_FAILURE],
  callAPI: (state, options) => api.save(transactionURL('transaction/add-advices-to-transaction'), payload),
  dispatchOnSuccess: successActionsList,
  successDisplayType: 'modal',
  successTitle: i18n.t('transactionDetails.remittances.openAdvices.addToTransaction.title'),
  successMessage: i18n.t('transactionDetails.remittances.common.addToTransaction.message'),
  successCaseNotMet: response => ({ message: response?.data?.message }),
  httpErrorTypes,
  errorDisplayType: 'modal',
  errorTitle: i18n.t('transactionDetails.errorMessages.addAdvice')
});

export const addInvoicesToTransaction = (payload, successActionsList) => ({
  types: [
    ADD_INVOICES_TO_TRANSACTION_REQUEST,
    ADD_INVOICES_TO_TRANSACTION_SUCCESS,
    ADD_INVOICES_TO_TRANSACTION_FAILURE
  ],
  callAPI: (state, options) => api.save(transactionURL('transaction/add-open-invoices-to-transaction'), payload),
  dispatchOnSuccess: successActionsList,
  successDisplayType: 'modal',
  successTitle: i18n.t('transactionDetails.remittances.openInvoices.addToTransaction.title'),
  successMessage: i18n.t('transactionDetails.remittances.common.addToTransaction.message'),
  successCaseNotMet: response => ({ message: response?.data?.message }),
  httpErrorTypes,
  errorDisplayType: 'modal',
  errorTitle: i18n.t('transactionDetails.errorMessages.addInvoices')
});

export const assignTransaction = (transactionId, payload, dispatchOnSuccess) => ({
  types: [ASSIGN_TRANSACTION_REQUEST, ASSIGN_TRANSACTION_SUCCESS, ASSIGN_TRANSACTION_FAILURE],
  callAPI: (state, options) =>
    api.save(transactionURL(`transaction/${transactionId}/workflow/assign-transaction-workflow-to-user`), payload),
  dispatchOnSuccess: dispatchOnSuccess,
  httpErrorTypes,
  errorDisplayType: 'modal',
  errorTitle: i18n.t('transactionDetails.errorMessages.assignTransaction')
});

export const gpxActions = (transactionId, actionTaken, amount, dispatchOnSuccess) => {
  return {
    types: [GPX_ACTION_ITEM_REQUEST, GPX_ACTION_ITEM_SUCCESS, GPX_ACTION_ITEM_FAILURE],
    callAPI: (state, options) =>
      api.save(transactionURL(`payment/${actionTaken}-payment`), { transactionId, refundAmount: amount }),
    httpErrorTypes,
    errorDisplayType: 'modal',
    errorTitle:
      actionTaken === 'refund'
        ? i18n.t('transactionDetails.errorMessages.refundTransaction')
        : i18n.t('transactionDetails.errorMessages.cancelTransaction'),
    successDisplayType: 'modal',
    successTitle:
      actionTaken === 'refund'
        ? i18n.t('transactionDetails.messages.refundComplete')
        : i18n.t('transactionDetails.messages.cancelTransactionCompleted'),
    dispatchOnSuccess: [dispatchOnSuccess],
    createSuccessMessage: data => (
      <>
        <p>{data.message}</p>
        <p>Refund Total: {data.refundAmount}</p>
        <p>Confirmation Number: {data.confirmationNumber}</p>
      </>
    )
  };
};

export const deleteTransactionNote = (transactionId, noteTextId, t) => {
  return {
    types: [DELETE_TRANSACTION_NOTE_REQUEST, DELETE_TRANSACTION_NOTE_SUCCESS, DELETE_TRANSACTION_NOTE_FAILURE],
    callAPI: (state, options) => api.save(transactionURL(`note/${noteTextId}/delete-transaction-notes`)),
    dispatchOnSuccess: [
      () => loadTransactionNotes(transactionId),
      ({ announce, t }) => announce(t('transactionDetails.messages.successfullyDeletedNote'))
    ],
    httpErrorTypes,
    errorDisplayType: 'modal',
    errorTitle: t('transactionDetails.errorMessages.deleteNote')
  };
};

export const uploadRemittanceFile = (file, transactionId, programId, isIr, displayLegacyInvoiceMatching) => {
  const ir = isIr ? '?isIr=true' : '';
  const legacyInvoiceMatching = displayLegacyInvoiceMatching ? '?displayLegacyInvoiceMatching=true' : '';

  return {
    types: [UPLOAD_REMITTANCE_FILE_REQUEST, UPLOAD_REMITTANCE_FILE_SUCCESS, UPLOAD_REMITTANCE_FILE_FAILURE],
    callAPI: (state, options) =>
      api.postWithFile(
        transactionURL(
          `program/${programId}/transaction/${transactionId}/upload-remittance-details` + ir + legacyInvoiceMatching
        ),
        file
      ),
    httpErrorTypes,
    errorDisplayType: 'modal'
  };
};

export const downloadTemplate = (programId, programNumber, isIr, displayLegacyInvoiceMatching, t) => (
  dispatch,
  getState
) => {
  const ir = isIr ? '?isIr=true' : '';
  const legacyInvoiceMatching = displayLegacyInvoiceMatching ? '?displayLegacyInvoiceMatching=true' : '';

  return dispatch({
    types: [DOWNLOAD_TEMPLATE_FILE_REQUEST, DOWNLOAD_TEMPLATE_FILE_SUCCESS, DOWNLOAD_TEMPLATE_FILE_FAILURE],
    callAPI: (state, options) =>
      api.fetch(
        transactionURL(
          `programid/${programId}/programnumber/${programNumber}/remittance/download-template` +
            ir +
            legacyInvoiceMatching
        )
      ),
    dispatchOnSuccess: [() => handleDownloadTemplate()],
    httpErrorTypes,
    errorDisplayType: 'modal',
    errorTitle: t('transactionDetails.errorMessages.downloadTemplateFileFailure')
  });
};

export const saveData = (
  data,
  refItemData,
  columns,
  transactionDetails,
  userSettings,
  invoiceStatusHistRows,
  action,
  uploadData
) => {
  const remittancesList = createPayload(data, columns, userSettings, refItemData);
  const transactionId = sessionHelper.getItem('currentTransactionId');
  const complete = action === 'complete' ? '?markComplete=true' : '';
  return {
    types: [SAVE_DATA_REQUEST, SAVE_DATA_SUCCESS, action !== 'complete' ? SAVE_DATA_FAILURE : COMPLETE_DATA_FAILURE],
    callAPI: (state, options) =>
      api.save(transactionURL(`transaction/${transactionId}/save-remittance-details` + complete), {
        programId: Number(transactionDetails.program.id),
        transactionAmount: transactionDetails.amount,
        displayLegacyInvoiceMatching: transactionDetails.displayLegacyInvoiceMatching,
        invoiceBalancingEnabled: Number(transactionDetails.invoiceBalancingEnabled),
        invoiceBalancingField: transactionDetails.invoiceBalancingField,
        forcedBalancingEnabled: Number(transactionDetails.forcedBalancingEnabled),
        forcedBalancingInvoiceNumber: transactionDetails.forcedBalancingInvoiceNumber,
        remittancesList: remittancesList,
        uploadData: uploadData,
        userForceBalancingFlag: true,
        irEnabled: transactionDetails.irEnabled,
        action: action,
        invoiceStatusHistRows
      }),
    dispatchOnSuccess: [loadOpenAdvicesAndInvoices],
    httpErrorTypes,
    successDisplayType: 'toast',
    successTitle: i18n.t('transactionDetails.messages.succesfullySave'),
    successMessage: i18n.t('transactionDetails.messages.savedSDE'),
    errorDisplayType: 'modal',
    errorTitle:
      action !== 'complete'
        ? i18n.t('transactionDetails.errorMessages.saveDataFailure')
        : i18n.t('transactionDetails.errorMessages.completeDataFailure')
  };
};

export const loadOpenAdvicesAndInvoices = () => {
  return {
    types: [
      LOAD_OPEN_ADVICES_AND_INVOICES_REQUEST,
      LOAD_OPEN_ADVICES_AND_INVOICES_SUCCESS,
      LOAD_OPEN_ADVICES_AND_INVOICES_FAILURE,
      CANCEL_AND_KEEP_STATE
    ],
    shouldCallAPI: state =>
      state.transactiondetails?.data?.openAdviceAndInvoiceFlag && state.transactiondetails?.transactionIds?.[0],
    callAPI: ({ transactiondetails: { transactionIds } = {} }, { customerId, userId }) =>
      api.fetch(transactionURL(`transaction/${transactionIds?.[0]}/get-open-advices-and-invoices`), {}),
    httpErrorTypes
  };
};

export const loadWorkflowDetails = transactionId => ({
  types: [LOAD_WORKFLOW_DETAILS_REQUEST, LOAD_WORKFLOW_DETAILS_SUCCESS, LOAD_WORKFLOW_DETAILS_FAILURE],
  callAPI: (state, { customerId, userId }) =>
    api.fetch(transactionURL(`transaction/${transactionId}/get-workflow-details`)),
  httpErrorTypes
});

export const loadWorkflowDetailsForm = transactionId => ({
  types: [LOAD_WORKFLOW_DETAILS_FORM_REQUEST, LOAD_WORKFLOW_DETAILS_FORM_SUCCESS, LOAD_WORKFLOW_DETAILS_FORM_FAILURE],
  callAPI: (state, { customerId, userId }) =>
    api.fetch(transactionURL(`transaction/${transactionId}/get-workflow-drop-down-values`)),
  httpErrorTypes
});

export const updateWorkflowDetails = (transactionId, payload, dispatchOnSuccess) => ({
  types: [UPDATE_WORKFLOW_DETAILS_REQUEST, UPDATE_WORKFLOW_DETAILS_SUCCESS, UPDATE_WORKFLOW_DETAILS_FAILURE],
  callAPI: (state, options) =>
    api.save(transactionURL(`transaction/${transactionId}/workflow/update-workflow-details `), payload),
  dispatchOnSuccess: dispatchOnSuccess,
  httpErrorTypes,
  errorDisplayType: 'modal',
  errorTitle: i18n.t('transactionDetails.errorMessages.updateWorkflow')
});

export const editRemittanceDetails = (transactionId, programId, forceLock) => {
  return {
    types: [
      forceLock ? TAKE_OVER_TRANSACTION : CHECK_TRANSACTION_IN_PROGRESS_REQUEST,
      CHECK_TRANSACTION_IN_PROGRESS_SUCCESS,
      CHECK_TRANSACTION_IN_PROGRESS_FAILURE
    ],
    callAPI: (state, options) =>
      api.save(transactionURL(`transaction/${transactionId}/edit-remittance-details`), {
        programId: programId,
        forceLock: forceLock
      }),
    httpErrorTypes,
    errorDisplayType: 'modal',
    errorTitle: i18n.t('transactionDetails.errorMessages.checkTransactionInProgressFailure')
  };
};

export const cancelEditRemittanceDetails = (transactionId, programId, displayLegacyInvoiceMatching = false) => {
  return {
    types: [
      CANCEL_EDIT_TRANSACTION_REQUEST,
      CANCEL_EDIT_TRANSACTION_SUCCESS,
      CANCEL_EDIT_TRANSACTION_FAILURE,
      CANCEL_AND_KEEP_STATE
    ],
    callAPI: (state, options) =>
      api.save(
        transactionURL(
          `program/${programId}/transaction/${transactionId}/remittance-detail?displayLegacyInvoiceMatching=${displayLegacyInvoiceMatching}`
        ),
        {}
      ),
    dispatchOnSuccess: [loadOpenAdvicesAndInvoices],
    httpErrorTypes
  };
};

export const disassociateAdvices = (
  openAdvicesAndInvoicesState,
  transactionId,
  txnDetails,
  displayLegacyInvoiceMatching = false
) => {
  const payload = disassociateAdvicePayload(openAdvicesAndInvoicesState, transactionId, txnDetails);
  return {
    types: [DISASSOCIATE_ADVICES_REQUEST, DISASSOCIATE_ADVICES_SUCCESS, DISASSOCIATE_ADVICES_FAILURE],
    callAPI: (state, options) =>
      api.save(
        transactionURL(
          `transaction/disassociate-remittance-advice-details?displayLegacyInvoiceMatching=${displayLegacyInvoiceMatching}`
        ),
        payload
      ),
    dispatchOnSuccess: [
      () => cancelEditRemittanceDetails(transactionId, txnDetails?.programId, displayLegacyInvoiceMatching)
    ],
    httpErrorTypes,
    successDisplayType: 'toast',
    errorDisplayType: 'modal',
    errorTitle: i18n.t('transactionDetails.errorMessages.disassociateAdvices')
  };
};

export const loadInvoiceFields = () => ({
  types: [LOAD_INVOICE_FIELDS_REQUEST, LOAD_INVOICE_FIELDS_SUCCESS, LOAD_INVOICE_FIELDS_FAILURE],
  callAPI: (state, { customerId, userId }) => api.fetch(transactionURL(`transaction/invoice-detail-search-fields`)),
  httpErrorTypes
});

export const loadInvoices = (payload, transactionId, irTransactionId) => (dispatch, getState) => {
  const data = cloneDeep(payload);
  const userSettings = getState().settings.userSettings;
  const fieldsToUnformat = { amount: FIELD_TYPES.AMOUNT };
  const newPayload = unformatPayload(fieldsToUnformat, data)(userSettings);
  return dispatch({
    types: [LOAD_INVOICES_REQUEST, LOAD_INVOICES_SUCCESS, LOAD_INVOICES_FAILURE],
    callAPI: (state, options) =>
      api.save(transactionURL(`transaction/open-invoice-search`), { transactionId, irTransactionId, ...newPayload }),
    httpErrorTypes
  });
};

export const deactivateInvoices = (payload, transactionId, programId, transactiondetails) => (dispatch, getState) => {
  const data = cloneDeep(payload);
  const userSettings = getState().settings.userSettings;
  const fieldsToUnformat = { amount: FIELD_TYPES.AMOUNT };
  const {
    amount,
    displayLegacyInvoiceMatching,
    invoiceBalancingEnabled,
    forcedBalancingEnabled,
    invoiceBalancingField,
    forcedBalancingInvoiceNumber,
    irEnabled,
    procDate
  } = transactiondetails.data.transactionDetails;
  const { invoiceStatusHistRows } = transactiondetails.data.remittanceDetails;

  const remittancesList = createPayload(
    payload,
    transactiondetails.remittancesColumnList,
    userSettings,
    transactiondetails.refItemData
  );

  return dispatch({
    types: [DEACTIVATE_INVOICES_REQUEST, DEACTIVATE_INVOICES_SUCCESS, DEACTIVATE_INVOICES_FAILURE],
    callAPI: (state, options) =>
      api.save(transactionURL(`transaction/${transactionId}/deactivate-remittance-details`), {
        programId: Number(programId),
        transactionAmount: amount,
        displayLegacyInvoiceMatching: displayLegacyInvoiceMatching,
        invoiceBalancingEnabled: Number(invoiceBalancingEnabled),
        invoiceBalancingField: invoiceBalancingField,
        forcedBalancingEnabled: Number(forcedBalancingEnabled),
        forcedBalancingInvoiceNumber: forcedBalancingInvoiceNumber,
        remittancesList: remittancesList,
        userForceBalancingFlag: true,
        irEnabled: irEnabled,
        processDate: procDate,
        invoiceStatusHistRows: invoiceStatusHistRows
      }),
    dispatchOnSuccess: [() => cancelEditRemittanceDetails(transactionId, programId, displayLegacyInvoiceMatching)],
    httpErrorTypes,
    successDisplayType: 'toast',
    errorDisplayType: 'modal',
    errorTitle: i18n.t('transactionDetails.errorMessages.deactivateInvoices')
  });
};

export const updateDocTypeCode = payload => ({
  types: [UPDATE_TYPE_CODE_REQUEST, UPDATE_TYPE_CODE_SUCCESS, UPDATE_TYPE_CODE_FAILURE],
  callAPI: (state, options) => api.save(transactionURL(`transaction/save-image-type-code`), payload),
  httpErrorTypes,
  successDisplayType: 'toast',
  errorDisplayType: 'modal',
  errorTitle: i18n.t('transactionDetails.errorMessages.updateDocType')
});
