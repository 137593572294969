import { LOAD_USERINFO_REQUEST, LOAD_USERINFO_SUCCESS, LOAD_USERINFO_FAILURE } from '../actions/userinfoActions';

const initialState = {
  status: 0,
  list: [],
  isFetching: false,
  errorMessage: ''
};

function loadUserinfoRequest(state, action) {
  return {
    ...state,
    ...initialState,
    isFetching: true
  };
}

function loadUserinfoSuccess(
  state,
  {
    userinfo: {
      status,
      data: { users: list },
      headers
    }
  }
) {
  return Object.assign({}, state, {
    status,
    list,
    headers,
    isFetching: false
  });
}

function loadUserinfoFailure(state, action) {
  const status = action.error ? action.error.status : 0;

  return {
    ...state,
    status,
    errorMessage: action.error.message,
    isFetching: false
  };
}

export default function userinfoReducer(state = initialState, action) {
  const handlers = {
    [LOAD_USERINFO_REQUEST]: loadUserinfoRequest,
    [LOAD_USERINFO_SUCCESS]: loadUserinfoSuccess,
    [LOAD_USERINFO_FAILURE]: loadUserinfoFailure
  };

  return handlers.hasOwnProperty(action.type) ? handlers[action.type](state, action) : state;
}
