import { createContext, useContext } from 'react';

export const FormContext = createContext({
  narrow: false
});

export const useFormContext = () => {
  const context = useContext(FormContext);

  return context;
};
