import api from 'api';
import isEmpty from 'lodash/isEmpty';
import i18n from 'i18n';
import { userAdminURL } from './utils';
import { httpErrorTypes } from 'utility/error';

export const CLEAR_USER_DETAILS = 'CLEAR_USER_DETAILS';
export const DEACTIVATE_USER_REQUEST = 'DEACTIVATE_USER_REQUEST';
export const DEACTIVATE_USER_SUCCESS = 'DEACTIVATE_USER_SUCCESS';
export const DEACTIVATE_USER_FAILURE = 'DEACTIVATE_USER_FAILURE';
export const GET_USER_DETAILS_REQUEST = 'GET_USER_DETAILS_REQUEST';
export const GET_USER_DETAILS_SUCCESS = 'GET_USER_DETAILS_SUCCESS';
export const GET_USER_DETAILS_FAILURE = 'GET_USER_DETAILS_FAILURE';
export const REACTIVATE_USER_REQUEST = 'REACTIVATE_USER_REQUEST';
export const REACTIVATE_USER_SUCCESS = 'REACTIVATE_USER_SUCCESS';
export const REACTIVATE_USER_FAILURE = 'REACTIVATE_USER_FAILURE';
export const RESET_PW_REQUEST = 'RESET_PW_REQUEST';
export const RESET_PW_SUCCESS = 'RESET_PW_SUCCESS';
export const RESET_PW_FAILURE = 'RESET_PW_FAILURE';
export const RETRIEVE_ACTIVATION_CODE_REQUEST = 'RETRIEVE_ACTIVATION_CODE_REQUEST';
export const RETRIEVE_ACTIVATION_CODE_SUCCESS = 'RETRIEVE_ACTIVATION_CODE_SUCCESS';
export const RETRIEVE_ACTIVATION_CODE_FAILURE = 'RETRIEVE_ACTIVATION_CODE_FAILURE';
export const UNLOCK_USER_REQUEST = 'UNLOCK_USER_REQUEST';
export const UNLOCK_USER_SUCCESS = 'UNLOCK_USER_SUCCESS';
export const UNLOCK_USER_FAILURE = 'UNLOCK_USER_FAILURE';
export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';
export const SYNC_SELECTED_USER_ACCESS_EMAIL_REQUEST = 'SYNC_SELECTED_USER_ACCESS_EMAIL_REQUEST';
export const SYNC_SELECTED_USER_ACCESS_EMAIL_SUCCESS = 'SYNC_SELECTED_USER_ACCESS_EMAIL_SUCCESS';
export const SYNC_SELECTED_USER_ACCESS_EMAIL_FAILURE = 'SYNC_SELECTED_USER_ACCESS_EMAIL_FAILURE';

export const viewUserDetailsErrorTypes = {
  GET_USER_DETAILS: 'GET_USER_DETAILS',
  DEACTIVATE_USER: 'DEACTIVATE_USER',
  REACTIVATE_USER: 'REACTIVATE_USER',
  UNLOCK_USER: 'UNLOCK_USER',
  RETRIEVE_ACTIVATION_CODE: 'RETRIEVE_ACTIVATION_CODE',
  RESET_PW: 'RESET_PW',
  DELETE_USER: 'DELETE_USER',
  SYNC_USER_EMAIL: {
    type: SYNC_SELECTED_USER_ACCESS_EMAIL_FAILURE,
    message: i18n.t('administration.actionMessages.emailError')
  }
};

export const editUserActionTypes = {
  DEACTIVATE_USER: 'DEACTIVATE_USER',
  UNLOCK_USER: 'UNLOCK_USER',
  RETRIEVE_ACTIVATION_CODE: 'RETRIEVE_ACTIVATION_CODE',
  RESET_PW: 'RESET_PW',
  REACTIVATE_USER: 'REACTIVATE_USER',
  DELETE_USER: 'DELETE_USER'
};

export const clearUserDetails = () => {
  return { type: CLEAR_USER_DETAILS };
};

export const getUserDetails = userId => {
  return {
    types: [GET_USER_DETAILS_REQUEST, GET_USER_DETAILS_SUCCESS, GET_USER_DETAILS_FAILURE],
    callAPI: () => api.fetch(userAdminURL(`view/users/${userId}`)),
    httpErrorTypes,
    errorDisplayType: 'modal'
  };
};

export const requestDeactivateUser = () => {
  return { type: DEACTIVATE_USER_REQUEST };
};

export const deactivateUser = (adminCustId, adminUserId, userId) => {
  return dispatch => {
    return api
      .save(`usersvc/v0/cust/${adminCustId}/user/${adminUserId}/admin/deactivateuser/${userId}`, {})
      .then(response => {
        const data = response.data;
        dispatch({ type: DEACTIVATE_USER_SUCCESS, data });
      })
      .catch(err => {
        const error = {
          type: viewUserDetailsErrorTypes.DEACTIVATE_USER,
          status: err.status,
          message: err.data.message
        };
        dispatch({ type: DEACTIVATE_USER_FAILURE, errors: error });
      });
  };
};

export const reactivateUser = (userId, editUserPayload) => {
  return {
    types: [REACTIVATE_USER_REQUEST, REACTIVATE_USER_SUCCESS, REACTIVATE_USER_FAILURE],
    callAPI: () => api.save(userAdminURL(`reactivate/${userId}`), editUserPayload),
    httpErrorTypes,
    errorDisplayType: 'modal'
  };
};

export const requestUnlockUser = () => {
  return { type: UNLOCK_USER_REQUEST };
};

export const unlockUser = (adminCustId, adminUserId, userId, geUserId, jpmAccessId) => {
  return dispatch => {
    return api
      .save(`usersvc/v0/cust/${adminCustId}/user/${adminUserId}/admin/unlockuser/${userId}`, {
        geUserId,
        jpmAccessId
      })
      .then(response => {
        dispatch({ type: UNLOCK_USER_SUCCESS });
      })
      .catch(err => {
        const error = {
          type: viewUserDetailsErrorTypes.UNLOCK_USER,
          status: err.status,
          message: err.data.message
        };
        dispatch({ type: UNLOCK_USER_FAILURE, errors: error });
      });
  };
};

export const requestRetrieveActivationCode = () => {
  return { type: RETRIEVE_ACTIVATION_CODE_REQUEST };
};

export const retrieveActivationCode = (adminCustId, adminUserId, userId, geUserId, jpmAccessId) => {
  return dispatch => {
    return api
      .save(`usersvc/v0/cust/${adminCustId}/user/${adminUserId}/admin/retrieveactivationcode/${userId}`, {
        geUserId,
        jpmAccessId
      })
      .then(response => {
        const { activationCode } = response.data;
        dispatch({ type: RETRIEVE_ACTIVATION_CODE_SUCCESS, activationCode });
      })
      .catch(err => {
        const error = {
          type: viewUserDetailsErrorTypes.RETRIEVE_ACTIVATION_CODE,
          status: err.status,
          message: err.data.message || i18n.t('administration.actionMessages.exception')
        };
        dispatch({ type: RETRIEVE_ACTIVATION_CODE_FAILURE, errors: error });
      });
  };
};

export const requestResetPassword = () => {
  return { type: RESET_PW_REQUEST };
};

export const resetPassword = (adminCustId, adminUserId, userId, geUserId, jpmAccessId) => {
  return dispatch => {
    return api
      .save(`usersvc/v0/cust/${adminCustId}/user/${adminUserId}/admin/passwordreset/${userId}`, {
        geUserId,
        jpmAccessId
      })
      .then(response => {
        const { password } = response.data;
        dispatch({ type: RESET_PW_SUCCESS, password });
      })
      .catch(err => {
        const error = {
          type: viewUserDetailsErrorTypes.RESET_PW,
          status: err.status,
          message: err.data.message
        };
        dispatch({ type: RESET_PW_FAILURE, errors: error });
      });
  };
};

export const deleteUser = userId => {
  return {
    types: [DELETE_USER_REQUEST, DELETE_USER_SUCCESS, DELETE_USER_FAILURE],
    callAPI: () => api.save(userAdminURL(`deleteuser/${userId}`)),
    httpErrorTypes
  };
};

export const validateUserPersonalInformation = (custId, userId, userProfile) => {
  return dispatch => {
    return api
      .save(`usersvc/v0/cust/${custId}/user/${userId}/admin/validatepersonalinfo`, userProfile)
      .then(response => {
        return response.data;
      })
      .catch(err => {
        return {
          valid: false,
          duplicate: true,
          errors: {
            userFullName: i18n.t('administration.actionMessages.unableFetch')
          }
        };
      });
  };
};

export const syncSelectedUserAccessEmail = (selectedUserId, selectedUserEmail, selectedSmUserId) => {
  return {
    types: [
      SYNC_SELECTED_USER_ACCESS_EMAIL_REQUEST,
      SYNC_SELECTED_USER_ACCESS_EMAIL_SUCCESS,
      SYNC_SELECTED_USER_ACCESS_EMAIL_FAILURE
    ],
    callAPI: () =>
      api.save(userAdminURL('syncuseremail'), {
        selectedUserId: selectedUserId,
        selectedUserCurrentEmail: selectedUserEmail,
        selectedSmUserId: selectedSmUserId
      }),
    httpErrorTypes: {
      DEFAULT: httpErrorTypes.DEFAULT,
      401: viewUserDetailsErrorTypes.SYNC_USER_EMAIL,
      403: viewUserDetailsErrorTypes.SYNC_USER_EMAIL,
      404: viewUserDetailsErrorTypes.SYNC_USER_EMAIL,
      503: viewUserDetailsErrorTypes.SYNC_USER_EMAIL
    },
    successDisplayType: 'toast',
    successTitle: i18n.t('administration.labels.syncEmailSuccess'),
    successMessage: i18n.t('administration.messages.syncEmailSuccess')
  };
};
