import api from 'api';
import cloneDeep from 'lodash/cloneDeep';

import { errorActions, httpErrorTypes } from 'utility/error';
import { FIELD_TYPES, unformatPayload } from 'hooks/useFieldFormatter';

export const LOAD_CAPTURERESULTS_REQUEST = 'LOAD_CAPTURERESULTS_REQUEST';
export const LOAD_CAPTURERESULTS_SUCCESS = 'LOAD_CAPTURERESULTS_SUCCESS';
export const LOAD_CAPTURERESULTS_FAILURE = 'LOAD_CAPTURERESULTS_FAILURE';
export const RESET_CAPTURERESULTS = 'RESET_CAPTURERESULTS';
export const DELETE_BATCHES_REQUEST = 'DELETE_BATCHES_REQUEST';
export const DELETE_BATCHES_SUCCESS = 'DELETE_BATCHES_SUCCESS';
export const DELETE_BATCHES_FAILURE = 'DELETE_BATCHES_FAILURE';
export const CHECK_BATCH_IN_PROGRESS_REQUEST = 'CHECK_BATCH_IN_PROGRESS_REQUEST';
export const CHECK_BATCH_IN_PROGRESS_SUCCESS = 'CHECK_BATCH_IN_PROGRESS_SUCCESS';
export const CHECK_BATCH_IN_PROGRESS_FAILURE = 'CHECK_BATCH_IN_PROGRESS_FAILURE';
export const CLEAR_BATCH_IN_PROGRESS_RESPONSE = 'CLEAR_BATCH_IN_PROGRESS_RESPONSE';
export const LOAD_CAPTURE_RESULTS_PROGRAMS_FILTER_REQUEST = 'LOAD_CAPTURE_RESULTS_PROGRAMS_FILTER_REQUEST';
export const LOAD_CAPTURE_RESULTS_PROGRAMS_FILTER_SUCCESS = 'LOAD_CAPTURE_RESULTS_PROGRAMS_FILTER_SUCCESS';
export const LOAD_CAPTURE_RESULTS_PROGRAMS_FILTER_FAILURE = 'LOAD_CAPTURE_RESULTS_PROGRAMS_FILTER_FAILURE';
export const SUBMIT_BATCHES_REQUEST = 'SUBMIT_BATCHES_REQUEST';
export const SUBMIT_BATCHES_SUCCESS = 'SUBMIT_BATCHES_SUCCESS';
export const SUBMIT_BATCHES_FAILURE = 'SUBMIT_BATCHES_FAILURE';
export const LOAD_EXPORT_REQUEST = 'LOAD_EXPORT_REQUEST';
export const LOAD_EXPORT_SUCCESS = 'LOAD_EXPORT_SUCCESS';
export const LOAD_EXPORT_FAILURE = 'LOAD_EXPORT_FAILURE';
export const LOAD_SUBMISSION_CONF_REQUEST = 'LOAD_SUBMISSION_CONF_REQUEST';
export const LOAD_SUBMISSION_CONF_SUCCESS = 'LOAD_SUBMISSION_CONF_SUCCESS';
export const LOAD_SUBMISSION_CONF_FAILURE = 'LOAD_SUBMISSION_CONF_FAILURE';

export function resetCaptureResults() {
  return { type: RESET_CAPTURERESULTS };
}

export const loadCaptureResultsProgramsFilter = (customerId, userId) => {
  return {
    types: [
      LOAD_CAPTURE_RESULTS_PROGRAMS_FILTER_REQUEST,
      LOAD_CAPTURE_RESULTS_PROGRAMS_FILTER_SUCCESS,
      LOAD_CAPTURE_RESULTS_PROGRAMS_FILTER_FAILURE
    ],
    callAPI: (state, { customerId, userId }) =>
      api.fetch(`lockboxsvc/v0/cust/${customerId}/user/${userId}/vr/resultfilter`),
    httpErrorTypes
  };
};

export const loadCaptureResults = (payload, page) => ({
  types: [LOAD_CAPTURERESULTS_REQUEST, LOAD_CAPTURERESULTS_SUCCESS, LOAD_CAPTURERESULTS_FAILURE],
  callAPI: ({ settings: { userSettings } }, { customerId, userId }) => {
    const data = cloneDeep(payload);
    const fieldsToUnformat = { amount: FIELD_TYPES.AMOUNT, checkAmount: FIELD_TYPES.AMOUNT };
    const unformattedPayload = unformatPayload(fieldsToUnformat, data)(userSettings);
    const newPayload = unformattedPayload;

    return api.save(`remotesvc/v0/capture/results/cust/${customerId}/user/${userId}/${page}`, newPayload);
  },
  httpErrorTypes
});

export const deleteBatches = batches => ({
  types: [DELETE_BATCHES_REQUEST, DELETE_BATCHES_SUCCESS, DELETE_BATCHES_FAILURE],
  callAPI: ({}, { customerId, userId }) =>
    api.save(`remotesvc/v0/capture/cust/${customerId}/user/${userId}/results/delete`, batches),
  httpErrorTypes
});

export const submitBatches = batches => ({
  types: [SUBMIT_BATCHES_REQUEST, SUBMIT_BATCHES_SUCCESS, SUBMIT_BATCHES_FAILURE],
  callAPI: ({}, { customerId, userId }) =>
    api.save(`remotesvc/v0/capture/cust/${customerId}/user/${userId}/results`, batches),
  httpErrorTypes
});

export const checkBatchInProgress = request => {
  const { batchUniqueId } = request;

  return {
    types: [CHECK_BATCH_IN_PROGRESS_REQUEST, CHECK_BATCH_IN_PROGRESS_SUCCESS, CHECK_BATCH_IN_PROGRESS_FAILURE],
    callAPI: ({}, { customerId, userId }) =>
      api.save(
        'remotesvc/v0/capture/cust/' + customerId + '/user/' + userId + '/batch/movetoinprogress/update',
        request
      ),
    httpErrorTypes,
    params: { batchUniqueId }
  };
};

export function clearBatchInProgressResponse() {
  return { type: CLEAR_BATCH_IN_PROGRESS_RESPONSE };
}

export const loadExport = (payload, property) => ({
  types: [LOAD_EXPORT_REQUEST, LOAD_EXPORT_SUCCESS, LOAD_EXPORT_FAILURE],
  callAPI: ({ settings: { userSettings } }, { customerId, userId }) => {
    const data = cloneDeep(payload);

    return api.save(`remotesvc/v0/capture/results/cust/${customerId}/user/${userId}/${property}/export`, data);
  },
  httpErrorTypes
});

export const loadSubmissionConfirmationReport = payload => {
  return {
    types: [LOAD_SUBMISSION_CONF_REQUEST, LOAD_SUBMISSION_CONF_SUCCESS, LOAD_SUBMISSION_CONF_FAILURE],
    callAPI: (state, { customerId, userId }) =>
      api.fetchPdf(
        `remotesvc/v0/capture/cust/${customerId}/user/${userId}/results/submissionConfirmation/history`,
        payload
      ),
    httpErrorTypes
  };
};
