import Scanner from 'api/scanner';

export const ENQUEUE_SCAN_ACTION = 'ENQUEUE_SCAN_ACTION';
export const DEQUEUE_SCAN_ACTION = 'DEQUEUE_SCAN_ACTION';
export const STOP_CALLED = 'STOP_CALLED';

export const enqueue = act => (dispatch, getState) => {
  dispatch({ type: ENQUEUE_SCAN_ACTION, action: act });
};

export const dequeue = () => dispatch => {
  dispatch({ type: DEQUEUE_SCAN_ACTION });
};

export const execute = action => dispatch => Scanner.call(action);
