import api from 'api';
import i18n from 'i18n';
import { httpErrorTypes } from 'utility/error';
import { userProgramURL, userAdminURL } from './utils';
export const CLEAR_PROGRAMS = 'CLEAR_PROGRAMS';
export const CLEAR_GROUPS = 'CLEAR_GROUPS';
export const LOAD_GROUPS_REQUEST = 'LOAD_GROUPS_REQUEST';
export const LOAD_GROUPS_SUCCESS = 'LOAD_GROUPS_SUCCESS';
export const LOAD_GROUPS_FAILURE = 'LOAD_GROUPS_FAILURE';
export const LOAD_PROGRAMS_REQUEST = 'LOAD_PROGRAMS_REQUEST';
export const LOAD_PROGRAMS_SUCCESS = 'LOAD_PROGRAMS_SUCCESS';
export const LOAD_PROGRAMS_FAILURE = 'LOAD_PROGRAMS_FAILURE';

export const programsAndGroupsErrorTypes = {
  LOAD_PROGRAMS: 'LOAD_PROGRAMS',
  LOAD_GROUPS: 'LOAD_GROUPS'
};

export const clearPrograms = () => {
  return { type: CLEAR_PROGRAMS };
};

export const clearGroups = () => {
  return { type: CLEAR_GROUPS };
};

export const loadGroups = (body, t = i18n.t) => {
  return {
    types: [LOAD_GROUPS_REQUEST, LOAD_GROUPS_SUCCESS, LOAD_GROUPS_FAILURE],
    callAPI: (state, options) => api.save(userProgramURL('groups'), body),
    httpErrorTypes,
    errorDisplayType: 'modal',
    errorTitle: t('administration.actionMessages.unableFetch')
  };
};

export const loadPrograms = (t = i18n.t) => {
  return {
    types: [LOAD_PROGRAMS_REQUEST, LOAD_PROGRAMS_SUCCESS, LOAD_PROGRAMS_FAILURE],
    callAPI: (state, options) => api.fetch(userProgramURL('programs')),
    httpErrorTypes,
    errorDisplayType: 'modal',
    errorTitle: t('administration.actionMessages.unableFetch')
  };
};

export const loadEditUserPrograms = (editUserId, t = i18n.t) => {
  return {
    types: [LOAD_PROGRAMS_REQUEST, LOAD_PROGRAMS_SUCCESS, LOAD_PROGRAMS_FAILURE],
    callAPI: (state, options) => api.fetch(userAdminURL(`edituser/${editUserId}/programs`)),
    httpErrorTypes,
    errorDisplayType: 'modal',
    errorTitle: t('administration.actionMessages.unableFetch')
  };
};

export const loadEditUserGroups = (editUserId, body) => {
  return {
    types: [LOAD_GROUPS_REQUEST, LOAD_GROUPS_SUCCESS, LOAD_GROUPS_FAILURE],
    callAPI: (state, options) => api.save(userAdminURL(`edituser/${editUserId}/groups`), body),
    httpErrorTypes,
    errorDisplayType: 'modal',
    errorTitle: i18n.t('administration.actionMessages.unableFetch')
  };
};
