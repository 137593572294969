import React from 'react';
import { Dropdown } from '@jpmuitk/dropdown';
import { FormField } from '@jpmuitk/form-field';
import ConditionalWrapper from 'Components/ConditionalWrapper';
import { ColumnLayout } from '@jpmuitk/column-layout';
import { columnLayoutItemSizes } from 'Components/Forms/helpers';
import i18n from 'i18n';

const DEFAULT_PLACEHOLDER = i18n.t('fullWidthWrapper.defaultPlaceholder');
const DEFAULT_ITEM_TO_STRING = item => (item ? item.description : DEFAULT_PLACEHOLDER);

const DropdownFullWidthWrapper = ({
  source,
  selected,
  onChange,
  itemToString = DEFAULT_ITEM_TO_STRING,
  required = false,
  label,
  description,
  popperProps = {},
  widthResizeThreshold,
  rowsToDisplay,
  useColumnLayout,
  dataTestId,
  ...rest
}) => {
  const onSelect = (_, item) => {
    onChange(item);
  };
  return (
    <ConditionalWrapper
      condition={useColumnLayout}
      wrapper={children => (
        <ColumnLayout item {...columnLayoutItemSizes()}>
          {children}
        </ColumnLayout>
      )}
    >
      <FormField
        label={label}
        description={description}
        LabelProps={{
          NecessityIndicator: () => (required ? <i>&nbsp;{i18n.t('necessity.required')}</i> : '')
        }}
      >
        <Dropdown
          source={source}
          selectedItem={selected || DEFAULT_PLACEHOLDER}
          itemToString={itemToString}
          onSelect={onSelect}
          PopperProps={popperProps}
          widthResizeThreshold={widthResizeThreshold}
          rowsToDisplay={rowsToDisplay}
          ButtonProps={{ 'data-test-id': dataTestId }}
          {...rest}
        />
      </FormField>
    </ConditionalWrapper>
  );
};

export default DropdownFullWidthWrapper;
