import { useEffect, useState, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import amountFormatter, { amountLabels, amountUnformatter, amountValidations } from './amountFormatter';
import { dateUnformatter } from './dateFormatter';

export const FIELD_TYPES = {
  AMOUNT: 'amount',
  DATE: 'date',
  NONE: ''
};

const FIELD_TYPE_FORMATTERS = {
  [FIELD_TYPES.AMOUNT]: (content, userSettings) => amountFormatter(content, userSettings),
  [FIELD_TYPES.NONE]: undefined
};
const FIELD_TYPE_UNFORMATTERS = {
  [FIELD_TYPES.AMOUNT]: (content, userSettings) => amountUnformatter(content, userSettings),
  [FIELD_TYPES.DATE]: (content, userSettings) => dateUnformatter(content, userSettings),
  [FIELD_TYPES.NONE]: undefined
};
const grabValidations = {
  [FIELD_TYPES.AMOUNT]: (userSettings, params) => amountValidations(userSettings, params),
  [FIELD_TYPES.NONE]: () => ({})
};
const grabLabels = {
  [FIELD_TYPES.AMOUNT]: (label, userSettings) => amountLabels(label, userSettings),
  [FIELD_TYPES.NONE]: (label, userSettings) => ({ label })
};

export const useFieldFormatter = (content, type = FIELD_TYPES.NONE, labels = {}, params) => {
  const userSettings = useSelector(state => state.settings.userSettings);
  const isValidType = FIELD_TYPES.hasOwnProperty(type.toUpperCase());

  const formatter = isValidType ? FIELD_TYPE_FORMATTERS[type] : FIELD_TYPE_FORMATTERS[FIELD_TYPES.NONE];
  const updatedContent = format(formatter)(content, userSettings, params);

  const validations = isValidType ? grabValidations[type] : grabValidations[FIELD_TYPES.NONE];
  const validation = validations(userSettings, params);

  const label = isValidType ? grabLabels[type] : grabLabels[FIELD_TYPES.NONE];
  const adaLabels = format(label)(labels, userSettings, params);

  return [updatedContent, validation, adaLabels];
};

const format = formatter => (content, userSettings) => {
  if (Array.isArray(content)) {
    return content.map(value => format(formatter)(value, userSettings));
  } else if (!isNil(content) && typeof content === 'object') {
    return Object.keys(content).reduce((acc, key) => {
      return { ...acc, [key]: format(formatter)(content[key], userSettings) };
    }, {});
  } else {
    if (content === null || (isNaN(content) && isEmpty(content))) {
      return content;
    } else {
      return formatter ? formatter(content, userSettings) : content;
    }
  }
};

export const fieldFormatter = (content, type = FIELD_TYPES.NONE, userSettings, labels = {}) => {
  const updatedContent = format(FIELD_TYPE_FORMATTERS[type])(content, userSettings);
  const validation = grabValidations[type](userSettings);
  return [updatedContent, validation];
};

export const fieldUnformatter = (content, type = FIELD_TYPES.NONE, userSettings) => {
  const isValidType = FIELD_TYPES.hasOwnProperty(type.toUpperCase());
  const unformatter = isValidType ? FIELD_TYPE_UNFORMATTERS[type] : FIELD_TYPE_UNFORMATTERS[FIELD_TYPES.NONE];
  return format(unformatter)(content, userSettings);
};

export const unformatPayload = (fieldsToUnformat, data) => userSettings =>
  Object.keys(data).reduce((acc, key) => {
    return {
      ...acc,
      [key]: fieldsToUnformat.hasOwnProperty(key)
        ? fieldUnformatter(data[key], fieldsToUnformat[key], userSettings)
        : data[key]
    };
  }, {});
