import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { withStyles } from '@jpmuitk/theme';

import Tag from 'Components/Tag';
import ButtonAsLink from 'Components/Button/AsLink';

import moment from 'moment';
import { isInclusivelyAfterDay } from 'react-dates';

import { listStyles } from 'styles';

const DATE_OF_RECENT_UPDATE = 30;

const ProductGuideRenderer = ({ list, guide, classes }) => {
  const { t } = useTranslation();
  const removeFileExtension = fileName => {
    const indexOfLastPeriod = fileName.lastIndexOf('.');

    return indexOfLastPeriod > -1 ? fileName.slice(0, indexOfLastPeriod) : fileName;
  };

  const sortProductGuideByFileName = list => {
    return list.sort((item1, item2) => {
      return item1.fileName > item2.fileName ? 1 : -1;
    });
  };

  const renderIsProductGuideNew = lastUpdatedDate => {
    const cutoffForRecentDate = moment().subtract(DATE_OF_RECENT_UPDATE, 'days');
    const productGuideLastUpdated = moment(lastUpdatedDate);

    return isInclusivelyAfterDay(productGuideLastUpdated, cutoffForRecentDate) ? (
      <Tag>{t('support.productGuideRenderer.new')}</Tag>
    ) : null;
  };

  const sortedProductGuideList = sortProductGuideByFileName(list);

  return (
    <ul className={classes.list}>
      {sortedProductGuideList.map(productGuide => (
        <li className={classes.densityLow}>
          <ButtonAsLink
            key={productGuide.fileName}
            label={removeFileExtension(productGuide.fileName)}
            id="downloadPDF"
            aria-describedby="downloadPDF"
            aria-label={t('support.productGuideRenderer.downloadPDF', {
              filename: removeFileExtension(productGuide.fileName)
            })}
            onClick={() => guide(productGuide.productGuideId)}
          />
          {renderIsProductGuideNew(productGuide.lastUpdatedDate)}
        </li>
      ))}
    </ul>
  );
};

export default withStyles(listStyles)(ProductGuideRenderer);
