import api from 'api';
import { userAdminURL } from './utils';

export const CLEAR_USER_ACCOUNT = 'CLEAR_USER_ACCOUNT';
export const EDIT_USER_REQUEST = 'EDIT_USER_REQUEST';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const EDIT_USER_FAILURE = 'EDIT_USER_FAILURE';
export const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE';
export const LOAD_USER_SERVICE_ENTITLEMENTS_REQUEST = 'LOAD_USER_SERVICE_ENTITLEMENTS_REQUEST';
export const LOAD_USER_SERVICE_ENTITLEMENTS_SUCCESS = 'LOAD_USER_SERVICE_ENTITLEMENTS_SUCCESS';
export const LOAD_USER_SERVICE_ENTITLEMENTS_FAILURE = 'LOAD_USER_SERVICE_ENTITLEMENTS_FAILURE';

export const editUserErrorTypes = {
  GET_USERS: 'GET_USERS',
  EDIT_USER: 'EDIT_USER'
};

export const clearUserAccount = () => {
  return { type: CLEAR_USER_ACCOUNT };
};

export const editUser = (editUserId, editUserPayload) => {
  return {
    types: [EDIT_USER_REQUEST, EDIT_USER_SUCCESS, EDIT_USER_FAILURE],
    callAPI: () => api.save(userAdminURL(`edituser/${editUserId}`), editUserPayload),
    httpErrorTypes: { DEFAULT: editUserErrorTypes.EDIT_USER },
    errorDisplayType: 'modal'
  };
};

export const getUsers = t => {
  return {
    types: [GET_USERS_REQUEST, GET_USERS_SUCCESS, GET_USERS_FAILURE],
    successCase: response => response.data.users.length > 0,
    successCaseNotMet: {
      errorType: editUserErrorTypes.GET_USERS,
      message: t('administration.actionMessages.noUserAvailable')
    },
    callAPI: () => api.fetch(userAdminURL('view/users')),
    httpErrorTypes: { DEFAULT: editUserErrorTypes.GET_USERS }
  };
};

export const loadUserServiceEntitlements = (editUserId, hasAssignedDMPrograms) => {
  return {
    types: [
      LOAD_USER_SERVICE_ENTITLEMENTS_REQUEST,
      LOAD_USER_SERVICE_ENTITLEMENTS_SUCCESS,
      LOAD_USER_SERVICE_ENTITLEMENTS_FAILURE
    ],
    callAPI: () => {
      return hasAssignedDMPrograms == null
        ? api.fetch(userAdminURL(`edituser/${editUserId}/serviceentitlements`))
        : api.fetch(
            userAdminURL(`edituser/${editUserId}/serviceentitlements?hasAssignedDMPrograms=${hasAssignedDMPrograms}`)
          );
    },
    httpErrorTypes: { DEFAULT: LOAD_USER_SERVICE_ENTITLEMENTS_FAILURE }
  };
};
