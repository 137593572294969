import {
  LOAD_BROADCAST_MESSAGES_REQUEST,
  LOAD_BROADCAST_MESSAGES_SUCCESS,
  LOAD_BROADCAST_MESSAGES_FAILURE
} from '../actions/broadcastalertActions';

function broadcastalertRequest(state, action) {
  return Object.assign({}, state, { isFetching: true });
}
function broadcastalertSuccess(state, action) {
  return Object.assign({}, state, { broadcastalerts: action.messages }, { isFetching: false });
}
function broadcastalertFailure(state, action) {
  return Object.assign({}, state, { isFetching: false });
}
export default function broadcastalertReducer(state = {}, action) {
  const handlers = {
    [LOAD_BROADCAST_MESSAGES_REQUEST]: broadcastalertRequest,
    [LOAD_BROADCAST_MESSAGES_SUCCESS]: broadcastalertSuccess,
    [LOAD_BROADCAST_MESSAGES_FAILURE]: broadcastalertFailure
  };
  if (handlers.hasOwnProperty(action.type)) {
    return handlers[action.type](state, action);
  } else {
    return state;
  }
}
