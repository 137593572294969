import cssVariables from 'Constants/_variables.module.scss';

export const sectionStyles = {
  card: {
    border: `1px solid ${cssVariables.grey60}`,
    padding: `0 ${cssVariables.space2}`,
    '& h3': {
      borderBottom: `1px solid ${cssVariables.grey60}`,
      paddingBottom: cssVariables.space2
    }
  },
  resourceCenter: {
    '& a:focus': {
      outline: `2px dotted ${cssVariables.blue300}`
    }
  }
};
