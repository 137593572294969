import {
  LOAD_ORIGINAL_ADVICE_REQUEST,
  LOAD_ORIGINAL_ADVICE_SUCCESS,
  LOAD_ORIGINAL_ADVICE_FAILURE,
  CLEAR_DOWNLOAD_ORIGINAL_ADVICE_EMAIL_STATE,
  DOWNLOAD_ORIGINAL_ADVICE_EMAIL_REQUEST,
  DOWNLOAD_ORIGINAL_ADVICE_EMAIL_SUCCESS,
  DOWNLOAD_ORIGINAL_ADVICE_EMAIL_FAILURE
} from './originalAdviceActions';

export const initialState = {
  errors: {},
  originalAdviceDetails: {}
};

const loadOriginalAdviceRequest = (state, action) => ({
  ...state,
  errors: {},
  originalAdviceDetails: {},
  isLoading: true
});

const loadOriginalAdviceSuccess = (state, action) => ({
  ...state,
  errors: {},
  originalAdviceDetails: action.response.data,
  isLoading: false
});

const loadOriginalAdviceFailure = (state, action) => ({
  ...state,
  errors: { ...action.error, type: LOAD_ORIGINAL_ADVICE_REQUEST },
  isLoading: false,
  originalAdviceDetails: {}
});

const downloadOriginalAdviceEmailRequest = (state, action) => ({
  ...state,
  exportError: undefined,
  isFetchingExport: true,
  exportData: undefined
});

const downloadOriginalAdviceEmailSuccess = (state, action) => ({
  ...state,
  exportError: undefined,
  isFetchingExport: false,
  exportData: action.response
});

const downloadOriginalAdviceEmailFailure = (state, action) => ({
  ...state,
  exportError: action.error,
  isFetchingExport: false,
  exportData: undefined
});

const clearDownloadOriginalAdviceEmailState = (state, action) => ({
  ...state,
  exportError: undefined,
  isFetchingExport: false,
  exportData: undefined
});

export default function originalAdviceReducer(state = initialState, action) {
  const handlers = {
    [LOAD_ORIGINAL_ADVICE_REQUEST]: loadOriginalAdviceRequest,
    [LOAD_ORIGINAL_ADVICE_SUCCESS]: loadOriginalAdviceSuccess,
    [LOAD_ORIGINAL_ADVICE_FAILURE]: loadOriginalAdviceFailure,
    [CLEAR_DOWNLOAD_ORIGINAL_ADVICE_EMAIL_STATE]: clearDownloadOriginalAdviceEmailState,
    [DOWNLOAD_ORIGINAL_ADVICE_EMAIL_REQUEST]: downloadOriginalAdviceEmailRequest,
    [DOWNLOAD_ORIGINAL_ADVICE_EMAIL_SUCCESS]: downloadOriginalAdviceEmailSuccess,
    [DOWNLOAD_ORIGINAL_ADVICE_EMAIL_FAILURE]: downloadOriginalAdviceEmailFailure
  };
  if (handlers.hasOwnProperty(action.type)) {
    return handlers[action.type](state, action);
  } else {
    return state;
  }
}
