import React from 'react';
import { useDispatch } from 'react-redux';
import { ContentStatus } from '@jpmuitk/content-status';
import UploadFile from 'Components/UploadFileButton';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@jpmuitk/theme';
import { uploadFileZoneStyles } from './styles';
import classNames from 'classnames';

export const handleUploadData = (event, func) => {
  const file = event.target.files[0];
  const formData = new FormData();
  formData.append('file', file);
  func(formData);
};

export const UploadFileZone = props => {
  const { classes, fileErrorMessage, uploadFile, uploadSuccess, uploadStatus, disabled = false } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const loadingProps = {
    status: 'loading',
    message: t('pageWithUpload.uploadFileZone.mainLoadingMsg')
  };
  const defaultProps = {
    status: 'info',
    title: t('pageWithUpload.uploadFileZone.mainDefaultMsg')
  };
  const errorProps = {
    status: 'error',
    title: fileErrorMessage
  };
  const successProps = {
    status: 'success',
    title: uploadSuccess
  };

  const contentStatusProps = {
    ['loading']: loadingProps,
    ['error']: errorProps,
    ['success']: successProps,
    ['default']: defaultProps
  };

  return (
    <div id="contentStatus" className={classNames(classes.root, { [classes.disabled]: disabled })}>
      <ContentStatus {...contentStatusProps[uploadStatus]} className={classes.statusCls} />
      <div className={classes.action}>
        <UploadFile
          uploadStatus={uploadStatus}
          title={t('pageWithUpload.uploadFileZone.title')}
          disabled={disabled}
          handleUploadData={e => handleUploadData(e, file => dispatch(uploadFile(file)))}
        />
      </div>
    </div>
  );
};

export default withStyles(uploadFileZoneStyles)(UploadFileZone);
