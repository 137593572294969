import { makeStyles } from '@jpmuitk/theme';

import { ColumnLayout } from '@jpmuitk/column-layout';

const containerUseStyles = makeStyles({
  item: {
    '& > *:not(:first-child)': {
      marginTop: 20
    }
  }
});

const SECTION_SIZE_LIST = { xs: 10, md: 8, lg: 6 };

export default ({ children, spacing = 0, sidebar }) => {
  const classes = containerUseStyles();
  const sizes = sidebar ? { xs: 12 } : SECTION_SIZE_LIST;

  return (
    <ColumnLayout container justify="center" spacing={spacing}>
      <ColumnLayout item {...sizes} classes={classes}>
        {children}
      </ColumnLayout>
    </ColumnLayout>
  );
};
