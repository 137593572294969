import { createUserReducer } from './createUserReducer';
import { editUserReducer } from './editUserReducer';
import { viewUsersReducer } from './viewUsersReducer';
import { programsAndGroupsReducer } from './programsAndGroupsReducer';
import { viewUserDetailsReducer } from './viewUserDetailsReducer';

import { CLEAR_ADMIN_ERROR } from 'routes/UserAdmin/userAdminActions';

export const initialState = {
  errors: {},
  isFetching: false,
  isFetchingPrograms: false,
  userAccount: {
    programs: {},
    groups: {}
  },
  userAction: {},
  activeUsers: [],
  paginatedUsers: [],
  exportedUsersContent: {}
};

const clearAdminFailure = state => {
  return { ...state, errors: {} };
};

export default function userAdminReducer(state = initialState, action) {
  const handlers = {
    [CLEAR_ADMIN_ERROR]: clearAdminFailure,
    ...createUserReducer,
    ...editUserReducer,
    ...programsAndGroupsReducer,
    ...viewUserDetailsReducer,
    ...viewUsersReducer
  };
  return handlers.hasOwnProperty(action.type) ? handlers[action.type](state, action) : state;
}
