import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

export const DELIMITERS = {
  COMMA: ',',
  DECIMAL: '.',
  SPACE: ' ',
  TICK: "'"
};

export const formatDecimals = decimals => {
  if (isEmpty(decimals)) {
    return decimals;
  } else if (decimals.length >= 2) {
    return decimals.substring(0, 2);
  } else {
    return decimals;
  }
};

const defaultCurrencyFormat = [DELIMITERS.COMMA, DELIMITERS.DECIMAL];

export const delineateThousands = (thousandDelimiter, radices) => {
  return radices.replace(/(\d)(?=(?:\d{3})+(?:\.|$))/g, `$1${thousandDelimiter}`);
};
export const stripDelimiters = radices => {
  return radices.replace(/[ ,.']/g, '');
};

const checkDefaultCurrencyFormat = amount => {
  return amount.indexOf(DELIMITERS.DECIMAL) > amount.indexOf(DELIMITERS.COMMA);
};

const amountFormatter = amount => {
  const stringifiedAmount = typeof amount !== 'string' ? amount.toString() : amount;
  const [thousandDelimiter, radixDecimalDelimeter] = defaultCurrencyFormat;
  const [radices, decimals] = stringifiedAmount.split(radixDecimalDelimeter);
  const strippedRadices = stripDelimiters(radices);
  const thousandDelinatedRadices = delineateThousands(thousandDelimiter, strippedRadices);
  const formattedDecimals = formatDecimals(decimals);
  const combined = radixDecimalDelimeter + formattedDecimals;
  const dec = !isNil(decimals) ? combined : '';
  return thousandDelinatedRadices + dec;
};

export const amountUnformatter = amount => {
  const stringifiedAmount = typeof amount !== 'string' ? amount.toString() : amount;
  const [thousandDelimiter, radixDecimalDelimeter] = checkDefaultCurrencyFormat(stringifiedAmount)
    ? defaultCurrencyFormat
    : defaultCurrencyFormat.slice().reverse();
  const [radices, decimals] = stringifiedAmount.split(radixDecimalDelimeter);
  const strippedRadices = stripDelimiters(radices);
  const formattedDecimals = formatDecimals(decimals);
  const combined = DELIMITERS.DECIMAL + formattedDecimals;
  const dec = !isNil(decimals) ? combined : '';
  const amt = strippedRadices + dec;
  return isNaN(Number(amt)) ? 0 : Number(amt);
};

export const format = formatter => content => {
  if (Array.isArray(content)) {
    return content.map(value => format(formatter)(value));
  } else if (!isNil(content) && typeof content === 'object') {
    return Object.keys(content).reduce((acc, key) => {
      return { ...acc, [key]: format(formatter)(content[key]) };
    }, {});
  } else {
    if (isEmpty(content) || isNil(content)) {
      return content;
    } else {
      return formatter(content);
    }
  }
};

export const unformatAmount = content => {
  return format(amountUnformatter)(content);
};

export const formatAmount = content => {
  return format(amountFormatter)(content);
};

export const addZeros = (num, length, places = length + 1) => String(num).padEnd(places, '0');
