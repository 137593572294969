import { useCallback, useEffect, useRef, useState } from 'react';
import { useId } from '@jpmuitk/utils';
import { useTranslation } from 'react-i18next';

import { Form, FormSpy } from 'react-final-form';

import createDecorator from 'final-form-focus';

import { SaltProvider } from '@salt-ds/core';

import { Checkbox } from '@jpmuitk/checkbox';
import { Dialog, DialogContent, DialogActions, DialogTitle } from '@jpmuitk/dialog';

import ButtonRenderer from 'Components/Forms/ButtonRenderer';
import ConditionalWrapper from 'Components/ConditionalWrapper';

import isNull from 'lodash/isNull';
import { useStyles } from './styles.js';
import uuid from 'uuid';

const focusOnErrors = createDecorator();

const ConfirmationDialog = props => {
  const classes = useStyles();
  const titleRef = useRef(null);
  const {
    id,
    dialogContent: {
      formConfig,
      message,
      openDialog = false,
      ariaDescribedBy = null,
      dialogState,
      handleClose,
      dialogTitle,
      dialogTitleId = `${uuid.v1()}_title`,
      errorArray,
      renderCheckbox,
      checkboxLabel,
      onCheck,
      btnContent = null
    },
    dialogContent,
    children,
    overrideHandleClose
  } = props;
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const renderErrorArray = errorArray => {
    return errorArray.map((error, index) => (
      <p key={index} className={classes.errorList}>
        {error}
      </p>
    ));
  };

  const renderMessageWithConfirmationCheckbox = (message, checkboxLabel, checkboxClick) => {
    return (
      <>
        {message}
        <div className="checkbox-confirmation">
          <Checkbox label={checkboxLabel} onChange={checkboxClick} />
        </div>
      </>
    );
  };

  const handleCloseDefault = () => setOpen(false);
  const messageWithCheckbox = renderCheckbox
    ? renderMessageWithConfirmationCheckbox(message, checkboxLabel, onCheck)
    : message;
  const displayedMessage = !errorArray ? messageWithCheckbox : renderErrorArray(errorArray);

  useEffect(() => {
    setOpen(openDialog);
  }, [openDialog]);

  const handleOnClose = overrideHandleClose
    ? overrideHandleClose
    : () => {
        handleClose && handleClose();
        handleCloseDefault();
      };

  return (
    <Dialog
      open={open}
      id={id}
      className={classes.root}
      onClose={handleOnClose}
      autoFocusRef={titleRef}
      state={dialogState || undefined}
      aria-describedby={ariaDescribedBy}
      aria-labelledby={dialogTitleId}
      width={500}
    >
      <SaltProvider>
        <ConditionalWrapper
          condition={formConfig}
          wrapper={children => (
            <Form
              {...formConfig}
              decorators={[focusOnErrors]}
              render={({ handleSubmit, submitting, errors }) => (
                <form
                  aria-label={t('confirmationDialog', { formTitle: dialogTitle })}
                  className={classes.form}
                  name="ConfirmationDialog"
                  onSubmit={handleSubmit}
                >
                  {children}
                  <DialogActions>
                    <FormSpy subscription={{ errors: true }}>
                      {({ errors }) => (
                        <ButtonRenderer
                          buttonList={btnContent}
                          submitting={submitting}
                          disabledSubmit={Object.values(errors).length > 0}
                        />
                      )}
                    </FormSpy>
                  </DialogActions>
                </form>
              )}
            />
          )}
        >
          <DialogTitle disableAria onClose={handleOnClose}>
            <span id={dialogTitleId} aria-level="2" role="heading" ref={titleRef} tabIndex={-1}>
              {dialogTitle}
            </span>
          </DialogTitle>
          <DialogContent
            classes={{ root: classes.dialogContent }}
            style={isNull(btnContent) ? { marginBottom: 0 } : {}}
          >
            {displayedMessage || children}
          </DialogContent>
          {!formConfig && (
            <DialogActions>
              <ButtonRenderer buttonList={btnContent} />
            </DialogActions>
          )}
        </ConditionalWrapper>
      </SaltProvider>
    </Dialog>
  );
};

export default ConfirmationDialog;
