import api from 'api';

import isEmpty from 'lodash/isEmpty';
import { CONTENT_DISPOSITION, CONTENT_TYPE } from 'Constants/headers';
import { FILENAME_REGEX } from 'Constants/regex';
import { handleDownloadFile } from 'utility/downloadUtility';

export const LOAD_ENTITLED_REPORTS_REQUEST = 'LOAD_ENTITLED_REPORTS_REQUEST';
export const LOAD_ENTITLED_REPORTS_SUCCESS = 'LOAD_ENTITLED_REPORTS_SUCCESS';
export const LOAD_ENTITLED_REPORTS_FAILURE = 'LOAD_ENTITLED_REPORTS_FAILURE';

export const LOAD_REPORT_FILTERS_REQUEST = 'LOAD_REPORT_FILTERS_REQUEST';
export const LOAD_REPORT_FILTERS_SUCCESS = 'LOAD_REPORT_FILTERS_SUCCESS';
export const LOAD_REPORT_FILTERS_FAILURE = 'LOAD_REPORT_FILTERS_FAILURE';
export const CLEAR_REPORT_FILTERS = 'CLEAR_REPORT_FILTERS';

export const LOAD_REPORT_RESULT_REQUEST = 'LOAD_REPORT_RESULT_REQUEST';
export const LOAD_REPORT_RESULT_SUCCESS = 'LOAD_REPORT_RESULT_SUCCESS';
export const LOAD_REPORT_RESULT_FAILURE = 'LOAD_REPORT_RESULT_FAILURE';
export const CLEAR_REPORT_RESULT = 'CLEAR_REPORT_RESULT';

export const LOAD_REPORT_EXPORT_REQUEST = 'LOAD_REPORT_EXPORT_REQUEST';
export const LOAD_REPORT_EXPORT_SUCCESS = 'LOAD_REPORT_EXPORT_SUCCESS';
export const LOAD_REPORT_EXPORT_FAILURE = 'LOAD_REPORT_EXPORT_FAILURE';
export const CLEAR_REPORT_EXPORT = 'CLEAR_REPORT_EXPORT';
const EXPORT_FILENAME = 'ReportExport.csv';
export const reportsErrorTypes = {
  LOAD_ENTITLED_REPORTS: 'LOAD_ENTITLED_REPORTS',
  LOAD_REPORT_FILTERS: 'LOAD_REPORT_FILTERS',
  LOAD_REPORT_RESULT: 'LOAD_REPORT_RESULT',
  LOAD_REPORT_EXPORT: 'LOAD_REPORT_EXPORT'
};

export function loadEntitledReportsRequest() {
  return { type: LOAD_ENTITLED_REPORTS_REQUEST };
}

export function loadEntitledReportsSuccess(reportTypes) {
  return { type: LOAD_ENTITLED_REPORTS_SUCCESS, reportTypes };
}

export function loadEntitledReportsFailure(error, t) {
  return {
    type: LOAD_ENTITLED_REPORTS_FAILURE,
    errors: {
      type: reportsErrorTypes.LOAD_ENTITLED_REPORTS,
      status: error.status,
      message: t('reports.error.reportList')
    }
  };
}

export function loadEntitledReports(customerId, userId, reportType, t) {
  return dispatch => {
    return api
      .fetch(`reportentitlementsvc/v0/reports/entitlement/cust/${customerId}/user/${userId}/${reportType}/type`)
      .then(response => {
        const data = response.data;
        // TODO: figure out proper error checking for the actual returned object
        const reportTypes = data.reportType;
        dispatch(loadEntitledReportsSuccess(reportTypes));
      })
      .catch(err => {
        dispatch(loadEntitledReportsFailure(err, t));
      });
  };
}

export function loadReportFiltersRequest() {
  return { type: LOAD_REPORT_FILTERS_REQUEST };
}

export function loadReportFiltersSuccess(reportFilters) {
  return { type: LOAD_REPORT_FILTERS_SUCCESS, reportFilters };
}

export function loadReportFiltersFailure(error, t) {
  return {
    type: LOAD_REPORT_FILTERS_FAILURE,
    errors: {
      type: reportsErrorTypes.LOAD_REPORT_FILTERS,
      status: error.status,
      message: t('reports.customReport.message.reportFilters')
    }
  };
}

export function clearReportFilters() {
  return { type: CLEAR_REPORT_FILTERS };
}

export function loadReportFilters(customerId, userId, reportSubTypeId, t) {
  return dispatch => {
    return api
      .fetch(
        `reportdefinitionsvc/v0/reports/definition/cust/${customerId}/user/${userId}/onlineReports/reportFilter/${reportSubTypeId}`
      )
      .then(response => {
        const data = response.data;
        const reportFilters = data.filters;

        dispatch(loadReportFiltersSuccess(reportFilters));
      })
      .catch(err => {
        dispatch(loadReportFiltersFailure(err, t));
      });
  };
}

export const loadReportResult = (filterCriteria, reportType = 'transactionsummary', t) => {
  return {
    types: [LOAD_REPORT_RESULT_REQUEST, LOAD_REPORT_RESULT_SUCCESS, LOAD_REPORT_RESULT_FAILURE],
    successCase: response => {
      return response && response.data && !isEmpty(response.data) && !isEmpty(response.data.reportMetadata);
    },
    successCaseNotMet: {
      errorType: reportsErrorTypes.LOAD_REPORT_RESULT,
      message: t('reports.error.loadReport')
    },
    callAPI: (state, { customerId, userId }) => {
      return api.save(
        `reportexportersvc/v0/reports/exporter/cust/${customerId}/user/${userId}/online-reports/${reportType}`,
        filterCriteria
      );
    },
    httpErrorTypes: {
      DEFAULT: reportsErrorTypes.LOAD_REPORT_RESULT,
      404: {
        type: reportsErrorTypes.LOAD_REPORT_RESULT_NO_DATA,
        message: t('reports.error.noReportData')
      }
    }
  };
};

export function clearReportResult() {
  return { type: CLEAR_REPORT_RESULT };
}
const downloadExport = () => (dispatch, getState) => {
  const state = getState();
  const exportData = state.reports?.exportData;
  if (exportData && exportData.data) {
    const disposition = exportData.request.getResponseHeader(CONTENT_DISPOSITION);
    const type = exportData.request.getResponseHeader(CONTENT_TYPE);
    const matches = FILENAME_REGEX.exec(disposition);
    const filename = matches !== null && matches[1] ? matches[1].replace(/['"]/g, '') : EXPORT_FILENAME;
    handleDownloadFile(exportData.data, filename, type);
  }
};

export function loadReportExport(filterCriteria, reportType = 'capturesummary', t) {
  return {
    types: [LOAD_REPORT_EXPORT_REQUEST, LOAD_REPORT_EXPORT_SUCCESS, LOAD_REPORT_EXPORT_FAILURE],
    successCase: response => {
      return response && response.data && !isEmpty(response.data);
    },
    successCaseNotMet: {
      errorType: reportsErrorTypes.LOAD_REPORT_EXPORT,
      message: t('reports.error.exportReport')
    },
    callAPI: (state, { customerId, userId }) =>
      api.save(
        `reportexportersvc/v0/reports/exporter/cust/${customerId}/user/${userId}/online-reports/${reportType}/export`,
        filterCriteria
      ),
    dispatchOnSuccess: [() => downloadExport()],
    httpErrorTypes: {
      DEFAULT: reportsErrorTypes.LOAD_REPORT_EXPORT,
      404: {
        type: reportsErrorTypes.LOAD_REPORT_EXPORT_NO_DATA,
        message: t('reports.error.noReportData')
      }
    }
  };
}
export function clearReportExport() {
  return { type: CLEAR_REPORT_EXPORT };
}
