import {
  LOAD_DECISION_MANAGER_HISTORY_REQUEST,
  LOAD_DECISION_MANAGER_HISTORY_SUCCESS,
  LOAD_DECISION_MANAGER_HISTORY_FAILURE,
  EXPORT_DECISION_REQUEST,
  EXPORT_DECISION_SUCCESS,
  EXPORT_DECISION_FAILURE,
  CLEAR_DECISION_MANAGER
} from './decisionManagerActions';

const initialState = {
  data: {},
  dataError: {},
  isFetchingData: true
};

const clearDecisionManager = (state, action) => {
  return {
    ...state,
    dataError: {},
    isFetchingData: true,
    data: {},
    isFetchingExport: false,
    exportError: null,
    exportData: null
  };
};

const loadDecisionManagerHistoryRequest = state => {
  return {
    ...state,
    data: {},
    dataError: {},
    isFetchingData: true
  };
};

const loadDecisionManagerHistorySuccess = (state, action) => {
  return {
    ...state,
    dataError: {},
    isFetchingData: false,
    data: action.response.data
  };
};

const loadDecisionManagerHistoryFailure = (state, action) => {
  return {
    ...state,
    data: {},
    isFetchingData: false,
    dataError: action.error
  };
};

const exportDecisionsRequest = (state, action) => {
  return {
    ...state,
    isFetchingExport: true,
    exportError: null,
    exportData: null
  };
};

const exportDecisionsSuccess = (state, action) => {
  return {
    ...state,
    exportData: action.response,
    isFetchingExport: false
  };
};

const exportDecisionsFailure = (state, action) => {
  return {
    ...state,
    exportError: action.error,
    isFetchingExport: false
  };
};

export default function decisionManagerReducer(state = initialState, action) {
  const handlers = {
    [LOAD_DECISION_MANAGER_HISTORY_REQUEST]: loadDecisionManagerHistoryRequest,
    [LOAD_DECISION_MANAGER_HISTORY_SUCCESS]: loadDecisionManagerHistorySuccess,
    [LOAD_DECISION_MANAGER_HISTORY_FAILURE]: loadDecisionManagerHistoryFailure,
    [EXPORT_DECISION_REQUEST]: exportDecisionsRequest,
    [EXPORT_DECISION_SUCCESS]: exportDecisionsSuccess,
    [EXPORT_DECISION_FAILURE]: exportDecisionsFailure,
    [CLEAR_DECISION_MANAGER]: clearDecisionManager
  };

  return handlers.hasOwnProperty(action.type) ? handlers[action.type](state, action) : state;
}
