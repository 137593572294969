import { Spinner } from '@jpmuitk/spinner';
import { ColumnLayout } from '@jpmuitk/column-layout';

import TextWithIcon from 'Components/TextWithIcon';
import { PageTitle, PageRoot, PageContent } from 'Components/Page';

import ProductGuideRenderer from './ProductGuideRenderer';

import { useAriaAnnouncer } from '@jpmuitk/aria-announcer';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withStyles } from '@jpmuitk/theme';

import { loadSupportPageInfo, downloadProductGuide, resetSupportPage } from 'routes/Support/supportActions';

import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { columnLayoutItemSizes } from 'Components/Forms/helpers';
import { getFileName, getFileType, handleDownloadFile } from 'utility/downloadUtility';
import { parseHTML } from 'Components/Dialogs/BroadcastDialog/broadcastParser';
import { sectionStyles } from './styles';
import { parseBroadcastMessages } from 'Components/Dialogs/BroadcastDialog/broadcastParser.js';

const SupportSectionDefault = props => {
  const { t } = useTranslation();
  const { title, content, itemSizes: itemSizesConfig, classes, type, welcomeMessage, supportInfoType } = props;
  const itemSizes = itemSizesConfig ? itemSizesConfig : columnLayoutItemSizes();
  return (
    <ColumnLayout item {...itemSizes}>
      <div
        className={classNames({ [classes.card]: type === 'card', [classes.resourceCenter]: type === 'resourceCenter' })}
      >
        <h2>{title}</h2>
        {!isEmpty(welcomeMessage) && <div>{parseHTML(welcomeMessage[0].messageText)}</div>}
        {content || `${t('support.supportPage.noSupport', { support: supportInfoType })}`}
      </div>
    </ColumnLayout>
  );
};

const SupportSection = withStyles(sectionStyles)(SupportSectionDefault);

export const SupportComponent = props => {
  const { announce } = useAriaAnnouncer();
  const { t } = useTranslation();
  const { loadSupportPageInfo, resetSupportPage, support } = props;
  const {
    downloadedGuide,
    supportError,
    productGuideList,
    helpDeskContactsMessageList,
    quickTipMessageList,
    publicMessageList
  } = support;

  useEffect(() => {
    loadSupportPageInfo();
    return () => {
      resetSupportPage();
    };
  }, [loadSupportPageInfo, resetSupportPage]);

  useEffect(() => {
    if (supportError) {
      announce(supportError.message);
    }
  }, [supportError]);

  useEffect(() => {
    if (downloadedGuide) {
      const type = getFileType(downloadedGuide);
      const fileName = getFileName(downloadedGuide);
      handleDownloadFile(downloadedGuide.data, fileName, type);
    }
  }, [downloadedGuide]);

  const parsedPublicMessageList = parseBroadcastMessages({ publicMessageList });

  return (
    <PageRoot>
      <PageTitle />
      <PageContent noFooter>
        {support.isFetching ? (
          <Spinner className="spinner" />
        ) : (
          <>
            <ColumnLayout container spacing={3}>
              {support.supportError.message && (
                <TextWithIcon type="error" icon="error">
                  {support.supportError.message}
                </TextWithIcon>
              )}
              <SupportSection
                title={t('support.supportPage.type.product')}
                content={
                  !isEmpty(productGuideList) && (
                    <ProductGuideRenderer list={support.productGuideList} guide={props.downloadProductGuide} />
                  )
                }
                supportInfoType={t('support.supportPage.type.product')}
                itemSizes={{ md: 4, xs: 12 }}
              />
              <SupportSection
                title={t('support.supportPage.contact')}
                content={
                  !isEmpty(helpDeskContactsMessageList) && parseHTML(support.helpDeskContactsMessageList[0].messageText)
                }
                supportInfoType={t('support.supportPage.type.contact')}
                itemSizes={{ md: 4, xs: 12 }}
              />
              <SupportSection
                type="resourceCenter"
                title={t('support.supportPage.resourceCenter')}
                content={!isEmpty(quickTipMessageList) && parseHTML(support.quickTipMessageList[0].messageText)}
                supportInfoType={t('support.supportPage.type.resourceCenter')}
                itemSizes={{ md: 4, xs: 12 }}
              />
            </ColumnLayout>
            <ColumnLayout container>
              <SupportSection
                type="card"
                title={t('support.supportPage.messageCenter')}
                welcomeMessage={support.onlineWelcomeMessageList}
                content={!isEmpty(parsedPublicMessageList) && parsedPublicMessageList}
                supportInfoType={t('support.supportPage.type.messages')}
                itemSizes={{ xs: 12 }}
              />
            </ColumnLayout>
          </>
        )}
      </PageContent>
    </PageRoot>
  );
};

export const mapStateToProps = state => {
  return {
    support: state.support
  };
};

const mapDispatchToProps = {
  loadSupportPageInfo,
  resetSupportPage,
  downloadProductGuide
};

export default connect(mapStateToProps, mapDispatchToProps)(SupportComponent);
