import api from 'api';
import { httpErrorTypes } from 'utility/error';
import { fieldUnformatter, FIELD_TYPES } from 'hooks/useFieldFormatter';
import i18n from 'i18n';

export const UPDATE_EXPIRED_TRANSACTION = 'UPDATE_EXPIRED_TRANSACTION';
export const LOCK_DECISION_DETAILS_REQUEST = 'LOCK_DECISION_DETAILS_REQUEST';
export const LOCK_DECISION_DETAILS_SUCCESS = 'LOCK_DECISION_DETAILS_SUCCESS';
export const LOCK_DECISION_DETAILS_FAILURE = 'LOCK_DECISION_DETAILS_FAILURE';
export const LOAD_DECISION_DETAILS_REQUEST = 'LOAD_DECISION_DETAILS_REQUEST';
export const LOAD_DECISION_DETAILS_SUCCESS = 'LOAD_DECISION_DETAILS_SUCCESS';
export const LOAD_DECISION_DETAILS_FAILURE = 'LOAD_DECISION_DETAILS_FAILURE';
export const FETCH_ADDITIONAL_IMAGES_REQUEST = 'FETCH_ADDITIONAL_IMAGES_REQUEST';
export const FETCH_ADDITIONAL_IMAGES_SUCCESS = 'FETCH_ADDITIONAL_IMAGES_SUCCESS';
export const FETCH_ADDITIONAL_IMAGES_FAILURE = 'FETCH_ADDITIONAL_IMAGES_FAILURE';
export const SAVE_DECISION_DETAILS_REQUEST = 'SAVE_DECISION_DETAILS_REQUEST';
export const SAVE_DECISION_DETAILS_SUCCESS = 'SAVE_DECISION_DETAILS_SUCCESS';
export const SAVE_DECISION_DETAILS_FAILURE = 'SAVE_DECISION_DETAILS_FAILURE';
export const SUBMIT_TRANSACTION_REQUEST = 'SUBMIT_TRANSACTION_REQUEST';
export const SUBMIT_TRANSACTION_SUCCESS = 'SUBMIT_TRANSACTION_SUCCESS';
export const SUBMIT_TRANSACTION_FAILURE = 'SUBMIT_TRANSACTION_FAILURE';
export const SAVE_DECISION_TRANSACTION_REQUEST = 'SAVE_DECISION_TRANSACTION_REQUEST';
export const SAVE_DECISION_TRANSACTION_SUCCESS = 'SAVE_DECISION_TRANSACTION_SUCCESS';
export const SAVE_DECISION_TRANSACTION_FAILURE = 'SAVE_DECISION_TRANSACTION_FAILURE';
export const SAVE_SINGLE_DOCUMENT_REQUEST = 'SAVE_SINGLE_DOCUMENT_REQUEST';
export const SAVE_SINGLE_DOCUMENT_SUCCESS = 'SAVE_SINGLE_DOCUMENT_SUCCESS';
export const SAVE_SINGLE_DOCUMENT_FAILURE = 'SAVE_SINGLE_DOCUMENT_FAILURE';
export const RESET_DECISION_DETAILS = 'RESET_DECISION_DETAILS';
export const CANCEL_EDIT_DECISION_DETAILS_FAILURE = 'CANCEL_EDIT_DECISION_DETAILS_FAILURE';
export const CANCEL_EDIT_DECISION_DETAILS_REQUEST = 'CANCEL_EDIT_DECISION_DETAILS_REQUEST';
export const CANCEL_EDIT_DECISION_DETAILS_SUCCESS = 'CANCEL_EDIT_DECISION_DETAILS_SUCCESS';

const getFieldToUnformatList = colDefs =>
  colDefs.reduce((acc, { columnType, columnId }) => {
    if (columnType === 'D') {
      acc.push({ id: `${columnId}`, type: FIELD_TYPES.DATE });
    }
    return acc;
  }, []);

export const resetDecisionDetails = () => {
  return { type: RESET_DECISION_DETAILS };
};

export const saveSingleDocument = (suspendId, values) => {
  return {
    types: [SAVE_SINGLE_DOCUMENT_REQUEST, SAVE_SINGLE_DOCUMENT_SUCCESS, SAVE_SINGLE_DOCUMENT_FAILURE],
    callAPI: (state, { customerId, userId }) => {
      const fieldToUnformatList = getFieldToUnformatList(
        state.decisionDetails.data.suspendedTransactionDetails.referenceDetails.columnDescriptors
      );

      values.rows.map(({ rowItems }) => {
        rowItems.map(column => {
          const { type } = fieldToUnformatList.find(({ id }) => id === column.columnId) || {};
          if (type) {
            column.value = fieldUnformatter(column.value, type, state.settings.userSettings);
          }
          return column;
        });
      });

      return api.save(
        `txndecisionmanagersvc/v0/cust/${customerId}/user/${userId}/decision/${suspendId}/save-document-details`,
        values
      );
    },
    httpErrorTypes,
    errorDisplayType: 'modal',
    errorTitle: i18n.t('decisionManagerDetails.errors.unableToSaveDocument')
  };
};

export const saveTransaction = (suspendId, values) => {
  return {
    types: [SAVE_DECISION_TRANSACTION_REQUEST, SAVE_DECISION_TRANSACTION_SUCCESS, SAVE_DECISION_TRANSACTION_FAILURE],
    callAPI: (state, { customerId, userId }) => {
      const fieldToUnformatList = getFieldToUnformatList(
        state.decisionDetails.data.suspendedTransactionDetails.referenceDetails.columnDescriptors
      );

      values.userDocuments.map(({ rows }) =>
        rows.map(({ rowItems }) =>
          rowItems.map(column => {
            const { type } = fieldToUnformatList.find(({ id }) => id === column.columnId) || {};
            if (type) {
              column.value = fieldUnformatter(column.value, type, state.settings.userSettings);
            }
            return column;
          })
        )
      );

      return api.save(
        `txndecisionmanagersvc/v0/cust/${customerId}/user/${userId}/decision/${suspendId}/save-transaction-details`,
        values
      );
    },
    params: { params: { updatedDocuments: values.userDocuments } },
    httpErrorTypes,
    errorDisplayType: 'modal',
    errorTitle: i18n.t('decisionManagerDetails.errors.unableToSaveTransaction')
  };
};

export const submitTransaction = (suspendId, values) => {
  return {
    types: [SUBMIT_TRANSACTION_REQUEST, SUBMIT_TRANSACTION_SUCCESS, SUBMIT_TRANSACTION_FAILURE],
    callAPI: (state, { customerId, userId }) => {
      const fieldToUnformatList = getFieldToUnformatList(
        state.decisionDetails.data.suspendedTransactionDetails.referenceDetails.columnDescriptors
      );

      values.userDocuments.map(({ rows }) =>
        rows.map(({ rowItems }) =>
          rowItems.map(column => {
            const { type } = fieldToUnformatList.find(({ id }) => id === column.columnId) || {};
            if (type) {
              column.value = fieldUnformatter(column.value, type, state.settings.userSettings);
            }
            return column;
          })
        )
      );

      return api.save(
        `txndecisionmanagersvc/v0/cust/${customerId}/user/${userId}/decision/${suspendId}/submit-decision-details`,
        values
      );
    },
    params: { params: { updatedDocuments: values.userDocuments } },
    httpErrorTypes,
    errorDisplayType: 'modal',
    errorTitle: i18n.t('decisionManagerDetails.errors.unableToSubmitTransaction')
  };
};

export const lockDecisionDetails = (suspendId, t, Trans, handleEditParams, forceLock = false) => {
  const [
    setAllowOnChangeTrue,
    setAllowOnChangeFalse,
    displayReprocessDropdownInEditMode,
    displayWrongProgramDropdownInEditMode,
    setDisplayPrograms
  ] = handleEditParams;
  return {
    types: [LOCK_DECISION_DETAILS_REQUEST, LOCK_DECISION_DETAILS_SUCCESS, LOCK_DECISION_DETAILS_FAILURE],
    callAPI: (state, { customerId, userId }) =>
      api.save(
        `txndecisionmanagersvc/v0/cust/${customerId}/user/${userId}/decision/${suspendId}/edit-transaction-details`,
        {
          forceLock
        }
      ),
    httpErrorTypes,
    dispatchOnSuccess: [
      () => setAllowOnChangeTrue,
      () => displayReprocessDropdownInEditMode,
      () => displayWrongProgramDropdownInEditMode
    ],
    successCase: response =>
      !response.data.errors && response.data.status !== 'Submitted' && response.data.status !== 'Expired',
    dispatchOnFailure: [() => setAllowOnChangeFalse, () => setDisplayPrograms],
    successCaseNotMet: response => ({
      /* This now contains all values that were originally a separate object known as "modalProps" */
      /* They are now in here so they have access to the "response" item returned by svc */
      /* Results are still the same */
      errorType: 'DEFAULT_ERROR',
      state: 'warning',
      title: t(
        response.data.errors
          ? 'decisionManagerDetails.modal.edit.lockedOutTitle'
          : 'decisionManagerDetails.modal.edit.blockedEditTitle'
      ),
      options: [suspendId, t, Trans, handleEditParams, true],
      btnOverride: true,
      message: (
        <Trans
          t={t}
          i18nKey={
            response.data.errors
              ? 'decisionManagerDetails.modal.edit.lockedOutMessage'
              : 'decisionManagerDetails.modal.edit.blockedEditMessage'
          }
          values={{
            pageTitle: t('decisionManagerDetails.title'),
            status: response.data.status === 'Submitted' ? 'been submitted' : 'expired',
            userName: response.data.errors ? response.data.errors[0]?.owner : ''
          }}
          components={{ strong: <strong /> }}
        />
      ),
      btnContent: (dispatch, options = []) =>
        response.data.errors
          ? [
              { name: t('decisionManagerDetails.modal.edit.button.no') },
              {
                name: t('decisionManagerDetails.modal.edit.button.yes'),
                variant: 'cta',
                actionButton: true,
                onClick: () => dispatch(lockDecisionDetails(...options))
              }
            ]
          : [
              {
                name: t('decisionManagerDetails.modal.edit.button.ok'),
                onClick: () => dispatch(loadDecisionDetails(suspendId))
              }
            ]
    }),
    errorDisplayType: 'modal'
  };
};

export const loadDecisionDetails = suspendId => {
  return {
    types: [LOAD_DECISION_DETAILS_REQUEST, LOAD_DECISION_DETAILS_SUCCESS, LOAD_DECISION_DETAILS_FAILURE],
    callAPI: (state, { customerId, userId }) =>
      api.fetch(`txndecisionmanagersvc/v0/cust/${customerId}/user/${userId}/decision/${suspendId}/details`),
    httpErrorTypes
  };
};

export const retrieveAdditionalDetailImages = (suspendId, documentIds) => {
  return {
    types: [FETCH_ADDITIONAL_IMAGES_REQUEST, FETCH_ADDITIONAL_IMAGES_SUCCESS, FETCH_ADDITIONAL_IMAGES_FAILURE],
    callAPI: (state, { customerId, userId }) =>
      api.save(`txndecisionmanagersvc/v0/cust/${customerId}/user/${userId}/decision/${suspendId}/images`, {
        documentIds
      }),
    httpErrorTypes
  };
};

export const cancelEditDecisionDetails = (suspendId, navigateAway) => {
  return {
    types: [
      CANCEL_EDIT_DECISION_DETAILS_REQUEST,
      CANCEL_EDIT_DECISION_DETAILS_SUCCESS,
      CANCEL_EDIT_DECISION_DETAILS_FAILURE
    ],
    callAPI: (state, { customerId, userId }) =>
      api.save(
        `txndecisionmanagersvc/v0/cust/${customerId}/user/${userId}/decision/${suspendId}/cancel-transaction-details`
      ),
    dispatchOnSuccess: !navigateAway ? [() => loadDecisionDetails(suspendId)] : '',
    httpErrorTypes
  };
};

export const expiredDecisionDetails = decisionExpiredDetails => {
  return { type: UPDATE_EXPIRED_TRANSACTION, params: { params: { decisionExpiredDetails } } };
};
