import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Overlay as DefaultOverlay } from '@jpmuitk/overlay';
import ButtonAsLink from 'Components/Button/AsLink';
import { useId } from '@jpmuitk/utils';
import { withStyles } from '@jpmuitk/theme';
import { grandTotalStyles, overlayStyles } from './styles';
import { list } from 'styles';

const Overlay = withStyles(overlayStyles)(DefaultOverlay);

const currencyTotals = grandTotal => {
  grandTotal.sort((a, b) => {
    const currency1 = a.currencyCode.toLowerCase();
    const currency2 = b.currencyCode.toLowerCase();
    if (currency1 < currency2) {
      return -1;
    }
    if (currency1 > currency2) {
      return 1;
    }
    return 0;
  });

  return (
    <ul style={{ ...list }}>
      {grandTotal.map((total, index) => {
        return (
          <li
            key={index}
            className={classNames(index + 1 !== grandTotal.length ? 'bottom-separator' : null, 'right-align')}
          >
            {total.currencyCode} {total.amount}
          </li>
        );
      })}
    </ul>
  );
};

const GrandTotal = ({ grandTotal, classes }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const titleId = useId();

  const handleOnClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleOnClose = () => {
    setAnchorEl(null);
  };

  const showCurrency = () => {
    if (grandTotal.length === 0) {
      return '0';
    } else if (grandTotal.length === 1) {
      return grandTotal[0].currencyCode + ' ' + grandTotal[0].amount;
    }

    return (
      <>
        <ButtonAsLink id="multiple-currency-button" description={t('grandTotal.description')} onClick={handleOnClick}>
          {t('grandTotal.multipleCurrencies')}
        </ButtonAsLink>
        <Overlay
          id="grand-total-overlay"
          open={open}
          anchorEl={anchorEl}
          aria-labelledby={titleId}
          onClose={handleOnClose}
          placement="top"
          anchorReference="anchorEl"
          render={({ getTitleProps }) => (
            <div>
              <h2 {...getTitleProps()} className={classes.title} id={titleId}>
                {t('grandTotal.multipleCurrencies')}
              </h2>
              {currencyTotals(grandTotal)}
            </div>
          )}
        />
      </>
    );
  };

  return (
    <span className={classes.root}>
      <span className={classes.label}>{t('grandTotal.label')}</span>
      {showCurrency()}
    </span>
  );
};

export default withStyles(grandTotalStyles, { name: 'GrandTotal' })(GrandTotal);
