import api from 'api';
import { errorActions, httpErrorTypes } from 'utility/error';
import i18n from 'i18n';
export const CLEAR_USERS_PAGINATION = 'CLEAR_USERS_PAGINATION';
export const EXPORT_USER_SUMMARIES_REQUEST = 'EXPORT_USER_SUMMARIES_REQUEST';
export const EXPORT_USER_SUMMARIES_SUCCESS = 'EXPORT_USER_SUMMARIES_SUCCESS';
export const EXPORT_USER_SUMMARIES_FAILURE = 'EXPORT_USER_SUMMARIES_FAILURE';
export const EXPORT_USER_DETAILS_REQUEST = 'EXPORT_USER_DETAILS_REQUEST';
export const EXPORT_USER_DETAILS_SUCCESS = 'EXPORT_USER_DETAILS_SUCCESS';
export const EXPORT_USER_DETAILS_FAILURE = 'EXPORT_USER_DETAILS_FAILURE';
export const GET_USERS_PAGINATION_SUCCESS = 'GET_USERS_PAGINATION_SUCCESS';
export const GET_USERS_PAGINATION_FAILURE = 'GET_USERS_PAGINATION_FAILURE';
export const GET_USERS_PAGINATION_REQUEST = 'GET_USERS_PAGINATION_REQUEST';

export const viewUsersErrorTypes = {
  GET_USERS_PAGINATION: 'GET_USERS_PAGINATION',
  EXPORT_FAILURE: 'EXPORT_FAILURE'
};

export const clearUsersPagination = () => {
  return { type: CLEAR_USERS_PAGINATION };
};

export const exportUserSummaries = (t, payload) => ({
  types: [EXPORT_USER_SUMMARIES_REQUEST, EXPORT_USER_SUMMARIES_SUCCESS, EXPORT_USER_SUMMARIES_FAILURE],
  callAPI: (state, { customerId, userId }) =>
    api.save(`usersvc/v0/cust/${customerId}/user/${userId}/admin/view/users/export/summary`, payload),
  httpErrorTypes,
  errorDisplayType: 'modal',
  errorTitle: t('user.error.exportError')
});

export const exportUserDetails = (t, payload) => ({
  types: [EXPORT_USER_DETAILS_REQUEST, EXPORT_USER_DETAILS_SUCCESS, EXPORT_USER_DETAILS_FAILURE],
  callAPI: (state, { customerId, userId }) =>
    api.save(`usersvc/v0/cust/${customerId}/user/${userId}/admin/view/users/export/details`, payload),
  httpErrorTypes,
  errorDisplayType: 'modal',
  errorTitle: t('user.error.exportError')
});

export const loadUserList = payload => ({
  types: [GET_USERS_PAGINATION_REQUEST, GET_USERS_PAGINATION_SUCCESS, GET_USERS_PAGINATION_FAILURE],
  callAPI: (state, { customerId, userId }) =>
    api.save(`usersvc/v0/cust/${customerId}/user/${userId}/admin/view/users`, payload),
  httpErrorTypes,
  successCase: response => response.data.users?.length > 0,
  successCaseNotMet: response => ({
    type: viewUsersErrorTypes.GET_USERS_PAGINATION,
    status: response.status,
    message: i18n.t('administration.actionMessages.noUserFound')
  })
});
