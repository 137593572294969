import classNames from 'classnames';

import { withRouter } from 'react-router-dom';
import { withStyles } from '@jpmuitk/theme';

import { useStyles } from './styles';

const Link = ({
  history,
  location: { pathname: historyPathname },
  match,
  href,
  className,
  label,
  children,
  component,
  linkRef,
  noFocus = true,
  ...rest
}) => {
  const classes = useStyles();
  const ComponentProp = component || 'a';
  return (
    <ComponentProp
      innerRef={linkRef}
      className={classNames(className, { [classes.root]: noFocus, [classes.rootWithVisibleFocus]: !noFocus })}
      href={href}
      {...rest}
    >
      {label || children}
    </ComponentProp>
  );
};

export default withRouter(Link);
