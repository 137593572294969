import { useAriaAnnouncer } from '@jpmuitk/aria-announcer';
import { useEffect } from 'react';
import { withStyles } from '@jpmuitk/theme';

import { Spinner as DefaultSpinner } from '@jpmuitk/spinner';

import PropTypes from 'prop-types';
import classNames from 'classnames';

import { styles } from './styles';

import { useTranslation } from 'react-i18next';

const Spinner = ({ classes, announceMessage, className }) => {
  const { announce } = useAriaAnnouncer();
  const { t } = useTranslation();

  useEffect(() => {
    announce(announceMessage || t('spinner.loading'));
  }, []);

  return <DefaultSpinner className={classes[className]} />;
};

Spinner.propTypes = {
  announceMessage: PropTypes.string,
  className: PropTypes.string
};

export default withStyles(styles)(Spinner);
