import api from 'api';
import { loadBroadcastMessages, loadBroadcastMessagesRequest } from './broadcastalertActions';
import { loadNavigation, loadNavigationRequest } from './navigationActions';
import { HEALTH_CHECK_URL } from '../Constants/healthcheck';
import isNil from 'lodash/isNil';
import sessionStorage from 'utility/sessionStorageHelper';

export const LOAD_USERINFO_REQUEST = 'LOAD_USERINFO_REQUEST';
export const LOAD_USERINFO_SUCCESS = 'LOAD_USERINFO_SUCCESS';
export const LOAD_USERINFO_FAILURE = 'LOAD_USERINFO_FAILURE';
export const LOGOFF_USER_SUCCESS = 'LOGOFF_USER_SUCCESS';

export function loadUserInfoRequest(id) {
  return { type: LOAD_USERINFO_REQUEST, id };
}

export function loadUserInfoSuccess(userinfo) {
  return { type: LOAD_USERINFO_SUCCESS, userinfo };
}

export function loadUserInfoError(error) {
  return { type: LOAD_USERINFO_FAILURE, error };
}

export function loadUserInfo() {
  return dispatch => {
    return api
      .save('authsvc/v0/auth/login', {}, { retries: 0 })
      .then(data => {
        sessionStorage.setItem('userResponse', JSON.stringify(data));
        dispatch(loadUserInfoSuccess(data));
        dispatch(loadBroadcastMessagesRequest());
        dispatch(loadNavigationRequest());
      })
      .catch(err => {
        dispatch(
          loadUserInfoError({
            status: err.status,
            error: err.data?.error,
            message: err.data?.message
          })
        );
      });
  };
}

export function logUserLogin(customerId, userId) {
  return dispatch => {
    return api
      .saveWithLogging(`authsvc/v0/auth/login/cust/${customerId}/user/${userId}`, {})
      .then(() => {
        dispatch(loadNavigation(customerId, userId));
        dispatch(loadBroadcastMessages(customerId, userId));
      })
      .catch(err => {
        dispatch(
          loadUserInfoError({
            status: err.status,
            error: err.data.error,
            message: err.data.message
          })
        );
      });
  };
}

export const logoffUser = (
  userId,
  customerId,
  isFullPortal,
  logoutType = { forcedLogout: false },
  userToSelectCustWebId = false
) => async dispatch => {
  if (!isNil(userId)) {
    return api.saveWithLogging(`authsvc/v0/auth/cust/${customerId}/user/${userId}/logoff`, logoutType).finally(() => {
      if (!userToSelectCustWebId) {
        return dispatch({ type: LOGOFF_USER_SUCCESS, isFullPortal });
      }
    });
  } else if (!userToSelectCustWebId) {
    return dispatch({ type: LOGOFF_USER_SUCCESS, isFullPortal });
  }
};
