import React, { useRef, useEffect } from 'react';
import { compose } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { withStyles } from '@jpmuitk/theme';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import { useSkipLinkContext } from 'Components/SkipTo';
import { SIDEPANEL_FILTER } from 'Constants/sidePanel';

import { Panel } from '@jpmuitk/panel';
import { closeSidePanel, initializeSidePanels, toggleSidePanelWide } from 'actions/sidePanelsActions';
import { useTranslation } from 'react-i18next';
import { FilterDialogHeaderButtonList } from 'Components/Dialogs/FilterDialog';
import APP_CONFIG from 'config/app';
import styles from './styles';

export const FilterDialog = props => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const {
    filters: { isFilterLoading = true } = {},
    sidePanels: { displaying, wide } = {},
    hide = displaying !== SIDEPANEL_FILTER,
    title = wide ? t('filterDialog.wideTitle') : t('filterDialog.title')
  } = useSelector(state => {
    const { filters, sidePanels } = state;
    return { filters, sidePanels };
  }, isEqual);
  const {
    classes,
    location: { pathname },
    render
  } = props;
  const sidePanelQueue = APP_CONFIG(t).filter.openByDefault.indexOf(pathname) > -1 ? [SIDEPANEL_FILTER] : [];

  useEffect(() => {
    dispatch(initializeSidePanels(sidePanelQueue, false));
    return () => {
      dispatch(closeSidePanel(SIDEPANEL_FILTER));
    };
  }, []);
  const [mainRef] = useSkipLinkContext();
  useEffect(() => {
    if (wide) {
      mainRef.current = document.querySelector('h2');
    } else {
      mainRef.current = document.querySelector('h1');
    }
  }, [wide]);

  const handleAdvanceButton = () => {
    props.handleAdvanceFilter && props.fetchAdvance && props.handleAdvanceFilter();
    dispatch(toggleSidePanelWide());
  };

  return (
    <Panel
      className={classNames(classes.root, { [classes.advanced]: wide }, { [classes.hidden]: hide || isFilterLoading })}
      role="region"
      aria-label={t('filterDialog.name')}
    >
      <div className={classes.header}>
        <h2 data-test-id="filter" tabIndex="-1">
          {title}
        </h2>
        <FilterDialogHeaderButtonList
          focusOnFirstButton={!hide}
          classes={classes}
          pathname={pathname}
          handleClose={() => dispatch(closeSidePanel(SIDEPANEL_FILTER))}
          handleToggle={() => handleAdvanceButton()}
          wide={wide}
        />
      </div>
      {render({ hide, wide, classes })}
    </Panel>
  );
};

export default compose(withStyles(styles), withRouter)(FilterDialog);
