export const SPACE_KEY = 32;
export const SPACE = SPACE_KEY;
export const ENTER_KEY = 13;
export const ENTER = ENTER_KEY;
export const KEY_LEFT = 37;
export const KEY_UP = 38;
export const KEY_RIGHT = 39;
export const KEY_DOWN = 40;
export const END = 35;
export const HOME = 36;
export const SHIFT = 16;
export const CTRL = 17;
export const PAGE_UP = 33;
export const PAGE_DOWN = 34;
export const TAB = 9;
