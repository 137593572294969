import React, { useImperativeHandle, useRef } from 'react';
import { Link } from 'react-router-dom';

import { TRANSACTION_DETAIL, TRANSACTIONS } from 'Constants/routes';

import sessionHelper from 'utility/sessionStorageHelper';

export default (props, ref) => {
  const { column, rowIndex, api: gridApi } = props;
  const innerRef = useRef();

  useImperativeHandle(ref, () => {
    return {
      refresh() {
        const focusedCell = gridApi.getFocusedCell();

        if (focusedCell) {
          innerRef.current?.focus();
        }
      }
    };
  });

  return innerRef;
};
