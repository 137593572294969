import React from 'react';
import { PageRoot } from 'Components/Page';
import InvoicePatternDetails from './InvoicePatternDetails';
import { booleanDropdown } from './utils';
import sessionHelper from 'utility/sessionStorageHelper';
import isEmpty from 'lodash/isEmpty';

const CloneInvoicePattern = props => {
  const {
    location: { state: { patternDetails = {} } = {} }
  } = props;
  const initialValues = isEmpty(patternDetails)
    ? sessionHelper.getItem('currentInitialValues', true)
    : {
        program: patternDetails?.program,
        invoiceReference: { id: patternDetails?.invoiceRefId, description: patternDetails?.invoiceRefTxt },
        searchPaymentNumber: patternDetails?.searchPaymentNumIn ? booleanDropdown[0] : booleanDropdown[1],
        removeSpaces: patternDetails?.removeSpacesIn ? booleanDropdown[0] : booleanDropdown[1],
        removeSpecialCharacters: patternDetails?.removeSpecialCharactersIn ? booleanDropdown[0] : booleanDropdown[1],
        minLength: patternDetails?.minLength,
        maxLength: patternDetails?.maxLength,
        invoiceNumberFormat: patternDetails?.invoiceRefFieldFormat,
        truncateReferenceLeading: patternDetails?.truncateReferenceLeading,
        truncateReferenceTrailing: patternDetails?.truncateReferenceTrailing
      };

  return (
    <PageRoot centerContent>
      <InvoicePatternDetails initialValues={initialValues} />
    </PageRoot>
  );
};

export default CloneInvoicePattern;
