import {
  LOAD_INVOICE_ID_PATTERNS_REQUEST,
  LOAD_INVOICE_ID_PATTERNS_SUCCESS,
  LOAD_INVOICE_ID_PATTERNS_FAILURE,
  CLEAR_DOWNLOAD_INVOICE_PATTERNS_STATE,
  DOWNLOAD_INVOICE_PATTERNS_REQUEST,
  DOWNLOAD_INVOICE_PATTERNS_SUCCESS,
  DOWNLOAD_INVOICE_PATTERNS_FAILURE
} from './actions';

export const initialState = {
  identificationPatternsState: {},
  exportState: {}
};

const loadInvoiceIDPatternsRequest = (state, action) => ({
  ...state,
  identificationPatternsState: {
    errorFetchingPatterns: undefined,
    fetchingPatterns: true,
    patternsList: []
  }
});

const loadInvoiceIDPatternsSuccess = (state, action) => {
  const patternsList = action.response.data?.invoiceIdPatterns || [];
  if (action.lastModifiedPatternId) {
    const topIPindex = patternsList?.findIndex(ip => ip.invoiceIdPatternId === action.lastModifiedPatternId);
    if (topIPindex > -1 && patternsList?.length > 1) {
      const topIP = patternsList.splice(topIPindex, 1)[0];
      topIP.applyStyle = true;
      patternsList.unshift(topIP);
    }
  }

  return {
    ...state,
    identificationPatternsState: {
      errorFetchingPatterns: undefined,
      fetchingPatterns: false,
      patternsList
    }
  };
};

const loadInvoiceIDPatternsFailure = (state, action) => ({
  ...state,
  identificationPatternsState: {
    errorFetchingPatterns: action.error,
    fetchingPatterns: false,
    patternsList: []
  }
});

const downloadInvoicePatternsRequest = (state, action) => ({
  ...state,
  exportState: {
    exportError: undefined,
    isFetchingExport: true,
    exportData: undefined
  }
});

const downloadInvoicePatternsSuccess = (state, action) => ({
  ...state,
  exportState: {
    exportError: undefined,
    isFetchingExport: false,
    exportData: action.response
  }
});

const downloadInvoicePatternsFailure = (state, action) => ({
  ...state,
  exportState: {
    exportError: action.error,
    isFetchingExport: false,
    exportData: undefined
  }
});

const clearDownloadInvoicePatternsState = (state, action) => ({
  ...state,
  exportState: {}
});

export default function invoicePatternsReducer(state = initialState, action) {
  const handlers = {
    [LOAD_INVOICE_ID_PATTERNS_REQUEST]: loadInvoiceIDPatternsRequest,
    [LOAD_INVOICE_ID_PATTERNS_SUCCESS]: loadInvoiceIDPatternsSuccess,
    [LOAD_INVOICE_ID_PATTERNS_FAILURE]: loadInvoiceIDPatternsFailure,
    [CLEAR_DOWNLOAD_INVOICE_PATTERNS_STATE]: clearDownloadInvoicePatternsState,
    [DOWNLOAD_INVOICE_PATTERNS_REQUEST]: downloadInvoicePatternsRequest,
    [DOWNLOAD_INVOICE_PATTERNS_SUCCESS]: downloadInvoicePatternsSuccess,
    [DOWNLOAD_INVOICE_PATTERNS_FAILURE]: downloadInvoicePatternsFailure
  };
  if (handlers.hasOwnProperty(action.type)) {
    return handlers[action.type](state, action);
  } else {
    return state;
  }
}
