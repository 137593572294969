import api from 'api';
import axios from 'axios';
import { archiveTransactionURL } from './utils';
import { httpErrorTypes } from 'utility/error';

export const LOAD_IMAGEVIEWER_REQUEST = 'LOAD_IMAGEVIEWER_REQUEST';
export const LOAD_IMAGEVIEWER_SUCCESS = 'LOAD_IMAGEVIEWER_SUCCESS';
export const LOAD_IMAGEVIEWER_FAILURE = 'LOAD_IMAGEVIEWER_FAILURE';
export const LOAD_THUMBNAILS_REQUEST = 'LOAD_THUMBNAILS_REQUEST';
export const LOAD_THUMBNAILS_SUCCESS = 'LOAD_THUMBNAILS_SUCCESS';
export const LOAD_THUMBNAILS_FAILURE = 'LOAD_THUMBNAILS_FAILURE';
export const CANCEL_AND_KEEP_STATE = 'CANCEL_AND_KEEP_STATE';
export const SAVE_IMAGEVIEWER_REQUEST = 'SAVE_IMAGEVIEWER_REQUEST';
export const SAVE_IMAGEVIEWER_SUCCESS = 'SAVE_IMAGEVIEWER_SUCCESS';
export const SAVE_IMAGEVIEWER_FAILURE = 'SAVE_IMAGEVIEWER_FAILURE';
export const RESET_ARCHIVAL_STATE = 'RESET_ARCHIVAL_STATE';

let getImagesByTransactionRequest = null;

export const loadArchivedThumbnails = (axiosOptions = {}) => {
  return {
    types: [LOAD_THUMBNAILS_REQUEST, LOAD_THUMBNAILS_SUCCESS, LOAD_THUMBNAILS_FAILURE, CANCEL_AND_KEEP_STATE],
    shouldCallAPI: state =>
      state.transactiondetails.data?.transactionDetails?.imageArchived && state.transactiondetails?.transactionIds?.[0],
    callAPI: ({ transactiondetails: { transactionIds, data } }, options) =>
      api.fetch(
        archiveTransactionURL(
          `unarchive/transaction/${transactionIds?.[0]}/get-archived-thumbnail-images-by-transaction`
        ),
        {
          irTxnRev: data?.transactionDetails?.irTxnRev,
          procDate: data?.transactionDetails?.procDate
        },
        { token: axiosOptions?.token }
      ),
    httpErrorTypes
  };
};

export const loadArchivedImagesByTransaction = (imageDetails, axiosOptions = {}, params = {}) => {
  if (getImagesByTransactionRequest) {
    getImagesByTransactionRequest.cancel(CANCEL_AND_KEEP_STATE);
  }
  getImagesByTransactionRequest = axios.CancelToken.source();
  return {
    types: [LOAD_IMAGEVIEWER_REQUEST, LOAD_IMAGEVIEWER_SUCCESS, LOAD_IMAGEVIEWER_FAILURE, CANCEL_AND_KEEP_STATE],
    callAPI: ({ transactiondetails: { transactionIds, data } }, options) =>
      api.save(
        archiveTransactionURL(`unarchive/transaction/${transactionIds?.[0]}/get-archived-images-by-transaction`),
        {
          imageDetails: imageDetails,
          irTxnRev: data?.transactionDetails?.irTxnRev,
          procDate: data?.transactionDetails?.procDate
        },
        { token: axiosOptions?.token || getImagesByTransactionRequest?.token }
      ),
    params: params,
    httpErrorTypes
  };
};

export const saveArchivedImagesByTransaction = (fileType, imageDetails, serviceAction = '') => (dispatch, getState) => {
  const actionQueryParam = serviceAction ? `?action=${serviceAction}` : '';

  return dispatch({
    types: [SAVE_IMAGEVIEWER_REQUEST, SAVE_IMAGEVIEWER_SUCCESS, SAVE_IMAGEVIEWER_FAILURE],
    callAPI: ({ transactiondetails: { transactionIds, data } }, options) =>
      api.fetchPdf(
        archiveTransactionURL(
          `unarchive/transaction/${transactionIds?.[0]}/save-archived-images-as-${fileType.toLowerCase()}`
        ),
        {
          imageDetails: imageDetails,
          irTxnRev: data?.transactionDetails?.irTxnRev,
          procDate: data?.transactionDetails?.procDate,
          fileType
        }
      ),
    dispatchOnSuccess: [({ announce, t }) => announce(t('imageViewer.api.save.byArchiveTransactionId', { fileType }))],
    httpErrorTypes
  });
};

export const resetArchivalState = () => ({ type: RESET_ARCHIVAL_STATE });
