import React, { useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getReportFromNav } from 'routes/Reports/OnlineReports/selectors.js';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@jpmuitk/theme';
import { withRouter } from 'react-router';
import { Helmet } from 'react-helmet';
import sessionHelper from 'utility/sessionStorageHelper';
import { PAGE_TITLES } from 'Constants/pages';

import PageError from 'Components/Page/PageError';
import BackButton from 'Components/Button/BackButton';
import { useSkipLinkContext } from 'Components/SkipTo';

import { styles } from './styles';

const PageTitle = props => {
  const { t } = useTranslation();
  const {
    classes,
    customHelmet,
    pageTitleHAT,
    style,
    options,
    errorMessage,
    errorType,
    children,
    history,
    hideBackButton = false,
    match: {
      path,
      params: { id }
    },
    focusOnTitle,
    hidden
  } = props;

  const { navigationResponse } = useSelector(state => state.navigation);
  const title = getReportFromNav(navigationResponse, x => x.reportId === Number(id)).label;

  const [mainRef] = useSkipLinkContext();
  const breadcrumbRef = useRef();
  const titleRef = useRef();
  const pageTitle = children ? children : PAGE_TITLES(t)[path] ? PAGE_TITLES(t)[path] : title;
  const prevPageArray = sessionHelper.getItem('prevPageArray')?.split(',');
  const hasBreadcrumb = prevPageArray?.length > 1 && !hideBackButton;

  useEffect(() => {
    if (hasBreadcrumb) {
      if (breadcrumbRef.current) {
        mainRef.current = breadcrumbRef.current.querySelector('a');
      }
    } else {
      if (titleRef.current) {
        mainRef.current = titleRef.current;
      }
    }
  }, [titleRef, breadcrumbRef]);

  useEffect(() => {
    if (focusOnTitle) {
      titleRef.current?.focus();
    }
  }, [titleRef, focusOnTitle, pageTitleHAT]);

  return !pageTitle ? null : (
    <>
      <Helmet>
        <title>{`${pageTitle}${customHelmet ?? ''} - J.P. Morgan Receivables Online`}</title>
      </Helmet>
      {hasBreadcrumb && <BackButton innerRef={breadcrumbRef} history={history} />}
      <div style={style} className={classes.headingWrapper}>
        <div className={classes.heading}>
          <h1
            ref={titleRef}
            className={classes.title}
            tabIndex="-1"
            style={{ visibility: hidden ? 'hidden' : 'visible' }}
          >
            {pageTitle}
            {pageTitleHAT && <span className="visually-hidden">&nbsp;{pageTitleHAT}</span>}
          </h1>
          {options && options(classes)}
        </div>
        {errorMessage && <PageError type={errorType} message={errorMessage} />}
      </div>
    </>
  );
};
export default withStyles(styles, { name: 'PageTitle' })(withRouter(PageTitle));
