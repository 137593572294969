import * as V from 'utility/validation';
import {
  isGreaterThanTruncateReferenceSum,
  isAlphaNumericWithSPCPCTQMASOnCondition,
  isAlphaNumericWithSPCOnCondition
} from './utils';
import i18n from 'i18n';

const programItemToString = item => {
  const valueSite = item?.site?.name ? item.site.name : '';
  const valueNumber = item?.number ? item.number + ' - ' : '';
  const value = valueNumber + valueSite;
  return value;
};
const defaultItemToString = item => item?.description ?? '';
const defaultParse = item => item?.id ?? null;

const InvoicePatternDetailsFields = {
  PROGRAM: {
    id: 'program',
    label: ['invoicePatterns.columnHeaderNames.program'],
    type: 'dropdown',
    itemToString: programItemToString,
    translate: true,
    required: true,
    parse: defaultParse,
    validation: ({ isRequired }) => [V.isRequired(isRequired)]
  },
  MIN_LENGTH: {
    id: 'minLength',
    label: ['invoicePatterns.columnHeaderNames.minLength'],
    translate: true,
    required: true,
    validation: ({ isRequired }) => [
      V.isRequired(isRequired),
      V.isInteger,
      V.isGreaterThanOrEqualTo(2),
      V.isLessThanOrEqualTo(30),
      V.isLessThanOrEqualToOtherFieldLength({
        otherFieldPath: 'invoiceNumberFormat',
        otherFieldName: i18n.t('invoicePattern.otherFieldName')
      }),
      isGreaterThanTruncateReferenceSum
    ]
  },
  MAX_LENGTH: {
    id: 'maxLength',
    label: ['invoicePatterns.columnHeaderNames.maxLength'],
    translate: true,
    required: true,
    validation: ({ isRequired }) => [
      V.isRequired(isRequired),
      V.isInteger,
      V.isGreaterThanOrEqualTo(2),
      V.isLessThanOrEqualTo(30),
      V.isEqualToOtherFieldLength({ otherFieldPath: 'invoiceNumberFormat', otherFieldName: i18n.t('invoicePattern.otherFieldName') })
    ]
  },
  INVOICE_NUMBER_FORMAT: {
    id: 'invoiceNumberFormat',
    label: ['invoicePatterns.columnHeaderNames.invoiceRefFieldFormat'],
    translate: true,
    required: true,
    validation: ({ isRequired }) => [
      V.isRequired(isRequired),
      isAlphaNumericWithSPCPCTQMASOnCondition,
      isAlphaNumericWithSPCOnCondition
    ]
  },
  REFERENCE_FIELD: {
    id: 'invoiceReference',
    label: ['invoicePatterns.columnHeaderNames.invoiceRefTxt'],
    type: 'dropdown',
    itemToString: defaultItemToString,
    translate: true,
    required: true,
    parse: defaultParse,
    validation: ({ isRequired }) => [V.isRequired(isRequired)]
  },
  SEARCH_PAYMENT_NUMBER: {
    id: 'searchPaymentNumber',
    label: ['invoicePatterns.columnHeaderNames.searchPaymentNumIn'],
    type: 'dropdown',
    parse: defaultParse,
    itemToString: defaultItemToString,
    translate: true
  },
  REMOVE_SPACES: {
    id: 'removeSpaces',
    label: ['invoicePatterns.columnHeaderNames.removeSpacesIn'],
    type: 'dropdown',
    parse: defaultParse,
    itemToString: defaultItemToString,
    translate: true
  },
  REMOVE_SPECIAL_CHARACTERS: {
    id: 'removeSpecialCharacters',
    label: ['invoicePatterns.columnHeaderNames.removeSpecialCharactersIn'],
    type: 'dropdown',
    parse: defaultParse,
    itemToString: defaultItemToString,
    translate: true
  },
  TRUNCATE_REF_LEADING: {
    id: 'truncateReferenceLeading',
    label: ['invoicePatterns.columnHeaderNames.truncateReferenceLeading'],
    translate: true,
    required: true,
    validation: ({ isRequired }) => [V.isRequired(isRequired), V.isInteger, V.isGreaterThanOrEqualTo(0)]
  },
  TRUNCATE_REF_TRAILING: {
    id: 'truncateReferenceTrailing',
    label: ['invoicePatterns.columnHeaderNames.truncateReferenceTrailing'],
    translate: true,
    required: true,
    validation: ({ isRequired }) => [V.isRequired(isRequired), V.isInteger, V.isGreaterThanOrEqualTo(0)]
  }
};

export default InvoicePatternDetailsFields;
