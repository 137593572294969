import logger from 'redux-logger';
import rootReducer from 'reducers/index';
import thunk from 'redux-thunk';
import { applyMiddleware, compose, createStore } from 'redux';
import { callAPIMiddleware } from 'Middlewares/callApiMiddleWare';
import { sessionUser } from 'utility/sessionStorageHelper';

export default ({ announce, t }) => {
  const options = () => {
    return {
      announce,
      t,
      ...sessionUser.getLoggedInUser().getId()
    };
  };

  const middlewares = [callAPIMiddleware(options), thunk];

  if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_LOGGER_DISABLE !== 'true') {
    middlewares.push(logger);
  }

  const middlewareEnhancer = applyMiddleware(...middlewares);
  const composedEnhancers =
    (process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

  return createStore(rootReducer, {}, composedEnhancers(middlewareEnhancer));
};
