import React from 'react';
import { withRouter } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';

import ConfirmationDialog from 'Components/Dialogs/ConfirmationDialog';

import isEmpty from 'lodash/isEmpty';
import { closeDialog } from 'actions/modalActions';
import { errorActions } from 'utility/error';
import { callAll } from 'utility/renderProps';
import sessionHelper from 'utility/sessionStorageHelper';
import i18n from 'i18n';

export const ModalContainer = props => {
  const {
    isOpen: openDialog,
    title: dialogTitle,
    state,
    message,
    action,
    btnContent = [],
    btnOverride,
    children,
    reverseCloseActionInCallAll,
    dialogState = isEmpty(state) ? undefined : state,
    closeOverride,
    closeAction,
    formConfig
  } = useSelector(state => state.modals);

  const dispatch = useDispatch();
  const close = () => dispatch(closeDialog());
  const getDialogContent = () => {
    const actionHandler = {
      [errorActions.GO_BACK]: props.history.goBack,
      [errorActions.RELOAD_PAGE]: () => props.history.go(location.href)
    };
    const actions = [close];
    if (actionHandler.hasOwnProperty(action)) {
      actions.push(actionHandler[action]);
    }
    if (closeOverride) {
      actions.push(closeAction);
    }
    return {
      dialogContent: {
        formConfig,
        openDialog,
        dialogTitle,
        dialogState,
        message,
        handleClose: callAll(...actions),
        btnContent: !btnOverride
          ? [
              {
                actionButton: false,
                name: i18n.t('administration.button.close'),
                variant: 'cta',
                onClick: callAll(...actions)
              },
              ...btnContent.map(button => ({
                ...button,
                onClick: reverseCloseActionInCallAll ? callAll(close, button.onClick) : callAll(button.onClick, close)
              }))
            ]
          : btnContent.map(button => ({
              ...button,
              onClick: button.onClick
                ? reverseCloseActionInCallAll
                  ? callAll(close, button.onClick)
                  : callAll(button.onClick, close)
                : !formConfig
                ? close
                : null
            }))
      }
    };
  };
  return <ConfirmationDialog {...getDialogContent()}>{children}</ConfirmationDialog>;
};

export default withRouter(ModalContainer);
