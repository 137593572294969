import api from 'api';
import axios from 'axios';

import cloneDeep from 'lodash/cloneDeep';
import { FIELD_TYPES, unformatPayload } from 'hooks/useFieldFormatter';

export const LOAD_IMAGEVIEWER_REQUEST = 'LOAD_IMAGEVIEWER_REQUEST';
export const LOAD_IMAGEVIEWER_SUCCESS = 'LOAD_IMAGEVIEWER_SUCCESS';
export const LOAD_IMAGEVIEWER_FAILURE = 'LOAD_IMAGEVIEWER_FAILURE';
export const SAVE_IMAGEVIEWER_REQUEST = 'SAVE_IMAGEVIEWER_REQUEST';
export const SAVE_IMAGEVIEWER_SUCCESS = 'SAVE_IMAGEVIEWER_SUCCESS';
export const SAVE_IMAGEVIEWER_FAILURE = 'SAVE_IMAGEVIEWER_FAILURE';
export const LOAD_THUMBNAILS_REQUEST = 'LOAD_THUMBNAILS_REQUEST';
export const LOAD_THUMBNAILS_SUCCESS = 'LOAD_THUMBNAILS_SUCCESS';
export const LOAD_THUMBNAILS_FAILURE = 'LOAD_THUMBNAILS_FAILURE';
export const LOAD_SELECTED_THUMBNAILS_SUCCESS = 'LOAD_SELECTED_THUMBNAILS_SUCCESS';
export const LOAD_SELECTED_THUMBNAILS_REQUEST = 'LOAD_SELECTED_THUMBNAILS_REQUEST';
export const LOAD_SELECTED_THUMBNAILS_FAILURE = 'LOAD_SELECTED_THUMBNAILS_FAILURE';
export const RESET_IMAGE_VIEWER_INFO = 'RESET_IMAGE_VIEWER_INFO';
export const CANCEL_AND_KEEP_STATE = 'CANCEL_AND_KEEP_STATE';
export const CLEAR_DOWNLOAD_IMAGE_LIST = 'CLEAR_DOWNLOAD_IMAGE_LIST';
export const RESET_ARCHIVAL_STATE = 'RESET_ARCHIVAL_STATE';

import { httpErrorTypes } from 'utility/error';
import { transactionURL } from 'routes/Transactions/utils';
import { mapValuesToPayload } from 'routes/utils/payload';

export const loadArchivedThumbnails = () => ({
  types: [LOAD_THUMBNAILS_REQUEST, LOAD_THUMBNAILS_SUCCESS, LOAD_THUMBNAILS_FAILURE, CANCEL_AND_KEEP_STATE],
  shouldCallAPI: state =>
    !state.transactiondetails.data?.transactionDetails?.imageArchived && state.transactiondetails?.transactionIds?.[0],
  callAPI: ({ transactiondetails: { transactionIds, data } }, options) =>
    api.save(transactionURL(`transaction/${transactionIds?.[0]}/get-archived-thumbnail-images-by-transaction`), {
      irTxnRev: data?.transactionDetails?.irTxnRev,
      procDate: data?.transactionDetails?.procDate
    }),
  httpErrorTypes
});

export const loadImageById = (imageIds, params = {}) => {
  return {
    types: [LOAD_IMAGEVIEWER_REQUEST, LOAD_IMAGEVIEWER_SUCCESS, LOAD_IMAGEVIEWER_FAILURE, CANCEL_AND_KEEP_STATE],
    callAPI: (state, options) => api.save(transactionURL(`transaction/get-images`), imageIds),
    params: params,
    httpErrorTypes
  };
};

export const loadImagesByTransaction = (basePayload = {}, params = {}) => {
  return {
    types: [LOAD_IMAGEVIEWER_REQUEST, LOAD_IMAGEVIEWER_SUCCESS, LOAD_IMAGEVIEWER_FAILURE, CANCEL_AND_KEEP_STATE],
    callAPI: ({ transactiondetails: { transactionIds, data } }, options) =>
      api.save(transactionURL(`transaction/${transactionIds?.[0]}/get-images-by-transaction`), {
        ...basePayload,
        irTxnRev: data?.transactionDetails?.irTxnRev,
        procDate: data?.transactionDetails?.procDate
      }),
    params: params,
    httpErrorTypes
  };
};

export const loadThumbnails = () => ({
  types: [LOAD_THUMBNAILS_REQUEST, LOAD_THUMBNAILS_SUCCESS, LOAD_THUMBNAILS_FAILURE, CANCEL_AND_KEEP_STATE],
  shouldCallAPI: state =>
    !state.transactiondetails.data?.transactionDetails?.imageArchived && state.transactiondetails?.transactionIds?.[0],
  callAPI: ({ transactiondetails: { transactionIds, data } }, options) =>
    api.save(transactionURL(`transaction/${transactionIds?.[0]}/get-thumbnail-images-by-transaction`), {
      irTxnRev: data?.transactionDetails?.irTxnRev,
      procDate: data?.transactionDetails?.procDate
    }),
  httpErrorTypes
});

export const saveImagesByTransaction = (fileType, imageDetails, serviceAction = '') => (dispatch, getState) => {
  const actionQueryParam = serviceAction ? `?action=${serviceAction}` : '';

  return dispatch({
    types: [SAVE_IMAGEVIEWER_REQUEST, SAVE_IMAGEVIEWER_SUCCESS, SAVE_IMAGEVIEWER_FAILURE],
    callAPI: ({ transactiondetails: { transactionIds, data } }, options) =>
      api.fetchPdf(transactionURL(`transaction/${transactionIds?.[0]}/save-images-by-transaction${actionQueryParam}`), {
        imageDetails: imageDetails,
        irTxnRev: data?.transactionDetails?.irTxnRev,
        procDate: data?.transactionDetails?.procDate,
        fileType
      }),
    dispatchOnSuccess: [({ announce, t }) => announce(t('imageViewer.api.save.byTransactionId', { fileType }))],
    httpErrorTypes
  });
};

export const saveImgById = (fileType, imageDetails, serviceAction = '') => (dispatch, getState) => {
  const actionQueryParam = serviceAction ? `?action=${serviceAction}` : '';
  return dispatch({
    types: [SAVE_IMAGEVIEWER_REQUEST, SAVE_IMAGEVIEWER_SUCCESS, SAVE_IMAGEVIEWER_FAILURE],
    callAPI: (state, options) =>
      api.fetchPdf(transactionURL(`transaction/save-images${actionQueryParam}`), {
        imageDetails: imageDetails,
        fileType
      }),
    dispatchOnSuccess: [({ announce, t }) => announce(t('imageViewer.api.save.byId', { fileType }))],
    httpErrorTypes
  });
};

export const loadSelectedThumbnails = (selectedInfo, serviceAction = '') => (dispatch, getState) => {
  const actionQueryParam = serviceAction === 'transactions-requiring-action' ? `?action=${serviceAction}` : '';
  const data = cloneDeep(selectedInfo);
  const userSettings = getState().settings.userSettings;
  const fieldsToUnformat = { amount: FIELD_TYPES.AMOUNT };
  const unformattedPayload = unformatPayload(fieldsToUnformat, data)(userSettings);
  const newPayload = mapValuesToPayload(unformattedPayload);

  return dispatch({
    types: [LOAD_SELECTED_THUMBNAILS_REQUEST, LOAD_SELECTED_THUMBNAILS_SUCCESS, LOAD_SELECTED_THUMBNAILS_FAILURE],
    callAPI: (state, options) =>
      api.save(transactionURL(`transaction/get-thumbnail-images${actionQueryParam}`), newPayload),
    httpErrorTypes
  });
};

export const resetArchivalState = () => ({ type: RESET_ARCHIVAL_STATE });
export const resetImageViewerInfo = () => ({ type: RESET_IMAGE_VIEWER_INFO });
export const clearDownloadImageList = () => ({ type: CLEAR_DOWNLOAD_IMAGE_LIST });
