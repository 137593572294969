import {
  VALIDATE_BATCH_REQUEST,
  VALIDATE_BATCH_SUCCESS,
  GENERATE_BATCH_REQUEST,
  GENERATE_BATCH_SUCCESS
} from '../actions/batchActions';

export default function batchReducer(state = {}, action, batchData) {
  const payload = {
    batchUniqueId: state.batchUniqueId,
    lockboxid: state.lockboxid
  };
  switch (action.type) {
    case VALIDATE_BATCH_REQUEST:
      return Object.assign(payload, state, {
        batchValidated: true,
        isFetching: true,
        unique: undefined
      });
    case VALIDATE_BATCH_SUCCESS:
      return Object.assign({}, action.response, { isFetching: false });
    case GENERATE_BATCH_REQUEST:
      return Object.assign({}, state, { isFetching: true });
    case GENERATE_BATCH_SUCCESS:
      return Object.assign({}, state, action.response, { isFetching: false });
    default:
      return state;
  }
}
