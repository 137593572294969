import {
  SIDE_PANEL_CLOSE,
  SIDE_PANELS_INIT,
  SIDE_PANEL_OPEN,
  SIDE_PANEL_TOGGLE,
  SIDE_PANEL_TOGGLE_WIDE
} from 'actions/sidePanelsActions';

const initialState = {
  queue: [],
  displaying: '',
  wide: false
};

const sidePanelsInit = (
  state = initialState,
  { payload: { queue = initialState.queue, wide = initialState.wide } = {} }
) => ({
  ...state,
  queue,
  displaying: (queue && queue[0]) || initialState.displaying,
  wide
});

const sidePanelOpen = (state = initialState, { payload: { id } }) => {
  const newQueue = state.queue.slice();

  if (newQueue.indexOf(id) >= 0) {
    newQueue.splice(newQueue.indexOf(id), 1);
  }
  newQueue.unshift(id);

  return {
    queue: newQueue,
    displaying: newQueue[0],
    wide: initialState.wide
  };
};

const sidePanelClose = (state = initialState, { payload: { id } = {} }) => {
  const newQueue = state.queue.slice();

  if (newQueue.indexOf(id) > -1) {
    newQueue.splice(newQueue.indexOf(id), 1);
  }

  return {
    queue: newQueue,
    displaying: newQueue[0] || initialState.displaying,
    wide: initialState.wide
  };
};

const sidePanelToggle = (state = initialState, { payload: { id } }) => {
  const newQueue = state.queue.slice();

  if (newQueue.indexOf(id) > 0) {
    newQueue.splice(newQueue.indexOf(id), 1);
    newQueue.unshift(id);
  } else if (newQueue.indexOf(id) === 0) {
    newQueue.splice(newQueue.indexOf(id), 1);
  } else {
    newQueue.unshift(id);
  }
  return {
    queue: newQueue,
    displaying: newQueue[0] || initialState.displaying,
    wide: initialState.wide
  };
};

const sidePanelToggleWide = (state = initialState) => {
  return {
    ...state,
    wide: !state.wide
  };
};

export default (state = initialState, action) => {
  const handlers = {
    [SIDE_PANEL_CLOSE]: sidePanelClose,
    [SIDE_PANEL_OPEN]: sidePanelOpen,
    [SIDE_PANEL_TOGGLE]: sidePanelToggle,
    [SIDE_PANEL_TOGGLE_WIDE]: sidePanelToggleWide,
    [SIDE_PANELS_INIT]: sidePanelsInit
  };
  return handlers.hasOwnProperty(action.type) ? handlers[action.type](state, action) : state;
};
