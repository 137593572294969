import { FormGroup as UITKFormGroup } from '@jpmuitk/forms';
import { ColumnLayout } from '@jpmuitk/column-layout';
import { formGroupUseStyles as useStyles } from './styles';

const FormGroup = ({ children, ...rest }) => {
  const classes = useStyles();

  return (
    <UITKFormGroup classes={classes} {...rest}>
      <ColumnLayout container spacing={3}>
        {children}
      </ColumnLayout>
    </UITKFormGroup>
  );
};
export default FormGroup;
