export const disassociateAdvicePayload = (openAdvicesAndInvoicesState, currentTransactionId, txnDetails) => {
  const { openAdviceDetails, remittanceAdviceTransactionIds } = openAdvicesAndInvoicesState;
  const remittanceAdviceIds = openAdviceDetails.map(advice => advice.remittanceAdviceId);
  return {
    remittanceAdviceIds: remittanceAdviceTransactionIds,
    transactionId: Number(currentTransactionId),
    programId: Number(txnDetails.programId),
    irEnabled: txnDetails.irEnabled
  };
};
