export const balanceStyles = ({
  palette: {
    toolkit: { red500 }
  }
}) => ({
  balance: {
    color: red500,
    fontWeight: 600
  }
});

export const errorStyles = ({
  palette: {
    toolkit: { grey500 }
  }
}) => ({
  errorContainer: {
    marginTop: -24
  },
  error: {
    color: grey500,
    fontSize: 11,
    padding: '0 0 0 4px',
    marginTop: 18,
    lineHeight: 'normal'
  }
});
