import { withStyles } from '@jpmuitk/theme';

import { Form } from '@jpmuitk/forms';
import { ComboBox as CapitalizedDropdown } from '@jpmuitk/forms';
import { ColumnLayout } from '@jpmuitk/column-layout';
import { FormField } from '@jpmuitk/form-field';

import { FORM_ITEM_SIZE_LIST } from './utils';
import { defaultDropdownStyle } from 'Components/Forms/Dropdown/styles';
import i18n from 'i18n';

const Dropdown = withStyles(defaultDropdownStyle)(CapitalizedDropdown);

export default ({ label, required, helperText, name, ref, ...props }) => (
  <ColumnLayout item {...FORM_ITEM_SIZE_LIST}>
    <FormField helperText={helperText} label={label + (required ? i18n.t('necessity.required') : '')}>
      <Dropdown ListProps={{ name }} name={name} {...props} />
    </FormField>
  </ColumnLayout>
);
