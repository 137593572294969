import React from 'react';
import { OrderedButton } from '@salt-ds/lab';
import { Button } from '@salt-ds/core';

import isEmpty from 'lodash/isEmpty';
import sessionHelper from 'utility/sessionStorageHelper';

const ActionButton = props => {
  const { actionButton = false, innerRef, children, disabled, ordered, type = 'button', ...rest } = props;
  const affectsDbAndIsSimulationUser = actionButton && !isEmpty(JSON.parse(sessionHelper.getItem('simulation')));

  return !ordered ? (
    <Button ref={innerRef} type={type} disabled={disabled || affectsDbAndIsSimulationUser} {...rest}>
      {children}
    </Button>
  ) : (
    <OrderedButton ref={innerRef} type={type} disabled={disabled || affectsDbAndIsSimulationUser} {...rest}>
      {children}
    </OrderedButton>
  );
};

export default ActionButton;
