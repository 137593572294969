import { ColumnLayout } from '@jpmuitk/column-layout';
import { FORM_SECTION_SIZE_LIST } from './utils';
import { containerUseStyles, itemUseStyles } from './styles';

const FormContainer = ({ children, spacing = 0, sidebar }) => {
  const containerClasses = containerUseStyles();
  const itemClasses = itemUseStyles();
  const sizes = sidebar ? { xs: 12 } : FORM_SECTION_SIZE_LIST;

  return (
    <ColumnLayout container justify="center" spacing={spacing} classes={containerClasses}>
      <ColumnLayout item {...sizes} classes={itemClasses}>
        {children}
      </ColumnLayout>
    </ColumnLayout>
  );
};
export default FormContainer;
