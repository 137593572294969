import './i18n';
import 'core-js/es';
import 'regenerator-runtime/runtime';
import sessionStorage from 'utility/sessionStorageHelper';
import App from './App';

import { Provider } from 'react-redux';
import { AriaAnnouncerProvider, useAriaAnnouncer } from '@jpmuitk/aria-announcer';

import axiosHelper from 'api/axios-helper';
import generateStore from 'store';
import { loadUserInfo, loadUserInfoRequest, loadUserInfoSuccess } from 'actions/userinfoActions';
import { render } from 'react-dom';
import i18n from 'i18n';
import { worker } from './browser';

if (typeof Promise === 'undefined') {
  // Rejection tracking prevents a common issue where React gets into an
  // inconsistent state due to an error, but it gets swallowed by a Promise,
  // and the user has no idea what causes React's erratic future behavior.
  require('promise/lib/rejection-tracking').enable();
  window.Promise = require('promise/lib/es6-extensions.js');
}

// fetch() polyfill for making API calls.
require('whatwg-fetch');

// In tests, polyfill requestAnimationFrame since jsdom doesn't provide it yet.
// We don't polyfill it in the browser--this is user's responsibility.
if (process.env.NODE_ENV === 'test') {
  require('raf').polyfill(global);
}
if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_MOCKEVERNEXT_ENABLE === 'true') {
  const { worker } = require('browser');
  worker.start();
}
export const AppWithProvidersAndHooksInStore = () => (
  <AriaAnnouncerProvider>
    <AddHooksAndApp />
  </AriaAnnouncerProvider>
);

const AddHooksAndApp = () => {
  const { announce } = useAriaAnnouncer();
  const store = generateStore({ announce, t: i18n.t });
  if (process.env.NODE_ENV === 'development') {
    window.store = store;
  }
  if (
    axiosHelper.getUserHeader() ||
    BUILD_ENVIRONMENT === 'QA' ||
    BUILD_ENVIRONMENT === 'PERF' ||
    BUILD_ENVIRONMENT === 'PROD'
  ) {
    const userResponse = sessionStorage.getItem('userResponse', true);
    if (userResponse) {
      store.dispatch(loadUserInfoSuccess(userResponse));
    } else {
      store.dispatch(loadUserInfoRequest());
      store.dispatch(loadUserInfo());
    }
  }

  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
};

render(<AppWithProvidersAndHooksInStore />, document.getElementById('root'));
