import api from 'api';

import moment from 'moment-business-days';
import { decisionManagerURL } from './utils';
import { httpErrorTypes } from 'utility/error';

const FILTER_REQUEST = 'FILTER_REQUEST';
const FILTER_SUCCESS = 'FILTER_SUCCESS';
const FILTER_FAILURE = 'FILTER_FAILURE';
const LOAD_DECISION_MANAGER_HISTORY_REQUEST = 'LOAD_DECISION_MANAGER_HISTORY_REQUEST';
const LOAD_DECISION_MANAGER_HISTORY_SUCCESS = 'LOAD_DECISION_MANAGER_HISTORY_SUCCESS';
const LOAD_DECISION_MANAGER_HISTORY_FAILURE = 'LOAD_DECISION_MANAGER_HISTORY_FAILURE';
export const EXPORT_DECISION_REQUEST = 'EXPORT_DECISION_REQUEST';
export const EXPORT_DECISION_SUCCESS = 'EXPORT_DECISION_SUCCESS';
export const EXPORT_DECISION_FAILURE = 'EXPORT_DECISION_FAILURE';
const CLEAR_DECISION_MANAGER = 'CLEAR_DECISION_MANAGER';

const decisionManagerActionErrorTypes = {
  LOAD_DECISION_MANAGER_FILTER: 'LOAD_DECISION_MANAGER_FILTER',
  LOAD_DECISION_MANAGER_HISTORY: 'LOAD_DECISION_MANAGER_HISTORY'
};

const loadDecisionManagerFilter = () => {
  return {
    types: [FILTER_REQUEST, FILTER_SUCCESS, FILTER_FAILURE],
    callAPI: (state, { customerId, userId }) => api.fetch(decisionManagerURL('decisionmanagerfilter')),
    httpErrorTypes
  };
};

const loadDecisionManagerHistory = (payload, endpoint, t) => (dispatch, getState) => {
  return dispatch({
    types: [
      LOAD_DECISION_MANAGER_HISTORY_REQUEST,
      LOAD_DECISION_MANAGER_HISTORY_SUCCESS,
      LOAD_DECISION_MANAGER_HISTORY_FAILURE
    ],
    callAPI: (state, { customerId, userId }) => api.save(decisionManagerURL(endpoint), payload),
    httpErrorTypes: {
      DEFAULT: decisionManagerActionErrorTypes.LOAD_DECISION_MANAGER_HISTORY,
      404: {
        type: decisionManagerActionErrorTypes.LOAD_DECISION_MANAGER_HISTORY,
        message: t('decisionManager.messages.noSuspendedTransactionsFound')
      }
    }
  });
};

const exportDecisions = (payload, reportType = '', t) => (dispatch, getState) => {
  return dispatch({
    types: [EXPORT_DECISION_REQUEST, EXPORT_DECISION_SUCCESS, EXPORT_DECISION_FAILURE],
    callAPI: (state, { customerId, userId }) =>
      api.save(
        `txndecisionmanagersvc/v0/cust/${customerId}/user/${userId}/decision/export/${reportType}-data`,
        payload
      ),
    httpErrorTypes,
    errorDisplayType: 'modal',
    errorTitle: t('decisionManager.messages.exportDecisionsError')
  });
};

const clearDecisionManager = () => {
  return { type: CLEAR_DECISION_MANAGER };
};

export {
  decisionManagerActionErrorTypes,
  loadDecisionManagerFilter,
  FILTER_REQUEST,
  FILTER_SUCCESS,
  FILTER_FAILURE,
  LOAD_DECISION_MANAGER_HISTORY_REQUEST,
  LOAD_DECISION_MANAGER_HISTORY_SUCCESS,
  LOAD_DECISION_MANAGER_HISTORY_FAILURE,
  CLEAR_DECISION_MANAGER,
  loadDecisionManagerHistory,
  exportDecisions,
  clearDecisionManager
};
