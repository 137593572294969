import React from 'react';
import classNames from 'classnames';

import { Spinner } from '@jpmuitk/spinner';
import { withStyles } from '@jpmuitk/theme';

import Button from 'Components/Button/ActionButton';
import { ButtonBar } from '@jpmuitk/button-bar';

import ConditionalWrapper from 'Components/ConditionalWrapper';

import { buttonRendererStyles as styles } from './styles';

const ButtonRenderer = props => {
  const {
    classes,
    containerClassName,
    buttonList,
    render,
    containerAriaLabel,
    submitting: formSubmitting,
    unwrapped = true,
    ordered = false,
    alignLeft,
    disabledSubmit = false
  } = props;

  return !render ? (
    <ButtonBar aria-label={containerAriaLabel} alignLeft={alignLeft}>
      {buttonList?.map(
        (
          { text, name, className, onClick, variant, disabled, tabIndex, actionButton, ariaLabel, type, ...rest },
          index
        ) => {
          const buttonText = text || name || undefined;
          const submitting = type === 'submit' && formSubmitting;

          return (
            <>
              <Button
                ordered
                key={buttonText + index}
                type={type}
                className={classNames(classes.button, className)}
                onClick={onClick}
                variant={variant}
                disabled={disabled || submitting || (type === 'submit' && disabledSubmit)}
                tabIndex={tabIndex ? tabIndex : 0}
                actionButton={actionButton || false}
                aria-label={ariaLabel}
                {...rest}
              >
                {buttonText}
              </Button>
            </>
          );
        }
      ) || null}
      {formSubmitting && <Spinner type="spinner" />}
    </ButtonBar>
  ) : (
    <ConditionalWrapper
      condition={unwrapped}
      wrapper={children => (
        <div role="region" aria-label={containerAriaLabel} className={classNames(classes.root, containerClassName)}>
          {children}
        </div>
      )}
    >
      {render({ classes })}
    </ConditionalWrapper>
  );
};

export default withStyles(styles)(ButtonRenderer);
