import moment from 'moment-business-days';
import app from 'config/app';
import i18n from 'i18n';

export const feature = {
  pages: {
    stopGo: {
      currentFilter: 'stopGo',
      currentFilterPayload: 'stopGoPayload',
      filter: {
        mapList: {
          reportType: 'object'
        },
        defaultValues: {
          reportDate: {
            from: moment()
              .subtract(14, 'days')
              .format(app(i18n.t).server.dateFormat),
            to: moment().format(app(i18n.t).server.dateFormat)
          }
        }
      }
    }
  }
};
