import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Banner } from '@jpmuitk/banner';
import { Spinner } from '@jpmuitk/spinner';
import { useTranslation } from 'react-i18next';

import { PageRoot, PageTitle, PageError, PageContent, PageFooter } from 'Components/Page';

const Page = ({ narrow = false, isLoading, errorMessage, children, noFooter = true, bannerContent = {} }) => {
  const { t } = useTranslation();
  const closeButtonRef = useRef();

  useEffect(() => {
    if (bannerContent.showBanner) {
      closeButtonRef?.current?.focus();
    }
  }, [bannerContent.showBanner]);

  return (
    <PageRoot narrow loading={isLoading}>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {bannerContent.showBanner && (
            <Banner
              state={bannerContent.state}
              onClose={bannerContent.onClose}
              style={{ marginTop: 0 }}
              role="banner"
              aria-label={bannerContent.message}
              CloseButtonProps={{ 'aria-label': t('banner.visuallyHidden.closeBanner') }}
              closeRef={closeButtonRef}
            >
              {bannerContent.message}
            </Banner>
          )}
          <PageTitle errorMessage={errorMessage} />
          {errorMessage ? null : <PageContent noFooter>{children}</PageContent>}
        </>
      )}
    </PageRoot>
  );
};

Page.propTypes = {
  children: PropTypes.object.isRequired
};

export default Page;
