import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dequeue, execute } from 'actions/scanControlServiceActions';

const useWebScanService = (disable = false) => {
  const dispatch = useDispatch();
  const queue = useSelector(state => state.scanControl.queue);

  useEffect(() => {
    if (disable) {
      return;
    } else {
      async function executeCall() {
        if (queue.length > 0) {
          const action = queue[0];
          if (!action.pending) {
            action.pending = true;
            try {
              const response = await dispatch(execute(action));
              dispatch(dequeue());
              if (action.onSuccess) {
                action.onSuccess(response);
              }
            } catch (error) {
              dispatch(dequeue());
              if (action.onFailure) {
                action.onFailure(error);
              }
            }
          }
        }
      }

      executeCall();
    }
  }, [dispatch, queue]);
};

export const WebScanService = () => {
  useWebScanService();

  return null;
};

export default useWebScanService;
