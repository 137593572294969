import {
  LOAD_REASON_CODES_REQUEST,
  LOAD_REASON_CODES_SUCCESS,
  LOAD_REASON_CODES_FAILURE,
  SAVE_REASON_CODE_REQUEST,
  SAVE_REASON_CODE_SUCCESS,
  SAVE_REASON_CODE_FAILURE,
  CHANGE_STATUS_REQUEST,
  CHANGE_STATUS_SUCCESS,
  CHANGE_STATUS_FAILURE
} from './actions';

export const initialState = {
  data: {}
};

const loadReasonCodeRequest = (state, action) => {
  return {
    ...state,
    isFetching: true
  };
};
const loadReasonCodeSuccess = (state, action) => {
  return {
    ...state,
    ...action.response,
    ...action.params,
    isFetching: false
  };
};
const loadReasonCodeFailure = (state, action) => {
  return {
    ...state,
    ...action,
    isFetching: false
  };
};

const saveReasonCodeRequest = (state, action) => {
  return {
    ...state,
    isFetching: true
  };
};
const saveReasonCodeSuccess = (state, action) => {
  return {
    ...state,
    ...action.response,
    ...action.params,
    isFetching: false
  };
};
const saveReasonCodeFailure = (state, action) => {
  return {
    ...state,
    ...action,
    isFetching: false
  };
};

const changeStatusRequest = (state, action) => {
  return {
    ...state,
    isFetching: true
  };
};
const changeStatusSuccess = (state, action) => {
  return {
    ...state,
    ...action.response,
    ...action.params,
    isFetching: false
  };
};
const changeStatusFailure = (state, action) => {
  return {
    ...state,
    ...action,
    isFetching: false
  };
};

export default function reasonCodeReducer(state = initialState, action) {
  const handlers = {
    [LOAD_REASON_CODES_REQUEST]: loadReasonCodeRequest,
    [LOAD_REASON_CODES_SUCCESS]: loadReasonCodeSuccess,
    [LOAD_REASON_CODES_FAILURE]: loadReasonCodeFailure,
    [SAVE_REASON_CODE_REQUEST]: saveReasonCodeRequest,
    [SAVE_REASON_CODE_SUCCESS]: saveReasonCodeSuccess,
    [SAVE_REASON_CODE_FAILURE]: saveReasonCodeFailure,
    [CHANGE_STATUS_REQUEST]: changeStatusRequest,
    [CHANGE_STATUS_SUCCESS]: changeStatusSuccess,
    [CHANGE_STATUS_FAILURE]: changeStatusFailure
  };
  return handlers.hasOwnProperty(action.type) ? handlers[action.type](state, action) : state;
}
