import { makeStyles } from '@jpmuitk/theme';

import { list } from 'styles';

export const useStyles = makeStyles(theme => {
  return {
    footer: {
      height: 36,
      display: 'flex',
      backgroundColor: theme.toolkit.palette.grey800,
      padding: '0 36px'
    },
    copywriteWrapper: {
      height: 35,
      marginRight: 'auto',
      display: 'inline-flex',
      position: 'relative',
      alignItems: 'center',
      justifyContent: 'center'
    },
    copywrite: {
      color: theme.palette.toolkit.grey60
    },
    nav: {
      height: 35,
      display: 'inline-flex',
      position: 'relative',
      alignItems: 'center',
      justifyContent: 'center',
      '& ol': {
        textAlign: 'right',
        ...list
      }
    }
  };
});
