import {
  CLEAR_INVOICE_PATTERN_STATE,
  CREATE_INVOICE_PATTERN_REQUEST,
  CREATE_INVOICE_PATTERN_SUCCESS,
  CREATE_INVOICE_PATTERN_FAILURE,
  LOAD_INVOICE_PROGRAMS_REQUEST,
  LOAD_INVOICE_PROGRAMS_SUCCESS,
  LOAD_INVOICE_PROGRAMS_FAILURE,
  LOAD_INVOICE_REFERENCES_REQUEST,
  LOAD_INVOICE_REFERENCES_SUCCESS,
  LOAD_INVOICE_REFERENCES_FAILURE,
  UPDATE_INVOICE_PATTERN_REQUEST,
  UPDATE_INVOICE_PATTERN_SUCCESS,
  UPDATE_INVOICE_PATTERN_FAILURE
} from './actions';

export const initialState = {
  partialSourcesState: {},
  createPatternState: {},
  updatePatternState: {}
};

const clearInvoicePatternState = (state, action) => initialState;

const createInvoicePatternRequest = (state, action) => ({
  ...state,
  createPatternState: {
    errorCreatingPattern: undefined,
    creatingPattern: true,
    createdPatternId: undefined
  }
});

const createInvoicePatternSuccess = (state, action) => ({
  ...state,
  createPatternState: {
    errorCreatingPattern: undefined,
    creatingPattern: false,
    createdPatternId: action.response.data?.newIdentificationPattern?.invoiceIdPatternId
  }
});

const createInvoicePatternFailure = (state, action) => ({
  ...state,
  createPatternState: {
    errorCreatingPattern: action.error,
    creatingPattern: false,
    createdPatternId: undefined
  }
});

const loadInvoiceProgramsRequest = (state, action) => ({
  ...state,
  partialSourcesState: {
    ...state.partialSourcesState,
    errorFetchingSources: undefined,
    fetchingSources: true,
    programs: [],
    invoiceReferences: []
  }
});

const loadInvoiceProgramsSuccess = (state, action) => ({
  ...state,
  partialSourcesState: {
    ...state.partialSourcesState,
    errorFetchingSources: undefined,
    fetchingSources: false,
    programs: action.response.data?.programs || [],
    invoiceReferences: action.response.data?.referenceTypes || []
  }
});

const loadInvoiceProgramsFailure = (state, action) => ({
  ...state,
  partialSourcesState: {
    ...state.partialSourcesState,
    errorFetchingSources: action.error,
    fetchingSources: false,
    programs: [],
    invoiceReferences: []
  }
});

const loadInvoiceReferencesRequest = (state, action) => ({
  ...state,
  partialSourcesState: {
    ...state.partialSourcesState,
    errorFetchingSources: undefined,
    fetchingSources: true,
    invoiceReferences: []
  }
});

const loadInvoiceReferencesSuccess = (state, action) => ({
  ...state,
  partialSourcesState: {
    ...state.partialSourcesState,
    errorFetchingSources: undefined,
    fetchingSources: false,
    invoiceReferences: action.response.data?.referenceTypes || []
  }
});

const loadInvoiceReferencesFailure = (state, action) => ({
  ...state,
  partialSourcesState: {
    ...state.partialSourcesState,
    errorFetchingSources: action.error,
    fetchingSources: false,
    invoiceReferences: []
  }
});

const updateInvoicePatternRequest = (state, action) => ({
  ...state,
  updatePatternState: {
    errorUpdatingPattern: undefined,
    updatingPattern: true,
    updatedPatternId: undefined
  }
});

const updateInvoicePatternSuccess = (state, action) => ({
  ...state,
  updatePatternState: {
    errorUpdatingPattern: undefined,
    updatingPattern: false,
    updatedPatternId: action.response.data?.newIdentificationPattern?.invoiceIdPatternId
  }
});

const updateInvoicePatternFailure = (state, action) => ({
  ...state,
  updatePatternState: {
    errorUpdatingPattern: action.error,
    updatingPattern: false,
    updatedPatternId: undefined
  }
});

export default function invoicePatternReducer(state = initialState, action) {
  const handlers = {
    [CLEAR_INVOICE_PATTERN_STATE]: clearInvoicePatternState,
    [CREATE_INVOICE_PATTERN_REQUEST]: createInvoicePatternRequest,
    [CREATE_INVOICE_PATTERN_SUCCESS]: createInvoicePatternSuccess,
    [CREATE_INVOICE_PATTERN_FAILURE]: createInvoicePatternFailure,
    [LOAD_INVOICE_PROGRAMS_REQUEST]: loadInvoiceProgramsRequest,
    [LOAD_INVOICE_PROGRAMS_SUCCESS]: loadInvoiceProgramsSuccess,
    [LOAD_INVOICE_PROGRAMS_FAILURE]: loadInvoiceProgramsFailure,
    [LOAD_INVOICE_REFERENCES_REQUEST]: loadInvoiceReferencesRequest,
    [LOAD_INVOICE_REFERENCES_SUCCESS]: loadInvoiceReferencesSuccess,
    [LOAD_INVOICE_REFERENCES_FAILURE]: loadInvoiceReferencesFailure,
    [UPDATE_INVOICE_PATTERN_REQUEST]: updateInvoicePatternRequest,
    [UPDATE_INVOICE_PATTERN_SUCCESS]: updateInvoicePatternSuccess,
    [UPDATE_INVOICE_PATTERN_FAILURE]: updateInvoicePatternFailure
  };
  if (handlers.hasOwnProperty(action.type)) {
    return handlers[action.type](state, action);
  } else {
    return state;
  }
}
