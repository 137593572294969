import { useEffect, useReducer, useState } from 'react';
import api from 'api';

const dataFetchReducer = (state = {}, action) => {
  const { type, payload = {} } = action;
  const handlers = {
    ['FETCH_INIT']: {
      ...state,
      isLoading: true,
      isError: false
    },
    ['FETCH_SUCCESS']: {
      ...state,
      isLoading: false,
      isError: false,
      data: { ...payload }
    },
    ['FETCH_FAILURE']: {
      ...state,
      isLoading: false,
      isError: true,
      data: { ...payload }
    }
  };
  return handlers.hasOwnProperty(action.type) ? handlers[action.type] : state;
};

const useApi = (initialUrl, initialData, type = 'fetch', options = {}) => {
  const [url, setUrl] = useState(initialUrl);
  const [state, dispatch] = useReducer(dataFetchReducer, {
    isLoading: true,
    isError: false,
    data: initialData
  });

  useEffect(() => {
    let didCancel = false;

    const fetchData = async () => {
      dispatch({ type: 'FETCH_INIT' });

      const isUrlList = Array.isArray(url);
      try {
        let response;
        if (isUrlList) {
          response = await Promise.all(url.map(url => api[type](url)));
        } else {
          response = await api[type](url, options);
        }

        if (!didCancel) {
          let payload;
          if (Array.isArray(response)) {
            payload = response.reduce((acc, { data }) => ({ ...acc, ...data }), {});
          } else {
            payload = response.data;
          }

          dispatch({ type: 'FETCH_SUCCESS', payload });
        }
      } catch (response) {
        if (!didCancel) {
          let payload;
          if (Array.isArray(response)) {
            payload = {
              message: response.reduce((acc, { data: { message } }) => (acc ? `${acc} ${message}` : acc), '')
            };
          } else {
            payload = response?.data;
          }

          dispatch({ type: 'FETCH_FAILURE', payload });
        }
      }
    };

    fetchData();

    return () => {
      didCancel = true;
    };
  }, [url]);

  return { state, setUrl };
};

export default useApi;
