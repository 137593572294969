import { createContext, useContext } from 'react';

export const LanguageContext = createContext({
  userLanguage: null,
  languages: {}
});

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  return context;
};

export const LanguageContextProvider = ({ value, children }) => {
  return <LanguageContext.Provider value={value}>{children}</LanguageContext.Provider>;
};
