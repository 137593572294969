import { toast } from 'react-toastify';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ButtonBar } from '@salt-ds/lab';
import { Drawer } from '@jpmuitk/drawer';
import { DialogContent, DialogActions, DialogTitle } from '@jpmuitk/dialog';
import { Toast } from '@jpmuitk/toast';
import { SaltProvider } from '@salt-ds/core';

import { InputBuilder } from 'Components/Forms/Input';
import Button from 'Components/Button/ActionButton';
import PageWithGrid from 'Components/PageWithGrid';
import Spinner from 'Components/Spinner';
import EmptyCellRenderer from 'Components/DataGrid/Renderers/EmptyCellRenderer';
import ActionRenderer from './actionRenderer';
import DialogContext from '@jpmuitk/dialog/es/internal/DialogContext';
import { loadInvoiceIDPatterns, deleteInvoicePattern, downloadInvoicePatterns } from './actions';
import { openDialog, closeDialog } from 'actions/modalActions';
import ColumnDefinitions from './invoiceIdentificationPatternsColumns';
import VPDF from './viewPatternDetailsFields';
import { ThemeProvider, withStyles, useDensity } from '@jpmuitk/theme';
import { invoiceIdentificationPatternsStyles } from './styles';
import { CREATE_INVOICE_PATTERN, CLONE_INVOICE_PATTERN, EDIT_INVOICE_PATTERN } from 'Constants/routes';
import { setRoute } from 'utility/setRoute';
import { handleRowStyle } from './utils';

const frameworkComponents = {
  emptyCellRenderer: EmptyCellRenderer,
  actionRenderer: ActionRenderer
};

const InvoiceIdentificationPatterns = props => {
  const { classes, history } = props;
  const { errorFetchingPatterns, fetchingPatterns, patternsList } = useSelector(
    state => state.invoicePatterns.identificationPatternsState
  );
  const { userSettings } = useSelector(state => state.settings);

  const [gridApi, setGridApi] = useState({});
  const [openDrawer, setOpenDrawer] = useState(false);
  const [disableExport, setDisableExport] = useState(true);
  const [patternDetails, setPatternDetails] = useState({});
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const errors = errorFetchingPatterns
    ? { message: errorFetchingPatterns?.message, status: errorFetchingPatterns?.status === 404 ? 'warning' : 'error' }
    : {};
  const density = useDensity(ThemeProvider);
  const dialogContainerRef = useRef(null);

  useEffect(() => {
    const params = { ...history.location.state };
    dispatch(loadInvoiceIDPatterns(params));
    if (params.successToast) {
      toast.success(({ closeToast }) => (
        <Toast state="success" title={params.successToast.title} onClose={closeToast}>
          {params.successToast.message}
        </Toast>
      ));
    }
  }, []);

  const onGridReady = params => {
    setGridApi(params.api);
  };

  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };

  const handleSelectionChanges = () => {
    setDisableExport(gridApi?.selectionController?.isEmpty());
  };

  const deletePattern = invoicePatternId => {
    dispatch(
      openDialog({
        title: t('invoicePatterns.dialog.delete.title'),
        state: 'warning',
        message: <Spinner className="default" announceMessage={t('invoicePatterns.deletingPattern')} />,
        reverseCloseActionInCallAll: true,
        btnOverride: true,
        btnContent: []
      })
    );
    dispatch(
      deleteInvoicePattern(invoicePatternId, [
        () => (dispatch, getState) => {
          dispatch(closeDialog());
          setOpenDrawer(false);
          dispatch(loadInvoiceIDPatterns());
          toast.success(({ closeToast }) => (
            <Toast state="success" title={t('invoicePatterns.successToast.defaultTitle')} onClose={closeToast}>
              {t('invoicePatterns.successToast.deleteMessage')}
            </Toast>
          ));
        }
      ])
    );
  };

  const handleDeleteDialog = invoicePatternId => {
    dispatch(
      openDialog({
        title: t('invoicePatterns.dialog.delete.title'),
        state: 'warning',
        message: t('invoicePatterns.dialog.delete.message'),
        reverseCloseActionInCallAll: true,
        btnOverride: true,
        btnContent: [
          {
            ['data-test-id']: 'noDeleteBtn',
            actionButton: false,
            name: t('invoicePatterns.dialog.button.no'),
            onClick: () => dispatch(closeDialog())
          },
          {
            ['data-test-id']: 'yesDeleteBtn',
            actionButton: true,
            variant: 'cta',
            name: t('invoicePatterns.dialog.button.yes'),
            onClick: () => deletePattern(invoicePatternId)
          }
        ]
      })
    );
  };

  const handleExport = () => {
    const payload = {};
    const currentNodesSelected = gridApi?.getBestCostNodeSelection?.();

    if (currentNodesSelected?.length > 0 && currentNodesSelected.length === patternsList?.length) {
      payload.exportAll = true;
    } else {
      payload.invoicePatternIds = [];
      currentNodesSelected?.forEach(patternNode =>
        payload.invoicePatternIds.push(patternNode?.data?.invoiceIdPatternId)
      );
    }

    dispatch(
      downloadInvoicePatterns(payload, [
        () => (dispatch, getState) => {
          gridApi?.deselectAll?.();
        }
      ])
    );
  };

  const handleRedirect = (pathname, state) => {
    setRoute(pathname);
    history?.push({
      pathname: pathname,
      state: state
    });
  };

  const buttonsContent = [
    {
      name: t('invoicePatterns.footerButtons.create'),
      variant: 'cta',
      hideCount: true,
      onClick: () => handleRedirect(CREATE_INVOICE_PATTERN, undefined)
    },
    ...(patternsList?.length > 0
      ? [
          {
            name: t('invoicePatterns.footerButtons.export'),
            disabled: disableExport,
            onClick: handleExport
          }
        ]
      : [])
  ];
  const contentElementRef = useRef(null);
  const setContentElement = useCallback(node => {
    contentElementRef.current = node;
  }, []);

  return (
    <>
      <PageWithGrid
        hideFilter
        isLoading={fetchingPatterns}
        handleSelectionChanges={handleSelectionChanges}
        buttonsContent={buttonsContent}
        rows={patternsList}
        columnDefs={ColumnDefinitions(userSettings)}
        frameworkComponents={frameworkComponents}
        onGridReady={onGridReady}
        getRowStyle={handleRowStyle}
        context={{ t, history, handleDeleteDialog, handleOpenDrawer, setPatternDetails }}
        errors={errors}
      />
      <Drawer
        anchor="right"
        className={classes.drawerContentWrapper}
        openWidth={'500px'}
        onToggle={setOpenDrawer}
        open={openDrawer}
        variant="modal"
      >
        <DialogContext.Provider value={{ setContentElement, dialogContainerRef, density }}>
          <SaltProvider>
            <DialogContent className={classes.drawerContent}>
              <DialogTitle disableAria className={classes.dialogTitle}>
                <span aria-level="2" role="heading">
                  {t('invoicePatterns.drawer.title')}
                </span>
              </DialogTitle>
              <h3>{t('invoicePatterns.drawer.description')}</h3>
              <InputBuilder
                className={classes.inputBuilderOverride}
                unwrapped
                field={VPDF.PROGRAM}
                defaultValue={patternDetails.program}
                noSuffix
              />
              <InputBuilder
                className={classes.inputBuilderOverride}
                unwrapped
                field={VPDF.MIN_LENGTH}
                defaultValue={patternDetails.minLength}
                noSuffix
              />
              <InputBuilder
                className={classes.inputBuilderOverride}
                unwrapped
                field={VPDF.MAX_LENGTH}
                defaultValue={patternDetails.maxLength}
                noSuffix
              />
              <InputBuilder
                className={classes.inputBuilderOverride}
                unwrapped
                field={VPDF.INVOICE_NUMBER_FORMAT}
                defaultValue={patternDetails.invoiceRefFieldFormat}
                noSuffix
              />
              <InputBuilder
                className={classes.inputBuilderOverride}
                unwrapped
                field={VPDF.REFERENCE_FIELD}
                defaultValue={patternDetails.invoiceRefTxt}
                noSuffix
              />
              <InputBuilder
                className={classes.inputBuilderOverride}
                unwrapped
                field={VPDF.SEARCH_PAYMENT_NUMBER}
                defaultValue={patternDetails.searchPaymentNumIn}
                noSuffix
              />
              <InputBuilder
                className={classes.inputBuilderOverride}
                unwrapped
                field={VPDF.REMOVE_SPACES}
                defaultValue={patternDetails.removeSpacesIn}
                noSuffix
              />
              <InputBuilder
                className={classes.inputBuilderOverride}
                unwrapped
                field={VPDF.REMOVE_SPECIAL_CHARACTERS}
                defaultValue={patternDetails.removeSpecialCharactersIn}
                noSuffix
              />
              <InputBuilder
                className={classes.inputBuilderOverride}
                unwrapped
                field={VPDF.TRUNCATE_REF_LEADING}
                defaultValue={patternDetails.truncateReferenceLeading}
                noSuffix
              />
              <InputBuilder
                className={classes.inputBuilderOverride}
                unwrapped
                field={VPDF.TRUNCATE_REF_TRAILING}
                defaultValue={patternDetails.truncateReferenceTrailing}
                noSuffix
              />
            </DialogContent>
            <DialogActions className={classes.drawerActions}>
              <ButtonBar alignLeft sortAlignLeft="asc" stackAtBreakpoint={0}>
                <Button
                  ordered
                  alignLeftOrder={0}
                  onClick={() => handleRedirect(CLONE_INVOICE_PATTERN, { patternDetails })}
                  variant="cta"
                  data-test-id={'drawerCloneBtn'}
                >
                  {t('invoicePatterns.drawer.button.clone')}
                </Button>
                <Button
                  ordered
                  alignLeftOrder={1}
                  onClick={() => handleRedirect(EDIT_INVOICE_PATTERN, { patternDetails })}
                  data-test-id={'drawerEditBtn'}
                >
                  {t('invoicePatterns.drawer.button.edit')}
                </Button>
                <Button
                  ordered
                  alignLeftOrder={2}
                  onClick={() => handleDeleteDialog(patternDetails.invoiceIdPatternId)}
                  data-test-id={'drawerDeleteBtn'}
                >
                  {t('invoicePatterns.drawer.button.delete')}
                </Button>
              </ButtonBar>
            </DialogActions>
          </SaltProvider>
        </DialogContext.Provider>
      </Drawer>
    </>
  );
};

export default withStyles(invoiceIdentificationPatternsStyles)(InvoiceIdentificationPatterns);
