import { CONTENT_DISPOSITION, CONTENT_TYPE } from 'Constants/headers';

import {
  LOAD_DOWNLOAD_CENTER_REQUEST,
  LOAD_DOWNLOAD_CENTER_SUCCESS,
  LOAD_DOWNLOAD_CENTER_FAILURE,
  CLEAR_DOWNLOAD_CENTER,
  LOAD_DOWNLOAD_REPORT_REQUEST,
  LOAD_DOWNLOAD_REPORT_SUCCESS,
  LOAD_DOWNLOAD_REPORT_FAILURE,
  CLEAR_DOWNLOAD_REPORT,
  LOAD_DOWNLOAD_FILTER_FAILURE,
  LOAD_DOWNLOAD_FILTER_REQUEST,
  LOAD_DOWNLOAD_FILTER_SUCCESS
} from './actions';

export const initialState = {
  downloadReportResult: {},
  error: {},
  isFetchingDownloadCenter: false,
  isFetchingDownloadReport: false,
  reportDownloadError: {},
  rowData: []
};

const loadDownloadCenterRequest = state => {
  return {
    ...state,
    error: {},
    isFetchingDownloadCenter: true,
    rowData: []
  };
};

const loadDownloadCenterSuccess = (state, action) => {
  const response = action.response;
  return {
    ...state,
    error: {},
    isFetchingDownloadCenter: false,
    rowData: response.data.receivablesInboxDetails
  };
};

const loadDownloadCenterFailure = (state, action) => {
  return {
    ...state,
    error: action.error ? { ...action.error } : null,
    isFetchingDownloadCenter: false,
    rowData: []
  };
};

const clearDownloadCenter = (state, action) => {
  return {
    ...state,
    error: {},
    isFetchingDownloadCenter: false,
    rowData: []
  };
};

const loadDownloadReportRequest = state => {
  return {
    ...state,
    downloadReportResult: {},
    isFetchingDownloadReport: true,
    reportDownloadError: {}
  };
};

function getDownloadReportFromResponse(response) {
  const contentDisposition = response.request.getResponseHeader(CONTENT_DISPOSITION);
  const contentType = response.request.getResponseHeader(CONTENT_TYPE);

  return {
    contentDisposition,
    contentType,
    data: response.data
  };
}

const loadDownloadReportSuccess = (state, action) => {
  const response = action.response;
  const result = getDownloadReportFromResponse(response);
  return {
    ...state,
    downloadReportResult: result,
    isFetchingDownloadReport: false,
    reportDownloadError: {}
  };
};

const loadDownloadReportFailure = (state, action) => {
  return {
    ...state,
    downloadReportResult: {},
    isFetchingDownloadReport: false,
    reportDownloadError: action.error ? { ...action.error } : {}
  };
};

const clearDownloadReport = (state, action) => {
  return {
    ...state,
    downloadReportResult: {},
    isFetchingDownloadReport: false,
    reportDownloadError: {}
  };
};

export default function downloadCenterReducer(state = initialState, action) {
  const handlers = {
    [LOAD_DOWNLOAD_CENTER_REQUEST]: loadDownloadCenterRequest,
    [LOAD_DOWNLOAD_CENTER_SUCCESS]: loadDownloadCenterSuccess,
    [LOAD_DOWNLOAD_CENTER_FAILURE]: loadDownloadCenterFailure,
    [CLEAR_DOWNLOAD_CENTER]: clearDownloadCenter,
    [LOAD_DOWNLOAD_REPORT_REQUEST]: loadDownloadReportRequest,
    [LOAD_DOWNLOAD_REPORT_SUCCESS]: loadDownloadReportSuccess,
    [LOAD_DOWNLOAD_REPORT_FAILURE]: loadDownloadReportFailure,
    [CLEAR_DOWNLOAD_REPORT]: clearDownloadReport
  };

  return handlers.hasOwnProperty(action.type) ? handlers[action.type](state, action) : state;
}
