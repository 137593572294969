import React, { forwardRef } from 'react';
import { withStyles } from '@jpmuitk/theme';
import useGridCell from 'hooks/useGridCell';
import { SplitButton } from '@jpmuitk/split-button';

import { CLONE_INVOICE_PATTERN, EDIT_INVOICE_PATTERN } from 'Constants/routes';
import { setRoute } from 'utility/setRoute';

const StyledSplitButton = withStyles({
  root: {
    '& button:focus': {
      outline: 'none !important'
    }
  }
})(SplitButton);

export default forwardRef((props, ref) => {
  const innerRef = useGridCell(props, ref);
  const initialSource = {
    menuItems: [
      {
        title: props.context.t?.('invoicePatterns.actionSplitButton.clone'),
        onClick: () => {
          setRoute(CLONE_INVOICE_PATTERN);
          props?.context?.history?.push({
            pathname: CLONE_INVOICE_PATTERN,
            state: { patternDetails: props?.data || {} }
          });
        }
      },
      {
        title: props.context.t?.('invoicePatterns.actionSplitButton.edit'),
        onClick: () => {
          setRoute(EDIT_INVOICE_PATTERN);
          props?.context?.history?.push({
            pathname: EDIT_INVOICE_PATTERN,
            state: { patternDetails: props?.data || {} }
          });
        }
      },
      {
        title: props.context.t?.('invoicePatterns.actionSplitButton.delete'),
        onClick: () => {
          props?.context?.handleDeleteDialog(props?.data?.invoiceIdPatternId);
        }
      }
    ]
  };
  const handleClick = () => {
    props?.context?.setPatternDetails(props?.data);
    props?.context?.handleOpenDrawer();
  };
  const handleAlternativeActionClick = ({ onClick }) => onClick && onClick();
  return (
    <StyledSplitButton
      data-test-id="actionSplitBtn"
      ariaLabel={props.context.t?.('invoicePatterns.viewDetails')}
      density="high"
      defaultButtonRef={innerRef}
      CascadingMenuProps={{
        initialSource,
        minWidth: 75,
        onItemClick: handleAlternativeActionClick
      }}
      onClick={handleClick}
    >
      {props.context.t?.('invoicePatterns.actionSplitButton.default')}
    </StyledSplitButton>
  );
});
