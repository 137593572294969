import isArray from 'lodash/isArray';
import { reduceByKey, chain, sourceByKey } from 'utility/arrayHelper';

export const getSourceByKey = (value, key, reducer = sourceByKey) =>
  isArray(value)
    ? chain(value)
        .reduce(reducer(key), [])
        .uniqBy('id')
        .sortBy('number')
        .value()
    : value &&
      chain(value[key])
        .uniqBy('id')
        .sortBy('number')
        .value();
