import { sessionUser } from 'utility/sessionStorageHelper';

export const transactionURL = endpoint => {
  const { customerId, userId } = sessionUser.getLoggedInUser().getId();
  return `transactionsvc/v0/cust/${customerId}/user/${userId}/` + endpoint;
};

export const archiveTransactionURL = endpoint => {
  const { customerId, userId } = sessionUser.getLoggedInUser().getId();
  return `archivesvc/v0/cust/${customerId}/user/${userId}/` + endpoint;
};
