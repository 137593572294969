import { makeStyles } from '@jpmuitk/theme';

export const gridStyles = {
  root: {
    height: 'auto'
  }
};

export const useGridStyles = makeStyles({
  ...gridStyles
});
