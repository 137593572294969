import React from 'react';
import { withRouter } from 'react-router';
import { useSelector } from 'react-redux';

import BackButtonComponent from './BackButtonComponent';
import sessionHelper from 'utility/sessionStorageHelper';
import { PAGE_TITLES } from 'Constants/pages';
import { getReportFromNav } from 'routes/Reports/OnlineReports/selectors.js';
import i18n from 'i18n';

const backButtonLabelHelper = (prevPage, id) => {
  const { navigationResponse } = useSelector(state => state.navigation);
  const title = id ? getReportFromNav(navigationResponse, x => x.reportId === Number(id)).label : null;
  return PAGE_TITLES(i18n.t)[prevPage] || title || i18n.t('backButton.previousPage');
};

import { Breadcrumbs, Breadcrumb } from '@jpmuitk/breadcrumbs';

const BackButton = props => {
  const prevPageArray = sessionHelper.getItem('prevPageArray')?.split(',');

  //Trim all pages from prevPageArray that come after the current page so that they get
  //removed from the breadcrumb trail when navigating back to a previous page

  const currentPage = props.match.path;
  const index = prevPageArray?.findIndex(url => url === currentPage);
  const last = prevPageArray?.length - 1;
  prevPageArray?.splice(index + 1, last);
  sessionHelper.setItem('prevPageArray', prevPageArray);

  return (
    <Breadcrumbs wrap hideCurrentLevel ref={props.innerRef}>
      {prevPageArray.map(page => {
        const onlineReportLink = page.includes('onlinereports') && page.split('/');
        return (
          <Breadcrumb role="link" tabIndex="0" onClick={() => props.history.push(page)}>
            {backButtonLabelHelper(page, onlineReportLink?.[2])}
          </Breadcrumb>
        );
      })}
    </Breadcrumbs>
  );
};

export default withRouter(BackButton);
