import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { withStyles } from '@jpmuitk/theme';
import { createToolkitTheme, ThemeProvider } from '@jpmuitk/theme';

import { MegaMenu as DefaultMegaMenu } from '@jpmuitk/mega-menu';

import { flattenNavAndFindBy } from 'utility/nav';
import sessionHelper from 'utility/sessionStorageHelper';

import { styles } from './styles';

const MegaMenu = withStyles(styles)(DefaultMegaMenu);
const DARK_THEME = createToolkitTheme('dark', {
  palette: { useNextColors: true }
});

export const NavigationBar = ({ history, location: { pathname } }) => {
  const tabs = useSelector(state => state.navigation?.navigationResponse);
  const megaMenuRef = useRef();

  /**
   * Remove unnecessary ARIA attributes from the inner divs of the tabs on the nav bar,
   * which were causing ADA defects.
   */
  useEffect(() => {
    const megaMenuInterval = setInterval(() => {
      const tabOuterDivs = megaMenuRef.current?.getElementsByClassName('muiTabOuterDiv');

      if (!tabOuterDivs) {
        return;
      }

      for (const tabOuterDiv of tabOuterDivs) {
        const tabInnerDiv = tabOuterDiv?.children?.item(0);

        if (tabInnerDiv) {
          tabInnerDiv.removeAttribute('aria-expanded');
          tabInnerDiv.removeAttribute('aria-haspopup');
          tabInnerDiv.removeAttribute('aria-current');
        }
      }
    }, 1000);

    return () => clearInterval(megaMenuInterval);
  }, [megaMenuRef]);

  const handleNavigate = e => {
    if (history.location?.pathname !== e.menuItem.href) {
      sessionHelper.setItem('prevPageArray', e.menuItem.href);
    }
    history.push(e.menuItem.href);
  };
  const pathroot = pathname.split('/')[1];

  return !!pathname.replace('/', '') ? (
    <ThemeProvider theme={DARK_THEME}>
      <MegaMenu
        metadata={tabs}
        defaultValue={
          flattenNavAndFindBy(tabs, x => x.href?.includes(pathroot)).id ||
          flattenNavAndFindBy(tabs, (x, i) => i === 0).id
        }
        onChange={handleNavigate}
        overflowMenu
        ref={megaMenuRef}
      />
    </ThemeProvider>
  ) : null;
};

export default compose(withRouter)(NavigationBar);
