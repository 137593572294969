export const CAPTURE_DETAIL = '/capturedetail';
export const CAPTURE_HISTORY = '/capturehistory';
export const CAPTURE_RESULTS = '/captureresults';
export const CLONE_INVOICE_PATTERN = '/cloneinvoicepattern';
export const CLONE_USER_SETUP = '/cloneusersetup';
export const CREATE_ALERT = '/createalert';
export const CREATE_INVOICE_PATTERN = '/createinvoicepattern';
export const CREATE_REPORT = '/onlinereports/:id/report/create';
export const EDIT_REPORT = '/onlinereports/:id/report/:templateId/edit';
export const CREATE_USER = '/createuser';
export const CREATE_REMITTANCE_ADVICE = '/createremittanceadvice';
export const CUSTOMER_CLASSIFICATION = '/customerclassification';
export const DECISION_MANAGER_HISTORY = '/decisionhistory';
export const DECISION_MANAGER_DETAILS = `${DECISION_MANAGER_HISTORY}/:id/details`;
export const DECISIONS_REQUIRING_ACTION = '/decisionsrequiringaction';
export const DECISIONS_REQUIRING_ACTION_DETAILS = `${DECISIONS_REQUIRING_ACTION}/:id/details`;
export const DISPLAYPRINTSETTINGS = '/displayprintsettings';
export const DOWNLOAD_CENTER = '/receivablesinbox';
export const EDIT = '/edit';
export const EDIT_ALERT = '/editalert/:id/:alertTypeDesc';
export const EDIT_INVOICE_PATTERN = '/editinvoicepattern';
export const EDIT_USER = '/edituser';
export const ENTITY_LOOKUP = '/entitylookup';
export const ENTITY_LIST = '/entitylist';
export const ENTITIES_REQUIRING_ACTIVATION = '/entitiesra';
export const ENTITY_CREATE = '/createentity';
export const ENTITY_DETAILS = '/entitydetails';
export const ENTITY_UPLOAD = '/uploadentity';
export const EXPORT_IMAGE = '/imageviewer';
export const EXPRESS_USER_SETUP = '/expressusersetup';
export const HCEOBEXCEPTIONS = '/hceobexceptions';
export const INBOX = '/inbox';
export const INVOICE_IDENTIFICATION_PATTERNS = '/invoiceidentificationpatterns';
export const INVOICE_DETAILS = '/invoicedetails';
export const INVOICE_SEARCH = '/invoicesearch';
export const MANAGE = '/manage';
export const ONLINE_REPORTS = '/onlinereports';
export const ONLINE_REPORT_RESULTS = '/results';
export const REACTIVATE = '/reactivate';
export const REASON_CODES = '/reasoncodes';
export const REMITTANCE_ADVICE_LIST = '/remittanceadvicelist';
export const REMITTANCE_ADVICE_DETAILS = '/remittanceadvicedetails';
export const SCAN = '/scan';
export const SITEMAP = '/sitemap';
export const SUPPORT_PAGE = '/support';
export const TRANSACTIONS = '/transactions';
export const TRANSACTIONS_ASSIGNED_TO_ME = '/transactionsassignedtome';
export const TRANSACTIONS_REQUIRING_ACTION = '/transactionsrequiringaction';
export const TRANSACTION_DETAIL = '/transactiondetails';
export const UPLOAD_WORKFLOW_NOTES = '/uploadworkflownotes';
export const UPLOAD_REMITTANCE_ADVICES = '/uploadremittanceadvices';
export const USER_ADMIN = '/useradmin';
export const USER_DETAIL = '/userdetail';
export const VIEW_USER = '/viewusers';
export const STOP_GO = '/UploadStopGoFile';
export const LOGOUT = '/logout';

export const LANDING_PAGE = {
  2: INBOX,
  3: TRANSACTIONS,
  4: DOWNLOAD_CENTER,
  5: DOWNLOAD_CENTER,
  6: CAPTURE_RESULTS,
  7: CAPTURE_HISTORY,
  8: SCAN,
  9: TRANSACTIONS_REQUIRING_ACTION,
  10: USER_ADMIN + CREATE_USER,
  11: USER_ADMIN + EDIT_USER,
  12: USER_ADMIN + VIEW_USER,
  13: '',
  14: REMITTANCE_ADVICE_LIST,
  15: DECISION_MANAGER_HISTORY,
  16: DECISIONS_REQUIRING_ACTION,
  17: ENTITY_LIST,
  18: ENTITY_UPLOAD,
  19: ENTITIES_REQUIRING_ACTIVATION,
  20: CUSTOMER_CLASSIFICATION,
  21: UPLOAD_REMITTANCE_ADVICES,
  22: TRANSACTIONS_ASSIGNED_TO_ME
};

export const DEFAULT_ROUTE = { id: 3, description: 'Transaction History' };
