import cssVariables from 'Constants/_variables.module.scss';
import { list } from 'styles';

export const styles = {
  headingWrapper: {
    '& div:not(:only-child):not(:last-child)': {
      marginBottom: 24
    }
  },
  heading: {
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    marginTop: 0,
    marginRight: 0,
    marginBottom: cssVariables.space3,
    marginLeft: 0
  },
  cta: {
    '& ul': {
      ...list
    },
    '& li': {
      padding: '0 0.25rem',
      width: 'auto',
      display: 'inline'
    },
    '& li:first-child': {
      paddingLeft: '0.5rem'
    },
    '& li:last-child': {
      paddingRight: '0.5rem'
    }
  }
};
