import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@jpmuitk/theme';

import styles from './styles';

const Fieldset = ({ legend, children, classes, className }) => (
  <fieldset className={classNames(classes.root, className.root)}>
    <legend className={classNames(classes.legend, className.legend)}>{legend}</legend>
    {children}
  </fieldset>
);

Fieldset.defaultProps = {
  className: {}
};

export default withStyles(styles)(Fieldset);
