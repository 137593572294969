import { checkboxColDefs, RENDERER_TYPE } from 'Components/DataGrid/utils';
import { numberNameComparator } from 'utility/comparators';
import i18n from 'i18n';

const numberSiteDisplayHelper = params => {
  const data = params.getValue();
  const valueSite = data?.site?.name ? data.site.name : '';
  const valueNumber = data?.number ? data.number + ' - ' : '';
  const valueToRender = valueNumber + valueSite;
  return { value: valueToRender };
};

const booleanTransformer = params => {
  return params.value ? 'Yes' : 'No';
};

const headerValueGetter = params => {
  const customHeaderName = params?.context?.t
    ? params.context.t(`invoicePatterns.columnHeaderNames.${params.colDef.field}`)
    : params.colDef.headerName;
  return customHeaderName;
};

export const ColumnDefinitions = userSettings => {
  return [
    { ...checkboxColDefs },
    {
      field: 'program',
      headerName: i18n.t('invoicePatterns.columnHeaderNames.program'),
      headerValueGetter: headerValueGetter,
      type: [RENDERER_TYPE],
      width: 370,
      cellRenderer: 'emptyCellRenderer',
      cellRendererParams: numberSiteDisplayHelper,
      comparator: numberNameComparator(userSettings)
    },
    {
      field: 'minLength',
      headerName: i18n.t('invoicePatterns.columnHeaderNames.minLength'),
      headerValueGetter: headerValueGetter,
      cellRenderer: 'emptyCellRenderer',
      width: 100
    },
    {
      field: 'maxLength',
      headerName:i18n.t('invoicePatterns.columnHeaderNames.maxLength'),
      headerValueGetter: headerValueGetter,
      cellRenderer: 'emptyCellRenderer',
      width: 100
    },
    {
      field: 'invoiceRefFieldFormat',
      headerName: i18n.t('invoicePatterns.columnHeaderNames.invoiceRefFieldFormat'),
      headerValueGetter: headerValueGetter,
      cellRenderer: 'emptyCellRenderer',
      width: 280
    },
    {
      field: 'invoiceRefTxt',
      headerName: i18n.t('invoicePatterns.columnHeaderNames.invoiceRefTxt'),
      headerValueGetter: headerValueGetter,
      cellRenderer: 'emptyCellRenderer',
      minWidth: 200
    },
    {
      field: 'searchPaymentNumIn',
      headerName: i18n.t('invoicePatterns.columnHeaderNames.searchPaymentNumIn'),
      headerValueGetter: headerValueGetter,
      valueFormatter: booleanTransformer,
      minWidth: 100
    },
    {
      field: 'action',
      headerName: i18n.t('invoicePatterns.columnHeaderNames.action'),
      headerValueGetter: headerValueGetter,
      cellRenderer: 'actionRenderer',
      minWidth: 100
    }
  ];
};

export default ColumnDefinitions;
