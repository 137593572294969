import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { withStyles } from '@jpmuitk/theme';
import { app } from 'config';
import { useField } from 'react-final-form';
import { useErrorProps, useInteractiveProps } from 'Components/Forms/Hooks';
import { Input } from '@jpmuitk/input';
import { FormField } from '@jpmuitk/form-field';
import { DatePicker } from '@jpmuitk/date-picker';
import { ColumnLayout } from '@jpmuitk/column-layout';
import ConditionalWrapper from 'Components/ConditionalWrapper';
import { composeValidators } from 'utility/validation';
import { inputStyles } from 'styles';
import { useId } from '@jpmuitk/utils';
import { useTranslation } from 'react-i18next';

const SingleDatePickerBuilder = props => {
  const {
    fieldRest,
    helperText,
    helperTextPlacement,
    hide,
    id,
    inputProps,
    isDisabled,
    isRequired,
    isValidationDisabled,
    label,
    onChange,
    noSuffix,
    propsRest,
    readOnly,
    validation,
    validateOnLoad,
    defaultHelperText,
    PopperProps,
    YearDropdownProps,
    // Layout
    columnLayoutProps,
    useColumnLayout,
    labelPlacement
  } = useInteractiveProps(props);
  const helperTextId = useId();
  const {
    userDateFormat: { description: userDateFormat }
  } = useSelector(state => state.settings.userSettings);
  const { t } = useTranslation();

  const { input, meta } = useField(`${id}${noSuffix ? '' : 'Field'}`, {
    validate: composeValidators(validation({ isRequired, format: userDateFormat })),
    parse: momentDate => momentDate?.format(app(t).server.dateFormat),
    format: date => (date ? moment(date) : '')
  });
  const { type, msg } = useErrorProps({ meta, validateOnLoad, isValidationDisabled });
  const labelId = `ui-toolkit-form-field-label-${Math.floor(Math.random() * 10)}`;
  return hide ? null : (
    <ConditionalWrapper
      condition={useColumnLayout}
      wrapper={children => (
        <ColumnLayout item {...columnLayoutProps}>
          {children}
        </ColumnLayout>
      )}
    >
      {!readOnly || !isDisabled ? (
        <div data-ro-test="date-picker">
          <FormField
            label={label({ format: userDateFormat })}
            labelPlacement={labelPlacement}
            validationState={type}
            helperText={msg || defaultHelperText}
            LabelProps={{
              id: labelId,
              NecessityIndicator: () => (isRequired ? <i>&nbsp;{t('necessity.required')}</i> : '')
            }}
            HelperTextProps={{
              id: helperTextId
            }}
          >
            {/* const { id: labelId } = getChildProps({}); */}
            <DatePicker
              {...input}
              defaultDate={moment(input.value).isValid() ? moment(input.value) : undefined}
              dateFormat={userDateFormat}
              onDateChange={date => {
                input.onChange(date);
              }}
              inputProps={{
                'aria-invalid': type === 'error',
                'aria-labelledby': labelId,
                'aria-label': undefined,
                'aria-describedby': helperTextId
              }}
              PopperProps={PopperProps}
              YearDropdownProps={YearDropdownProps}
            />
          </FormField>
        </div>
      ) : (
        <FormField
          label={label({ format: userDateFormat, plain: false })}
          labelPlacement={labelPlacement}
          readOnly={readOnly}
        >
          <Input {...input} readOnly={readOnly} value={moment(input.value).format(userDateFormat)} />
        </FormField>
      )}
    </ConditionalWrapper>
  );
};

export default withStyles(inputStyles)(SingleDatePickerBuilder);
