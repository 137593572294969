import React, { useRef, useEffect } from 'react';
import isNil from 'lodash/isNil';
import { useTranslation } from 'react-i18next';

import APP_CONFIG from 'config/app';

import { Icon } from '@jpmuitk/icon';
import { Button } from '@salt-ds/core';

import ConditionalWrapper from 'Components/ConditionalWrapper';

const HeaderButtonList = props => {
  const { t } = useTranslation();
  const expandButtonRef = useRef();
  const { pathname, classes, handleClose, handleToggle, wide } = props;
  const isSimpleFilter = APP_CONFIG(t).filter.simpleList.indexOf(pathname) > -1 || isNil(wide);

  useEffect(() => {
    if (props.focusOnFirstButton) {
      expandButtonRef?.current?.focus();
    }
  }, [props.focusOnFirstButton]);

  return (
    <ConditionalWrapper
      condition={!isSimpleFilter}
      wrapper={children => <ul className={classes.buttons}>{children}</ul>}
    >
      <ConditionalWrapper condition={!isSimpleFilter} wrapper={children => <li>{children}</li>}>
        {isSimpleFilter ? null : (
          <Button
            type="button"
            data-test-id="expand-collapse-button"
            aria-label={wide ? t('filter.button.collapse.hat') : t('filter.button.expand.hat')}
            variant="secondary"
            onClick={handleToggle}
            ref={expandButtonRef}
          >
            <Icon name={wide ? 'left-expand' : 'right-expand'} />
          </Button>
        )}
      </ConditionalWrapper>
      <ConditionalWrapper condition={!isSimpleFilter} wrapper={children => <li>{children}</li>}>
        <Button
          type="button"
          variant="secondary"
          onClick={handleClose}
          aria-label={t('filter.button.close.hat')}
          ref={isSimpleFilter ? expandButtonRef : null}
        >
          <Icon name="close" />
        </Button>
      </ConditionalWrapper>
    </ConditionalWrapper>
  );
};

export default HeaderButtonList;
