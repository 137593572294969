import api from 'api';
import { httpErrorTypes } from 'utility/error';
import i18n from 'i18n';

export const LOAD_INVOICE_PROGRAMS_REQUEST = 'LOAD_INVOICE_PROGRAMS_REQUEST';
export const LOAD_INVOICE_PROGRAMS_SUCCESS = 'LOAD_INVOICE_PROGRAMS_SUCCESS';
export const LOAD_INVOICE_PROGRAMS_FAILURE = 'LOAD_INVOICE_PROGRAMS_FAILURE';
export const CLEAR_INVOICE_PATTERN_STATE = 'CLEAR_INVOICE_PATTERN_STATE';
export const LOAD_INVOICE_REFERENCES_REQUEST = 'LOAD_INVOICE_REFERENCES_REQUEST';
export const LOAD_INVOICE_REFERENCES_SUCCESS = 'LOAD_INVOICE_REFERENCES_SUCCESS';
export const LOAD_INVOICE_REFERENCES_FAILURE = 'LOAD_INVOICE_REFERENCES_FAILURE';
export const CREATE_INVOICE_PATTERN_REQUEST = 'CREATE_INVOICE_PATTERN_REQUEST';
export const CREATE_INVOICE_PATTERN_SUCCESS = 'CREATE_INVOICE_PATTERN_SUCCESS';
export const CREATE_INVOICE_PATTERN_FAILURE = 'CREATE_INVOICE_PATTERN_FAILURE';
export const UPDATE_INVOICE_PATTERN_REQUEST = 'UPDATE_INVOICE_PATTERN_REQUEST';
export const UPDATE_INVOICE_PATTERN_SUCCESS = 'UPDATE_INVOICE_PATTERN_SUCCESS';
export const UPDATE_INVOICE_PATTERN_FAILURE = 'UPDATE_INVOICE_PATTERN_FAILURE';

export const clearInvoicePatternState = () => ({ type: CLEAR_INVOICE_PATTERN_STATE });

export const loadInvoicePrograms = (programId = '', dispatchOnSuccess = []) => {
  const queryParam = programId ? `?programIdForRefs=${programId}` : '';
  return {
    types: [LOAD_INVOICE_PROGRAMS_REQUEST, LOAD_INVOICE_PROGRAMS_SUCCESS, LOAD_INVOICE_PROGRAMS_FAILURE],
    callAPI: (state, { customerId, userId }) =>
      api.fetch(
        `invoicereferencesvc/v0/cust/${customerId}/user/${userId}/get-invoice-pattern-programs-and-optional-refs${queryParam}`
      ),
    dispatchOnSuccess: dispatchOnSuccess,
    httpErrorTypes
  };
};

export const loadInvoiceReferences = programId => ({
  types: [LOAD_INVOICE_REFERENCES_REQUEST, LOAD_INVOICE_REFERENCES_SUCCESS, LOAD_INVOICE_REFERENCES_FAILURE],
  callAPI: (state, { customerId, userId }) =>
    api.fetch(
      `invoicereferencesvc/v0/cust/${customerId}/user/${userId}/get-refs-types-for-invoice-pattern/${programId}`
    ),
  httpErrorTypes
});

export const createInvoicePattern = (payload, dispatchOnSuccess = []) => ({
  types: [CREATE_INVOICE_PATTERN_REQUEST, CREATE_INVOICE_PATTERN_SUCCESS, CREATE_INVOICE_PATTERN_FAILURE],
  callAPI: (state, { customerId, userId }) =>
    api.save(`invoicereferencesvc/v0/cust/${customerId}/user/${userId}/save-new-pattern`, payload),
  dispatchOnSuccess: dispatchOnSuccess,
  httpErrorTypes,
  errorDisplayType: 'modal',
  errorTitle: i18n.t('invoicePattern.errors.unableToCreate')
});

export const updateInvoicePattern = (payload, dispatchOnSuccess = []) => ({
  types: [UPDATE_INVOICE_PATTERN_REQUEST, UPDATE_INVOICE_PATTERN_SUCCESS, UPDATE_INVOICE_PATTERN_FAILURE],
  callAPI: (state, { customerId, userId }) =>
    api.save(`invoicereferencesvc/v0/cust/${customerId}/user/${userId}/update-pattern`, payload),
  dispatchOnSuccess: dispatchOnSuccess,
  httpErrorTypes,
  errorDisplayType: 'modal',
  errorTitle: i18n.t('invoicePattern.errors.unableToEdit')
});
