import React, { useEffect } from 'react';
import { useForm } from 'react-final-form';
import { IPD_PAGES } from './utils';

const FormManager = props => {
  const { getState } = useForm();

  useEffect(() => {
    return () => {
      const { values = {} } = getState();

      if (IPD_PAGES[props.history.location.pathname]) {
        sessionStorage.setItem('currentInitialValues', JSON.stringify(values));
      } else {
        sessionStorage.removeItem('currentInitialValues');
      }
    };
  }, [props.history.location.pathname]);

  return null;
};

export default FormManager;
