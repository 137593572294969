import {
  CHECK_BATCH_IN_PROGRESS_FAILURE,
  CHECK_BATCH_IN_PROGRESS_REQUEST,
  CHECK_BATCH_IN_PROGRESS_SUCCESS,
  CLEAR_BATCH_IN_PROGRESS_RESPONSE,
  DELETE_BATCHES_FAILURE,
  DELETE_BATCHES_REQUEST,
  DELETE_BATCHES_SUCCESS,
  LOAD_CAPTURE_RESULTS_PROGRAMS_FILTER_FAILURE,
  LOAD_CAPTURE_RESULTS_PROGRAMS_FILTER_SUCCESS,
  LOAD_CAPTURERESULTS_FAILURE,
  LOAD_CAPTURERESULTS_REQUEST,
  LOAD_CAPTURERESULTS_SUCCESS,
  LOAD_EXPORT_FAILURE,
  LOAD_EXPORT_REQUEST,
  LOAD_EXPORT_SUCCESS,
  LOAD_SUBMISSION_CONF_FAILURE,
  LOAD_SUBMISSION_CONF_REQUEST,
  LOAD_SUBMISSION_CONF_SUCCESS,
  RESET_CAPTURERESULTS,
  SUBMIT_BATCHES_FAILURE,
  SUBMIT_BATCHES_REQUEST,
  SUBMIT_BATCHES_SUCCESS
} from './actions';
import i18n from 'i18n';

export const initialState = {
  error: null,
  exportData: null,
  isProcessing: false,
  message: '',
  errorMessage: ''
};

const captureResultsRequest = (state = initialState) => {
  return {
    ...state,
    message: initialState.message,
    isFetching: true
  };
};

const captureResultsSuccess = (
  state,
  {
    response: {
      data: { captureResults, ...rest }
    }
  }
) => {
  return {
    ...state,
    batches: captureResults,
    ...rest,
    message: '',
    isFetching: false
  };
};

// Ties to errors via helper function
// Requires specific structure
const captureResultsFailure = (
  state,
  {
    error: {
      data: { message }
    }
  }
) => {
  return {
    ...state,
    batches: null,
    grandTotal: [],
    totalRows: 0,
    message,
    isFetching: false
  };
};

const resetCaptureResults = (state, action) => {
  return {
    ...initialState
  };
};

// Check BIP
const checkBatchInProgressRequest = (state = initialState) => ({
  ...state,
  ...initialState,
  isProcessing: true,
  batchProgressRes: {}
});

const checkBatchInProgressSuccess = (
  state = initialState,
  {
    batchUniqueId,
    response: {
      data: { message }
    }
  }
) => {
  return {
    ...state,
    batchProgressRes: {
      batchUniqueId,
      message
    }
  };
};

const checkBatchInProgressFailure = (state = initialState, { error }) => {
  return {
    ...state,
    error
  };
};

const clearBatchInProgressResponse = (state, action) => {
  return { ...state, batchProgressRes: {} };
};

// Delete Batch
const deleteBatchRequest = (state = initialState, action) => {
  return {
    ...state,
    isProcessing: true
  };
};

const deleteBatchSuccess = (state, action) => {
  return {
    ...state,
    responses: {
      ...action.response.data,
      type: 'delete'
    },
    isProcessing: false
  };
};

const deleteBatchFailure = (state, { error }) => {
  return {
    ...state,
    error,
    isProcessing: false
  };
};

// Export
const loadExportRequest = state => {
  return {
    ...state,
    isFetchingExport: true,
    exportError: null,
    exportData: null
  };
};

const loadExportSuccess = (state, { response: exportData }) => {
  return {
    ...state,
    exportData,
    isFetchingExport: false
  };
};

const loadExportFailure = (state, { error }) => {
  return {
    ...state,
    error,
    isFetchingExport: false
  };
};

const captureResultsProgramsFilterSuccess = (
  state,
  {
    response: {
      data: { programs: programsData }
    }
  }
) => {
  const { programs, ...rest } = state.errors.filter;
  return {
    ...state,
    programs: programsData,
    errors: {
      filter: rest
    },
    isFetchingLbx: false
  };
};

const captureResultsProgramsFilterFailure = (state, action) => {
  return {
    ...state,
    errors: {
      filter: {
        ...initialState.errors?.filter,
        message: action.error.message
      }
    },
    programs: [],
    isFetchingLbx: false
  };
};

const submitBatchRequest = state => {
  return {
    ...state,
    message: '',
    errorMessage: '',
    isProcessing: true
  };
};

const submitBatchSuccess = (state, action) => {
  return {
    ...state,
    ...action.response,
    responses: {
      ...action.response.data,
      type: 'submit'
    },
    isProcessing: false
  };
};

const submitBatchFailure = (state, { error }) => ({
  ...state,
  ...initialState,
  error
});

const loadSubmissionConfirmationRequest = (state, action) => ({
  ...state,
  isSubmissionConfirmationLoading: true
});

const loadSubmissionConfirmationSuccess = (state, action) => ({
  ...state,
  isSubmissionConfirmationLoading: false,
  submissionConfirmationData: action.response
});

const loadSubmissionConfirmationFailure = (state, action) => ({
  ...state,
  isSubmissionConfirmationLoading: false,
  error: {
    type: action.error.type,
    message: i18n.t('captureHistory.messages.unableToLoadSubmissionConfirmation')
  }
});

export default function captureResultsReducer(state = initialState, action) {
  const handlers = {
    [LOAD_CAPTURERESULTS_REQUEST]: captureResultsRequest,
    [LOAD_CAPTURERESULTS_SUCCESS]: captureResultsSuccess,
    [LOAD_CAPTURERESULTS_FAILURE]: captureResultsFailure,
    [RESET_CAPTURERESULTS]: resetCaptureResults,
    [DELETE_BATCHES_REQUEST]: deleteBatchRequest,
    [DELETE_BATCHES_SUCCESS]: deleteBatchSuccess,
    [DELETE_BATCHES_FAILURE]: deleteBatchFailure,
    [CHECK_BATCH_IN_PROGRESS_REQUEST]: checkBatchInProgressRequest,
    [CHECK_BATCH_IN_PROGRESS_SUCCESS]: checkBatchInProgressSuccess,
    [CHECK_BATCH_IN_PROGRESS_FAILURE]: checkBatchInProgressFailure,
    [CLEAR_BATCH_IN_PROGRESS_RESPONSE]: clearBatchInProgressResponse,
    [LOAD_CAPTURE_RESULTS_PROGRAMS_FILTER_SUCCESS]: captureResultsProgramsFilterSuccess,
    [LOAD_CAPTURE_RESULTS_PROGRAMS_FILTER_FAILURE]: captureResultsProgramsFilterFailure,
    [SUBMIT_BATCHES_REQUEST]: submitBatchRequest,
    [SUBMIT_BATCHES_SUCCESS]: submitBatchSuccess,
    [SUBMIT_BATCHES_FAILURE]: submitBatchFailure,
    [LOAD_EXPORT_REQUEST]: loadExportRequest,
    [LOAD_EXPORT_SUCCESS]: loadExportSuccess,
    [LOAD_EXPORT_FAILURE]: loadExportFailure,
    [LOAD_SUBMISSION_CONF_REQUEST]: loadSubmissionConfirmationRequest,
    [LOAD_SUBMISSION_CONF_SUCCESS]: loadSubmissionConfirmationSuccess,
    [LOAD_SUBMISSION_CONF_FAILURE]: loadSubmissionConfirmationFailure
  };
  if (handlers.hasOwnProperty(action.type)) {
    return handlers[action.type](state, action);
  } else {
    return state;
  }
}
