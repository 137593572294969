import api from 'api';
import { httpErrorTypes } from 'utility/error';
import { handleDownloadFile, getFileName } from 'utility/downloadUtility';
import { CONTENT_TYPE } from 'Constants/headers';
import { ENTITIES_REQUIRING_ACTIVATION } from 'Constants/routes';

export const CLEAR_ENTITY_DETAILS = 'CLEAR_ENTITY_DETAILS';
export const CLEAR_ENTITY_LIST = 'CLEAR_ENTITY_LIST';
export const ASSIGN_ENTITIES_REQUEST = 'ASSIGN_ENTITIES_REQUEST';
export const ASSIGN_ENTITIES_SUCCESS = 'ASSIGN_ENTITIES_SUCCESS';
export const ASSIGN_ENTITIES_FAILURE = 'ASSIGN_ENTITIES_FAILURE';
export const LOAD_ASSIGNABLE_USERS_REQUEST = 'LOAD_ASSIGNABLE_USERS_REQUEST';
export const LOAD_ASSIGNABLE_USERS_SUCCESS = 'LOAD_ASSIGNABLE_USERS_SUCCESS';
export const LOAD_ASSIGNABLE_USERS_FAILURE = 'LOAD_ASSIGNABLE_USERS_FAILURE';
export const LOAD_ENTITY_DETAILS_REQUEST = 'LOAD_ENTITY_DETAILS_REQUEST';
export const LOAD_ENTITY_DETAILS_SUCCESS = 'LOAD_ENTITY_DETAILS_SUCCESS';
export const LOAD_ENTITY_DETAILS_FAILURE = 'LOAD_ENTITY_DETAILS_FAILURE';
export const LOAD_ENTITY_REQUEST = 'LOAD_ENTITY_REQUEST';
export const LOAD_ENTITY_SUCCESS = 'LOAD_ENTITY_SUCCESS';
export const LOAD_ENTITY_FAILURE = 'LOAD_ENTITY_FAILURE';
export const LOAD_ENTITY_FILTER_INFO_REQUEST = 'LOAD_ENTITY_FILTER_INFO_REQUEST';
export const LOAD_ENTITY_FILTER_INFO_SUCCESS = 'LOAD_ENTITY_FILTER_INFO_SUCCESS';
export const LOAD_ENTITY_FILTER_INFO_FAILURE = 'LOAD_ENTITY_FILTER_INFO_FAILURE';
export const EXPORT_ENTITY_REQUEST = 'EXPORT_ENTITY_REQUEST';
export const EXPORT_ENTITY_SUCCESS = 'EXPORT_ENTITY_SUCCESS';
export const EXPORT_ENTITY_FAILURE = 'EXPORT_ENTITY_FAILURE';
export const SAVE_ENTITY_DETAILS_REQUEST = 'SAVE_ENTITY_DETAILS_REQUEST';
export const SAVE_ENTITY_DETAILS_SUCCESS = 'SAVE_ENTITY_DETAILS_SUCCESS';
export const SAVE_ENTITY_DETAILS_FAILURE = 'SAVE_ENTITY_DETAILS_FAILURE';
export const DOWNLOAD_ENTITY_TEMPLATE_REQUEST = 'DOWNLOAD_ENTITY_TEMPLATE_REQUEST';
export const DOWNLOAD_ENTITY_TEMPLATE_SUCCESS = 'DOWNLOAD_ENTITY_TEMPLATE_SUCCESS';
export const DOWNLOAD_ENTITY_TEMPLATE_FAILURE = 'DOWNLOAD_ENTITY_TEMPLATE_FAILURE';
export const CLEAR_ENTITY_TEMPLATE_STATE = 'CLEAR_ENTITY_TEMPLATE_STATE';
export const CHANGE_ENTITY_STATUS_REQUEST = 'CHANGE_ENTITY_STATUS_REQUEST';
export const CHANGE_ENTITY_STATUS_SUCCESS = 'CHANGE_ENTITY_STATUS_SUCCESS';
export const CHANGE_ENTITY_STATUS_FAILURE = 'CHANGE_ENTITY_STATUS_FAILURE';
export const UPLOAD_ENTITY_FILE_REQUEST = 'UPLOAD_ENTITY_FILE_REQUEST';
export const UPLOAD_ENTITY_FILE_SUCCESS = 'UPLOAD_ENTITY_FILE_SUCCESS';
export const UPLOAD_ENTITY_FILE_FAILURE = 'UPLOAD_ENTITY_FILE_FAILURE';
export const SAVE_ENTITY_FILE_REQUEST = 'SAVE_ENTITY_FILE_REQUEST';
export const SAVE_ENTITY_FILE_SUCCESS = 'SAVE_ENTITY_FILE_SUCCESS';
export const SAVE_ENTITY_FILE_FAILURE = 'SAVE_ENTITY_FILE_FAILURE';
export const RESET_ENTITY_STATE = 'RESET_ENTITY_STATE';
export const CUSTOMER_CLASSIFICATION_REQUEST = 'CUSTOMER_CLASSIFICATION_REQUEST';
export const CUSTOMER_CLASSIFICATION_SUCCESS = 'CUSTOMER_CLASSIFICATION_SUCCESS';
export const CUSTOMER_CLASSIFICATION_FAILURE = 'CUSTOMER_CLASSIFICATION_FAILURE';
export const SAVE_CUSTOMER_CLASSIFICATION_REQUEST = 'SAVE_CUSTOMER_CLASSIFICATION_REQUEST';
export const SAVE_CUSTOMER_CLASSIFICATION_SUCCESS = 'SAVE_CUSTOMER_CLASSIFICATION_SUCCESS';
export const SAVE_CUSTOMER_CLASSIFICATION_FAILURE = 'SAVE_CUSTOMER_CLASSIFICATION_FAILURE';
export const ACTIVATE_CUSTOMER_CLASSIFICATION_REQUEST = 'ACTIVATE_CUSTOMER_CLASSIFICATION_REQUEST';
export const ACTIVATE_CUSTOMER_CLASSIFICATION_SUCCESS = 'ACTIVATE_CUSTOMER_CLASSIFICATION_SUCCESS';
export const ACTIVATE_CUSTOMER_CLASSIFICATION_FAILURE = 'ACTIVATE_CUSTOMER_CLASSIFICATION_FAILURE';
export const CREATE_DEPOSIT_SLIP_REQUEST = 'CREATE_DEPOSIT_SLIP_REQUEST';
export const CREATE_DEPOSIT_SLIP_SUCCESS = 'CREATE_DEPOSIT_SLIP_SUCCESS';
export const CREATE_DEPOSIT_SLIP_FAILURE = 'CREATE_DEPOSIT_SLIP_FAILURE';
export const LOAD_ASSIGN_USERS_BY_PROGRAM_REQUEST = 'LOAD_ASSIGN_USERS_BY_PROGRAM_REQUEST';
export const LOAD_ASSIGN_USERS_BY_PROGRAM_SUCCESS = 'LOAD_ASSIGN_USERS_BY_PROGRAM_SUCCESS';
export const LOAD_ASSIGN_USERS_BY_PROGRAM_FAILURE = 'LOAD_ASSIGN_USERS_BY_PROGRAM_FAILURE';
export const DELETE_ENTITY_REQUEST = 'DELETE_ENTITY_REQUEST';
export const DELETE_ENTITY_SUCCESS = 'DELETE_ENTITY_SUCCESS';
export const DELETE_ENTITY_FAILURE = 'DELETE_ENTITY_FAILURE';
export const INVALID_RDR = 'INVALID_RDR';
export const RESET_RDR_ERROR = 'RESET_RDR_ERROR';

import { payerEntityURL, payerEntityWorkflowURL, customerClassificationURL, usersURL } from 'routes/Entity/utils';
import i18n from 'i18n';
import { CREATE } from './EntityDetails/modeTypes';

export const clearEntityList = () => ({
  type: CLEAR_ENTITY_LIST
});

export const clearEntityDetails = () => ({ type: CLEAR_ENTITY_DETAILS });

export const clearEntityTemplateState = () => ({
  type: CLEAR_ENTITY_TEMPLATE_STATE
});

export const resetRdrError = () => ({ type: RESET_RDR_ERROR });

const handleDownloadTemplate = () => (dispatch, getState) => {
  const templateData = getState()?.entity?.templateData;
  if (templateData?.data) {
    const type = templateData.request.getResponseHeader(CONTENT_TYPE);
    const filename = getFileName(templateData, 'Template.csv');
    handleDownloadFile(templateData.data, filename, type);
    return dispatch(clearEntityTemplateState());
  }
};

export const assignEntities = (payload, dispatchOnSuccess = []) => ({
  types: [ASSIGN_ENTITIES_REQUEST, ASSIGN_ENTITIES_SUCCESS, ASSIGN_ENTITIES_FAILURE],
  callAPI: (state, { customerId, userId }) => api.save(payerEntityWorkflowURL('entity-assign'), payload),
  dispatchOnSuccess: dispatchOnSuccess,
  successDisplayType: 'toast',
  successTitle: i18n.t('entityList.titles.success'),
  successMessage: i18n.t('entityList.messages.assigned'),
  httpErrorTypes,
  errorDisplayType: 'modal',
  errorTitle: i18n.t('entityList.errors.unableToAssign')
});

export const loadEntityList = payload => ({
  types: [LOAD_ENTITY_REQUEST, LOAD_ENTITY_SUCCESS, LOAD_ENTITY_FAILURE],
  callAPI: (state, options) => api.save(payerEntityURL(`entity-search`), payload),
  httpErrorTypes
});

export const loadEntityFilterInfo = action => ({
  types: [LOAD_ENTITY_FILTER_INFO_REQUEST, LOAD_ENTITY_FILTER_INFO_SUCCESS, LOAD_ENTITY_FILTER_INFO_FAILURE],
  callAPI: (state, options) => api.fetch(payerEntityURL(action ? `entity-filter?action=${action}` : `entity-filter`)),
  httpErrorTypes
});

export const loadEntityDetails = entityId => ({
  types: [LOAD_ENTITY_DETAILS_REQUEST, LOAD_ENTITY_DETAILS_SUCCESS, LOAD_ENTITY_DETAILS_FAILURE],
  callAPI: (state, options) => api.fetch(payerEntityURL(`${entityId}`)),
  httpErrorTypes
});

export const loadAssignableUsers = data => {
  const payload = data ? data : { disabledIndicator: 0, userRoleIds: [32] };
  return {
    types: [LOAD_ASSIGNABLE_USERS_REQUEST, LOAD_ASSIGNABLE_USERS_SUCCESS, LOAD_ASSIGNABLE_USERS_FAILURE],
    callAPI: (state, options) => api.save(usersURL(`get-users-by-filter`), payload),
    httpErrorTypes,
    errorDisplayType: 'modal',
    errorTitle: i18n.t('entityList.assignDialog.title')
  };
};

export const loadAssignableUsersByProgram = programId => {
  return {
    types: [
      LOAD_ASSIGN_USERS_BY_PROGRAM_REQUEST,
      LOAD_ASSIGN_USERS_BY_PROGRAM_SUCCESS,
      LOAD_ASSIGN_USERS_BY_PROGRAM_FAILURE
    ],
    callAPI: (state, options) => api.fetch(customerClassificationURL(`program/${programId}/workflow/entity-users`)),
    httpErrorTypes
  };
};

export const exportEntity = (payload, path) => {
  const action = path === ENTITIES_REQUIRING_ACTIVATION ? '?action=ACTIVATION' : '';
  return {
    types: [EXPORT_ENTITY_REQUEST, EXPORT_ENTITY_SUCCESS, EXPORT_ENTITY_FAILURE],
    callAPI: (state, options) => api.save(payerEntityURL(`export/entity-data${action}`), payload),
    httpErrorTypes,
    errorDisplayType: 'modal',
    errorTitle: i18n.t('entityList.errors.unableToExport')
  };
};
export const saveEntity = (payload, action) => ({
  types: [SAVE_ENTITY_DETAILS_REQUEST, SAVE_ENTITY_DETAILS_SUCCESS, SAVE_ENTITY_DETAILS_FAILURE],
  callAPI: (state, options) => api.save(payerEntityURL(`save-entity?action=` + action), payload),
  successCase: response => response.data.errorCode === 0,
  successCaseNotMet: response =>
    response.data.errorCode === 11
      ? { errorType: INVALID_RDR, noModal: true }
      : { type: SAVE_ENTITY_DETAILS_FAILURE, message: response.data.message },
  successDisplayType: 'toast',
  successTitle: i18n.t('entityList.titles.success'),
  httpErrorTypes,
  errorDisplayType: 'modal',
  errorTitle: i18n.t('entityList.errors.unableToSave')
});
export const downloadTemplate = () => ({
  types: [DOWNLOAD_ENTITY_TEMPLATE_REQUEST, DOWNLOAD_ENTITY_TEMPLATE_SUCCESS, DOWNLOAD_ENTITY_TEMPLATE_FAILURE],
  callAPI: (state, options) => api.fetch(payerEntityURL(`download-template`)),
  dispatchOnSuccess: [handleDownloadTemplate],
  httpErrorTypes,
  errorDisplayType: 'modal',
  errorTitle: i18n.t('entityList.errors.unableToDownload')
});
export const changeEntityStatus = (action, payload) => ({
  types: [CHANGE_ENTITY_STATUS_REQUEST, CHANGE_ENTITY_STATUS_SUCCESS, CHANGE_ENTITY_STATUS_FAILURE],
  callAPI: (state, options) => api.save(payerEntityURL(`${action}`), payload),
  dispatchOnSuccess: [() => loadEntityDetails(payload.entityIds)],
  successDisplayType: 'toast',
  successTitle: i18n.t('entityList.titles.success'),
  httpErrorTypes,
  errorDisplayType: 'modal',
  errorTitle: i18n.t('entityList.errors.unableToChangeStatus')
});

export const uploadEntityFile = file => ({
  types: [UPLOAD_ENTITY_FILE_REQUEST, UPLOAD_ENTITY_FILE_SUCCESS, UPLOAD_ENTITY_FILE_FAILURE],
  callAPI: (state, options) => api.postWithFile(payerEntityURL(`validate-entity-details`), file),
  httpErrorTypes
});
export const saveEntityFile = data => ({
  types: [SAVE_ENTITY_FILE_REQUEST, SAVE_ENTITY_FILE_SUCCESS, SAVE_ENTITY_FILE_FAILURE],
  callAPI: (state, options) => api.postWithFile(payerEntityURL(`save-entity-details`), data),
  httpErrorTypes,
  errorDisplayType: 'modal',
  errorTitle: i18n.t('entityList.errors.unableToSaveEntityFile')
});
export const resetUploadEntityState = () => ({ type: RESET_ENTITY_STATE });

export const getCustomerClassification = () => {
  return {
    types: [CUSTOMER_CLASSIFICATION_REQUEST, CUSTOMER_CLASSIFICATION_SUCCESS, CUSTOMER_CLASSIFICATION_FAILURE],
    callAPI: (state, options) => api.fetch(customerClassificationURL(`view-customer-classifications`)),
    httpErrorTypes
  };
};

export const saveCustomerClassification = (payload, action, dispatchOnSuccess = []) => {
  const mode =
    action === CREATE
      ? i18n.t('customerClassification.labels.created')
      : i18n.t('customerClassification.labels.edited');
  return {
    types: [
      SAVE_CUSTOMER_CLASSIFICATION_REQUEST,
      SAVE_CUSTOMER_CLASSIFICATION_SUCCESS,
      SAVE_CUSTOMER_CLASSIFICATION_FAILURE
    ],
    callAPI: (state, options) =>
      api.save(customerClassificationURL(`save-customer-classification?action=${action}`), payload),
    dispatchOnSuccess: dispatchOnSuccess,
    successDisplayType: 'toast',
    successTitle: i18n.t('callApiMiddleWare.success'),
    successMessage: i18n.t('customerClassification.labels.saveMessage', { name: payload.name, modeOption: mode }),
    httpErrorTypes,
    errorDisplayType: 'modal',
    errorTitle: i18n.t('entityList.errors.unableToAction', { action })
  };
};

export const activateCustomerClassification = (custClassId, status) => {
  const action = status === 0 ? 'activate' : 'deactivate';
  return {
    types: [
      ACTIVATE_CUSTOMER_CLASSIFICATION_REQUEST,
      ACTIVATE_CUSTOMER_CLASSIFICATION_SUCCESS,
      ACTIVATE_CUSTOMER_CLASSIFICATION_FAILURE
    ],
    callAPI: (state, options) =>
      api.save(customerClassificationURL(`custClass/${custClassId}?activeStatus=${status === 0}`)),
    httpErrorTypes,
    errorDisplayType: 'modal',
    errorTitle: i18n.t('entityList.errors.unableToAction', { action })
  };
};
export const createDepositSlip = payload => {
  return {
    types: [CREATE_DEPOSIT_SLIP_REQUEST, CREATE_DEPOSIT_SLIP_SUCCESS, CREATE_DEPOSIT_SLIP_FAILURE],
    callAPI: (state, options) => api.save(payerEntityURL('generate-ref-deposit'), payload),
    successDisplayType: 'toast',
    successTitle: i18n.t('entityDetails.dialogTitle.created'),
    httpErrorTypes,
    errorDisplayType: 'modal',
    errorTitle: i18n.t('entityList.errors.unableToCreate'),
    successCase: response => response.data.errorCode === 0,
    successCaseNotMet: response => {
      return {
        type: CREATE_DEPOSIT_SLIP_FAILURE,
        message: response.data.errorMessage || i18n.t('entityList.errors.unexpected')
      };
    }
  };
};
export const deleteEntities = entityIds => {
  return {
    types: [DELETE_ENTITY_REQUEST, DELETE_ENTITY_SUCCESS, DELETE_ENTITY_FAILURE],
    callAPI: (state, options) => api.save(customerClassificationURL(`entity/delete`), { entityIds: entityIds }),
    successDisplayType: 'toast',
    successTitle: i18n.t('entityList.titles.success'),
    httpErrorTypes,
    errorDisplayType: 'modal',
    errorTitle: i18n.t('entityList.errors.unableToDelete')
  };
};
