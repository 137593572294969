import {
  CLEAR_ALERT_INBOX,
  LOAD_ALERT_COUNT_FAILURE,
  LOAD_ALERT_COUNT_REQUEST,
  LOAD_ALERT_COUNT_SUCCESS,
  LOAD_ALERT_INBOX_FAILURE,
  LOAD_ALERT_INBOX_REQUEST,
  LOAD_ALERT_INBOX_SUCCESS,
  LOAD_TRANSACTION_IDS_REQUEST,
  LOAD_TRANSACTION_IDS_SUCCESS,
  LOAD_TRANSACTION_IDS_FAILURE,
  CLEAR_TRANSACTION_IDS,
  MARK_AS_UNREAD_READ_FAILURE,
  MARK_AS_UNREAD_READ_REQUEST,
  MARK_AS_UNREAD_READ_SUCCESS,
  LOAD_ALERT_FILTER_FAILURE,
  LOAD_ALERT_FILTER_REQUEST,
  LOAD_ALERT_FILTER_SUCCESS,
  LOAD_ALERT_SETTING_FAILURE,
  LOAD_ALERT_SETTING_REQUEST,
  LOAD_ALERT_SETTING_SUCCESS
} from 'routes/Alerts/alertsActions/alertsInboxActions';

export const initialState = {
  alertInbox: [],
  alertTransactions: {},
  filterData: [],
  isFetchingAlertInbox: true,
  isFetchingAlertFilter: true,
  isFetchingTransactionIds: true,
  inboxErrors: {},
  settingData: false,
  settingErrors: {}
};

const clearAlertInbox = (state, action) => {
  return {
    ...initialState,
    alertCount: state.alertCount
  };
};

const loadAlertCountRequest = state => {
  return {
    ...state,
    countErrors: {},
    isFetchingAlertCount: true
  };
};

const loadAlertCountSuccess = (state, action) => {
  return {
    ...state,
    countErrors: {},
    isFetchingAlertCount: false,
    alertCount: action.response.data.unreadAlertCount
  };
};

const loadAlertCountFailure = (state, action) => {
  return {
    ...state,
    countErrors: action.error,
    isFetchingAlertCount: false
  };
};

const loadAlertInboxRequest = (state = initialState) => {
  return {
    ...state,
    isFetchingAlertInbox: true
  };
};

const loadAlertInboxSuccess = (state, action) => {
  const alertInbox = action.response.data.alertNotifications.map(item => ({
    ...item,
    alertTypeDesc: item.type.alertTypeDesc,
    alertOriginatorName: item.originator.alertOriginatorName,
    alertTypeId: item.type.id
  }));

  return {
    ...state,
    inboxErrors: {},
    isFetchingAlertInbox: false,
    alertInbox
  };
};

const loadAlertInboxFailure = (state, action) => {
  return {
    ...state,
    inboxErrors: action.error,
    isFetchingAlertInbox: false,
    alertInbox: []
  };
};

const loadTransactionIdsRequest = (state = initialState, action) => {
  return {
    ...state,
    typeId: action.typeId,
    isFetchingTransactionIds: true
  };
};

const loadTransactionIdsSuccess = (state, action) => {
  return {
    ...state,
    isFetchingTransactionIds: false,
    alertTransactions: action.response.data
  };
};

const loadTransactionIdsFailure = (state, action) => {
  return {
    ...state,
    inboxErrors: action.error,
    isFetchingTransactionIds: false,
    alertTransactions: {}
  };
};

const clearTransactionIds = (state, action) => {
  return {
    ...state,
    isFetchingTransactionIds: false,
    transactionIds: []
  };
};
const markAsUnreadReadRequest = state => {
  return {
    ...state,
    isSavingMarkAsUnread: true
  };
};

const markAsUnreadReadSuccess = (state, action) => {
  return {
    ...state,
    inboxErrors: {},
    isSavingMarkAsUnread: false
  };
};

const markAsUnreadReadFailure = (state, action) => {
  return {
    ...state,
    inboxErrors: action.error,
    isFetchingAlertInbox: false
  };
};

const loadAlertFilterRequest = state => {
  return {
    ...state,
    filterErrors: {},
    isFetchingAlertFilter: true
  };
};

const loadAlertFilterSuccess = (state, action) => {
  return {
    ...state,
    filterErrors: {},
    isFetchingAlertFilter: false,
    filterData: action.response.data
  };
};

const loadAlertFilterFailure = (state, action) => {
  return {
    ...state,
    filterErrors: action.error,
    isFetchingAlertFilter: false
  };
};

const loadAlertSettingRequest = state => {
  return {
    ...state,
    settingErrors: {},
    isFetchingAlertSetting: true
  };
};

const loadAlertSettingSuccess = (state, action) => {
  return {
    ...state,
    settingErrors: {},
    isFetchingAlertSetting: false,
    settingData: action.response.data.manageAlertsEntitledUser
  };
};

const loadAlertSettingFailure = (state, action) => {
  return {
    ...state,
    settingErrors: action.error,
    isFetchingAlertSetting: false
  };
};

export const inboxAlertReducer = {
  [CLEAR_ALERT_INBOX]: clearAlertInbox,
  [LOAD_ALERT_COUNT_REQUEST]: loadAlertCountRequest,
  [LOAD_ALERT_COUNT_SUCCESS]: loadAlertCountSuccess,
  [LOAD_ALERT_COUNT_FAILURE]: loadAlertCountFailure,
  [LOAD_ALERT_INBOX_REQUEST]: loadAlertInboxRequest,
  [LOAD_ALERT_INBOX_SUCCESS]: loadAlertInboxSuccess,
  [LOAD_ALERT_INBOX_FAILURE]: loadAlertInboxFailure,
  [LOAD_TRANSACTION_IDS_REQUEST]: loadTransactionIdsRequest,
  [LOAD_TRANSACTION_IDS_SUCCESS]: loadTransactionIdsSuccess,
  [LOAD_TRANSACTION_IDS_FAILURE]: loadTransactionIdsFailure,
  [CLEAR_TRANSACTION_IDS]: clearTransactionIds,
  [MARK_AS_UNREAD_READ_REQUEST]: markAsUnreadReadRequest,
  [MARK_AS_UNREAD_READ_SUCCESS]: markAsUnreadReadSuccess,
  [MARK_AS_UNREAD_READ_FAILURE]: markAsUnreadReadFailure,
  [LOAD_ALERT_FILTER_REQUEST]: loadAlertFilterRequest,
  [LOAD_ALERT_FILTER_SUCCESS]: loadAlertFilterSuccess,
  [LOAD_ALERT_FILTER_FAILURE]: loadAlertFilterFailure,
  [LOAD_ALERT_SETTING_REQUEST]: loadAlertSettingRequest,
  [LOAD_ALERT_SETTING_SUCCESS]: loadAlertSettingSuccess,
  [LOAD_ALERT_SETTING_FAILURE]: loadAlertSettingFailure
};
