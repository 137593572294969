import { ColumnLayout } from '@jpmuitk/column-layout';
import Button from 'Components/Button/ActionButton';
import GrandTotal from './GrandTotal';
import { withRouter } from 'react-router';
import useStyles, { gridBottomStyles } from './styles';
import GrandItemCount from './GrandItemCount';

const GridBottom = props => {
  const classes = useStyles();
  const { buttonsContent, grandTotal, history, selectedRowCount, totalRows, isLoading } = props;

  // Need to restore this
  const getCount = (selectedRowCount, maxAllowed) => {
    return maxAllowed ? `${selectedRowCount}/${maxAllowed}` : selectedRowCount;
  };

  const getDisabled = (disabled, selectedRowCount, maxAllowed) => {
    if (!maxAllowed) {
      return disabled;
    } else {
      return disabled || selectedRowCount > maxAllowed;
    }
  };

  const renderingButtons = buttons => {
    const route = history.location.pathname.slice(1);
    const title = route.replace(/\s/g, '');

    const buttonList = buttons.map(
      ({ id = '', name, ariaLabel, disabled, maxAllowed, actionButton, onClick, variant }, index) => {
        const testId = title + '_' + id.replace(/ /g, '').toLowerCase() + index + '_test';

        return (
          <Button
            key={testId}
            id={testId}
            data-test-id={testId}
            aria-label={
              typeof ariaLabel === 'function'
                ? ariaLabel(getCount(selectedRowCount, maxAllowed), selectedRowCount)
                : ariaLabel
            }
            disabled={getDisabled(disabled, selectedRowCount, maxAllowed)}
            actionButton={actionButton}
            onClick={onClick}
            variant={variant}
            style={{ marginRight: 6 }}
          >
            {typeof name === 'function' ? name(getCount(selectedRowCount, maxAllowed), selectedRowCount) : name}
          </Button>
        );
      }
    );

    return (
      <div role="toolbar" className={classes.toolbar}>
        {buttonList}
      </div>
    );
  };

  return (
    <ColumnLayout container spacing={6}>
      <ColumnLayout item xs style={{ paddingRight: 0 }}>
        {renderingButtons(buttonsContent)}
      </ColumnLayout>
      {!!totalRows && !isLoading && (
        <ColumnLayout
          item
          xs={5}
          sm={5}
          style={{ textAlign: 'right', paddingLeft: 0 }}
          role="region"
          aria-live="polite"
        >
          <GrandItemCount totalRowCount={totalRows} selectedRowCount={selectedRowCount} />
          {grandTotal?.length > 0 && <GrandTotal grandTotal={grandTotal} />}
        </ColumnLayout>
      )}
    </ColumnLayout>
  );
};

export default withRouter(GridBottom);
