import cssVariables from 'Constants/_variables.module.scss';
import { legend, list, errorMsg, formPadding } from 'styles';

export default {
  root: {
    flex: `0 0 ${cssVariables.sideBarWidth}`,
    padding: 0,
    boxShadow: `2px 0 2px ${cssVariables.grey50}`,
    zIndex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  // filter states
  loading: {
    justifyContent: 'center',
    spinner: {
      margin: '0 auto'
    }
  },
  advanced: {
    flex: 1,
    zIndex: 3,
    borderRight: 'none'
  },
  hidden: {
    flex: 0,
    visibility: 'hidden'
  },
  // filter parts
  header: {
    position: 'sticky',
    top: 0,
    backgroundColor: 'white',
    display: 'flex',
    // margin: `0 ${cssVariables.space1}`,
    // Margin breaks background color continuity
    padding: `${cssVariables.space2} ${cssVariables.space1}`,
    '& h2': {
      margin: 0,
      marginRight: 'auto'
    },
    zIndex: 1
  },
  buttons: {
    display: 'flex',
    ...list
  },

  // LAL create a form component
  // Form buttons
  // Move to form withStyle, will allow for simpler page styling
  filterButtons: {
    zIndex: 100,
    position: 'sticky',
    bottom: 0,
    margin: `0 ${cssVariables.space1}`,
    padding: `${cssVariables.space2} 0`,
    backgroundColor: 'white',
    borderTop: '1px solid #C5C9D0'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1
  },
  columnLayoutContainer: {
    margin: 0,
    padding: '0 12px',
    width: '100%'
  },
  columnLayoutSubContainer: {
    margin: 0,
    padding: 0,
    width: '100%'
  },
  formPadding
};

export const filterDialogStyles = {
  root: {
    flexGrow: 1,
    margin: `0 auto`
  },
  '& .filter': {
    boxShadow: `2px 0 2px ${cssVariables.grey50}`
  },
  filterDialog: {
    display: 'flex',
    flexDirection: 'column',
    borderRight: `1px solid ${cssVariables.grey50}`
  },
  filterTitle: {
    position: 'sticky',
    top: 0,
    display: 'flex',
    flexDirection: 'row',
    boxSizing: 'border-box',
    '& h2': {
      marginTop: cssVariables.space2,
      marginBottom: cssVariables.space2,
      marginLeft: cssVariables.space1,
      width: '100%'
    }
  },
  filterContent: {
    display: 'flex',
    flexGrow: '1',
    flexDirection: 'column',
    padding: '0 24px',
    '& .errorMsg': errorMsg
  },
  btns: {
    margin: '0',
    marginTop: cssVariables.space2,
    marginRight: cssVariables.space1,
    display: 'flex',
    flexDirection: 'row'
  },
  item: {
    padding: `0 ${cssVariables.space1}`,
    paddingTop: '0',
    margin: `${cssVariables.space2} 0 0 0`,
    '& .checkboxLabel': {
      '& input': {
        marginTop: cssVariables.space3,
        marginRight: cssVariables.space3,
        marginLeft: 0
      },
      '& input:focus': {
        outline: `2px dotted ${cssVariables.blue300}`
      }
    }
  },
  filterButtons: {
    margin: `${cssVariables.space2} ${cssVariables.space1}`,
    boxSizing: 'border-box'
  }
};

export const ValueDateStyles = {
  legend
};

export const TransactionDateRange = {
  rangePicker: {
    marginTop: cssVariables.space2
  }
};

export const TextFieldFilterStyles = {
  legend: {
    height: '3px'
  },
  root: {
    '& .filterTextField': {
      display: 'flex',
      marginBottom: cssVariables.space2
    },
    '& .singleTextField': {
      marginTop: cssVariables.space2
    }
  }
};

export const FilterSubSectionStyles = {
  heading: {
    '& h3:not(:last-child)': {
      marginBottom: 0
    },
    '& .heading-error': {
      ...errorMsg,
      marginBottom: '1rem'
    }
  },
  field: {
    display: 'block!important'
  },
  subSectionWrapper: {
    margin: 0,
    padding: 0,
    width: '100%'
  }
};
