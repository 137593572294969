import Scanner from 'api/scanner';
import { SCAN, CAPTURE_DETAILS } from 'Constants/routes';
import {
  isSelectedScannerCaptureOne,
  isSelectedScannerSimulateMode,
  scanningErrorTypes,
  HANDLE_SCAN_ERROR,
  enqueue
} from './index';
import { twainDriverActionHref } from './connectToScannerDriverActions';
import i18n from 'i18next';
export const getTwainScannerSourcesErrorMessage = i18n.t('scanner.messages.noAvailableScanSources');
export const getTwainScannerSourcesActionMessage = i18n.t('scanner.messages.noAvailableScanSourcesAction');
export const selectedTwainScanSourceErrorMessage = i18n.t('scanner.messages.unableToConfirm');
export const selectedTwainScanSourceActionMessage = i18n.t('scanner.messages.pleaseTryAgain');
export const noSourcesWereFoundErrorMessage = i18n.t('scanner.messages.unableToGetAvailableSourcesAction');
export const noSourcesWereFoundActionMessage = i18n.t('scanner.messages.unableToGetAvailableSources');
export const noScannerSelectedActionMessage = i18n.t('scanner.messages.noScannerSelectedAction');
export const noScannerSelectedErrorMessage = i18n.t('scanner.messages.noScannerSelected');

export const MORE_THAN_ONE_AVAIL_SCAN_SOURCE = 'MORE_THAN_ONE_AVAIL_SCAN_SOURCE';
export const ONE_AVAIL_SCAN_SOURCE = 'ONE_AVAIL_SCAN_SOURCE';
export const SELECT_TWAIN_SCAN_SOURCE = 'SELECT_TWAIN_SCAN_SOURCE';
export const SELECT_TWAIN_SCAN_SOURCE_SUCCESS = 'SELECT_TWAIN_SCAN_SOURCE_SUCCESS';
export const CLEAR_SCAN_SOURCE = 'CLEAR_SCAN_SOURCE';

const getTwainScannerSourcesOnFailure = error => (dispatch, getState) => {
  dispatch({
    type: HANDLE_SCAN_ERROR,
    error: {
      errorType: scanningErrorTypes.GET_TWAIN_SOURCES,
      errorMessage: getTwainScannerSourcesErrorMessage,
      actionMessage: getTwainScannerSourcesActionMessage,
      actionLinkHref: '',
      actionLinkMessage: '',
      preventScanning: true
    }
  });
};

const getTwainScannerSourcesOnSuccess = response => (dispatch, getState) => {
  const sources = response.data;
  const numSources = sources.length;
  if (!Array.isArray(sources) || numSources === 0) {
    dispatch(getTwainScannerSourcesOnFailure(response));
  } else {
    if (numSources > 1) {
      dispatch({
        type: MORE_THAN_ONE_AVAIL_SCAN_SOURCE,
        twainScanSources: sources
      });
    } else {
      dispatch(selectTwainScanSource(sources[0].Name));
      dispatch({
        type: ONE_AVAIL_SCAN_SOURCE,
        twainScanSources: sources,
        selectedTwainScanSource: sources[0].Name
      });
    }
  }
};

export const getTwainScannerSources = () => (dispatch, getState) => {
  const data = {
    id: getState().scanControl.sessionId,
    userData: null,
    isCaptureOne: isSelectedScannerCaptureOne(getState)
  };
  if (isSelectedScannerCaptureOne(getState) || isSelectedScannerSimulateMode(getState)) {
    return;
  }
  let resolved = false;
  let rejected = false;
  let resp;
  let err;
  const interval = 2000;
  const waitForSources = (resolve, reject) => {
    if (resolved) {
      resolve(resp);
    } else if (rejected) {
      reject(err);
    } else {
      setTimeout(waitForSources, interval, resolve, reject);
    }
  };
  const onSuccess = response => {
    resolved = true;
    resp = response;
    dispatch(getTwainScannerSourcesOnSuccess(response));
  };
  const onFailure = error => {
    rejected = true;
    err = error;
    dispatch(getTwainScannerSourcesOnFailure(error));
  };
  dispatch(enqueue(Scanner.post('GetSources', { data, onSuccess, onFailure })));
  return new Promise(waitForSources);
};

export const selectTwainScanSource = (selectedSource, onSuccess = () => {}, onFailure = () => {}) => (
  dispatch,
  getState
) => {
  const data = {
    id: getState().scanControl.sessionId,
    sourceName: selectedSource,
    userData: null,
    isCaptureOne: isSelectedScannerCaptureOne(getState)
  };

  let resolved, rejected, resp, err;
  const interval = 2000;

  const waitForSourceSelection = (resolve, reject) => {
    if (resolved) {
      resolve(resp);
    } else if (rejected) {
      reject(err);
    } else {
      setTimeout(waitForSourceSelection, interval, resolve, reject);
    }
  };

  if (getState().scanControl.sourceConfirmed === false) {
    const handleSuccess = response => {
      resolved = true;
      resp = response;
      onSuccess(response);
      if (isSelectedScannerSimulateMode(getState)) {
        dispatch({ type: 'START_TWAIN_SESSION_FINISHED' });
      }
      dispatch({ type: SELECT_TWAIN_SCAN_SOURCE, selectedSource });
    };
    const handleFailure = error => {
      rejected = true;
      err = error;
      onFailure(error);
      dispatch({
        type: HANDLE_SCAN_ERROR,
        errorType: scanningErrorTypes.SELECT_TWAIN_SOURCE,
        errorMessage: selectedTwainScanSourceErrorMessage,
        actionMessage: selectedTwainScanSourceActionMessage,
        actionLinkHref: '',
        actionLinkMessage: '',
        preventScanning: true
      });
    };
    dispatch(enqueue(Scanner.post('SelectSource', { data, onSuccess: handleSuccess, onFailure: handleFailure })));
    return new Promise(waitForSourceSelection);
  }
};

export const setDuplexEnabled = () => (dispatch, getState) => {
  const duplexEnabledVal = 4115;
  const modeVal = 6;
  const value = 'true';
  const data = {
    capabilityType: duplexEnabledVal,
    id: getState().scanControl.sessionId,
    itemType: modeVal,
    userData: null,
    value,
    isCaptureOne: isSelectedScannerCaptureOne(getState)
  };
  dispatch(enqueue(Scanner.post('SetCapability', { data })));
};

export const clearScanSource = () => {
  return dispatch => {
    dispatch({
      type: CLEAR_SCAN_SOURCE
    });
  };
};

export const noScannerSelected = () => {
  return dispatch => {
    dispatch({
      type: HANDLE_SCAN_ERROR,
      error: {
        errorType: scanningErrorTypes.NO_SCANNER_SELECTED,
        errorMessage: noScannerSelectedErrorMessage,
        actionMessage: noScannerSelectedActionMessage,
        actionLinkHref: twainDriverActionHref,
        actionLinkMessage: i18n.t('scanner.messages.settings'),
        preventScanning: true
      }
    });
  };
};
