import {
  CLEAR_UPLOAD_REMITTANCE_ADVICES,
  DOWNLOAD_UPLOAD_REMITTANCE_ADVICES_TEMPLATE_REQUEST,
  DOWNLOAD_UPLOAD_REMITTANCE_ADVICES_TEMPLATE_SUCCESS,
  DOWNLOAD_UPLOAD_REMITTANCE_ADVICES_TEMPLATE_FAILURE,
  UPLOAD_REMITTANCES_FILE_REQUEST,
  UPLOAD_REMITTANCES_FILE_SUCCESS,
  UPLOAD_REMITTANCES_FILE_FAILURE,
  CLEAR_DOWNLOAD_UPLOAD_REMITTANCE_ADVICES_TEMPLATE_STATE,
  SAVE_REMITTANCES_REQUEST,
  SAVE_REMITTANCES_SUCCESS,
  SAVE_REMITTANCES_FAILURE
} from './uploadRemittanceAdvicesActions';

export const initialState = {
  errors: {},
  isFetchingTemplate: false,
  isRunningFileValidation: false,
  isSavingRemittances: false,
  templateData: undefined
};

const clearUploadRemittanceAdvices = (state, action) => initialState;

const downloadUploadRemittanceAdvicesTemplateRequest = (state, action) => ({
  ...state,
  errors: {},
  isFetchingTemplate: true,
  templateData: undefined
});

const downloadUploadRemittanceAdvicesTemplateSuccess = (state, action) => ({
  ...state,
  errors: {},
  isFetchingTemplate: false,
  templateData: action.response
});

const downloadUploadRemittanceAdvicesTemplateFailure = (state, action) => ({
  ...state,
  errors: { ...action.error, type: DOWNLOAD_UPLOAD_REMITTANCE_ADVICES_TEMPLATE_REQUEST },
  isFetchingTemplate: false,
  templateData: undefined
});

const uploadRemittancesFileRequest = (state, action) => ({
  ...state,
  errors: {},
  isRunningFileValidation: true,
  successToast: undefined,
  uploadErrors: undefined,
  uploadSuccess: undefined
});
const uploadRemittancesFileSuccess = (state, action) => ({
  ...state,
  errors: {},
  isRunningFileValidation: false,
  uploadErrors: undefined,
  uploadSuccess: action.response.data
});
const uploadRemittancesFileFailure = (state, action) => ({
  ...state,
  isRunningFileValidation: false,
  uploadErrors: action.error,
  uploadSuccess: undefined
});

const saveRemittancesRequest = (state, action) => ({
  ...state,
  errors: {},
  isSavingRemittances: true,
  successToast: undefined
});
const saveRemittancesSuccess = (state, action) => ({
  ...state,
  errors: {},
  successToast: action.response.data,
  isSavingRemittances: false
});
const saveRemittancesFailure = (state, action) => ({
  ...state,
  errors: action.error,
  successToast: undefined,
  isSavingRemittances: false
});

const clearDownloadUploadRemittanceAdvicesTemplateState = (state, action) => ({
  ...state,
  errors: {},
  isFetchingTemplate: false,
  templateData: undefined
});

export default function originalAdviceReducer(state = initialState, action) {
  const handlers = {
    [CLEAR_UPLOAD_REMITTANCE_ADVICES]: clearUploadRemittanceAdvices,
    [DOWNLOAD_UPLOAD_REMITTANCE_ADVICES_TEMPLATE_REQUEST]: downloadUploadRemittanceAdvicesTemplateRequest,
    [DOWNLOAD_UPLOAD_REMITTANCE_ADVICES_TEMPLATE_SUCCESS]: downloadUploadRemittanceAdvicesTemplateSuccess,
    [DOWNLOAD_UPLOAD_REMITTANCE_ADVICES_TEMPLATE_FAILURE]: downloadUploadRemittanceAdvicesTemplateFailure,
    [UPLOAD_REMITTANCES_FILE_REQUEST]: uploadRemittancesFileRequest,
    [UPLOAD_REMITTANCES_FILE_SUCCESS]: uploadRemittancesFileSuccess,
    [UPLOAD_REMITTANCES_FILE_FAILURE]: uploadRemittancesFileFailure,
    [SAVE_REMITTANCES_REQUEST]: saveRemittancesRequest,
    [SAVE_REMITTANCES_SUCCESS]: saveRemittancesSuccess,
    [SAVE_REMITTANCES_FAILURE]: saveRemittancesFailure,
    [CLEAR_DOWNLOAD_UPLOAD_REMITTANCE_ADVICES_TEMPLATE_STATE]: clearDownloadUploadRemittanceAdvicesTemplateState
  };
  if (handlers.hasOwnProperty(action.type)) {
    return handlers[action.type](state, action);
  } else {
    return state;
  }
}
