import { Link } from 'react-router-dom';
import LinkRoute from 'Components/Link/Route';
import { ThemeProvider } from '@jpmuitk/theme';

import { useStyles } from './styles';

import { createToolkitTheme } from '@jpmuitk/theme';

import { SITEMAP } from 'Constants/routes';

import { useTranslation } from 'react-i18next';

const DARK_THEME = createToolkitTheme('dark', {
  palette: { useNextColors: true }
});

export default props => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <ThemeProvider theme={DARK_THEME}>
      <footer className={classes.footer}>
        <span className={classes.copywriteWrapper}>
          <span className={classes.copywrite}>&copy; {'JPMorgan Chase & Co'}</span>
        </span>
        <nav aria-label={t('footer.footerNavigation')} className={classes.nav}>
          <ol>
            <li>
              <LinkRoute noFocus={false} component={Link} to={{ pathname: SITEMAP }}>
                {t('footer.sitemap')}
              </LinkRoute>
            </li>
          </ol>
        </nav>
      </footer>
    </ThemeProvider>
  );
};
