import {
  LOAD_REMITTANCE_DETAILS_REQUEST,
  LOAD_REMITTANCE_DETAILS_SUCCESS,
  LOAD_REMITTANCE_DETAILS_FAILURE,
  LOAD_PROGRAM_CURRENCIES_REQUEST,
  LOAD_PROGRAM_CURRENCIES_SUCCESS,
  LOAD_PROGRAM_CURRENCIES_FAILURE,
  DOWNLOAD_REMITTANCE_ADVICE_TEMPLATE_FILE_REQUEST,
  DOWNLOAD_REMITTANCE_ADVICE_TEMPLATE_FILE_SUCCESS,
  DOWNLOAD_REMITTANCE_ADVICE_TEMPLATE_FILE_FAILURE,
  SAVE_REMITTANCE_ADVICE_DETAILS_REQUEST,
  SAVE_REMITTANCE_ADVICE_DETAILS_SUCCESS,
  SAVE_REMITTANCE_ADVICE_DETAILS_FAILURE,
  GET_ADVICE_DETAILS_FOR_CREATION_REQUEST,
  GET_ADVICE_DETAILS_FOR_CREATION_SUCCESS,
  CLEAR_REMITTANCE_ADVICE_DETAILS,
  RELOAD_REFERENCES_REM_ADV_REQUEST,
  RELOAD_REFERENCES_REM_ADV_SUCCESS,
  RELOAD_REFERENCES_REM_ADV_FAILURE,
  UPLOAD_REMITTANCE_ADVICE_DETAILS_REQUEST,
  UPLOAD_REMITTANCE_ADVICE_DETAILS_SUCCESS,
  UPLOAD_REMITTANCE_ADVICE_DETAILS_FAILURE
} from './RemittanceAdviceDetailsAction';
import cloneDeep from 'lodash/cloneDeep';

export const initialState = {
  errors: {},
  adviceDetails: {},
  programCurrencies: [],
  invoiceDetails: {}
};

const toleranceDict = {
  amount: 'toleranceAmount',
  percentage: 'tolerancePercent'
};

const clearRemittanceAdviceDetails = (state, action) => ({ ...initialState });

const loadRemittanceAdviceDetailsRequest = (state, action) => ({
  ...state,
  errors: {},
  invoiceDetails: {},
  adviceDetails: {},
  uploadData: {},
  isLoading: true
});

const loadRemittanceAdviceDetailsSuccess = (
  state,
  {
    response: {
      data: {
        invoiceDetails: { references, remittancesColumnList, ...balancingFlags },
        tolerance: { adviceToleranceOption, tolerance, ...toleranceRest },
        ...adviceDetails
      }
    }
  }
) => ({
  ...state,
  errors: {},
  adviceDetails: {
    ...adviceDetails,
    ...balancingFlags,
    ...toleranceRest,
    adviceToleranceOption: toleranceDict[adviceToleranceOption],
    toleranceAmount: tolerance,
    tolerancePercent: tolerance
  },
  invoiceDetails: { references, remittancesColumnList, ...balancingFlags },
  isLoading: false
});

const loadProgramCurrenciesRequest = (state, action) => ({
  ...state,
  errors: {},
  isLoading: true
});

const loadProgramCurrenciesSuccess = (state, action) => ({
  ...state,
  errors: {},
  isLoading: false,
  programCurrencies: action.response.data.programCurrencies
});

const loadProgramCurrenciesFailure = (state, action) => ({
  ...state,
  errors: { ...action.error, type: LOAD_PROGRAM_CURRENCIES_REQUEST },
  isLoading: false,
  programCurrencies: []
});

const downloadRemittanceAdviceTemplateFileRequest = (state, action) => {
  return {
    ...state,
    isFetchingTemplate: true,
    templateData: undefined
  };
};

const downloadRemittanceAdviceTemplateFileSuccess = (state, action) => {
  return {
    ...state,
    isFetchingTemplate: false,
    templateData: action.response
  };
};

const downloadRemittanceAdviceTemplateFileFailure = (state, action) => {
  return {
    ...state,
    isFetchingTemplate: false,
    templateData: undefined
  };
};

const getAdviceDetailsForCreationRequest = (state, action) => {
  return {
    ...state,
    isLoading: true
  };
};

const getAdviceDetailsForCreationSuccess = (
  state,
  {
    response: {
      data: {
        invoiceDetails: { remittancesColumnList, ...balancingFlags },
        tolerance,
        ...rest
      }
    }
  }
) => ({
  ...state,
  errors: {},
  adviceDetails: { ...tolerance, ...balancingFlags, ...rest },
  invoiceDetails: { remittancesColumnList, ...balancingFlags },
  isLoading: false
});

const reloadReferencesAdviceRequest = (state, action) => ({
  ...state,
  isLoading: true
});

const reloadReferencesAdviceSuccess = (state, action) => ({
  ...state,
  isLoading: false,
  invoiceDetails: {
    ...state.invoiceDetails,
    references: action.response.data.references,
    remittancesColumnList: action.response.data.remittancesColumnList
  }
});

const reloadReferencesAdviceFailure = (state, action) => ({
  ...state,
  isLoading: false,
  errors: { ...action.error, type: RELOAD_REFERENCES_REM_ADV_REQUEST }
});
const uploadRemittanceDetailsRequest = (state, action) => {
  return {
    ...state,
    isFetching: true,
    uploadData: undefined
  };
};
const uploadRemittanceDetailsSuccess = (state, action) => {
  return {
    ...state,
    isFetching: false,
    uploadData: action.response.data
  };
};
const uploadRemittanceDetailsFailure = (state, action) => {
  return {
    ...state,
    isFetching: false,
    uploadData: undefined
  };
};

export default function remittanceAdviceDetails(state = initialState, action) {
  const handlers = {
    [CLEAR_REMITTANCE_ADVICE_DETAILS]: clearRemittanceAdviceDetails,
    [GET_ADVICE_DETAILS_FOR_CREATION_REQUEST]: getAdviceDetailsForCreationRequest,
    [GET_ADVICE_DETAILS_FOR_CREATION_SUCCESS]: getAdviceDetailsForCreationSuccess,
    [RELOAD_REFERENCES_REM_ADV_REQUEST]: reloadReferencesAdviceRequest,
    [RELOAD_REFERENCES_REM_ADV_SUCCESS]: reloadReferencesAdviceSuccess,
    [RELOAD_REFERENCES_REM_ADV_FAILURE]: reloadReferencesAdviceFailure,
    [LOAD_REMITTANCE_DETAILS_REQUEST]: loadRemittanceAdviceDetailsRequest,
    [LOAD_REMITTANCE_DETAILS_SUCCESS]: loadRemittanceAdviceDetailsSuccess,
    [LOAD_PROGRAM_CURRENCIES_REQUEST]: loadProgramCurrenciesRequest,
    [LOAD_PROGRAM_CURRENCIES_SUCCESS]: loadProgramCurrenciesSuccess,
    [LOAD_PROGRAM_CURRENCIES_FAILURE]: loadProgramCurrenciesFailure,
    [DOWNLOAD_REMITTANCE_ADVICE_TEMPLATE_FILE_REQUEST]: downloadRemittanceAdviceTemplateFileRequest,
    [DOWNLOAD_REMITTANCE_ADVICE_TEMPLATE_FILE_SUCCESS]: downloadRemittanceAdviceTemplateFileSuccess,
    [DOWNLOAD_REMITTANCE_ADVICE_TEMPLATE_FILE_FAILURE]: downloadRemittanceAdviceTemplateFileFailure,
    [UPLOAD_REMITTANCE_ADVICE_DETAILS_REQUEST]: uploadRemittanceDetailsRequest,
    [UPLOAD_REMITTANCE_ADVICE_DETAILS_SUCCESS]: uploadRemittanceDetailsSuccess,
    [UPLOAD_REMITTANCE_ADVICE_DETAILS_FAILURE]: uploadRemittanceDetailsFailure
  };
  if (handlers.hasOwnProperty(action.type)) {
    return handlers[action.type](state, action);
  } else {
    return state;
  }
}
