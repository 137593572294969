import { useRef, useEffect } from 'react';
import { Icon } from '@jpmuitk/icon';
import { Button } from '@jpmuitk/button';
import { ENTER_KEY, SPACE_KEY } from 'Constants/keyCodes';
import { useTranslation } from 'react-i18next';

export const UploadFile = ({ uploadStatus, classes, variant, title, handleUploadData, disabled = false }) => {
  const saveFileInput = useRef(null);
  let buttonRef = useRef();
  const { t } = useTranslation();

  const onClick = e => {
    if (!saveFileInput.current) {
      return;
    } else {
      saveFileInput.current.click();
    }
  };

  useEffect(() => {
    if (uploadStatus === 'error') {
      buttonRef.current.focus();
    }
  }, [uploadStatus]);

  const onKeyDown = e => {
    if ([ENTER_KEY, SPACE_KEY].includes(e.keyCode)) {
      onClick(e);
    }
  };
  return (
    <Button
      innerRef={buttonRef}
      data-test-id="sdeUploadFileBtn"
      variant={variant}
      component="label"
      htmlFor="file-upload"
      onKeyDown={onKeyDown}
      type="button"
    >
      <Icon name="upload" size={12} accessibleText={t('uploadFileButton.upload')} />{' '}
      {` ${title ? title : t('uploadFileButton.file')}`}
      <input
        id="file-upload"
        type="file"
        ref={saveFileInput}
        disabled={disabled}
        hidden
        onInput={handleUploadData}
        onClick={event => {
          event.target.value = null;
        }}
      />
    </Button>
  );
};

export default UploadFile;
