import React from 'react';

import { Input } from '@jpmuitk/forms';
import { ColumnLayout } from '@jpmuitk/column-layout';

import { FORM_ITEM_SIZE_LIST } from './utils';

export default props => (
  <ColumnLayout item {...FORM_ITEM_SIZE_LIST}>
    <Input {...props} />
  </ColumnLayout>
);
