import api from 'api';
import { httpErrorTypes, errorActions } from 'utility/error';
import { handleDownloadFile, getFileName } from 'utility/downloadUtility';
import { CONTENT_TYPE } from 'Constants/headers';
import { rduTemplate } from 'Constants/defaultFileNames';

export const DOWNLOAD_UPLOAD_REMITTANCE_ADVICES_TEMPLATE_REQUEST =
  'DOWNLOAD_UPLOAD_REMITTANCE_ADVICES_TEMPLATE_REQUEST';
export const DOWNLOAD_UPLOAD_REMITTANCE_ADVICES_TEMPLATE_SUCCESS =
  'DOWNLOAD_UPLOAD_REMITTANCE_ADVICES_TEMPLATE_SUCCESS';
export const DOWNLOAD_UPLOAD_REMITTANCE_ADVICES_TEMPLATE_FAILURE =
  'DOWNLOAD_UPLOAD_REMITTANCE_ADVICES_TEMPLATE_FAILURE';
export const CLEAR_UPLOAD_REMITTANCE_ADVICES = 'CLEAR_UPLOAD_REMITTANCE_ADVICES';
export const UPLOAD_REMITTANCES_FILE_REQUEST = 'UPLOAD_REMITTANCES_FILE_REQUEST';
export const UPLOAD_REMITTANCES_FILE_SUCCESS = 'UPLOAD_REMITTANCES_FILE_SUCCESS';
export const UPLOAD_REMITTANCES_FILE_FAILURE = 'UPLOAD_REMITTANCES_FILE_FAILURE';
export const SAVE_REMITTANCES_REQUEST = 'SAVE_REMITTANCES_REQUEST';
export const SAVE_REMITTANCES_SUCCESS = 'SAVE_REMITTANCES_SUCCESS';
export const SAVE_REMITTANCES_FAILURE = 'SAVE_REMITTANCES_FAILURE';
export const CLEAR_DOWNLOAD_UPLOAD_REMITTANCE_ADVICES_TEMPLATE_STATE =
  'CLEAR_DOWNLOAD_UPLOAD_REMITTANCE_ADVICES_TEMPLATE_STATE';

const handleDownloadTemplate = () => (dispatch, getState) => {
  const templateData = getState()?.uploadRemittanceAdvices?.templateData;
  if (templateData?.data) {
    const type = templateData.request.getResponseHeader(CONTENT_TYPE);
    const fileName = getFileName(templateData, rduTemplate);
    handleDownloadFile(templateData.data, fileName, type);
    return dispatch(clearDownloadUploadRemittanceAdvicesTemplateState());
  }
};

export const clearDownloadUploadRemittanceAdvicesTemplateState = () => ({
  type: CLEAR_DOWNLOAD_UPLOAD_REMITTANCE_ADVICES_TEMPLATE_STATE
});

export const downloadUploadRemittanceAdvicesTemplate = t => ({
  types: [
    DOWNLOAD_UPLOAD_REMITTANCE_ADVICES_TEMPLATE_REQUEST,
    DOWNLOAD_UPLOAD_REMITTANCE_ADVICES_TEMPLATE_SUCCESS,
    DOWNLOAD_UPLOAD_REMITTANCE_ADVICES_TEMPLATE_FAILURE
  ],
  callAPI: (state, { customerId, userId }) =>
    api.fetch(
      `remittanceadvicesvc/v0/cust/${customerId}/user/${userId}/remittance-advice/download-remittance-data-template`
    ),
  dispatchOnSuccess: [handleDownloadTemplate],
  httpErrorTypes,
  errorDisplayType: 'modal',
  errorTitle: t('uploadremittanceadvices.downloadTemplate.error')
});

export const uploadRemittancesFile = file => ({
  types: [UPLOAD_REMITTANCES_FILE_REQUEST, UPLOAD_REMITTANCES_FILE_SUCCESS, UPLOAD_REMITTANCES_FILE_FAILURE],
  callAPI: (state, { customerId, userId }) =>
    api.postWithFile(
      `remittanceadvicesvc/v0/cust/${customerId}/user/${userId}/remittance-advice/validate-remittance-advices`,
      file
    ),
  httpErrorTypes
});

export const saveRemittances = (data, t) => ({
  types: [SAVE_REMITTANCES_REQUEST, SAVE_REMITTANCES_SUCCESS, SAVE_REMITTANCES_FAILURE],
  callAPI: (state, { customerId, userId }) =>
    api.save(
      `remittanceadvicesvc/v0/cust/${customerId}/user/${userId}/remittance-advice/save-uploaded-remittance-advices`,
      data
    ),
  httpErrorTypes,
  errorDisplayType: 'modal',
  errorTitle: t('uploadremittanceadvices.saveFile.error')
});

export const clearUploadRemittanceAdvices = () => ({
  type: CLEAR_UPLOAD_REMITTANCE_ADVICES
});
