import { CREATE_INVOICE_PATTERN, CLONE_INVOICE_PATTERN, EDIT_INVOICE_PATTERN } from 'Constants/routes';
import isNil from 'lodash/isNil';
import i18n from 'i18n';

export const booleanDropdown = [
  { id: 'yes', description: 'Yes' },
  { id: 'no', description: 'No' }
];

export const IPD_PAGES = {
  [CREATE_INVOICE_PATTERN]: true,
  [CLONE_INVOICE_PATTERN]: true,
  [EDIT_INVOICE_PATTERN]: true
};

export const isAlphaNumericWithSPCPCTQMASOnCondition = (value, allValues) => {
  const removeSpecialCharacters = allValues?.removeSpecialCharacters?.id === 'yes';
  return !isNil(value) && removeSpecialCharacters && !/^[a-zA-Z0-9*?%\s]+$/.test(value)
    ? { type: 'error', msg: i18n.t('invoicePattern.special') }
    : undefined;
};

export const isAlphaNumericWithSPCOnCondition = (value, allValues) => {
  const removeSpaces = allValues?.removeSpaces?.id === 'yes';
  return !isNil(value) && removeSpaces && !/^[\S]+$/.test(value)
    ? { type: 'error', msg: i18n.t('invoicePattern.space') }
    : undefined;
};

export const isGreaterThanTruncateReferenceSum = (fieldValue, allValues) => {
  const trlValue = Number(allValues['truncateReferenceLeading']);
  const trtValue = Number(allValues['truncateReferenceTrailing']);
  const value = Number(fieldValue);

  return !isNaN(trlValue) && !isNaN(trtValue) && value <= trtValue + trlValue
    ? {
        type: 'error',
        msg: i18n.t('invoicePattern.sum')
      }
    : undefined;
};
