import { sessionUser } from 'utility/sessionStorageHelper';

export const userAdminURL = endpoint => {
  const { customerId, userId } = sessionUser.getLoggedInUser().getId();
  return `usersvc/v0/cust/${customerId}/user/${userId}/admin/` + endpoint;
};

export const userProgramURL = endpoint => {
  const { customerId, userId } = sessionUser.getLoggedInUser().getId();
  return `lockboxsvc/v0/cust/${customerId}/user/${userId}/` + endpoint;
};
