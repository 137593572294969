import { makeStyles } from '@jpmuitk/theme';
import { style as linkStyle } from '@jpmuitk/link/style';
import { noFocus } from 'styles';

export const useStyles = makeStyles(theme => {
  const { root, ...rest } = linkStyle(theme);
  const {
    palette: { toolkit, type }
  } = theme;

  const baseRoot = {
    ...root,
    color: type === 'light' ? 'black' : '#ffffff',
    '&:visited': {
      color: type === 'light' ? 'black' : '#ffffff'
    },
    /*WCAG CONTRAST FIX*/
    '&:hover': {
      color: type === 'light' ? toolkit.blue800 : '#4B9FD8'
    }
  };
  return {
    ...rest,
    root: {
      ...baseRoot,
      ...noFocus
    },
    rootWithVisibleFocus: baseRoot,
    secondaryFocus: {
      '&:focus': {
        outline: 'none !important'
      }
    }
  };
});
