import i18n from 'i18n';
import {
  extraSpace,
  isRangeValid,
  isGreaterThanOrEqualTo,
  isLessThanOrEqual,
  isNumeric,
  isInteger,
  isInvalidCharacter,
  isRequired,
  isRequiredRange
} from 'utility/validation';

export const RANGE = t => ({
  TO: {
    id: 'to',
    label: t('form.range.to'),
    className: 'field',
    labelPlacement: 'top',
    validation: (
      { formatter, unformatter, userSettings, required },
      onlyAllowIntegers,
      onlyAllowPositive,
      allowSign,
      rangeRequired
    ) => [
      onlyAllowPositive && isGreaterThanOrEqualTo(0),
      onlyAllowIntegers && isInteger,
      extraSpace(),
      !unformatter ? isNumeric : isInvalidCharacter(userSettings, allowSign),
      rangeRequired && isRequiredRange(required)
    ]
  },
  FROM: {
    id: 'from',
    label: t('form.range.from'),
    className: 'field',
    labelPlacement: 'top',
    validation: (
      { formatter, unformatter, userSettings, required },
      onlyAllowIntegers,
      onlyAllowPositive,
      allowSign,
      rangeRequired
    ) => [
      onlyAllowPositive && isGreaterThanOrEqualTo(0),
      onlyAllowIntegers && isInteger,
      extraSpace(),
      !unformatter ? isNumeric : isInvalidCharacter(userSettings, allowSign),
      rangeRequired && isRequiredRange(required)
    ]
  },
  validation: ({ formatter, unformatter, label }) => [isRangeValid({ formatter, unformatter, label })]
});

export const DATE_RANGE = t => ({
  START: {
    id: 'from',
    label: t('form.range.startDateDefaultText'),
    className: 'field',
    labelPlacement: 'top',
    validation: ({ format, required, requiredRange, label }) => [
      isRequired(required, undefined, t('form.range.startDateDefaultText')),
      isRequiredRange(requiredRange, undefined, label)
    ]
  },
  END: {
    id: 'to',
    label: t('form.range.endDateDefaultText'),
    className: 'field',
    labelPlacement: 'top',
    validation: ({ format, required, requiredRange, label }) => [
      isRequired(required, undefined, t('form.range.endDateDefaultText')),
      isRequiredRange(requiredRange, undefined, label)
    ]
  }
});
