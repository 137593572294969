import { makeStyles } from '@jpmuitk/theme';
import cssVariables from 'Constants/_variables.module.scss';

export const grandItemCountStyles = {
  grandItem: {
    paddingLeft: cssVariables.space1
  }
};

export const grandTotalStyles = props => ({
  root: {
    paddingLeft: cssVariables.space1
  },
  label: {
    paddingRight: cssVariables.space3
  },
  title: {
    ...props.typography.h6,
    color: props.palette.toolkit.grey700,
    fontWeight: 'bold',
    marginTop: 0,
    marginBottom: '8px'
  }
});

export const overlayStyles = {
  content: {
    width: '200px',
    padding: cssVariables.space3,
    '& .right-align': {
      textAlign: 'end'
    },
    '& h3': {
      marginTop: 0,
      marginBottom: cssVariables.space3
    },
    '& .bottom-separator': {
      marginBottom: cssVariables.space3,
      borderBottom: cssVariables.borderLine
    }
  }
};

export default makeStyles(theme => ({
  root: {
    display: 'flex',
    columnGap: cssVariables.space3,
    '& button:not(:last-child)': {
      marginRight: cssVariables.space3
    }
  },
  toolbar: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    rowGap: cssVariables.space3,
    alignItems: 'end'
  },
  grandTotal: {
    marginLeft: 'auto'
  }
}));
