import api from 'api';

export const LOAD_BROADCAST_MESSAGES_REQUEST = 'LOAD_BROADCAST_MESSAGES_REQUEST';
export const LOAD_BROADCAST_MESSAGES_SUCCESS = 'LOAD_BROADCAST_MESSAGES_SUCCESS';
export const LOAD_BROADCAST_MESSAGES_FAILURE = 'LOAD_BROADCAST_MESSAGES_FAILURE';

export function loadBroadcastMessagesRequest() {
  return { type: LOAD_BROADCAST_MESSAGES_REQUEST };
}

export function loadBroadcastMessagesSuccess(messages) {
  return { type: LOAD_BROADCAST_MESSAGES_SUCCESS, messages };
}

export function loadBroadcastMessagesFailure(error) {
  return { type: LOAD_BROADCAST_MESSAGES_FAILURE, error };
}

export function loadBroadcastMessages(custId, userId) {
  return dispatch => {
    return api
      .fetch(`authsvc/v0/auth/system/cust/${custId}/user/${userId}/broadcast-messages`)
      .then(broadcastMessages => {
        dispatch(loadBroadcastMessagesSuccess(broadcastMessages.data));
        return broadcastMessages.data;
      })
      .catch(error => {
        dispatch(loadBroadcastMessagesFailure(error));
      });
  };
}
