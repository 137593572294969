import api from 'api';
import cloneDeep from 'lodash/cloneDeep';
import { mapValuesToPayload } from 'routes/utils/payload';
import { invoiceURL } from 'routes/InvoiceSearch/utils';
import { httpErrorTypes } from 'utility/error';
import { unformatPayload, FIELD_TYPES } from 'hooks/useFieldFormatter';
import i18n from 'i18n';

export const LOAD_INVOICES_REQUEST = 'LOAD_INVOICES_REQUEST';
export const LOAD_INVOICES_SUCCESS = 'LOAD_INVOICES_SUCCESS';
export const LOAD_INVOICES_FAILURE = 'LOAD_INVOICES_FAILURE';
export const LOAD_INVOICE_DETAILS_REQUEST = 'LOAD_INVOICE_DETAILS_REQUEST';
export const LOAD_INVOICE_DETAILS_SUCCESS = 'LOAD_INVOICE_DETAILS_SUCCESS';
export const LOAD_INVOICE_DETAILS_FAILURE = 'LOAD_INVOICE_DETAILS_FAILURE';
export const EXPORT_INVOICE_REQUEST = 'EXPORT_INVOICE_REQUEST';
export const EXPORT_INVOICE_SUCCESS = 'EXPORT_INVOICE_SUCCESS';
export const EXPORT_INVOICE_FAILURE = 'EXPORT_INVOICE_FAILURE';
export const CLEAR_INVOICES_ERR = 'CLEAR_INVOICES_ERR';
export const CLEAR_INVOICES_LIST = 'CLEAR_INVOICES_LIST';

export const INVOICE_LIST_ERR_TYPES = {
  DEFAULT: 'TRY_AGAIN'
};

export const clearInvoicesList = () => ({
  type: CLEAR_INVOICES_LIST
});

export const loadInvoices = payload => (dispatch, getState) => {
  const data = cloneDeep(payload);
  const userSettings = getState().settings.userSettings;

  const fieldsToUnformat = { invoiceAmount: FIELD_TYPES.AMOUNT };
  const newPayload = unformatPayload(fieldsToUnformat, data)(userSettings);
  return dispatch({
    types: [LOAD_INVOICES_REQUEST, LOAD_INVOICES_SUCCESS, LOAD_INVOICES_FAILURE],
    callAPI: (state, options) => api.save(invoiceURL(`legacy-invoice-search`), newPayload),
    httpErrorTypes: INVOICE_LIST_ERR_TYPES,
    successCase: response => response.data.invoiceSearchRows?.length > 0,
    successCaseNotMet: response => ({
      data: {
        type: LOAD_INVOICES_SUCCESS,
        status: response.status,
        message: i18n.t('invoiceSearch.messages.noInvoicesFound')
      }
    })
  });
};

export const loadInvoiceDetails = opnInvId => (dispatch, getState) => {
  return dispatch({
    types: [LOAD_INVOICE_DETAILS_REQUEST, LOAD_INVOICE_DETAILS_SUCCESS, LOAD_INVOICE_DETAILS_FAILURE],
    callAPI: (state, options) => api.fetch(invoiceURL(`invoice/${opnInvId}/legacy-invoice-details`)),
    httpErrorTypes: INVOICE_LIST_ERR_TYPES,
    errorDisplayType: 'modal',
    errorTitle: i18n.t('invoiceSearch.messages.unableToLoad')
  });
};

export const exportInvoice = invoicesData => (dispatch, getState) => {
  const data = cloneDeep(invoicesData);
  const userSettings = getState().settings.userSettings;
  const fieldsToUnformat = { invoiceAmount: FIELD_TYPES.AMOUNT };
  const unformattedPayload = unformatPayload(fieldsToUnformat, data)(userSettings);
  const newPayload = mapValuesToPayload(unformattedPayload);

  return dispatch({
    types: [EXPORT_INVOICE_REQUEST, EXPORT_INVOICE_SUCCESS, EXPORT_INVOICE_FAILURE],
    callAPI: (state, options) => api.save(invoiceURL('export-legacy-invoice-data'), newPayload),
    httpErrorTypes,
    errorDisplayType: 'modal',
    errorTitle: i18n.t('invoiceSearch.messages.unableToExport')
  });
};
