import i18n from 'i18n';
import isFunction from 'lodash/isFunction';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import keys from 'lodash/keys';
import isString from 'lodash/isString';
import isEmpty from 'lodash/isEmpty';
import { openDialog } from 'actions/modalActions';
import { errorActions } from 'utility/error';
export const DEFAULT = 'DEFAULT';

import { toast } from 'react-toastify';
import { Toast } from '@jpmuitk/toast';

export const callAPIMiddleware = options => ({ dispatch, getState }) => next => action => {
  const {
    types,
    callAPI,
    successCase,
    successCaseNotMet,
    shouldCallAPI = () => true,
    params = {},
    httpErrorTypes,
    dispatchOnSuccess,
    dispatchOnFailure,
    errorDisplayType,
    successDisplayType,
    successTitle,
    successMessage,
    createSuccessMessage,
    modalActions,
    successModalActions,
    modalProps,
    errorTitle
  } = action;

  if (!types) {
    // Normal action: pass it on
    return next(action);
  }
  if (window.BUILD_ENVIRONMENT === 'MOCK' || window.BUILD_ENVIRONMENT === 'DEV') {
    runDevelopmentErrorChecks(action);
  }
  if (!shouldCallAPI(getState())) {
    return;
  }
  const [requestType, successType, failureType, cancelType] = types;
  dispatch(
    Object.assign({}, params, {
      type: requestType
    })
  );
  return callAPI(getState(), options()).then(
    response => {
      const updatedSuccesMessage = createSuccessMessage && createSuccessMessage(response.data);
      const message =
        updatedSuccesMessage ||
        successMessage ||
        response.data?.successMessage ||
        response.data?.message ||
        response.data;
      if (successCase && !successCase(response)) {
        const error = isFunction(successCaseNotMet)
          ? successCaseNotMet(response)
          : { type: successCaseNotMet.errorType, message: successCaseNotMet.message };
        dispatch(
          Object.assign({}, params, {
            type: failureType,
            error: {
              ...error,
              status: response.status
            }
          })
        );

        if ((errorDisplayType === 'modal' && !error.noModal) || (modalActions && !isEmpty(modalActions))) {
          handleModal(dispatch)({ ...error, ...modalProps }, modalActions);
        }

        return response;
      } else if (successDisplayType === 'toast') {
        handleToast(dispatch)({
          title: successTitle,
          message: message
        });
      } else if (successDisplayType === 'modal') {
        handleModal(dispatch)(
          {
            state: 'success',
            title: successTitle,
            message: message
          },
          successModalActions
        );
      }
      dispatch(
        Object.assign({}, params, {
          response,
          type: successType
        })
      );
      if (dispatchOnSuccess && isArray(dispatchOnSuccess)) {
        dispatchOnSuccess.map(func => {
          if (isFunction(func)) {
            dispatch(func(options()));
          }
        });
      }
      return response;
    },
    error => {
      if (error?.message === 'CANCEL_AND_KEEP_STATE') {
        dispatch(
          Object.assign({}, params, {
            type: cancelType,
            error: error
          })
        );
      } else {
        const errorDefinition = httpErrorTypes.hasOwnProperty(error?.status)
          ? httpErrorTypes[error.status]
          : httpErrorTypes[DEFAULT];
        const err = {
          type: isObject(errorDefinition) ? errorDefinition.type : errorDefinition,
          message: isObject(errorDefinition)
            ? errorDefinition.message
            : error?.data?.errorMessages
            ? error?.data?.errorMessages.join('.\n')
            : error?.data?.message === i18n.t('callApiMiddleWare.noMessageAvailable') || isEmpty(error?.data?.message)
            ? i18n.t('callApiMiddleWare.defaultErrorMessage')
            : error?.data?.message,
          errors: error?.data?.errorMessage || {},
          status: error?.status,
          data: error?.data
        };
        dispatch(
          Object.assign({}, params, {
            type: failureType,
            error: err
          })
        );

        if (errorDisplayType === 'modal' || (modalActions && !isEmpty(modalActions))) {
          handleModal(dispatch)({ ...err, title: errorTitle }, modalActions);
        }

        if (dispatchOnFailure && isArray(dispatchOnFailure)) {
          dispatchOnFailure.map(func => {
            if (isFunction(func)) {
              dispatch(func(options()));
            }
          });
        }
      }
      return error;
    }
  );
};

const handleModal = dispatch => (modalProps, modalActions) => {
  let nextAction = errorActions.TRY_AGAIN;
  const { title, state, errors, message, status, action, options, btnContent, btnOverride } = modalProps;
  if (!isEmpty(modalActions)) {
    nextAction = modalActions.hasOwnProperty(status) ? modalActions[status] : modalActions[DEFAULT];
  }

  dispatch(
    openDialog({
      title: title ?? i18n.t('transactionDetails.error.error'),
      state: state ?? 'error',
      message: isObject(errors)
        ? isEmpty(message)
          ? i18n.t('callApiMiddleWare.defaultErrorMessage')
          : message
        : errors?.length > 0
        ? errors
        : message,
      action: nextAction,
      btnContent: btnContent ? btnContent(dispatch, options) : undefined,
      btnOverride
    })
  );
};

const handleToast = dispatch => props => {
  toast.success(({ closeToast }) => (
    <Toast state="success" title={props.title || i18n.t('callApiMiddleWare.success')} onClose={closeToast}>
      {isString(props.message) && props.message}
    </Toast>
  ));
};

export const runDevelopmentErrorChecks = action => {
  const { types, callAPI, successCase, successCaseNotMet, httpErrorTypes } = action;
  if (!Array.isArray(types) || types.length < 3 || !types.every(type => isString(type))) {
    throw new Error(i18n.t('callApiMiddleWare.expectedStringArray'));
  }

  if (!isFunction(callAPI)) {
    throw new Error(i18n.t('callApiMiddleWare.expectedFunction'));
  }
  runSuccessCaseChecks(successCase, successCaseNotMet);
  if (!httpErrorTypes || !isObject(httpErrorTypes) || !httpErrorTypes.hasOwnProperty(DEFAULT)) {
    throw new Error(i18n.t('callApiMiddleWare.expectedObject'));
  }
  runHttpErrorTypesValidations(httpErrorTypes);
};

const runHttpErrorTypesValidations = httpErrorTypes => {
  const httpErrorTypeDefs = keys(httpErrorTypes);
  httpErrorTypeDefs.forEach(defn => {
    if (!isObject(httpErrorTypes[defn]) && !isString(httpErrorTypes[defn])) {
      throw new Error(i18n.t('callApiMiddleWare.errorStringOrObject'));
    } else if (
      isObject(httpErrorTypes[defn]) &&
      (!httpErrorTypes[defn].hasOwnProperty('type') || !httpErrorTypes[defn].hasOwnProperty('message'))
    ) {
      throw new Error(i18n.t('callApiMiddleWare.errorStringOrObject'));
    }
  });
};

const runSuccessCaseChecks = (successCase, successCaseNotMet) => {
  if (successCase) {
    if (!isFunction(successCase)) {
      throw new Error(i18n.t('callApiMiddleWare.expectedSuccaseCaseFunction'));
    }
    if (
      (!successCaseNotMet ||
        !isObject(successCaseNotMet) ||
        !successCaseNotMet.hasOwnProperty('errorType') ||
        !successCaseNotMet.hasOwnProperty('message')) &&
      !isFunction(successCaseNotMet)
    ) {
      throw new Error(i18n.t('callApiMiddleWare.expectedSuccaseCaseProperties'));
    }
  }
};

export const handleError = (dispatch, failureType, httpErrorTypes, error, action) => {
  const { params = {} } = action;
  const errorDefinition = httpErrorTypes.hasOwnProperty(error.status)
    ? httpErrorTypes[error.status]
    : httpErrorTypes[DEFAULT];

  dispatch(
    Object.assign({}, params, {
      type: failureType,
      error: {
        type: isObject(errorDefinition) ? errorDefinition.type : errorDefinition,
        message: isObject(errorDefinition)
          ? errorDefinition.message
          : error.data.message || i18n.t('callApiMiddleWare.unexpectedError'),
        errors: error.data.errorMessage || {},
        status: error.status
      }
    })
  );
};
