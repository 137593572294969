import cssVariables from 'Constants/_variables.module.scss';

const MENU_MENU_OFFSET = 15;

export const styles = ({ palette: { toolkit } }) => ({
  tab: {
    '& button': {
      color: `${toolkit.grey10} !important`,
      '&:hover': {
        color: `${toolkit.grey400} !important`
      }
    },
    fontSize: '14px',
    backgroundColor: `${toolkit.grey800} !important`
  },
  tabWrapper: {
    '&::after': {
      content: 'none !important'
    }
  },
  tabs: {
    backgroundColor: toolkit.grey800
  },
  tabSelected: {
    color: `${toolkit.grey10} !important`
  },
  currentViewTab: {
    '&:before': {
      background: toolkit.grey100,
      bottom: 0,
      color: toolkit.grey100,
      content: '""',
      height: 2,
      left: 0,
      position: 'absolute',
      right: 0
    }
  },
  paper: {
    backgroundColor: toolkit.grey600
  },
  popper: {
    border: 'none'
  },
  closeButton: {
    '& > span > span': {
      color: `${toolkit.white} !important`
    },
    background: 'transparent',
    border: 0,
    padding: '15px',
    marginLeft: '-43px',
    '&:hover': {
      background: 'transparent'
    }
  },
  actionItemsPanel: {
    backgroundColor: toolkit.grey900
  },
  menuGroupHeading: {
    borderBottom: `1px solid ${toolkit.grey100}`
  },
  menuGroupLabel: {
    color: toolkit.grey20,
    fontSize: '1em'
  },
  actionItemsLabel: {
    color: toolkit.grey20,
    fontSize: '1em'
  },
  menuItem: {
    fontSize: '14px',
    lineHeight: '1.2rem',
    borderBottom: `1px solid ${toolkit.grey400}`
  },
  menuItemLink: {
    color: `${toolkit.white} !important`,
    padding: '5px 5px 8px 4px',
    '&:hover': {
      color: toolkit.white,
      backgroundColor: toolkit.grey200
    },
    '&:focus': {
      color: toolkit.white,
      backgroundColor: toolkit.grey200
    }
  },
  tabMenuOpen: {
    backgroundColor: toolkit.grey500
  }
});
