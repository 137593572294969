import keyBy from 'lodash/keyBy';
import merge from 'lodash/merge';
import reduce from 'lodash/reduce';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import values from 'lodash/values';
import camelCase from 'lodash/camelCase';
import mapValues from 'lodash/mapValues';
import upperFirst from 'lodash/upperFirst';
import sortedUniqBy from 'lodash/sortedUniqBy';

const mergeByKey = (a, b, key) =>
  chain(merge(keyBy(a, key), keyBy(b, key)))
    .values()
    .value();

const flatten = (arr = []) => {
  return arr.reduce((a, b) => a.concat(b), []);
};

const reduceByKey = key => (acc, object) => {
  if (object?.[key]) {
    acc.push(object[key]);
  }
  return acc;
};

const sourceByKey = key => (acc, object) => {
  if (object?.[key]) {
    acc.push(object[key]);
  }
  const flattenArr = flatten(acc);
  return flattenArr;
};

// TODO Test
//arr = The array in question
//fieldToSortBy = the field of the object that is most pertinent for sorting (id, number, etc.)
const sortArrayOfObjects = (arr, fieldToSortBy, uniqueField) => {
  arr.sort((a, b) => {
    const field1 = a[fieldToSortBy];
    const field2 = b[fieldToSortBy];
    if (field1 < field2) {
      return -1;
    }
    if (field1 > field2) {
      return 1;
    }
    return 0;
  });
  //The presence of uniqueField will remove duplicates from the sorted array
  return uniqueField ? sortedUniqBy(arr, uniqueField) : arr;
};

export const stringRangeToNumberList = (stringRange, { delimiter = '-', offset = 0 } = {}) => {
  const [startStr, endStr, start = Number(startStr), end = Number(endStr), range = end - start + 1] = stringRange.split(
    delimiter
  );
  return [...Array(range).keys()].map(x => x + start + offset);
};

const chainableFunctions = {
  camelCase,
  reduce,
  sortBy,
  uniqBy,
  upperFirst,
  values
};

const chain = input => {
  let value = input;
  const wrapper = {
    ...mapValues(chainableFunctions, f => (...args) => {
      // lodash always puts input as the first argument
      value = f(value, ...args);
      return wrapper;
    }),
    value: () => value
  };
  return wrapper;
};

const sortByOrderSeq = (a, b) => {
  if (a.orderSeq > b.orderSeq) {
    return 1;
  } else if (a.orderSeq < b.orderSeq) {
    return -1;
  }
  return 0;
};

// TODO Test
const arrayMoveMutate = (array, from, to) => {
  const startIndex = from < 0 ? array.length + from : from;

  if (startIndex >= 0 && startIndex < array.length) {
    const endIndex = to < 0 ? array.length + to : to;

    const [item] = array.splice(from, 1);
    array.splice(endIndex, 0, item);
  }
};

// TODO Test
const arrayMove = (array, from, to) => {
  array = [...array];
  arrayMoveMutate(array, from, to);
  return array;
};
export { flatten, reduceByKey, sourceByKey, sortArrayOfObjects, sortByOrderSeq, chain, arrayMove, mergeByKey };
