import React from 'react';
import { Form as UITKForm, FormBanner } from '@jpmuitk/forms';
import { getIn } from 'final-form';
import { Form as FinalForm } from 'react-final-form';
import createDecorator from 'final-form-focus';
import { formUseStyles } from './styles';

export const findInput = (fields, errors) => {
  return fields.find(
    field => Object.keys(errors).includes(field['dataset'].testId) || (field.name && getIn(errors, field.name))
  );
};

const getFormInputs = name => () => {
  if (typeof document === 'undefined') {
    return [];
  }
  const form = document.forms[name];
  const inputs =
    form && form.length
      ? form.querySelectorAll('input, textarea, select, *:not(button)[tabindex]:not([tabindex="-1"])')
      : [];
  return Array.from(inputs);
};

const Form = props => {
  const {
    children,
    unwrapped,
    useFinalForm,
    sidebar = false,
    debug = false,
    name = 'form',
    errors,
    ...formProps
  } = props;
  const focusOnErrors = createDecorator(getFormInputs(name), findInput);
  const classes = formUseStyles({ sidebar });

  return unwrapped ? (
    <form {...formProps} className={classes.form}>
      {children}
    </form>
  ) : useFinalForm ? (
    <FinalForm
      {...formProps}
      decorators={[focusOnErrors]}
      subscription={debug ? undefined : {}}
      render={({ handleSubmit, initialValues, values, errors }) => {
        return (
          <form className={classes.form} name={name} onSubmit={handleSubmit}>
            {debug && (
              <>
                <pre>{JSON.stringify(initialValues, 0, 2)}</pre>
                <pre>{JSON.stringify(values, 0, 2)}</pre>
                <pre>{JSON.stringify(errors, 0, 2)}</pre>
              </>
            )}
            {children}
          </form>
        );
      }}
    />
  ) : (
    <UITKForm {...formProps} classes={classes} banner={errors ? <FormBanner errors={errors} /> : undefined}>
      {children}
    </UITKForm>
  );
};
export default Form;
