import React from 'react';
import PropTypes from 'prop-types';

import TextWithIcon from 'Components/TextWithIcon';

const PageError = ({ message, iconSize, type = 'error' }) => (
  <TextWithIcon type={type} icon={type} iconSize={iconSize} role="status">
    <strong>{message}</strong>
  </TextWithIcon>
);

PageError.defaultProps = {
  iconSize: 20
};

PageError.propTypes = {
  iconSize: PropTypes.number,
  message: PropTypes.string.isRequired
};

export default PageError;
