import {
  CLEAR_UPLOAD_WORKFLOW_NOTES,
  CLEAR_DOWNLOAD_UPLOAD_WORKFLOW_NOTES_TEMPLATE_STATE,
  DOWNLOAD_UPLOAD_WORKFLOW_NOTES_TEMPLATE_REQUEST,
  DOWNLOAD_UPLOAD_WORKFLOW_NOTES_TEMPLATE_SUCCESS,
  DOWNLOAD_UPLOAD_WORKFLOW_NOTES_TEMPLATE_FAILURE,
  UPLOAD_WORKFLOW_NOTES_REQUEST,
  UPLOAD_WORKFLOW_NOTES_SUCCESS,
  UPLOAD_WORKFLOW_NOTES_FAILURE,
  SAVE_WORKFLOW_NOTES_REQUEST,
  SAVE_WORKFLOW_NOTES_SUCCESS,
  SAVE_WORKFLOW_NOTES_FAILURE
} from './uploadWorkflowNotesActions';

export const initialState = {
  errors: {},
  isFetchingTemplate: false,
  isRunningFileValidation: false,
  isSavingWorkflow: false,
  templateData: undefined
};

const clearUploadWorkflowNotes = (state, action) => initialState;

const downloadUploadWorkflowNotesTemplateRequest = (state, action) => ({
  ...state,
  errors: {},
  isFetchingTemplate: true,
  templateData: undefined
});

const downloadUploadWorkflowNotesTemplateSuccess = (state, action) => ({
  ...state,
  errors: {},
  isFetchingTemplate: false,
  templateData: action.response
});

const downloadUploadWorkflowNotesTemplateFailure = (state, action) => ({
  ...state,
  errors: { ...action.error, type: DOWNLOAD_UPLOAD_WORKFLOW_NOTES_TEMPLATE_REQUEST },
  isFetchingTemplate: false,
  templateData: undefined
});

const clearDownloadUploadWorkflowNotesTemplateState = (state, action) => ({
  ...state,
  errors: {},
  isFetchingTemplate: false,
  templateData: undefined
});

const uploadWorkflowNotesFileRequest = (state, action) => ({
  ...state,
  errors: {},
  isRunningFileValidation: true,
  successToast: undefined,
  uploadErrors: undefined,
  uploadSuccess: undefined
});
const uploadWorkflowNotesFileSuccess = (state, action) => ({
  ...state,
  errors: {},
  isRunningFileValidation: false,
  uploadErrors: undefined,
  uploadSuccess: action.response.data
});
const uploadWorkflowNotesFileFailure = (state, action) => ({
  ...state,
  isRunningFileValidation: false,
  uploadErrors: action.error,
  uploadSuccess: undefined
});

const saveWorkflowNotesFileRequest = (state, action) => ({
  ...state,
  errors: {},
  isRunningFileValidation: true,
  isSavingWorkflowNotes: true,
  successToast: undefined
});
const saveWorkflowNotesFileSuccess = (state, action) => ({
  ...state,
  isRunningFileValidation: false,
  uploadErrors: undefined,
  uploadSuccess: action.response.data,
  errors: {},
  successToast: action.response.data,
  isSavingWorkflowNotes: false
});
const saveWorkflowNotesFileFailure = (state, action) => ({
  ...state,
  errors: action.error,
  isRunningFileValidation: false,
  successToast: undefined,
  isSavingWorkflowNotes: false
});

export default function originalAdviceReducer(state = initialState, action) {
  const handlers = {
    [CLEAR_UPLOAD_WORKFLOW_NOTES]: clearUploadWorkflowNotes,
    [DOWNLOAD_UPLOAD_WORKFLOW_NOTES_TEMPLATE_REQUEST]: downloadUploadWorkflowNotesTemplateRequest,
    [DOWNLOAD_UPLOAD_WORKFLOW_NOTES_TEMPLATE_SUCCESS]: downloadUploadWorkflowNotesTemplateSuccess,
    [DOWNLOAD_UPLOAD_WORKFLOW_NOTES_TEMPLATE_FAILURE]: downloadUploadWorkflowNotesTemplateFailure,
    [CLEAR_DOWNLOAD_UPLOAD_WORKFLOW_NOTES_TEMPLATE_STATE]: clearDownloadUploadWorkflowNotesTemplateState,
    [UPLOAD_WORKFLOW_NOTES_REQUEST]: uploadWorkflowNotesFileRequest,
    [UPLOAD_WORKFLOW_NOTES_SUCCESS]: uploadWorkflowNotesFileSuccess,
    [UPLOAD_WORKFLOW_NOTES_FAILURE]: uploadWorkflowNotesFileFailure,
    [SAVE_WORKFLOW_NOTES_REQUEST]: saveWorkflowNotesFileRequest,
    [SAVE_WORKFLOW_NOTES_SUCCESS]: saveWorkflowNotesFileSuccess,
    [SAVE_WORKFLOW_NOTES_FAILURE]: saveWorkflowNotesFileFailure
  };
  if (handlers.hasOwnProperty(action.type)) {
    return handlers[action.type](state, action);
  } else {
    return state;
  }
}
