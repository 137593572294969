import useWebScanService from 'hooks/useWebScanService';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router';

import { createToolkitTheme } from '@jpmuitk/theme';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Announcement from 'Components/Announcement';
import BroadcastDialog from 'Components/Dialogs/BroadcastDialog';
import Footer from 'Components/Footer';
import ModalContainer from 'ModalContainer';
import NavigationBar from 'Components/Navigation';
import { Router } from 'routes/Router';
import { SkipLinkContextProvider } from 'Components/SkipTo';
import { SpinnerWithText } from 'Components/Spinner';

import WebIdSelection from 'containers/WebIdSelection';
import ApplicationHeader from 'containers/ApplicationHeader';
import { sessionBroadcast } from 'utility/sessionStorageHelper';
import isEmpty from 'lodash/isEmpty';
import { PaniniEverneXtId } from 'Constants/scannerIds';
import { LOGOUT } from 'Constants/routes';

const DARK_THEME = createToolkitTheme('dark', {
  palette: { useNextColors: true }
});

const isCustWebIdSelected = userCustomer => !isEmpty(userCustomer);

export const MainLayoutContent = props => {
  const { t } = useTranslation();
  const {
    navigation,
    users,
    settings: { userCustomer }
  } = useSelector(state => state);

  const isLogoutPage = props.location?.pathname?.toLowerCase() === LOGOUT;

  if (!isLogoutPage && ((!isCustWebIdSelected(userCustomer) && users.list.length >= 1) || users.errorMessage)) {
    return <WebIdSelection userinfo={users} />;
  } else if (
    !isLogoutPage &&
    ((!isCustWebIdSelected(userCustomer) && users.list.length === 0) || !navigation.navigationResponse)
  ) {
    return <SpinnerWithText message={t('profile.loading')} />;
  } else {
    return (
      <>
        {navigation.navigationResponse ? (
          <nav aria-label="primary">
            <NavigationBar />
          </nav>
        ) : null}
        {!sessionBroadcast.getBroadcastFlag() && <BroadcastDialog />}
        <Router />
        <Footer />
      </>
    );
  }
};

const MainLayout = props => {
  const { userCustomer, userSettings } = useSelector(state => state.settings);
  useWebScanService(userSettings?.userScanner?.id === PaniniEverneXtId);

  return (
    <>
      <Announcement />
      <ModalContainer />
      <SkipLinkContextProvider>
        <ApplicationHeader theme={DARK_THEME} isIdSelected={isCustWebIdSelected(userCustomer)} />
        <MainLayoutContent userCustomer={userCustomer} location={props.location} />
      </SkipLinkContextProvider>
      <ToastContainer
        newestOnTop
        hideProgressBar
        position="bottom-right"
        autoClose={false}
        className="toast-uitk"
        closeButton={false}
      />
    </>
  );
};

export default withRouter(MainLayout);
