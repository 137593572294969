export const WHOLE_INTEGER_REGEX = /\D/;
export const INTEGER_REGEX = /^[0-9]+$/;
//Below REGEX allows user to enter amount with ','
export const AMOUNT_REGEX = /^(?!0+\.0$)^([0-9]){1,3}(,\d{3})*(\.[0-9]{1,2})?$/;
export const USD_REGEX = /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/;
// disallow leading and trailing space - this differs from the legacy rule
// we must have at least two non-space characters and they must be the first and last character
export const USER_FULL_NAME_REGEX = /^[-a-zA-Z0-9,.'][-a-zA-Z0-9\s,.']{0,253}[-a-zA-Z0-9,.']$/;
export const USER_EMAIL_REGEX = /^[\w!#$%&'*+/=?`{|}~^-]+(?:\.[\w!#$%&'*+/=?`{|}~^-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}$/;
export const FILENAME_REGEX = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
export const ALPHA_AND_SPACE_ONLY_REGEX = /^[A-Za-z ]+$/;
export const ALPHA_NUMERIC = /^[a-zA-Z0-9]+$/;
export const ALPHA_NUMERIC_AND_SPACE = /^[a-zA-Z0-9.' ]+$/;
export const ALPHA_AND_SPECIAL_CHARACTERS = /^[a-zA-Z"!#$%&'()*+,-./:;<=>?@^_`{|}\[\]~\ ]+$/;
export const NUMERIC_AND_SPECIAL_CHARACTERS = /^[0-9\"!#$%&'\\()*+,-./:;<=>?@\[\]^_`{|}~\\s\\\\]+$/;
