import React from 'react';
import { PageRoot } from 'Components/Page';
import InvoicePatternDetails from './InvoicePatternDetails';
import sessionHelper from 'utility/sessionStorageHelper';
import isEmpty from 'lodash/isEmpty';

const CreateInvoicePattern = props => {
  const currentInitialValues = sessionHelper.getItem('currentInitialValues', true);
  const initialValues = isEmpty(currentInitialValues) ? {} : currentInitialValues;

  return (
    <PageRoot centerContent>
      <InvoicePatternDetails initialValues={initialValues} />
    </PageRoot>
  );
};

export default CreateInvoicePattern;
