import React from 'react';

import { FormFooter as UITKFormFooter } from '@jpmuitk/forms';
import { ButtonBar } from '@salt-ds/lab';
import { makeStyles } from '@jpmuitk/theme';

const useStyles = makeStyles({
  sticky: { zIndex: 1 }
});

const FormFooter = ({ children, alignRight = true, alignLeft = !alignRight || false }) => {
  const classes = useStyles();
  return (
    <UITKFormFooter classes={classes}>
      <ButtonBar stackAtBreakpoint="xs" alignLeft={alignLeft} alignRight={alignRight}>
        {children}
      </ButtonBar>
    </UITKFormFooter>
  );
};
export default FormFooter;
