export const SIDE_PANELS_INIT = 'SIDE_PANELS_INIT';
export const SIDE_PANEL_OPEN = 'SIDE_PANELS_OPEN';
export const SIDE_PANEL_CLOSE = 'SIDE_PANELS_CLOSE';
export const SIDE_PANEL_TOGGLE = 'SIDE_PANELS_TOGGLE';
export const SIDE_PANEL_TOGGLE_WIDE = 'SIDE_PANELS_TOGGLE_WIDE';

export function initializeSidePanels(queue = [], wide) {
  return {
    type: SIDE_PANELS_INIT,
    payload: {
      queue,
      wide
    }
  };
}

export function toggleSidePanel(id) {
  return {
    type: SIDE_PANEL_TOGGLE,
    payload: {
      id
    }
  };
}

export function toggleSidePanelWide() {
  return {
    type: SIDE_PANEL_TOGGLE_WIDE
  };
}

export const openSidePanel = id => {
  return {
    type: SIDE_PANEL_OPEN,
    payload: {
      id
    }
  };
};

export function closeSidePanel(id) {
  return {
    type: SIDE_PANEL_CLOSE,
    payload: {
      id
    }
  };
}
