import api from 'api';

export const LOAD_REASON_CODES_REQUEST = 'LOAD_REASON_CODES_REQUEST';
export const LOAD_REASON_CODES_SUCCESS = 'LOAD_REASON_CODES_SUCCESS';
export const LOAD_REASON_CODES_FAILURE = 'LOAD_REASON_CODES_FAILURE';
export const SAVE_REASON_CODE_REQUEST = 'SAVE_REASON_CODE_REQUEST';
export const SAVE_REASON_CODE_SUCCESS = 'SAVE_REASON_CODE_SUCCESS';
export const SAVE_REASON_CODE_FAILURE = 'SAVE_REASON_CODE_FAILURE';
export const CHANGE_STATUS_REQUEST = 'CHANGE_STATUS_REQUEST';
export const CHANGE_STATUS_SUCCESS = 'CHANGE_STATUS_SUCCESS';
export const CHANGE_STATUS_FAILURE = 'CHANGE_STATUS_FAILURE';

import { httpErrorTypes } from 'utility/error';
import { transactionURL } from '../Transactions/utils';
import i18n from 'i18n';
import { CREATE } from '../Entity/EntityDetails/modeTypes';

export const loadReasonCodes = () => {
  return {
    types: [LOAD_REASON_CODES_REQUEST, LOAD_REASON_CODES_SUCCESS, LOAD_REASON_CODES_FAILURE],
    callAPI: (state, options) => api.fetch(transactionURL(`view-reason-code`)),
    httpErrorTypes
  };
};

export const saveReasonCode = (payload, action, dispatchOnSuccess) => {
  const mode = action === CREATE ? i18n.t('reasoncode.labels.created') : i18n.t('reasoncode.labels.edited');
  return {
    types: [SAVE_REASON_CODE_REQUEST, SAVE_REASON_CODE_SUCCESS, SAVE_REASON_CODE_FAILURE],
    callAPI: (state, options) => api.save(transactionURL(`save-reason-code?action=${action}`), payload),
    dispatchOnSuccess: dispatchOnSuccess,
    httpErrorTypes,
    successDisplayType: 'toast',
    successTitle: i18n.t('callApiMiddleWare.success'),
    successMessage: i18n.t('reasoncode.labels.saveMessage', { name: payload?.description, modeOption: mode }),
    errorDisplayType: 'modal',
    errorTitle: i18n.t('reasoncode.error', { action })
  };
};

export const changeStatus = (status, reasonId) => {
  const action = status ? 'activate' : 'deactivate';
  return {
    types: [CHANGE_STATUS_REQUEST, CHANGE_STATUS_SUCCESS, CHANGE_STATUS_FAILURE],
    callAPI: (state, options) => api.save(transactionURL(`reason/${reasonId}/activate-reason-code?activate=${status}`)),
    httpErrorTypes,
    errorDisplayType: 'modal',
    errorTitle: i18n.t('reasoncode.error', { action })
  };
};
