import React from 'react';
import {
  LOAD_TRANSACTIONS_REQUEST,
  LOAD_TRANSACTIONS_SUCCESS,
  LOAD_TRANSACTIONS_FAILURE,
  RESET_TRANSACTION_FILTER,
  LOAD_ADVANCE_FILTER_REQUEST,
  LOAD_ADVANCE_FILTER_SUCCESS,
  LOAD_ADVANCE_FILTER_FAILURE,
  EXPORT_TRANSACTION_REQUEST,
  EXPORT_TRANSACTION_SUCCESS,
  EXPORT_TRANSACTION_FAILURE,
  ASSIGN_TRANSACTIONS_REQUEST,
  ASSIGN_TRANSACTIONS_SUCCESS,
  ASSIGN_TRANSACTIONS_FAILURE
} from './transactionsActions';

const initialState = {
  errors: {
    filter: {}
  }
};

const loadTransactionRequest = (state, action) => ({
  ...state,
  ...initialState,
  isFetching: true
});

const loadTransactionSuccess = (state, action) => ({
  ...state,
  ...action.response,
  transactionsErrors: null,
  isFetching: false
});

const loadTransactionFailure = (state, action) => {
  return {
    ...state,
    transactionsErrors: action.error,
    data: [],
    isFetching: false
  };
};

function loadAdvanceFilterRequest(state, action) {
  return Object.assign({}, state, { isFilterFetching: true });
}
const loadAdvanceFilterSuccess = (state, action) => {
  return Object.assign({}, state, {
    advanceFilter: { courierEnabled: action.response.data.courierEnabled, ...action.response.data.transactionFilter },
    isFilterFetching: false
  });
};
const loadAdvanceFilterFailure = (state, action) => {
  return {
    ...state,
    isFilterFetching: false,
    errors: {
      filter: {
        ...state.errors.filter,
        CMSS: {
          message: action.error.message
        }
      }
    }
  };
};

function exportTransactionRequest(state, action) {
  return Object.assign({}, state, {
    isFetchingExport: true,
    exportError: null,
    exportData: null
  });
}
function exportTransactionSuccess(state, action) {
  return Object.assign({}, state, {
    exportData: action.response,
    isFetchingExport: false
  });
}
const exportTransactionFailure = (state, action) => {
  return {
    ...state,
    exportError: action.error,
    isFetchingExport: false
  };
};

function assignTransactionsRequest(state, action) {
  return Object.assign({}, state, {
    isFetching: true,
    successToast: null
  });
}
function assignTransactionsSuccess(state, action) {
  return Object.assign({}, state, {
    successToast: action.response.data,
    isFetching: false
  });
}
const assignTransactionsFailure = (state, action) => {
  return {
    ...state,
    error: action.error,
    isFetching: false
  };
};

const resetTransactionFilter = (state, action) => ({ ...state, advanceFilter: undefined });

export default function transactionsReducer(state = initialState, action) {
  const handlers = {
    [RESET_TRANSACTION_FILTER]: resetTransactionFilter,
    [LOAD_TRANSACTIONS_REQUEST]: loadTransactionRequest,
    [LOAD_TRANSACTIONS_SUCCESS]: loadTransactionSuccess,
    [LOAD_TRANSACTIONS_FAILURE]: loadTransactionFailure,
    [LOAD_ADVANCE_FILTER_REQUEST]: loadAdvanceFilterRequest,
    [LOAD_ADVANCE_FILTER_SUCCESS]: loadAdvanceFilterSuccess,
    [LOAD_ADVANCE_FILTER_FAILURE]: loadAdvanceFilterFailure,
    [EXPORT_TRANSACTION_REQUEST]: exportTransactionRequest,
    [EXPORT_TRANSACTION_SUCCESS]: exportTransactionSuccess,
    [EXPORT_TRANSACTION_FAILURE]: exportTransactionFailure,
    [ASSIGN_TRANSACTIONS_REQUEST]: assignTransactionsRequest,
    [ASSIGN_TRANSACTIONS_SUCCESS]: assignTransactionsSuccess,
    [ASSIGN_TRANSACTIONS_FAILURE]: assignTransactionsFailure
  };
  if (handlers.hasOwnProperty(action.type)) {
    return handlers[action.type](state, action);
  } else {
    return state;
  }
}
