import Scanner from 'api/scanner';
import api from 'api';
import { isSelectedScannerCaptureOne, clearScanError, isSelectedScannerSimulateMode, enqueue } from './index';
import { abortScanSession, getStatus } from './scanActions';
import { reScanItem, scanMore } from '../capturedetailActions';
import { sessionUser } from 'utility/sessionStorageHelper';

const bluebird = require('bluebird');

export const INIT_PROCESSED_COUNT = 'INIT_PROCESSED_COUNT';
export const PROCESSING_ITEM_NUM = 'PROCESSING_ITEM_NUM';
export const PROCESSING_LAST_ITEM_FAILURE = 'PROCESSING_LAST_ITEM_FAILURE';
export const FINISHED_PROCESSING = 'FINISHED_PROCESSING';
export const CLEAR_BATCH_RESPONSE = 'CLEAR_BATCH_RESPONSE';

const numberPerScanPost = 5;
const concurrency = 2;
const synchronousPageCount = 1;

export const SCAN_TYPE = {
  INITIATE_SCAN: 'INITIATE_SCAN',
  SCAN_MORE: 'SCAN_MORE',
  RESCAN: 'RESCAN'
};

export const scanEndpoint = {
  [SCAN_TYPE.RESCAN]: ({ customerId, userId }, { transactionId, transactionDetailId, jobNumber }) =>
    `remotesvc/v0/capture/cust/${customerId}/user/${userId}/capturedetails/transaction/${transactionId}/detail/${transactionDetailId}/job/${jobNumber}/rescan`,
  [SCAN_TYPE.SCAN_MORE]: ({ customerId, userId }, { jobNumber }) =>
    `remotesvc/v0/capture/cust/${customerId}/user/${userId}/job/${jobNumber}/scanmore`,
  [SCAN_TYPE.INITIATE_SCAN]: ({ customerId, userId }) => `remotesvc/v0/capture/cust/${customerId}/user/${userId}/scan`
};

export const handleLastItem = {
  [SCAN_TYPE.RESCAN]: (scanPayload, scanInfoObject) => dispatch => dispatch(reScanItem(scanInfoObject, scanPayload)),
  [SCAN_TYPE.SCAN_MORE]: (scanPayload, scanInfoObject) => dispatch => dispatch(scanMore(scanPayload, scanInfoObject))
};

export const getScannedImageReqArr = numScannedImages => {
  if (numScannedImages === 0) {
    return [];
  }
  const indexToStartAtOne = 1;
  const lastGroupLength = (numScannedImages - 1) % numberPerScanPost;
  const length =
    lastGroupLength === 0
      ? Math.floor((numScannedImages - 1) / numberPerScanPost)
      : Math.floor((numScannedImages - 1) / numberPerScanPost) + 1;
  const scannedImageReqArray = Array.from({ length: length }, (val, index) => {
    const islastIndex = index === length - 1;
    const lastGroupHasCustomLength = islastIndex && lastGroupLength > 0;
    const pageCount = lastGroupHasCustomLength ? lastGroupLength : numberPerScanPost;
    return {
      scannedItemNumber: index * numberPerScanPost + indexToStartAtOne,
      pageCount: pageCount
    };
  });
  scannedImageReqArray.push({
    scannedItemNumber: numScannedImages,
    pageCount: 1
  });
  return scannedImageReqArray;
};

function createScanImageData(
  scannedImageReqArr,
  scannedItemNumber,
  pageCount,
  islastItem,
  lockboxDetail,
  batchUniqueId,
  scannerId
) {
  return {
    scannedImageReqArr: scannedImageReqArr,
    scannedItemNumber: scannedItemNumber,
    pageCount: pageCount,
    islastItem: islastItem.islastItem,
    lockboxDetail: lockboxDetail,
    batchUniqueId: batchUniqueId,
    scannerId: scannerId
  };
}

export const getScannedItems = (scannedItemNumber, pageCount, batchUniqueId, isCaptureOne, cancelToken) => (
  dispatch,
  getState
) => {
  const compressedTiffFormatVal = 29;
  const data = {
    bpp: 0,
    format: compressedTiffFormatVal,
    height: 0,
    id: batchUniqueId,
    pageNumber: scannedItemNumber,
    pageCount: pageCount,
    userData: null,
    width: 0,
    isCaptureOne: isCaptureOne
  };
  let resolved = false;
  let rejected = false;
  let response;
  let error;
  const interval = 2000;

  const waitForItems = (resolve, reject) => {
    if (resolved) {
      resolve(response);
    } else if (rejected) {
      reject(error);
    } else {
      setTimeout(waitForItems, interval, resolve, reject);
    }
  };
  const onSuccess = resp => {
    resolved = true;
    response = resp;
  };

  const onFailure = err => {
    rejected = true;
    error = err;
  };

  dispatch(enqueue(Scanner.post('GetScannedItems', { data, cancelToken, onSuccess, onFailure })));
  return new Promise(waitForItems);
};

const getAndStreamMultipleScannedItems = (
  scanImageData,
  captureDateTime,
  startingSequenceNumOffset,
  scanType,
  isCaptureOne,
  groupIndex,
  lastIndex,
  scanInfoObject,
  cancelToken
) => (dispatch, getState) => {
  return dispatch(
    getScannedItems(
      scanImageData.scannedItemNumber,
      scanImageData.pageCount,
      scanImageData.scannerId,
      isCaptureOne,
      cancelToken
    )
  ).then(response => {
    const scannedItemResponse = response.data;

    const scanPayload = createScanPayload(
      groupIndex,
      lastIndex,
      startingSequenceNumOffset,
      scannedItemResponse,
      scanImageData,
      captureDateTime,
      getState().scanControl.scanningStatus.pagesCount
    );
    const userinfo = sessionUser.getLoggedInUser().getId();
    const correctScanEndpoint = scanEndpoint[scanType](userinfo, scanInfoObject);
    const processedCount = getState().scanControl.scanningStatus.processedCount + scanImageData.pageCount;
    dispatch({ type: PROCESSING_ITEM_NUM, processedCount });
    if (scanImageData.islastItem && (scanType === SCAN_TYPE.RESCAN || scanType === SCAN_TYPE.SCAN_MORE)) {
      return dispatch(handleLastItem[scanType](scanPayload, scanInfoObject));
    } else {
      return api
        .save(correctScanEndpoint, scanPayload, { retries: 3 })
        .then(response => {
          return response;
        })
        .catch(err => {
          if (scanImageData.islastItem) {
            dispatch({ type: PROCESSING_LAST_ITEM_FAILURE, message: err.data?.message });
          }
        });
    }
  });
};

export function getAndStreamAllScannedItems(
  lockboxDetail,
  captureDateTime,
  numItemsAlreadyInBatch,
  existingBatchUniqueId,
  scanType,
  scanInfoObject,
  cancelToken
) {
  return async (dispatch, getState) => {
    const isCaptureOne = isSelectedScannerCaptureOne(getState);
    const numScannedImages = getState().scanControl.scanningStatus.pagesCount;
    const scannedImageReqArr = getScannedImageReqArr(numScannedImages);

    dispatch({ type: INIT_PROCESSED_COUNT });

    const lastScannedItem = scannedImageReqArr.pop();
    const scannerId = getState().scanControl.sessionId;
    const batchUniqueId = !existingBatchUniqueId ? scannerId : existingBatchUniqueId;

    if (scanType !== SCAN_TYPE.RESCAN) {
      await bluebird.map(
        scannedImageReqArr,
        (scannedItemGroup, groupIndex) => {
          const itemData = createScanImageData(
            scannedImageReqArr,
            scannedItemGroup.scannedItemNumber,
            scannedItemGroup.pageCount,
            { islastItem: false },
            lockboxDetail,
            batchUniqueId,
            scannerId
          );
          return dispatch(
            getAndStreamMultipleScannedItems(
              itemData,
              captureDateTime,
              numItemsAlreadyInBatch,
              scanType,
              isCaptureOne,
              groupIndex,
              -1,
              scanInfoObject,
              cancelToken
            )
          );
        },
        { concurrency }
      );
    }
    scannedImageReqArr.push(lastScannedItem);
    const scanImageData = createScanImageData(
      scannedImageReqArr,
      lastScannedItem.scannedItemNumber,
      synchronousPageCount,
      { islastItem: true },
      lockboxDetail,
      batchUniqueId,
      scannerId
    );
    const response = await dispatch(
      getAndStreamMultipleScannedItems(
        scanImageData,
        captureDateTime,
        numItemsAlreadyInBatch,
        scanType,
        isCaptureOne,
        scannedImageReqArr.length - 1,
        numScannedImages - 1,
        scanInfoObject,
        cancelToken
      )
    );
    dispatch({ type: FINISHED_PROCESSING, batchResponse: response });
    clearScanError();
  };
}

const createScanPayload = (
  groupIndex,
  lastIndex,
  startingSequenceNumOffset,
  scannedItemResponse,
  scanImageData,
  captureDateTime,
  totalPageCount
) => {
  const { lockboxDetail, islastItem, batchUniqueId, scannedImageReqArr } = scanImageData;
  const scanData = Array.from({ length: scannedImageReqArr[groupIndex].pageCount }, (val, index) => {
    const seqNumber =
      lastIndex !== -1
        ? lastIndex + startingSequenceNumOffset + 1
        : groupIndex * numberPerScanPost + index + startingSequenceNumOffset + 1;
    const scannedItem = scannedItemResponse[index];
    return {
      seqNumber,
      frontImage: scannedItem.frontImage,
      isCheckImage: scannedItem.isCheckImage,
      micr: scannedItem.micr,
      rearImage: scannedItem.rearImage
    };
  });

  const firstScannedItem = scannedItemResponse[0];
  return {
    ...lockboxDetail,
    islastItem,
    batchUniqueId,
    scanData,
    ipAddress: firstScannedItem.ipAddress,
    machineName: firstScannedItem.machineName,
    scanCount: scanImageData.pageCount,
    scannerName: firstScannedItem.scannerName,
    scannerSerial: firstScannedItem.scannerSerial,
    userName: firstScannedItem.userName,
    captureTime: captureDateTime,
    totalPageCount
  };
};

export const clearFinalBatchResponse = () => {
  return { type: CLEAR_BATCH_RESPONSE };
};
