import { makeStyles } from '@jpmuitk/theme';
import cssVariables from 'Constants/_variables.module.scss';

// Narrow form styles -- start
export const containerUseStyles = makeStyles({
  container: {
    flexGrow: 1,
    padding: '0 !important'
  }
});

export const itemUseStyles = makeStyles({
  item: {
    '& > *:not(:first-child)': {
      marginTop: 20
    },
    flexGrow: 1,
    flexBasis: 0,
    display: 'flex',
    flexDirection: 'column'
  }
});
// Narrow form styles -- end

// Form override
export const formUseStyles = makeStyles(theme => ({
  form: ({ sidebar }) => ({
    position: sidebar ? undefined : 'absolute',
    margin: '0 auto',
    maxWidth: sidebar ? `${cssVariables.sideBarWidth} !important` : 'none !important',
    width: '100%',
    padding: 0,
    boxSizing: 'border-box'
  })
}));

export const formGroupUseStyles = makeStyles(theme => ({
  fieldContainer: {
    maxWidth: 'initial'
  }
}));
