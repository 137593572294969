import { legend, errorMsg } from 'styles';

export default {
  root: {
    margin: 0,
    padding: 0,
    border: 'none',
    '& .errorMsg': errorMsg
  },
  legend
};
