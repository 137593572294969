export const rangeStyles = {
  /**
   * Test for regressions LAL
   */
  touchDensity: {
    '&$root': {
      width: 350
    }
  },
  lowDensity: {
    '&$root': {
      width: 325
    }
  },
  contentAreaBackground: {
    background: 'none'
  }
};
