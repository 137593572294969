import isEmpty from 'lodash/isEmpty';
import {
  LOAD_PROGRAMS_REQUEST,
  LOAD_PROGRAMS_SUCCESS,
  LOAD_PROGRAMS_FAILURE,
  LOAD_GROUPS_REQUEST,
  LOAD_GROUPS_SUCCESS,
  LOAD_GROUPS_FAILURE,
  CLEAR_PROGRAMS,
  CLEAR_GROUPS
} from 'routes/UserAdmin/userAdminActions';

const loadProgramsRequest = state => {
  return { ...state, isFetchingPrograms: true, errors: {} };
};

const loadProgramsSuccess = (state, action) => {
  const programs = action.response.data;
  let custIds = [];
  let loadEditUserGroupsBody = {};
  if (programs['programsRemoteMobile']) {
    custIds = programs['programsRemoteMobile'].assigned.map(item => item.programCustId);
  }
  if (programs['programsRemoteCapture']) {
    custIds = custIds.concat(programs['programsRemoteCapture'].assigned.map(item => item.programCustId));
  }
  if (!isEmpty(custIds)) {
    loadEditUserGroupsBody = {
      assignedLockboxCustIds: custIds
    };
  }

  return {
    ...state,
    isFetchingPrograms: false,
    userAccount: { ...state.userAccount, programs, loadEditUserGroupsBody }
  };
};

const loadProgramsFailure = (state, action) => {
  return { ...state, isFetchingPrograms: false, errors: action.errors };
};

const loadGroupsRequest = state => {
  return { ...state, isFetchingGroups: true, errors: {} };
};

const loadGroupsSuccess = (state, action) => {
  return {
    ...state,
    isFetchingGroups: false,
    userAccount: {
      ...state.userAccount,
      groups: action.response.data.groups
    }
  };
};

const loadGroupsFailure = (state, action) => {
  return { ...state, isFetchingGroups: false, errors: action.errors };
};

const clearGroups = state => {
  return { ...state, userAccount: { ...state.userAccount, groups: undefined, loadEditUserGroupsBody: undefined } };
};

const clearPrograms = state => {
  return { ...state, userAccount: { ...state.userAccount, programs: undefined, groups: undefined } };
};

export const programsAndGroupsReducer = {
  [LOAD_PROGRAMS_REQUEST]: loadProgramsRequest,
  [LOAD_PROGRAMS_SUCCESS]: loadProgramsSuccess,
  [LOAD_PROGRAMS_FAILURE]: loadProgramsFailure,
  [LOAD_GROUPS_REQUEST]: loadGroupsRequest,
  [LOAD_GROUPS_SUCCESS]: loadGroupsSuccess,
  [LOAD_GROUPS_FAILURE]: loadGroupsFailure,
  [CLEAR_GROUPS]: clearGroups,
  [CLEAR_PROGRAMS]: clearPrograms
};
