import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import isEmpty from 'lodash/isEmpty';

import { loadAlertCount } from 'routes/Alerts/alertsActions/alertsInboxActions';

import { useDensity } from '@jpmuitk/theme';
import { SkipLink } from '@jpmuitk/skip-link';
import { ThemeProvider } from '@jpmuitk/theme';
import { AppHeaderNext } from '@jpmuitk/app-header';
import { useAriaAnnouncer } from '@jpmuitk/aria-announcer';

import { useSkipLinkContext } from 'Components/SkipTo';

import { INBOX, DISPLAYPRINTSETTINGS, SUPPORT_PAGE, LOGOUT } from 'Constants/routes';
import i18n from 'i18n';
import sessionHelper from 'utility/sessionStorageHelper';

const actionsWidth = {
  touch: 250,
  low: 250,
  medium: 235,
  high: 205
};

const DEFAULT_LINK_LIST = [
  {
    itemId: 'logout',
    overflowLabel: i18n.t('applicationHeader.label.logout'),
    ariaLabel: i18n.t('applicationHeader.label.logout'),
    iconName: 'tear-out',
    'data-test-id': 'logout'
  }
];

export const ApplicationHeader = ({ history, theme, isIdSelected }) => {
  const density = useDensity();
  const dispatch = useDispatch();
  const [mainRef] = useSkipLinkContext();
  const { announce } = useAriaAnnouncer();
  const alertCount = useSelector(state => state.alerts.alertCount);
  const [linkList, setLinkList] = useState([]);

  useEffect(() => {
    if (!isIdSelected) {
      setLinkList(DEFAULT_LINK_LIST);
    } else {
      setLinkList([
        {
          itemId: 'support',
          overflowLabel: i18n.t('applicationHeader.label.support'),
          ariaLabel: i18n.t('applicationHeader.label.support'),
          iconName: 'help',
          'data-test-id': 'support'
        },
        {
          itemId: 'alert',
          overflowLabel: i18n.t('applicationHeader.label.alerts', { count: alertCount }),
          iconName: 'alert',
          badgeContent: alertCount,
          'data-test-id': 'alert'
        },
        {
          itemId: 'settings',
          overflowLabel: i18n.t('applicationHeader.label.settings'),
          ariaLabel: i18n.t('applicationHeader.label.settings'),
          iconName: 'settings',
          'data-test-id': 'settings'
        },
        ...DEFAULT_LINK_LIST
      ]);
    }
  }, [isIdSelected, alertCount]);

  useEffect(() => {
    if (isIdSelected) {
      dispatch(loadAlertCount());
    }
  }, [isIdSelected]);

  useEffect(() => {
    announce(i18n.t('applicationHeader.messages.unreadAlerts', { count: alertCount }));
  }, [alertCount]);

  const navigateTo = pathname => {
    if (history.location?.pathname !== pathname) {
      sessionHelper.setItem('prevPageArray', pathname);
    }

    history.push({
      pathname
    });
  };

  const onItemClick = actionType => {
    switch (actionType) {
      case 'support':
        navigateTo(SUPPORT_PAGE);
        break;
      case 'alert':
        navigateTo(INBOX);
        break;
      case 'settings':
        navigateTo(DISPLAYPRINTSETTINGS);
        break;
      case 'logout':
        navigateTo(LOGOUT);
        break;
    }
  };

  const actionProps = {
    maxWidth: actionsWidth[density],
    onItemClick
  };

  return (
    <ThemeProvider theme={theme}>
      <SkipLink
        style={{ zIndex: 10000 }}
        targetRef={mainRef}
        href=""
        onClick={e => {
          e.preventDefault();
          mainRef.current.focus();
        }}
        role="button"
      >
        {i18n.t('applicationHeader.label.skipToMain')}
      </SkipLink>
      <AppHeaderNext
        key={linkList.length}
        actionProps={actionProps}
        appTitle="Receivables Online"
        density="low"
        actionSource={linkList}
      />
    </ThemeProvider>
  );
};

export default withRouter(ApplicationHeader);
