import cssVariables from 'Constants/_variables.module.scss';

export const styles = ({ palette }) => ({
  root: {
    marginLeft: cssVariables.space3,
    color: cssVariables.green500,
    padding: `0 ${cssVariables.space3}`,
    border: `2px solid ${cssVariables.green500}`,
    fontWeight: 600
  }
});
