import { CLEAR_NAVIGATION, LOAD_NAVIGATION_REQUEST, LOAD_NAVIGATION_SUCCESS } from 'actions/navigationActions';
import { formatMenu, formatReport, getActionItemsAndMenuGroups } from 'utility/nav';
import i18n from 'i18n';

export function setLandingRouteAndFormatMenu(data) {
  const navigationResponseWithLinks = data.map((menuPane, menuPaneIndex) => {
    const [actionItems, menuGroups] = getActionItemsAndMenuGroups(menuPane);

    return {
      ...menuPane,
      id: `${menuPaneIndex}`,
      index: menuPaneIndex,
      closeMenuLabel: i18n.t('megaMenu.label', { menuPaneLabel: menuPane.label }),
      ...actionItems,
      menuGroups: menuGroups.map((menu, menuGroupIndex) => ({
        ...menu,
        index: menuGroupIndex,
        id: `${menuGroupIndex}`,
        menuItems: menu.menuItems.map(
          menu.id !== 'ReportsByType'
            ? formatMenu(menuPaneIndex, menuGroupIndex)
            : formatReport(menuPaneIndex, menuGroupIndex)
        )
      }))
    };
  });

  return {
    navigationResponse: navigationResponseWithLinks
  };
}

export default function navigationReducer(state = {}, action) {
  switch (action.type) {
    case LOAD_NAVIGATION_REQUEST:
      return Object.assign({}, state, { isFetching: true });
    case LOAD_NAVIGATION_SUCCESS:
      return Object.assign({}, state, {
        ...setLandingRouteAndFormatMenu(action.navigationinfo.navigationResponse),
        landingRoute: action.navigationinfo.userStartPage,
        isFetching: false
      });
    case CLEAR_NAVIGATION:
      return {};
    default:
      return state;
  }
}
