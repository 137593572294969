export * from './createUserActions';
export * from './editUserActions';
export * from './programsAndGroupsActions';
export * from './viewUsersActions';
export * from './viewUserDetailsActions';
import { createUserErrorTypes } from './createUserActions';
import { editUserErrorTypes } from './editUserActions';
import { programsAndGroupsErrorTypes } from './programsAndGroupsActions';
import { viewUsersErrorTypes } from './viewUsersActions';
import { viewUserDetailsErrorTypes } from './viewUserDetailsActions';

export const errorTypes = {
  ...createUserErrorTypes,
  ...editUserErrorTypes,
  ...viewUsersErrorTypes,
  ...viewUserDetailsErrorTypes,
  ...programsAndGroupsErrorTypes
};

export const CLEAR_ADMIN_ERROR = 'CLEAR_ADMIN_ERROR';

export const clearAdminError = () => {
  return { type: CLEAR_ADMIN_ERROR };
};
