import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { withStyles } from '@jpmuitk/theme';

import { Panel } from '@jpmuitk/panel';
import { Input } from '@jpmuitk/input';
import { Button } from '@salt-ds/core';
import { ColumnLayout } from '@jpmuitk/column-layout';
import { FormField } from '@jpmuitk/form-field';

import { loadUserInfo, loadUserInfoRequest } from 'actions/userinfoActions';
import axiosHelper from 'api/axios-helper';
import i18n from 'i18n';

const DEF_USERNAME = 'sthota12';

const styleColumnLayout = {
  container: {
    height: '100%',
    width: '100%',
    margin: 0
  }
};

const FullHeightColumnLayout = withStyles(styleColumnLayout)(ColumnLayout);

export class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: '',
      buttonDisabled: true
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.userinfo !== this.props.userinfo && this.props.userinfo.status === 200) {
      this.props.close();
    }
  }

  loadUserData(userId) {
    this.props.loadUserInfoRequest();
    this.props.loadUserInfo(userId);
  }

  handleUsernameChange = event => {
    if (event.target.value) {
      this.setState({ userName: event.target.value, buttonDisabled: false });
    } else {
      this.setState({ buttonDisabled: true });
    }
  };

  handleSubmit = event => {
    event.preventDefault();
    axiosHelper.setUserHeader(this.state.userName);
    this.loadUserData(this.state.userName);
  };

  render() {
    const errorMessage = this.props.userinfo.error
      ? `${this.props.userinfo.error} - ${this.props.userinfo.message}`
      : '';

    return (
      <FullHeightColumnLayout container spacing={3} justify="center" alignItems="center">
        <ColumnLayout item xs={12} sm={8} md={6}>
          <Panel>
            <h1>{i18n.t('login.label.login')}</h1>
            <form name="loginForm" onSubmit={this.handleSubmit}>
              <FormField
                id="username"
                name="username"
                className="loginInput"
                label={i18n.t('login.label.username')}
                validationState={this.props.userinfo.message ? 'error' : undefined}
                helperText={errorMessage}
                labelPlacement="left"
              >
                <Input name="username" onChange={this.handleUsernameChange} />
              </FormField>
              <div
                className="login-options"
                style={{
                  margin: '1rem 0',
                  textAlign: 'right'
                }}
              >
                <Button name="login" type="submit" disabled={this.state.buttonDisabled}>
                  {i18n.t('login.label.login')}
                </Button>
              </div>
            </form>
          </Panel>
        </ColumnLayout>
      </FullHeightColumnLayout>
    );
  }
}

Login.defaultProps = {
  userinfo: {
    list: [],
    isFetching: false,
    status: 0,
    error: '',
    message: ''
  }
};

Login.propTypes = {
  userinfo: PropTypes.shape({
    list: PropTypes.array,
    isFetching: PropTypes.boolean,
    status: PropTypes.number,
    error: PropTypes.string,
    message: PropTypes.string
  })
};

export function mapStateToProps(state) {
  return {
    userinfo: state.users
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    loadUserInfoRequest: () => {
      dispatch(loadUserInfoRequest());
    },
    loadUserInfo: userId => {
      dispatch(loadUserInfo(userId));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
