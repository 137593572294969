import api from 'api';

export const LOAD_NAVIGATION_REQUEST = 'LOAD_NAVIGATION_REQUEST';
export const LOAD_NAVIGATION_SUCCESS = 'LOAD_NAVIGATION_SUCCESS';
export const LOAD_NAVIGATION_FAILURE = 'LOAD_NAVIGATION_FAILURE';
export const CLEAR_NAVIGATION = 'CLEAR_NAVIGATION';

export function loadNavigationRequest() {
  return { type: LOAD_NAVIGATION_REQUEST };
}
export function loadNavigationSuccess(navigationinfo) {
  return { type: LOAD_NAVIGATION_SUCCESS, navigationinfo };
}
export function loadNavigation(customerId, userId) {
  return dispatch => {
    return api
      .fetch(`entitlementsvc/v0/cust/${customerId}/user/${userId}/navigation`)
      .then(navigationResponse => {
        dispatch(loadNavigationSuccess(navigationResponse.data));
      })
      .catch(error => {
        throw error;
      });
  };
}
export const clearNavigation = () => ({ type: CLEAR_NAVIGATION });
