import {
  LOAD_IMAGEVIEWER_REQUEST,
  LOAD_IMAGEVIEWER_SUCCESS,
  LOAD_IMAGEVIEWER_FAILURE,
  SAVE_IMAGEVIEWER_REQUEST,
  SAVE_IMAGEVIEWER_SUCCESS,
  SAVE_IMAGEVIEWER_FAILURE,
  LOAD_THUMBNAILS_REQUEST,
  LOAD_THUMBNAILS_SUCCESS,
  LOAD_THUMBNAILS_FAILURE,
  LOAD_SELECTED_THUMBNAILS_REQUEST,
  LOAD_SELECTED_THUMBNAILS_SUCCESS,
  LOAD_SELECTED_THUMBNAILS_FAILURE,
  RESET_IMAGE_VIEWER_INFO
} from './actions';
import { RESET_ARCHIVAL_STATE } from 'routes/Transactions/archiveActions';

export const initialState = {
  thumbnailsResponse: []
};

const loadImageViewerRequest = state => {
  return Object.assign({}, state, {
    imageViewerErrors: null,
    isFetchingImg: true,
    imageInfo: null
  });
};

const loadImageViewerSuccess = (state, action) => {
  const imageInfoMap = new Map();
  action.response.data.forEach(img => imageInfoMap.set(img.imageId, img));
  return Object.assign({}, state, {
    imageInfo: imageInfoMap,
    imageViewerErrors: null,
    isFetchingImg: false,
    transactionId: action.transactionId,
    minThumbnail: action.minThumbnail,
    maxThumbnail: action.maxThumbnail
  });
};

const loadImageViewerFailure = (state, action) => {
  return Object.assign({}, state, {
    imageViewerErrors: action.error,
    imageInfo: null,
    isFetchingImg: false
  });
};

const saveImageViewerRequest = state => {
  return Object.assign({}, state, { isFetchingImgSave: true });
};

const saveImageViewerSuccess = (state, action) => {
  return Object.assign({}, state, { savedImages: action.response, saveImageError: null, isFetchingImgSave: false });
};

const saveImageViewerFailure = (state, action) => {
  return Object.assign({}, state, {
    saveImageError: action.error,
    isFetchingImgSave: false
  });
};

const loadThumbnailsRequest = state => {
  return { ...initialState, isFetching: true };
};

const loadThumbnailsSuccess = (state, action) => {
  return Object.assign({}, state, {
    thumbnailsResponse: action.response.data.imageResponseList,
    imageViewerErrors: null,
    isFetching: false
  });
};

const loadThumbnailsFailure = (state, action) => {
  return Object.assign({}, state, {
    imageViewerErrors: action.error,
    thumbnailsResponse: null,
    isFetching: false
  });
};

const loadSelectedThumbnailsRequest = state => {
  return Object.assign({}, state, { isFetchingSelectedImages: true });
};

const loadSelectedThumbnailsSuccess = (state, action) => {
  return Object.assign({}, state, {
    thumbnailsResponse: action.response.data,
    imageViewerErrors: null,
    isFetchingSelectedImages: false
  });
};

const loadSelectedThumbnailsFailure = (state, action) => {
  return Object.assign({}, state, {
    imageViewerErrors: action.error,
    thumbnailsResponse: { imageResponseList: null },
    isFetchingSelectedImages: false
  });
};

const resetArchivalState = state => {
  return Object.assign({}, state, {
    isFetching: false
  });
};
const resetImageViewerInfo = state => {
  return { ...initialState };
};

const handlers = {
  [LOAD_IMAGEVIEWER_REQUEST]: loadImageViewerRequest,
  [LOAD_IMAGEVIEWER_SUCCESS]: loadImageViewerSuccess,
  [LOAD_IMAGEVIEWER_FAILURE]: loadImageViewerFailure,
  [SAVE_IMAGEVIEWER_REQUEST]: saveImageViewerRequest,
  [SAVE_IMAGEVIEWER_SUCCESS]: saveImageViewerSuccess,
  [SAVE_IMAGEVIEWER_FAILURE]: saveImageViewerFailure,
  [LOAD_THUMBNAILS_REQUEST]: loadThumbnailsRequest,
  [LOAD_THUMBNAILS_SUCCESS]: loadThumbnailsSuccess,
  [LOAD_THUMBNAILS_FAILURE]: loadThumbnailsFailure,
  [LOAD_SELECTED_THUMBNAILS_REQUEST]: loadSelectedThumbnailsRequest,
  [LOAD_SELECTED_THUMBNAILS_SUCCESS]: loadSelectedThumbnailsSuccess,
  [LOAD_SELECTED_THUMBNAILS_FAILURE]: loadSelectedThumbnailsFailure,
  [RESET_IMAGE_VIEWER_INFO]: resetImageViewerInfo,
  [RESET_ARCHIVAL_STATE]: resetArchivalState
};

export default function reducer(state = initialState, action) {
  return handlers.hasOwnProperty(action.type) ? handlers[action.type](state, action) : state;
}
