import {
  CLEAR_ENTITY_DETAILS,
  CLEAR_ENTITY_LIST,
  ASSIGN_ENTITIES_REQUEST,
  ASSIGN_ENTITIES_SUCCESS,
  ASSIGN_ENTITIES_FAILURE,
  LOAD_ASSIGNABLE_USERS_REQUEST,
  LOAD_ASSIGNABLE_USERS_SUCCESS,
  LOAD_ASSIGNABLE_USERS_FAILURE,
  LOAD_ENTITY_DETAILS_REQUEST,
  LOAD_ENTITY_DETAILS_SUCCESS,
  LOAD_ENTITY_DETAILS_FAILURE,
  LOAD_ENTITY_REQUEST,
  LOAD_ENTITY_SUCCESS,
  LOAD_ENTITY_FAILURE,
  LOAD_ENTITY_FILTER_INFO_REQUEST,
  LOAD_ENTITY_FILTER_INFO_SUCCESS,
  LOAD_ENTITY_FILTER_INFO_FAILURE,
  EXPORT_ENTITY_REQUEST,
  EXPORT_ENTITY_SUCCESS,
  EXPORT_ENTITY_FAILURE,
  SAVE_ENTITY_DETAILS_REQUEST,
  SAVE_ENTITY_DETAILS_SUCCESS,
  SAVE_ENTITY_DETAILS_FAILURE,
  DOWNLOAD_ENTITY_TEMPLATE_REQUEST,
  DOWNLOAD_ENTITY_TEMPLATE_SUCCESS,
  DOWNLOAD_ENTITY_TEMPLATE_FAILURE,
  CLEAR_ENTITY_TEMPLATE_STATE,
  CHANGE_ENTITY_STATUS_REQUEST,
  CHANGE_ENTITY_STATUS_SUCCESS,
  CHANGE_ENTITY_STATUS_FAILURE,
  UPLOAD_ENTITY_FILE_REQUEST,
  UPLOAD_ENTITY_FILE_SUCCESS,
  UPLOAD_ENTITY_FILE_FAILURE,
  SAVE_ENTITY_FILE_REQUEST,
  SAVE_ENTITY_FILE_SUCCESS,
  SAVE_ENTITY_FILE_FAILURE,
  RESET_ENTITY_STATE,
  CUSTOMER_CLASSIFICATION_REQUEST,
  CUSTOMER_CLASSIFICATION_SUCCESS,
  CUSTOMER_CLASSIFICATION_FAILURE,
  SAVE_CUSTOMER_CLASSIFICATION_REQUEST,
  SAVE_CUSTOMER_CLASSIFICATION_SUCCESS,
  SAVE_CUSTOMER_CLASSIFICATION_FAILURE,
  ACTIVATE_CUSTOMER_CLASSIFICATION_REQUEST,
  ACTIVATE_CUSTOMER_CLASSIFICATION_SUCCESS,
  ACTIVATE_CUSTOMER_CLASSIFICATION_FAILURE,
  DELETE_ENTITY_REQUEST,
  DELETE_ENTITY_SUCCESS,
  DELETE_ENTITY_FAILURE,
  INVALID_RDR,
  RESET_RDR_ERROR
} from './actions';

export const initialState = {
  payerEntityList: [],
  workflowAssignState: {},
  assignEntitiesState: {},
  isFetching: false,
  isSaveFail: undefined,
  entityErrors: undefined,
  errors: undefined,
  saveSuccessToast: undefined
};

const clearEntityList = state => ({ ...state, ...initialState, successToast: null });

const clearEntityDetails = state => ({ ...state, ...initialState, editEntityData: {}, successToast: null });

const clearEntityTemplateState = (state, action) => ({ ...state, templateData: null, successToast: null });

const assignEntitiesRequest = (state, action) => ({
  ...state,
  workflowAssignState: { fetching: true }
});

const assignEntitiesSuccess = (state, action) => ({
  ...state,
  workflowAssignState: { fetching: false }
});

const assignEntitiesFailure = (state, action) => ({
  ...state,
  workflowAssignState: { fetching: false }
});

const loadAssignableUsersRequest = (state, action) => ({
  ...state,
  workflowAssignState: {
    errorFetchingUsers: undefined,
    fetching: true,
    availableUsers: []
  }
});

const loadAssignableUsersSuccess = (state, action) => ({
  ...state,
  workflowAssignState: {
    errorFetchingUsers: undefined,
    fetching: false,
    availableUsers: action.response.data
  }
});

const loadAssignableUsersFailure = (state, action) => ({
  ...state,
  workflowAssignState: {
    errorFetchingUsers: action.error,
    fetching: false,
    availableUsers: []
  }
});

const loadEntityRequest = state => ({
  ...state,
  ...initialState,
  isFetching: true,
  totalRows: undefined,
  workflowAssignState: {
    availableUsers: state?.workflowAssignState?.availableUsers
  }
});

function loadEntitySuccess(state, action) {
  const error = action.response.data.error ? action.response.data : null;
  return { ...state, ...action.response.data, isFetching: false, entityErrors: error };
}

function loadEntityFailure(state, action) {
  return { ...state, entityErrors: action.error, isFetching: false };
}

function loadEntityFilterInfoRequest(state) {
  return {
    ...state,
    isFetching: true
  };
}
function loadEntityFilterInfoSuccess(state, action) {
  return { ...state, ...action.response.data, isFetching: false };
}

function loadEntityFilterInfoFailure(state, action) {
  return { ...state, error: action.error, isFetching: false };
}

const exportEntityRequest = (state, action) => {
  return Object.assign({}, state, {
    isFetchingExport: true,
    exportData: null
  });
};
const exportEntitySuccess = (state, action) => {
  return Object.assign({}, state, {
    exportData: action.response,
    isFetchingExport: false
  });
};
const exportEntityFailure = (state, action) => {
  return {
    ...state,
    errors: { ...action.error, type: EXPORT_ENTITY_REQUEST },
    isFetchingExport: false
  };
};
function loadEntityDetailsRequest(state) {
  return { ...state, detailsErrors: null, isFetching: true, isFetchingDetails: true };
}
function loadEntityDetailsSuccess(state, action) {
  return {
    ...state,
    editEntityData: action.response.data,
    detailsErrors: null,
    isFetching: false,
    isFetchingDetails: false
  };
}
function loadEntityDetailsFailure(state, action) {
  return {
    ...state,
    detailsErrors: action.error,
    isFetching: false,
    isFetchingDetails: false
  };
}

const saveEntityRequest = (state, action) => {
  return Object.assign({}, state, {
    isFetchingSave: true,
    isRdrFail: false,
    isSaveFail: false,
    successToast: null
  });
};
const saveEntitySuccess = (state, action) => {
  return Object.assign({}, state, {
    successToast: { ...action.response.data, save: true },
    isSaveFail: false,
    isFetchingSave: false
  });
};
const saveEntityFailure = (state, action) => {
  return {
    ...state,
    errors: { ...action.error, type: SAVE_ENTITY_DETAILS_REQUEST },
    isRdrFail: action.error.errorType === INVALID_RDR,
    isSaveFail: true,
    isFetchingSave: false
  };
};
const downloadTemplateRequest = (state, action) => {
  return Object.assign({}, state, {
    isFetching: true,
    exportData: null
  });
};
const downloadTemplateSuccess = (state, action) => {
  return Object.assign({}, state, {
    templateData: action.response,
    isFetching: false
  });
};
const downloadTemplateFailure = (state, action) => {
  return {
    ...state,
    errors: { ...action.error, type: DOWNLOAD_ENTITY_TEMPLATE_REQUEST },
    isFetching: false
  };
};

const changeStatusRequest = (state, action) => {
  return Object.assign({}, state, {
    ...initialState,
    isFetchingSave: true,
    successToast: null
  });
};
const changeStatusSuccess = (state, action) => {
  return Object.assign({}, state, {
    successToast: { ...action.response.data, entityStatus: true },
    isFetchingSave: false
  });
};
const changeStatusFailure = (state, action) => {
  return {
    ...state,
    errors: { ...action.error, type: CHANGE_ENTITY_STATUS_REQUEST },
    isFetchingSave: false
  };
};

const upoadFileRequest = (state, action) => {
  return Object.assign({}, state, {
    uploadSuccess: undefined,
    uploadErrors: undefined,
    successToast: null,
    isLoading: true
  });
};
const upoadFileSuccess = (state, action) => {
  const data = action.response.data;
  const errors = data.errors;
  const result = !errors
    ? { uploadSuccess: data }
    : {
        uploadErrors: {
          message: data.fileErrorMessage,
          errorDetails: data.errorDetails
        }
      };
  return Object.assign({}, state, {
    ...result,
    errors: errors,
    isLoading: false,
    saveSuccessToast: null
  });
};
const upoadFileFailure = (state, action) => {
  return {
    ...state,
    uploadErrors: { ...action.error },
    isLoading: false
  };
};

const saveFileRequest = (state, action) => {
  return Object.assign({}, state, {
    isFetching: true,
    saveSuccessToast: null
  });
};
const saveFileSuccess = (state, action) => {
  return Object.assign({}, state, {
    saveSuccessToast: { ...action.response.data },
    isFetching: false
  });
};
const saveFileFailure = (state, action) => {
  return {
    ...state,
    saveSuccessToast: null,
    errors: { ...action.error },
    isFetching: false
  };
};
const resetEntityState = (state, action) => {
  return {
    ...state,
    uploadErrors: {},
    errors: {},
    uploadSuccess: {},
    isFetching: false,
    templateData: null,
    successToast: null
  };
};

const resetRdrError = (state, action) => ({ ...state, isRdrFail: false });

const customerClassificationRequest = (state, action) => {
  return Object.assign({}, state, {
    isLoading: true
  });
};
const customerClassificationSuccess = (state, action) => {
  return Object.assign({}, state, {
    ...action.response.data,
    isLoading: false
  });
};
const customerClassificationFailure = (state, action) => {
  return {
    ...state,
    uploadErrors: { ...action.error },
    isLoading: false
  };
};

const saveCCRequest = (state, action) => {
  return Object.assign({}, state, {
    isFetchingSave: true
  });
};
const saveCCSuccess = (state, action) => {
  const ccs = action.response.data.customerClassifications;
  const topCCindex = ccs?.findIndex(cc => cc.edited === true);
  if (topCCindex > -1 && ccs?.length > 1) {
    const topCC = ccs.splice(topCCindex, 1)[0];
    ccs.unshift(topCC);
  }

  return Object.assign({}, state, {
    customerClassifications: ccs,
    isFetchingSave: false
  });
};
const saveCCFailure = (state, action) => {
  return {
    ...state,
    isFetchingSave: false
  };
};

const activateCCRequest = (state, action) => {
  return Object.assign({}, state, {
    ...initialState,
    isFetchingActivate: true
  });
};
const activateCCSuccess = (state, action) => {
  return Object.assign({}, state, {
    customerClassifications: action.response.data.customerClassifications,
    isActivateFail: false,
    isFetchingActivate: false
  });
};
const activateCCFailure = (state, action) => {
  return {
    ...state,
    isActivateFail: { ...action.error },
    isFetchingActivate: false
  };
};

const deleteRequest = (state, action) => {
  return Object.assign({}, state, {
    ...initialState,
    isFetchingSave: true
  });
};
const deleteSuccess = (state, action) => {
  return Object.assign({}, state, {
    successToast: { ...action.response.data, save: true },
    isSaveFail: false,
    isFetchingSave: false
  });
};
const deleteFailure = (state, action) => {
  return {
    ...state,
    isFetchingSave: false
  };
};

export default function entityReducer(state = initialState, action) {
  const handlers = {
    [CLEAR_ENTITY_DETAILS]: clearEntityDetails,
    [CLEAR_ENTITY_LIST]: clearEntityList,
    [ASSIGN_ENTITIES_REQUEST]: assignEntitiesRequest,
    [ASSIGN_ENTITIES_SUCCESS]: assignEntitiesSuccess,
    [ASSIGN_ENTITIES_FAILURE]: assignEntitiesFailure,
    [LOAD_ASSIGNABLE_USERS_REQUEST]: loadAssignableUsersRequest,
    [LOAD_ASSIGNABLE_USERS_SUCCESS]: loadAssignableUsersSuccess,
    [LOAD_ASSIGNABLE_USERS_FAILURE]: loadAssignableUsersFailure,
    [LOAD_ENTITY_DETAILS_REQUEST]: loadEntityDetailsRequest,
    [LOAD_ENTITY_DETAILS_SUCCESS]: loadEntityDetailsSuccess,
    [LOAD_ENTITY_DETAILS_FAILURE]: loadEntityDetailsFailure,
    [LOAD_ENTITY_REQUEST]: loadEntityRequest,
    [LOAD_ENTITY_SUCCESS]: loadEntitySuccess,
    [LOAD_ENTITY_FAILURE]: loadEntityFailure,
    [LOAD_ENTITY_FILTER_INFO_REQUEST]: loadEntityFilterInfoRequest,
    [LOAD_ENTITY_FILTER_INFO_SUCCESS]: loadEntityFilterInfoSuccess,
    [LOAD_ENTITY_FILTER_INFO_FAILURE]: loadEntityFilterInfoFailure,
    [EXPORT_ENTITY_REQUEST]: exportEntityRequest,
    [EXPORT_ENTITY_SUCCESS]: exportEntitySuccess,
    [EXPORT_ENTITY_FAILURE]: exportEntityFailure,
    [SAVE_ENTITY_DETAILS_REQUEST]: saveEntityRequest,
    [SAVE_ENTITY_DETAILS_SUCCESS]: saveEntitySuccess,
    [SAVE_ENTITY_DETAILS_FAILURE]: saveEntityFailure,
    [DOWNLOAD_ENTITY_TEMPLATE_REQUEST]: downloadTemplateRequest,
    [DOWNLOAD_ENTITY_TEMPLATE_SUCCESS]: downloadTemplateSuccess,
    [DOWNLOAD_ENTITY_TEMPLATE_FAILURE]: downloadTemplateFailure,
    [CLEAR_ENTITY_TEMPLATE_STATE]: clearEntityTemplateState,
    [CHANGE_ENTITY_STATUS_REQUEST]: changeStatusRequest,
    [CHANGE_ENTITY_STATUS_SUCCESS]: changeStatusSuccess,
    [CHANGE_ENTITY_STATUS_FAILURE]: changeStatusFailure,
    [UPLOAD_ENTITY_FILE_REQUEST]: upoadFileRequest,
    [UPLOAD_ENTITY_FILE_SUCCESS]: upoadFileSuccess,
    [UPLOAD_ENTITY_FILE_FAILURE]: upoadFileFailure,
    [SAVE_ENTITY_FILE_REQUEST]: saveFileRequest,
    [SAVE_ENTITY_FILE_SUCCESS]: saveFileSuccess,
    [SAVE_ENTITY_FILE_FAILURE]: saveFileFailure,
    [RESET_RDR_ERROR]: resetRdrError,
    [RESET_ENTITY_STATE]: resetEntityState,
    [CUSTOMER_CLASSIFICATION_REQUEST]: customerClassificationRequest,
    [CUSTOMER_CLASSIFICATION_SUCCESS]: customerClassificationSuccess,
    [CUSTOMER_CLASSIFICATION_FAILURE]: customerClassificationFailure,
    [SAVE_CUSTOMER_CLASSIFICATION_REQUEST]: saveCCRequest,
    [SAVE_CUSTOMER_CLASSIFICATION_SUCCESS]: saveCCSuccess,
    [SAVE_CUSTOMER_CLASSIFICATION_FAILURE]: saveCCFailure,
    [ACTIVATE_CUSTOMER_CLASSIFICATION_REQUEST]: activateCCRequest,
    [ACTIVATE_CUSTOMER_CLASSIFICATION_SUCCESS]: activateCCSuccess,
    [ACTIVATE_CUSTOMER_CLASSIFICATION_FAILURE]: activateCCFailure,
    [DELETE_ENTITY_REQUEST]: deleteRequest,
    [DELETE_ENTITY_SUCCESS]: deleteSuccess,
    [DELETE_ENTITY_FAILURE]: deleteFailure
  };
  if (handlers.hasOwnProperty(action.type)) {
    return handlers[action.type](state, action);
  } else {
    return state;
  }
}
