import api from 'api';
import { errorActions, httpErrorTypes } from 'utility/error';
import i18n from 'i18n';

export const LOAD_CUSTOM_REPORTS_TEMPLATE_REQUEST = 'LOAD_CUSTOM_REPORTS_TEMPLATE_REQUEST';
export const LOAD_CUSTOM_REPORTS_TEMPLATE_SUCCESS = 'LOAD_CUSTOM_REPORTS_TEMPLATE_SUCCESS';
export const LOAD_CUSTOM_REPORTS_TEMPLATE_FAILURE = 'LOAD_CUSTOM_REPORTS_TEMPLATE_FAILURE';
export const RUN_CUSTOM_REPORT_REQUEST = 'RUN_CUSTOM_REPORT_REQUEST';
export const RUN_CUSTOM_REPORT_SUCCESS = 'RUN_CUSTOM_REPORT_SUCCESS';
export const RUN_CUSTOM_REPORT_FAILURE = 'RUN_CUSTOM_REPORT_FAILURE';
export const SAVE_CUSTOM_REPORT_REQUEST = 'SAVE_CUSTOM_REPORT_REQUEST';
export const SAVE_CUSTOM_REPORT_SUCCESS = 'SAVE_CUSTOM_REPORT_SUCCESS';
export const SAVE_CUSTOM_REPORT_FAILURE = 'SAVE_CUSTOM_REPORT_FAILURE';

export const LOAD_CUSTOM_REPORTS_SETTINGS_REQUEST = 'LOAD_CUSTOM_REPORTS_SETTINGS_REQUEST';
export const LOAD_CUSTOM_REPORTS_SETTINGS_SUCCESS = 'LOAD_CUSTOM_REPORTS_SETTINGS_SUCCESS';
export const LOAD_CUSTOM_REPORTS_SETTINGS_FAILURE = 'LOAD_CUSTOM_REPORTS_SETTINGS_FAILURE';
export const CLEAR_RUN_ERROR = 'CLEAR_RUN_ERROR';
export const reportsErrorTypes = {
  LOAD_CUSTOM_REPORTS: 'LOAD_CUSTOM_REPORTS',
  RUN_CUSTOM_REPORT: 'RUN_CUSTOM_REPORT',
  SAVE_CUSTOM_REPORT: 'SAVE_CUSTOM_REPORT',
  LOAD_CUSTOM_SETTINGS: 'LOAD_CUSTOM_SETTINGS',
  SAVE_REPORT: 'SAVE_REPORT',
  SAVE_REPORT_NO_DATA: 'SAVE_REPORT_NO_DATA'
};
const NEW = 'new';
const EDIT = 'edit';

export const loadCustomReportsTemplate = () => {
  return {
    types: [
      LOAD_CUSTOM_REPORTS_TEMPLATE_REQUEST,
      LOAD_CUSTOM_REPORTS_TEMPLATE_SUCCESS,
      LOAD_CUSTOM_REPORTS_TEMPLATE_FAILURE
    ],
    callAPI: (state, { customerId, userId }) =>
      api.fetch(
        `reportentitlementsvc/v0/reports/entitlement/cust/${customerId}/user/${userId}/custom-reports/templates`
      ),
    httpErrorTypes
  };
};

export const loadCustomReportSettings = (templateId = null) => {
  return {
    types: [
      LOAD_CUSTOM_REPORTS_SETTINGS_REQUEST,
      LOAD_CUSTOM_REPORTS_SETTINGS_SUCCESS,
      LOAD_CUSTOM_REPORTS_SETTINGS_FAILURE
    ],
    callAPI: (state, { customerId, userId }) =>
      api.fetch(
        templateId
          ? `reportdefinitionsvc/v0/reports/definition/cust/${customerId}/user/${userId}/custom-reports/filters/${templateId}`
          : `reportdefinitionsvc/v0/reports/definition/cust/${customerId}/user/${userId}/custom-reports/filters`
      ),
    httpErrorTypes,
    errorDisplayType: 'modal',
    errorTitle: i18n.t('reports.customReport.message.reportTemplate')
  };
};

export function clearRunError() {
  return { type: CLEAR_RUN_ERROR };
}

export const runCustomReport = (payload, type = null, t) => {
  const templateId = payload.templateId;
  return {
    types: [RUN_CUSTOM_REPORT_REQUEST, RUN_CUSTOM_REPORT_SUCCESS, RUN_CUSTOM_REPORT_FAILURE],
    callAPI: (state, { customerId, userId }) =>
      api.save(
        `reportdefinitionsvc/v0/reports/definition/cust/${customerId}/user/${userId}/custom-reports/templates/${templateId}/run-request`,
        payload
      ),
    params: { type },
    httpErrorTypes,
    successDisplayType: 'modal',
    successTitle: t('reports.label.runSuccess'),
    successMessage: t('reports.customReport.message.reportRequestSuccess'),
    modalActions: { DEFAULT: errorActions.RELOAD_PAGE },
    errorDisplayType: 'modal',
    errorTitle: t('reports.customReport.message.reportTemplate')
  };
};

export const deleteCustomReport = (payload, type = null, t) => {
  const templateId = payload.templateId;
  return {
    types: [RUN_CUSTOM_REPORT_REQUEST, RUN_CUSTOM_REPORT_SUCCESS, RUN_CUSTOM_REPORT_FAILURE],
    callAPI: (state, { customerId, userId }) =>
      api.save(
        `reportdefinitionsvc/v0/reports/definition/cust/${customerId}/user/${userId}/custom-reports/delete/${templateId}`,
        payload
      ),
    params: { type },
    httpErrorTypes,
    successDisplayType: 'modal',
    successTitle: t('reports.messages.deleteSuccess'),
    modalActions: { DEFAULT: errorActions.RELOAD_PAGE },
    successModalActions: { DEFAULT: errorActions.RELOAD_PAGE },
    errorDisplayType: 'modal',
    errorTitle: t('reports.customReport.message.reportTemplate')
  };
};

export const saveCustomReport = (payload, type, modeType) => {
  return {
    types: [SAVE_CUSTOM_REPORT_REQUEST, SAVE_CUSTOM_REPORT_SUCCESS, SAVE_CUSTOM_REPORT_FAILURE],
    callAPI: (state, { customerId, userId }) =>
      api.save(
        type === 'run'
          ? `reportdefinitionsvc/v0/reports/definition/cust/${customerId}/user/${userId}/custom-reports/templates/run-request`
          : type === 'run-save'
          ? `reportdefinitionsvc/v0/reports/definition/cust/${customerId}/user/${userId}/custom-reports/templates/save-run-request`
          : `reportdefinitionsvc/v0/reports/definition/cust/${customerId}/user/${userId}/custom-reports/templates`,
        payload
      ),
    params: { type },
    dispatchOnSuccess: [() => loadCustomReportSettings(payload.templateId)],
    httpErrorTypes,
    successDisplayType: modeType === EDIT ? 'toast' : 'modal',
    successMessage:
      type === 'run'
        ? i18n.t('reports.customReport.message.reportRequestSuccess')
        : type === 'run-save'
        ? i18n.t('reports.customReport.message.reportRequestSubmit')
        : i18n.t('reports.customReport.message.reportRequestSave'),
    successTitle:
      type === 'run' ? i18n.t('customReport.messages.runSuccess') : i18n.t('customReport.messages.saveSuccess'),
    successModalActions: { DEFAULT: errorActions.GO_BACK },
    errorDisplayType: 'modal',
    errorTitle: i18n.t('reports.customReport.message.refineCriteria')
  };
};
