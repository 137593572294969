import { forwardRef } from 'react';
import classNames from 'classnames';
import { withStyles } from '@jpmuitk/theme';

import { ENTER_KEY, SPACE_KEY } from 'Constants/keyCodes';

import { style as linkStyle } from '@jpmuitk/link/style';
import { noButtonStyles, noFocus } from 'styles';

const style = theme => {
  const { root, ...otherStyles } = linkStyle(theme);
  return {
    ...otherStyles,
    root: {
      ...root,
      ...noButtonStyles
    },
    noFocus: {
      ...root,
      ...noButtonStyles,
      ...noFocus
    }
  };
};

const Button = forwardRef(
  ({ type = 'button', noFocus, className, classes, label, children, description, ...rest }, ref) => (
    <button
      ref={ref}
      type={type}
      className={classNames(className, { [classes.root]: !noFocus, [classes.noFocus]: noFocus })}
      aria-description={description}
      {...rest}
    >
      {label || children}
    </button>
  )
);

export default withStyles(style)(Button);
