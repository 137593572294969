import {
  LOAD_ALERT_TYPES_FAILURE,
  LOAD_ALERT_TYPES_REQUEST,
  LOAD_ALERT_TYPES_SUCCESS,
  LOAD_ALERT_CRITERIA_FAILURE,
  LOAD_ALERT_CRITERIA_REQUEST,
  LOAD_ALERT_CRITERIA_SUCCESS,
  SAVE_ALERT_FAILURE,
  SAVE_ALERT_REQUEST,
  SAVE_ALERT_SUCCESS,
  LOAD_ALERT_DETAILS_FAILURE,
  LOAD_ALERT_DETAILS_REQUEST,
  LOAD_ALERT_DETAILS_SUCCESS,
  DELETE_ALERT_REQUEST,
  DELETE_ALERT_SUCCESS,
  DELETE_ALERT_FAILURE,
  LOAD_ALERT_MANAGE_FAILURE,
  LOAD_ALERT_MANAGE_REQUEST,
  LOAD_ALERT_MANAGE_SUCCESS,
  RESET_ALERT
} from 'routes/Alerts/alertsActions/actions';

import cloneDeep from 'lodash/cloneDeep';

export const initialState = {
  errors: {},
  isFetchingAlertDetails: false,
  alertDetails: {},
  deleteFlag: false,
  isFetchingAlertTypes: false,
  alertTypes: [],
  isFetchingAlertCriteria: false,
  alertCriteria: {},
  isFetchingAlertManage: true,
  alertManage: [],
  saveMessage: '',
  isFetching: false
};

const loadAlertTypesRequest = state => {
  return {
    ...state,
    ...initialState,
    isFetchingAlertTypes: true
  };
};

const loadAlertTypesSuccess = (state, action) => {
  return {
    ...state,
    errors: {},
    isFetchingAlertTypes: false,
    alertTypes: action.response.data.alertTypes
  };
};

const loadAlertTypesFailure = (state, action) => {
  return {
    ...state,
    errors: action.error,
    isFetchingAlertTypes: false,
    alertTypes: []
  };
};

const loadAlertCriteriaRequest = state => {
  return {
    ...state,
    errors: {},
    isFetchingAlertCriteria: true,
    alertCriteria: {}
  };
};

const loadAlertCriteriaSuccess = (state, action) => {
  const criteria = cloneDeep(action.response.data);
  if (action.response.request.responseURL.indexOf('decision-manager-alerts') > -1) {
    criteria.transactionAmount = {
      from: { value: criteria.transactionAmount.from },
      to: { value: criteria.transactionAmount.to }
    };
    criteria.group = { from: { value: criteria.group.from }, to: { value: criteria.group.to } };
  }
  return {
    ...state,
    errors: {},
    isFetchingAlertCriteria: false,
    alertCriteria: criteria
  };
};

const loadAlertCriteriaFailure = (state, action) => {
  return {
    ...state,
    errors: action.error,
    isFetchingAlertCriteria: false,
    alertCriteria: {}
  };
};

const saveAlertRequest = state => {
  return {
    ...state,
    errors: {},
    saveMessage: '',
    isFetching: true
  };
};

const saveAlertSuccess = (state, action) => {
  return {
    ...state,
    errors: {},
    saveMessage: action.response.data.message,
    isFetching: false
  };
};

const saveAlertFailure = (state, action) => {
  return {
    ...state,
    errors: action.error,
    isFetching: false
  };
};

const loadAlertDetailsRequest = state => {
  return {
    ...state,
    ...initialState,
    isFetchingAlertDetails: true
  };
};

const loadAlertDetailsSuccess = (state, action) => {
  const detail = cloneDeep(action.response.data);
  if (action.response.request.responseURL.indexOf('decision-manager-alerts') > -1) {
    detail.transactionAmount = {
      from: { value: detail.transactionAmount.from },
      to: { value: detail.transactionAmount.to }
    };
    detail.group = { from: { value: detail.group.from }, to: { value: detail.group.to } };
  }
  return {
    ...state,
    errors: {},
    isFetchingAlertDetails: false,
    alertDetails: detail
  };
};

const loadAlertDetailsFailure = (state, action) => {
  return {
    ...state,
    errors: action.error,
    isFetchingAlertDetails: false,
    alertDetails: {}
  };
};

const deleteAlertRequest = state => {
  return {
    ...state,
    isFetching: true,
    deleteFlag: false
  };
};

const deleteAlertSuccess = (state, action) => {
  return {
    ...state,
    errors: {},
    isFetching: false,
    deleteFlag: true
  };
};

const deleteAlertFailure = (state, action) => {
  return {
    ...state,
    isFetching: false,
    errors: action.error
  };
};

const loadAlertManageRequest = state => {
  return {
    ...state,
    isFetchingAlertManage: true
  };
};

const loadAlertManageSuccess = (state, action) => {
  const alertManage = action.response.data.alerts.map(item => ({
    name: item.name,
    id: item.id,
    alertTypeDesc: item.alertTypeDesc,
    frequencyDescription: item.frequencyDescription,
    statusDescription: item.statusDescription,
    recipients: item.originator + ', ' + item.recipients.map(({ fullName }) => fullName).join(', ')
  }));
  return {
    ...state,
    manageErrors: {},
    isFetchingAlertManage: false,
    alertManage
  };
};

const loadAlertManageFailure = (state, action) => {
  return {
    ...state,
    manageErrors: action.error,
    isFetchingAlertManage: false,
    alertManage: []
  };
};

const resetAlert = state => {
  return {
    ...state,
    ...initialState
  };
};

export const reducer = {
  [LOAD_ALERT_DETAILS_REQUEST]: loadAlertDetailsRequest,
  [LOAD_ALERT_DETAILS_SUCCESS]: loadAlertDetailsSuccess,
  [LOAD_ALERT_DETAILS_FAILURE]: loadAlertDetailsFailure,
  [DELETE_ALERT_REQUEST]: deleteAlertRequest,
  [DELETE_ALERT_SUCCESS]: deleteAlertSuccess,
  [DELETE_ALERT_FAILURE]: deleteAlertFailure,
  [LOAD_ALERT_TYPES_REQUEST]: loadAlertTypesRequest,
  [LOAD_ALERT_TYPES_SUCCESS]: loadAlertTypesSuccess,
  [LOAD_ALERT_TYPES_FAILURE]: loadAlertTypesFailure,
  [LOAD_ALERT_CRITERIA_REQUEST]: loadAlertCriteriaRequest,
  [LOAD_ALERT_CRITERIA_SUCCESS]: loadAlertCriteriaSuccess,
  [LOAD_ALERT_CRITERIA_FAILURE]: loadAlertCriteriaFailure,
  [SAVE_ALERT_REQUEST]: saveAlertRequest,
  [SAVE_ALERT_SUCCESS]: saveAlertSuccess,
  [SAVE_ALERT_FAILURE]: saveAlertFailure,
  [LOAD_ALERT_MANAGE_REQUEST]: loadAlertManageRequest,
  [LOAD_ALERT_MANAGE_SUCCESS]: loadAlertManageSuccess,
  [LOAD_ALERT_MANAGE_FAILURE]: loadAlertManageFailure,
  [RESET_ALERT]: resetAlert
};
