import i18n from 'i18n';
import {
  CAPTURE_DETAIL,
  CAPTURE_HISTORY,
  CAPTURE_RESULTS,
  CLONE_INVOICE_PATTERN,
  CLONE_USER_SETUP,
  CREATE_ALERT,
  CREATE_INVOICE_PATTERN,
  CREATE_REMITTANCE_ADVICE,
  CREATE_REPORT,
  CREATE_USER,
  EDIT_REPORT,
  CUSTOMER_CLASSIFICATION,
  DECISION_MANAGER_HISTORY,
  DECISION_MANAGER_DETAILS,
  DECISIONS_REQUIRING_ACTION,
  DECISIONS_REQUIRING_ACTION_DETAILS,
  DISPLAYPRINTSETTINGS,
  DOWNLOAD_CENTER,
  EDIT,
  EDIT_ALERT,
  EDIT_INVOICE_PATTERN,
  EDIT_USER,
  ENTITY_LOOKUP,
  EXPORT_IMAGE,
  EXPRESS_USER_SETUP,
  INBOX,
  INVOICE_IDENTIFICATION_PATTERNS,
  INVOICE_DETAILS,
  INVOICE_SEARCH,
  HCEOBEXCEPTIONS,
  MANAGE,
  ENTITY_LIST,
  ENTITIES_REQUIRING_ACTIVATION,
  ENTITY_CREATE,
  ENTITY_DETAILS,
  ENTITY_UPLOAD,
  ONLINE_REPORTS,
  ONLINE_REPORT_RESULTS,
  REACTIVATE,
  REASON_CODES,
  REMITTANCE_ADVICE_DETAILS,
  REMITTANCE_ADVICE_LIST,
  SCAN,
  STOP_GO,
  SUPPORT_PAGE,
  TRANSACTIONS,
  TRANSACTIONS_ASSIGNED_TO_ME,
  TRANSACTIONS_REQUIRING_ACTION,
  TRANSACTION_DETAIL,
  UPLOAD_WORKFLOW_NOTES,
  UPLOAD_REMITTANCE_ADVICES,
  USER_ADMIN,
  USER_DETAIL,
  VIEW_USER
} from './routes';

export const PAGE_TITLES = (t = i18n) => {
  return {
    [CAPTURE_DETAIL]: t('captureDetails.pageTitle'),
    [CAPTURE_HISTORY]: t('captureHistory.pageTitle'),
    [CAPTURE_RESULTS]: t('captureHistory.pageTitleCIRA'),
    [CLONE_INVOICE_PATTERN]: t('invoicePattern.cloneInvoicePattern'),
    [CREATE_ALERT]: t('alerts.createAlert.pageTitle'),
    [CREATE_INVOICE_PATTERN]: t('invoicePattern.createInvoicePattern'),
    [CREATE_REMITTANCE_ADVICE]: t('remittanceAdviceDetails.titles.create'),
    [CREATE_REPORT]: t('reports.customReport.newCustomReport'),
    [CUSTOMER_CLASSIFICATION]: t('customerClassification.pageTitle'),
    [DECISION_MANAGER_HISTORY]: t('decisionManager.titles.decisionHistory'),
    [DECISION_MANAGER_DETAILS]: t('decisionManagerDetails.title'),
    [DECISIONS_REQUIRING_ACTION]: t('decisionManager.titles.decisionRA'),
    [DECISIONS_REQUIRING_ACTION_DETAILS]: t('decisionManagerDetails.title'),
    [DISPLAYPRINTSETTINGS]: t('settingsPage.pageTitle'),
    [DOWNLOAD_CENTER]: t('downloadCenter.pageTitle'),
    [EDIT_ALERT]: t('alerts.editAlert.pageTitle'),
    [EDIT_INVOICE_PATTERN]: t('invoicePattern.editInvoicePattern'),
    [EDIT_REPORT]: t('reports.customReport.customReportTemplates'),
    [ENTITY_CREATE]: t('entityDetails.createEntity'),
    [ENTITY_DETAILS]: t('entityDetails.pageTitle'),
    [ENTITY_LIST]: t('entityList.pageTitle'),
    [ENTITY_LOOKUP]: t('entityLookup.pageTitle'),
    [ENTITIES_REQUIRING_ACTIVATION]: t('entityList.pagetitleERA'),
    [ENTITY_UPLOAD]: t('uploadentity.pageTitle'),
    [EXPORT_IMAGE]: t('imageViewer.imagePreview'),
    [HCEOBEXCEPTIONS]: t('transactions.titles.hcEOBexceptions'),
    [INBOX]: t('alerts.pageTitle'),
    [INVOICE_IDENTIFICATION_PATTERNS]: t('invoicePatterns.pageTitle'),
    [INVOICE_DETAILS]: t('invoiceSearch.invoiceDetails'),
    [INVOICE_SEARCH]: t('invoiceSearch.pageTitle'),
    [MANAGE]: t('alerts.manage.pageTitle'),
    [REASON_CODES]: t('reasoncode.pageTitle'),
    [REMITTANCE_ADVICE_DETAILS]: t('remittanceAdviceDetails.titles.details'),
    [REMITTANCE_ADVICE_LIST]: t('remittanceAdviceList.pageTitle'),
    [SCAN]: t('scanPage.pageTitle'),
    [STOP_GO]: t('stopGo.pageTitle'),
    [SUPPORT_PAGE]: t('support.pageTitle'),
    [TRANSACTIONS]: t('transactions.titles.transactionHistory'),
    [TRANSACTIONS_ASSIGNED_TO_ME]: t('transactions.titles.transactionATM'),
    [TRANSACTIONS_REQUIRING_ACTION]: t('transactions.titles.transactionRA'),
    [TRANSACTION_DETAIL]: t('transactionDetails.pageTitle'),
    [UPLOAD_WORKFLOW_NOTES]: t('uploadworkflownotes.pageTitle'),
    [UPLOAD_REMITTANCE_ADVICES]: t('uploadremittanceadvices.pageTitle'),
    [USER_ADMIN + CREATE_USER]: t('administration.createUser.pageTitle'),
    [USER_ADMIN + CREATE_USER + EXPRESS_USER_SETUP]: t('administration.expressSetup.pageTitle'),
    [USER_ADMIN + CREATE_USER + CLONE_USER_SETUP]: t('administration.cloneUser.pageTitle'),
    [USER_ADMIN + EDIT_USER]: t('administration.editUser.pageTitle'),
    [USER_ADMIN + EDIT_USER + EDIT]: t('administration.editUser.pageTitle'),
    [USER_ADMIN + EDIT_USER + REACTIVATE]: t('administration.reactivateUser.pageTitle'),
    [USER_ADMIN + VIEW_USER]: t('administration.viewUsers.pageTitle'),
    [USER_ADMIN + VIEW_USER + USER_DETAIL]: t('administration.userDetail.pageTitle')
  };
};
