import { Panel } from '@jpmuitk/panel';
import { ColumnLayout } from '@jpmuitk/column-layout';
import { ContentStatus } from '@jpmuitk/content-status';

import ConditionalWrapper from 'Components/ConditionalWrapper';

const SpinnerWithText = ({ container = true, message = 'Loading', height = '100%' }) => {
  return (
    <ConditionalWrapper
      condition={container}
      wrapper={children => (
        <ColumnLayout container justify="center" alignItems="center" style={{ height: '100%' }}>
          {children}
        </ColumnLayout>
      )}
    >
      <ColumnLayout xs={12} item style={{ height }}>
        <ContentStatus message={message} status="loading" />
      </ColumnLayout>
    </ConditionalWrapper>
  );
};

export default SpinnerWithText;
