import {
  CLEAR_USERS_PAGINATION,
  EXPORT_USER_DETAILS_FAILURE,
  EXPORT_USER_DETAILS_REQUEST,
  EXPORT_USER_DETAILS_SUCCESS,
  EXPORT_USER_SUMMARIES_FAILURE,
  EXPORT_USER_SUMMARIES_REQUEST,
  EXPORT_USER_SUMMARIES_SUCCESS,
  GET_USERS_PAGINATION_REQUEST,
  GET_USERS_PAGINATION_FAILURE,
  GET_USERS_PAGINATION_SUCCESS
} from 'routes/UserAdmin/userAdminActions';

const clearUsersPagination = (state, action) => {
  return { ...state, paginatedUsers: [] };
};

const exportUsersSummariesRequest = state => {
  return { ...state, isExportFetching: true };
};
const getUsersPaginationRequest = state => {
  return { ...state, isFetching: true, exportedUsersContent: undefined };
};

const exportUsersSummariesSuccess = (state, action) => {
  const { isExportFetching, ...rest } = state;
  return {
    ...rest,
    isExportFetching: false,
    exportedUsersContent: action.response
  };
};

const exportUsersSummariesFailure = (state, action) => {
  const { isExportFetching, ...rest } = state;
  return { ...rest, isExportFetching: false, errors: action.errors };
};

const exportUsersDetailsRequest = state => {
  return { ...state, isExportFetching: true, exportedUsersContent: undefined };
};

const exportUserDetailsSuccess = (state, action) => {
  const { isExportFetching, ...rest } = state;
  return {
    ...rest,
    isExportFetching: false,
    exportedUsersContent: action.response
  };
};

const exportUserDetailsFailure = (state, action) => {
  const { isExportFetching, ...rest } = state;
  return { ...rest, isExportFetching: false, errors: action.errors };
};

const getUsersPaginationSuccess = (state, action) => {
  const {
    response: {
      data: { users: paginatedUsers, totalRows }
    }
  } = action;

  return { ...state, paginatedUsers, totalRows, isFetching: false, errors: {} };
};

const getUsersPaginationFailure = (state, action) => {
  return {
    ...state,
    errors: action.error,
    isFetching: false,
    paginatedUsers: []
  };
};

export const viewUsersReducer = {
  [CLEAR_USERS_PAGINATION]: clearUsersPagination,
  [EXPORT_USER_SUMMARIES_REQUEST]: exportUsersSummariesRequest,
  [EXPORT_USER_SUMMARIES_SUCCESS]: exportUsersSummariesSuccess,
  [EXPORT_USER_SUMMARIES_FAILURE]: exportUsersSummariesFailure,
  [EXPORT_USER_DETAILS_REQUEST]: exportUsersDetailsRequest,
  [EXPORT_USER_DETAILS_SUCCESS]: exportUserDetailsSuccess,
  [EXPORT_USER_DETAILS_FAILURE]: exportUserDetailsFailure,
  [GET_USERS_PAGINATION_SUCCESS]: getUsersPaginationSuccess,
  [GET_USERS_PAGINATION_FAILURE]: getUsersPaginationFailure,
  [GET_USERS_PAGINATION_REQUEST]: getUsersPaginationRequest
};
