import api from 'api';
import { httpErrorTypes } from 'utility/error';
import { handleDownloadFile, getFileName } from 'utility/downloadUtility';
import { CONTENT_TYPE } from 'Constants/headers';
import { invoicePatternsDefaultFileName } from 'Constants/defaultFileNames';
import i18n from 'i18n';

export const LOAD_INVOICE_ID_PATTERNS_REQUEST = 'LOAD_INVOICE_ID_PATTERNS_REQUEST';
export const LOAD_INVOICE_ID_PATTERNS_SUCCESS = 'LOAD_INVOICE_ID_PATTERNS_SUCCESS';
export const LOAD_INVOICE_ID_PATTERNS_FAILURE = 'LOAD_INVOICE_ID_PATTERNS_FAILURE';
export const CLEAR_DOWNLOAD_INVOICE_PATTERNS_STATE = 'CLEAR_DOWNLOAD_INVOICE_PATTERNS_STATE';
export const DELETE_INVOICE_PATTERN_REQUEST = 'DELETE_INVOICE_PATTERN_REQUEST';
export const DELETE_INVOICE_PATTERN_SUCCESS = 'DELETE_INVOICE_PATTERN_SUCCESS';
export const DELETE_INVOICE_PATTERN_FAILURE = 'DELETE_INVOICE_PATTERN_FAILURE';
export const DOWNLOAD_INVOICE_PATTERNS_REQUEST = 'DOWNLOAD_INVOICE_PATTERNS_REQUEST';
export const DOWNLOAD_INVOICE_PATTERNS_SUCCESS = 'DOWNLOAD_INVOICE_PATTERNS_SUCCESS';
export const DOWNLOAD_INVOICE_PATTERNS_FAILURE = 'DOWNLOAD_INVOICE_PATTERNS_FAILURE';

const handleDownloadInvoicePatterns = () => (dispatch, getState) => {
  const exportData = getState()?.invoicePatterns.exportState.exportData;
  if (exportData?.data) {
    const type = exportData.request.getResponseHeader(CONTENT_TYPE);
    const fileName = getFileName(exportData, invoicePatternsDefaultFileName);
    handleDownloadFile(exportData.data, fileName, type);
    return dispatch(clearDownloadInvoicePatternsState());
  }
};

export const clearDownloadInvoicePatternsState = () => ({ type: CLEAR_DOWNLOAD_INVOICE_PATTERNS_STATE });

export const loadInvoiceIDPatterns = (params = {}) => ({
  types: [LOAD_INVOICE_ID_PATTERNS_REQUEST, LOAD_INVOICE_ID_PATTERNS_SUCCESS, LOAD_INVOICE_ID_PATTERNS_FAILURE],
  callAPI: (state, { customerId, userId }) =>
    api.fetch(`invoicereferencesvc/v0/cust/${customerId}/user/${userId}/invoice-identification-patterns`),
  params,
  httpErrorTypes
});

export const deleteInvoicePattern = (invoicePatternId, dispatchOnSuccess = []) => ({
  types: [DELETE_INVOICE_PATTERN_REQUEST, DELETE_INVOICE_PATTERN_SUCCESS, DELETE_INVOICE_PATTERN_FAILURE],
  callAPI: (state, { customerId, userId }) =>
    api.save(
      `invoicereferencesvc/v0/cust/${customerId}/user/${userId}/delete-invoice-identification-pattern/${invoicePatternId}`
    ),
  dispatchOnSuccess: [...dispatchOnSuccess],
  httpErrorTypes,
  errorDisplayType: 'modal',
  errorTitle: i18n.t('invoicePatterns.errors.unableToDelete')
});

export const downloadInvoicePatterns = (payload, dispatchOnSuccess = []) => ({
  types: [DOWNLOAD_INVOICE_PATTERNS_REQUEST, DOWNLOAD_INVOICE_PATTERNS_SUCCESS, DOWNLOAD_INVOICE_PATTERNS_FAILURE],
  callAPI: (state, { customerId, userId }) =>
    api.save(
      `invoicereferencesvc/v0/cust/${customerId}/user/${userId}/export-invoice-identification-patterns`,
      payload
    ),
  dispatchOnSuccess: [...dispatchOnSuccess, handleDownloadInvoicePatterns],
  httpErrorTypes,
  errorDisplayType: 'modal',
  errorTitle: i18n.t('invoicePatterns.errors.unableToExport')
});
