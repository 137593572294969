import {
  CLEAR_REPORT_EXPORT,
  CLEAR_REPORT_FILTERS,
  CLEAR_REPORT_RESULT,
  LOAD_ENTITLED_REPORTS_FAILURE,
  LOAD_ENTITLED_REPORTS_REQUEST,
  LOAD_ENTITLED_REPORTS_SUCCESS,
  LOAD_REPORT_EXPORT_FAILURE,
  LOAD_REPORT_EXPORT_REQUEST,
  LOAD_REPORT_EXPORT_SUCCESS,
  LOAD_REPORT_FILTERS_FAILURE,
  LOAD_REPORT_FILTERS_REQUEST,
  LOAD_REPORT_FILTERS_SUCCESS,
  LOAD_REPORT_RESULT_FAILURE,
  LOAD_REPORT_RESULT_REQUEST,
  LOAD_REPORT_RESULT_SUCCESS
} from './reportsActions';
import i18n from 'i18n';

const INPUT_TYPE_CHECKBOX = 'checkbox';
const INPUT_TYPE_DROPDOWN = 'dropdown';
const initialState = {
  columnDefs: [],
  columnTypes: {},
  reportTypeError: {},
  reportFiltersError: {},
  reportResultError: {},
  exportErrors: {},
  grandItemCount: null,
  grandTotal: null,
  isFetchingReportTypes: false,
  isFetchingReportFilters: false,
  isFetchingReportResult: false,
  isFetchingReportExport: false,
  reportFilters: [],
  rowData: [],
  totalRow: [],
  exportData: []
};

export const mapDefaultSelectionForFilters = filters => {
  return filters.map(entry => {
    if (entry.type === INPUT_TYPE_CHECKBOX) {
      return {
        ...entry,
        selected: entry.defaultOptionLabel === 'Checked'
      };
    }
    if (entry.type === INPUT_TYPE_DROPDOWN) {
      const matched = entry.data.find(item => item.optionLabel === entry.defaultOptionLabel);

      if (matched) {
        return {
          ...entry,
          data: entry.data,
          selected: matched
        };
      }

      const selected = {
        id: -1,
        optionLabel: entry.defaultOptionLabel || i18n.t('customreport.labels.all')
      };

      return entry.required
        ? {
            ...entry,
            data: entry.data
          }
        : {
            ...entry,
            data: [selected].concat(entry.data),
            selected
          };
    } else if (entry.defaultOptionLabel) {
      return {
        ...entry,
        selected: { optionLabel: entry.defaultOptionLabel }
      };
    } else {
      return entry;
    }
  });
};

const loadEntitledReportsRequest = state => {
  return {
    ...state,
    isFetchingReportTypes: true,
    reportTypes: [],
    reportTypeError: {},
    exportErrors: {}
  };
};

const loadEntitledReportsSuccess = (state, action) => {
  return {
    ...state,
    isFetchingReportTypes: false,
    reportTypes: action.reportTypes,
    reportTypeError: {}
  };
};

const loadEntitledReportsFailure = (state, action) => {
  return {
    ...state,
    isFetchingReportTypes: false,
    reportTypes: [],
    reportTypeError: action.errors
  };
};

const loadReportFiltersRequest = state => {
  return {
    ...state,
    isFetchingReportFilters: true,
    reportFilters: [],
    reportFiltersError: {},
    exportErrors: {}
  };
};

const loadReportFiltersSuccess = (state, action) => {
  return {
    ...state,
    isFetchingReportFilters: false,
    reportFilters: mapDefaultSelectionForFilters(action.reportFilters),
    reportFiltersError: {},
    exportData: []
  };
};

const loadReportFiltersFailure = (state, action) => {
  return {
    ...state,
    isFetchingReportFilters: false,
    reportFilters: [],
    reportFiltersError: action.errors,
    exportData: []
  };
};

const clearReportFilters = state => {
  return {
    ...state,
    isFetchingReportFilters: false,
    reportFilters: [],
    reportFiltersError: {},
    exportData: []
  };
};

const loadReportResultRequest = state => {
  return {
    ...state,
    isFetchingReportResult: true
  };
};

export function convertGrandTotalForGridBottom(grandTotal) {
  return Object.getOwnPropertyNames(grandTotal).map(k => {
    return { currencyCode: k, amount: grandTotal[k] };
  });
}

// middleware sends in action.type and action.response
// do post-processing here to ensure it only happens once after the service call
const loadReportResultSuccess = (state, action) => {
  const response = action.response;
  const responseGrandTotal = response.data.grandAmountTotal;
  const grandTotal = convertGrandTotalForGridBottom(responseGrandTotal);

  return {
    ...state,
    reportResultError: {},
    grandItemCount: response.data.grandItemCount,
    grandTotal,
    isFetchingReportResult: false,
    rowData: response.data.reportData.rows,
    totalRow: response.data.reportData.totalRow ? [response.data.reportData.totalRow] : [],
    data: response.data,
    exportData: []
  };
};

const loadReportResultFailure = (state, action) => {
  return {
    ...state,
    columnDefs: [],
    columnTypes: {},
    reportResultError: action.error,
    grandItemCount: null,
    grandTotal: null,
    isFetchingReportResult: false,
    rowData: [],
    exportData: []
  };
};

const clearReportResult = state => {
  return {
    ...state,
    columnDefs: [],
    columnTypes: {},
    reportResultError: {},
    grandItemCount: null,
    grandTotal: null,
    isFetchingReportResult: false,
    rowData: [],
    exportData: []
  };
};

const loadReportExportRequest = state => {
  return {
    ...state,
    exportErrors: {},
    isFetchingReportExport: true,
    exportData: []
  };
};
const loadReportExportSuccess = (state, action) => {
  return {
    ...state,
    exportErrors: {},
    isFetchingReportExport: false,
    exportData: action.response
  };
};

const loadReportExportFailure = (state, action) => {
  return {
    ...state,
    exportErrors: { ...action.error },
    isFetchingReportExport: false,
    exportData: []
  };
};

const clearReportExport = state => {
  return {
    ...state,
    exportErrors: {},
    isFetchingReportExport: false,
    exportData: []
  };
};

export default function reportsReducer(state = initialState, action) {
  const handlers = {
    [LOAD_ENTITLED_REPORTS_REQUEST]: loadEntitledReportsRequest,
    [LOAD_ENTITLED_REPORTS_SUCCESS]: loadEntitledReportsSuccess,
    [LOAD_ENTITLED_REPORTS_FAILURE]: loadEntitledReportsFailure,
    [LOAD_REPORT_FILTERS_REQUEST]: loadReportFiltersRequest,
    [LOAD_REPORT_FILTERS_SUCCESS]: loadReportFiltersSuccess,
    [LOAD_REPORT_FILTERS_FAILURE]: loadReportFiltersFailure,
    [CLEAR_REPORT_FILTERS]: clearReportFilters,
    [LOAD_REPORT_RESULT_REQUEST]: loadReportResultRequest,
    [LOAD_REPORT_RESULT_SUCCESS]: loadReportResultSuccess,
    [LOAD_REPORT_RESULT_FAILURE]: loadReportResultFailure,
    [CLEAR_REPORT_RESULT]: clearReportResult,
    [LOAD_REPORT_EXPORT_REQUEST]: loadReportExportRequest,
    [LOAD_REPORT_EXPORT_SUCCESS]: loadReportExportSuccess,
    [LOAD_REPORT_EXPORT_FAILURE]: loadReportExportFailure,
    [CLEAR_REPORT_EXPORT]: clearReportExport
  };

  return handlers.hasOwnProperty(action.type) ? handlers[action.type](state, action) : state;
}
