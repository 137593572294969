import api from 'api';
export const LOAD_STOPGO_REQUEST = 'LOAD_STOPGO_REQUEST';
export const LOAD_STOPGO_SUCCESS = 'LOAD_STOPGO_SUCCESS';
export const LOAD_STOPGO_FAILURE = 'LOAD_STOPGO_FAILURE';
export const UPLOAD_STOPGO_FILE_REQUEST = 'UPLOAD_STOPGO_FILE_REQUEST';
export const UPLOAD_STOPGO_FILE_SUCCESS = 'UPLOAD_STOPGO_FILE_SUCCESS';
export const UPLOAD_STOPGO_FILE_FAILURE = 'UPLOAD_STOPGO_FILE_FAILURE';
export const SAVE_STOPGO_REQUEST = 'SAVE_STOPGO_REQUEST';
export const SAVE_STOPGO_SUCCESS = 'SAVE_STOPGO_SUCCESS';
export const SAVE_STOPGO_FAILURE = 'SAVE_STOPGO_FAILURE';
export const CLEAR_UPLOAD = 'CLEAR_UPLOAD';
import { stopGoURL } from './utils';
import { httpErrorTypes } from 'utility/error';

export const errorTypes = {
  DEFAULT_ERROR: 'DEFAULT_ERROR',
  NO_DATA: 'NO_DATA'
};

export const loadStopGo = (payload, t) => ({
  types: [LOAD_STOPGO_REQUEST, LOAD_STOPGO_SUCCESS, LOAD_STOPGO_FAILURE],
  successCase: response => response.data,
  successCaseNotMet: {
    errorType: errorTypes.DEFAULT_ERROR,
    message: t('stopGo.messages.noReportsFound')
  },
  callAPI: (state, { customerId, userId }) => {
    return api.save(`stopgosvc/v0/cust/${customerId}/user/${userId}/stopgofiles`, payload);
  },
  httpErrorTypes: {
    DEFAULT: errorTypes.DEFAULT_ERROR,
    404: {
      type: errorTypes.NO_DATA,
      message: t('stopGo.messages.noDataFound')
    }
  }
});
export const SaveStopGo = (file, values) => {
  const uploadType = values.uploadType;
  const programId = values.programId?.id;
  return {
    types: [SAVE_STOPGO_REQUEST, SAVE_STOPGO_SUCCESS, SAVE_STOPGO_FAILURE],
    callAPI: (state, { customerId, userId }) =>
      api.postWithFile(stopGoURL(`program/${programId}/action/${uploadType}/upload-stopgofile`), file),
    dispatchOnSuccess: [clearUpload],
    dispatchOnFailure: [clearUpload],
    httpErrorTypes
  };
};
export const clearUpload = () => ({
  type: CLEAR_UPLOAD
});

export const uploadFileAction = (file, program_id, t) => ({
  types: [UPLOAD_STOPGO_FILE_REQUEST, UPLOAD_STOPGO_FILE_SUCCESS, UPLOAD_STOPGO_FILE_FAILURE],
  callAPI: (state, { customerId, userId }) =>
    api.postWithFile(stopGoURL(`program/${program_id}/validate-stopgofile`), file),
  httpErrorTypes: {
    DEFAULT: errorTypes.DEFAULT_ERROR,
    403: {
      type: errorTypes.NO_DATA,
      message: t('stopGo.messages.checkFileFormat')
    }
  }
});
