import React from 'react';
import {
  CLEAR_REM_ADV_LIST_ERR,
  LOAD_REMITTANCES_REQUEST,
  LOAD_REMITTANCES_SUCCESS,
  LOAD_REMITTANCES_FAILURE,
  EXPORT_REMITTANCES_SUCCESS,
  EXPORT_REMITTANCES_FAILURE
} from './RemittanceAdviceListActions';

export const initialState = {
  remittanceAdviceErrors: {},
  remittanceAdvices: []
};

const loadRemittancesRequest = (state, action) => ({
  ...state,
  ...initialState,
  isFetching: true,
  remittanceAdviceErrors: {}
});

const loadRemittancesSuccess = (state, action) => {
  const { grandTotal, remittanceAdvices, totalRows, eremittanceCustomer } = action.response.data;
  return {
    ...state,
    grandTotal,
    remittanceAdvices,
    eremittanceCustomer,
    totalRows,
    remittanceAdviceErrors: {},
    isFetching: false
  };
};

const loadRemittancesFailure = (state, action) => {
  return {
    ...state,
    remittanceAdviceErrors: { ...action.error, isGridError: true },
    totalRows: 0,
    isFetching: false
  };
};

const exportRemittancesSuccess = (state, action) => {
  return { ...state, remittanceAdviceErrors: {}, exportedRemittanceAdvices: action.response };
};
const exportRemittancesFailure = (state, action) => {
  return { ...state, exportedRemittanceAdvices: {}, remittanceAdviceErrors: action.error };
};

const claerRemmitancesErr = (state, action) => ({ ...state, remittanceAdviceErrors: {} });

export default function remittancesReducer(state = initialState, action) {
  const handlers = {
    [LOAD_REMITTANCES_REQUEST]: loadRemittancesRequest,
    [LOAD_REMITTANCES_SUCCESS]: loadRemittancesSuccess,
    [LOAD_REMITTANCES_FAILURE]: loadRemittancesFailure,
    [EXPORT_REMITTANCES_SUCCESS]: exportRemittancesSuccess,
    [EXPORT_REMITTANCES_FAILURE]: exportRemittancesFailure,
    [CLEAR_REM_ADV_LIST_ERR]: claerRemmitancesErr
  };
  if (handlers.hasOwnProperty(action.type)) {
    return handlers[action.type](state, action);
  } else {
    return state;
  }
}
