import {
  LOAD_SUPPORT_PAGE_REQUEST,
  LOAD_SUPPORT_PAGE_SUCCESS,
  LOAD_SUPPORT_PAGE_FAILURE,
  RESET_SUPPORT_PAGE,
  DOWNLOAD_PRODUCT_GUIDE_REQUEST,
  DOWNLOAD_PRODUCT_GUIDE_SUCCESS,
  DOWNLOAD_PRODUCT_GUIDE_FAILURE
} from './supportActions';

const initialState = {
  supportError: {}
};

const loadSupportPageRequest = (state, action) => {
  return {
    ...state,
    isFetching: true,
    supportError: {}
  };
};

const loadSupportPageSuccess = (state, action) => {
  return {
    ...state,
    ...action.response.data,
    isFetching: false
  };
};

const loadSupportPageFailure = (state, action) => {
  return {
    ...state,
    supportError: action.error,
    isFetching: false
  };
};

const resetSupportPage = (state, action) => {
  return {
    ...initialState
  };
};

const downloadProgramGuideRequest = (state, action) => {
  return {
    ...state,
    supportError: {},
    isFetchingProductGuide: true
  };
};

const downloadProgramGuideSuccess = (state, action) => {
  return {
    ...state,
    downloadedGuide: action.response,
    isFetchingProductGuide: false
  };
};

const downloadProgramGuideFailure = (state, action) => {
  return {
    ...state,
    supportError: action.error,
    isFetchingProductGuide: false
  };
};

export default function supportReducer(state = initialState, action) {
  const handlers = {
    [LOAD_SUPPORT_PAGE_REQUEST]: loadSupportPageRequest,
    [LOAD_SUPPORT_PAGE_SUCCESS]: loadSupportPageSuccess,
    [LOAD_SUPPORT_PAGE_FAILURE]: loadSupportPageFailure,
    [RESET_SUPPORT_PAGE]: resetSupportPage,
    [DOWNLOAD_PRODUCT_GUIDE_REQUEST]: downloadProgramGuideRequest,
    [DOWNLOAD_PRODUCT_GUIDE_SUCCESS]: downloadProgramGuideSuccess,
    [DOWNLOAD_PRODUCT_GUIDE_FAILURE]: downloadProgramGuideFailure
  };
  return handlers.hasOwnProperty(action.type) ? handlers[action.type](state, action) : state;
}
