import api from 'api';
import cloneDeep from 'lodash/cloneDeep';
import { mapValuesToPayload } from 'routes/utils/payload';
import { httpErrorTypes } from 'utility/error';
import { remittanceURL } from 'routes/RemittanceAdviceList/utils';
import { fieldUnformatter, FIELD_TYPES, unformatPayload } from 'hooks/useFieldFormatter';

export const LOAD_REMITTANCES_REQUEST = 'LOAD_REMITTANCES_REQUEST';
export const LOAD_REMITTANCES_SUCCESS = 'LOAD_REMITTANCES_SUCCESS';
export const LOAD_REMITTANCES_FAILURE = 'LOAD_REMITTANCES_FAILURE';
export const EXPORT_REMITTANCES_REQUEST = 'EXPORT_REMITTANCES_REQUEST';
export const EXPORT_REMITTANCES_SUCCESS = 'EXPORT_REMITTANCES_SUCCESSS';
export const EXPORT_REMITTANCES_FAILURE = 'EXPORT_REMITTANCES_FAILURE';

export const CLEAR_REM_ADV_LIST_ERR = 'CLEAR_REM_ADV_LIST_ERR';

export const REM_ADV_LIST_ERR_TYPES = {
  DEFAULT: 'TRY_AGAIN'
};

export const loadRemittances = (payload, serviceAction = '') => (dispatch, getState) => {
  const actionQueryParam = serviceAction ? `?action=${serviceAction}` : '';
  const data = cloneDeep(payload);
  const userSettings = getState().settings.userSettings;

  const fieldsToUnformat = { amount: FIELD_TYPES.AMOUNT };
  const newPayload = unformatPayload(fieldsToUnformat, data)(userSettings);
  return dispatch({
    types: [LOAD_REMITTANCES_REQUEST, LOAD_REMITTANCES_SUCCESS, LOAD_REMITTANCES_FAILURE],
    callAPI: (state, options) => api.save(remittanceURL(`remittance-advice-search${actionQueryParam}`), newPayload),
    httpErrorTypes: REM_ADV_LIST_ERR_TYPES
  });
};

export const exportRemittanceAdvices = payload => (dispatch, getState) => {
  const requestPayload = cloneDeep(payload);
  const userSettings = getState().settings.userSettings;
  const fieldsToUnformat = { amount: FIELD_TYPES.AMOUNT };
  const unformattedFilterCriteria = unformatPayload(fieldsToUnformat, requestPayload.filterCriteria)(userSettings);
  requestPayload.filterCriteria = mapValuesToPayload(unformattedFilterCriteria);

  return dispatch({
    types: [EXPORT_REMITTANCES_REQUEST, EXPORT_REMITTANCES_SUCCESS, EXPORT_REMITTANCES_FAILURE],
    callAPI: (state, { customerId, userId }) =>
      api.fetchPdf(
        `remittanceadvicesvc/v0/cust/${customerId}/user/${userId}/remittance-advice-search/export`,
        requestPayload
      ),
    httpErrorTypes: REM_ADV_LIST_ERR_TYPES,
    errorDisplayType: 'modal'
  });
};

export const clearRemittanceAdviceListError = () => ({ type: CLEAR_REM_ADV_LIST_ERR });
