import { UPDATE_IMAGES_CACHE, CLEAR_IMAGES_CACHE } from './imageViewerCacheActions';

export const initialState = {
  imagesCache: new Map(),
  thumbnailsCache: []
};

const updateImagesCache = (state, action) => {
  return Object.assign({}, state, {
    imagesCache: action.imagesMap,
    thumbnailsCache: action.thumbnails
  });
};

const clearImagesCache = state => {
  return initialState;
};

const handlers = {
  [UPDATE_IMAGES_CACHE]: updateImagesCache,
  [CLEAR_IMAGES_CACHE]: clearImagesCache
};

export default function imageViewerCacheReducer(state = initialState, action) {
  return handlers.hasOwnProperty(action.type) ? handlers[action.type](state, action) : state;
}
