import { style } from '@jpmuitk/app-header/style';

export const errorGridStyles = {
  root: {
    height: '100%',
    width: 610,
    overflow: 'hidden',
    '& .ag-center-cols-viewport': {
      overflow: 'hidden'
    }
  }
};

export const errorListStyles = {
  root: {
    maxHeight: 300,
    width: 650,
    height: 'auto',
    overflow: 'auto'
  },
  printBtn: {
    marginTop: 10,
    marginLeft: 500
  }
};

export const uploadFileZoneStyles = ({ palette: { toolkit } }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: toolkit.grey10,
    width: '600px',
    padding: '10px',
    border: `1px dashed ${toolkit.grey80}`
  },
  statusCls: {
    width: 'auto',
    height: 'auto',
    margin: 0,
    '& div:last-child': {
      maxWidth: 600
    }
  },
  action: {
    marginTop: '10px',
    alignSelf: 'center'
  },
  disabled: {
    cursor: 'not-allowed'
  }
});
