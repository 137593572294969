import cssVariables from 'Constants/_variables.module.scss';

export const styles = {
  default: {
    marginLeft: '50%'
  },
  imageLoadingSpinner: {
    marginLeft: '50%',
    marginTop: '10%'
  }
};

export const centerStyles = () => ({
  root: {
    margin: '15% auto'
  }
});
