import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Spinner } from '@jpmuitk/spinner';

import { FormContainer } from 'Components/FormsNext';
import ConditionalWrapper from 'Components/ConditionalWrapper';

import { FormContext } from './Context';
import { usePageStyles } from 'styles';

const PageRoot = ({ child, children, loading, centerContent, narrow }) => {
  const classes = usePageStyles({ narrow });
  const MainOrDiv = child ? 'div' : 'main';

  return (
    <FormContext.Provider value={{ narrow }}>
      <MainOrDiv className={centerContent ? classNames(classes.root, classes.centerContentWrapper) : classes.root}>
        <div className={classNames(classes.pageWrapper, { [classes.loading]: loading })}>
          <ConditionalWrapper condition={narrow} wrapper={children => <FormContainer>{children}</FormContainer>}>
            {!loading ? children : <Spinner />}
          </ConditionalWrapper>
        </div>
      </MainOrDiv>
    </FormContext.Provider>
  );
};

PageRoot.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired
};

export default PageRoot;
