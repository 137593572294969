import api from 'api';

import { httpErrorTypes } from 'utility/error';

import { clearScanSource, updateSelectedScanner } from 'actions/scanControlServiceActions';
import sessionHelper, { sessionUser } from 'utility/sessionStorageHelper';

export const CLEAR_USER_CUSTOMER = 'CLEAR_USER_CUSTOMER';
export const LOAD_SCANNERS_REQUEST = 'LOAD_SCANNERS_REQUEST';
export const LOAD_SCANNERS_SUCCESS = 'LOAD_SCANNERS_SUCCESS';
export const LOAD_SCANNERS_FAILURE = 'LOAD_SCANNERS_FAILURE';
export const SAVE_SELECTED_SCANNER_REQUEST = 'SAVE_SELECTED_SCANNER_REQUEST';
export const SAVE_SELECTED_SCANNER_SUCCESS = 'SAVE_SELECTED_SCANNER_SUCCESS';
export const SAVE_SELECTED_SCANNER_FAILURE = 'SAVE_SELECTED_SCANNER_FAILURE';
export const LOAD_USER_SETTINGS_REQUEST = 'LOAD_USER_SETTINGS_REQUEST';
export const LOAD_USER_SETTINGS_SUCCESS = 'LOAD_USER_SETTINGS_SUCCESS';
export const LOAD_USER_SETTINGS_FAILURE = 'LOAD_USER_SETTINGS_FAILURE';
export const SET_FULL_PORTAL = 'SET_FULL_PORTAL';
export const SET_SIMULATION_MODE = 'SET_SIMULATION_MODE';
export const SET_USER_CUSTOMER = 'SET_USER_CUSTOMER';

const SAVE_SETTINGS_REQUEST = 'SAVE_SETTINGS_REQUEST';
export const SAVE_SETTINGS_SUCCESS = 'SAVE_SETTINGS_SUCCESS';
const SAVE_SETTINGS_FAILURE = 'SAVE_SETTINGS_FAILURE';

const FULL_PORTAL_TYPE_CODE = 1;

export const saveSettings = (payload, values) => ({
  types: [SAVE_SETTINGS_REQUEST, SAVE_SETTINGS_SUCCESS, SAVE_SETTINGS_FAILURE],
  callAPI: (state, { customerId, userId }) =>
    api.save(`usersvc/v0/cust/${customerId}/user/${userId}/user/settings/display/update-user-settings`, values),
  httpErrorTypes,
  params: { values }
});

export const setUserConfig = (userInfo, simulateMode) => (dispatch, getState) => {
  sessionUser.setLoggedInUser(userInfo);
  sessionHelper.setItem('simulation', JSON.stringify(simulateMode));
  const { userCustomer } = userInfo;
  dispatch({ type: SET_USER_CUSTOMER, userCustomer, userInfo });
  dispatch({ type: SET_FULL_PORTAL, isFullPortal: userCustomer.portalTypeCode === FULL_PORTAL_TYPE_CODE });
  dispatch({ type: SET_SIMULATION_MODE, simulateMode });
};

export const clearCustomerWebId = () => {
  sessionUser.setLoggedInUser(null);
  return { type: SET_USER_CUSTOMER, userCustomer: {}, userInfo: {}, isFullPortal: false, simulateMode: {} };
};

export const setFullPortalStatus = ({ userCustomer }) => {
  return { type: SET_FULL_PORTAL, isFullPortal: userCustomer.portalTypeCode === FULL_PORTAL_TYPE_CODE };
};

export function loadScannersRequest() {
  return { type: LOAD_SCANNERS_REQUEST };
}
export function loadScannersSuccess(scanners) {
  return { type: LOAD_SCANNERS_SUCCESS, scanners };
}

export function loadScannersFailure() {
  return { type: LOAD_SCANNERS_FAILURE };
}

export function loadScanners(custId, userId) {
  const updateScannersForPanini = scanners => {
    const paniniVisionXScannerCode = 'X';
    return scanners.map(scanner =>
      scanner.scannerCode === paniniVisionXScannerCode
        ? { ...scanner, scannerDriverName: scanner.scannerDriverName + paniniVisionXScannerCode }
        : scanner
    );
  };
  return dispatch => {
    return api
      .fetch(`usersvc/v0/cust/${custId}/user/${userId}/view/scanners`)
      .then(scanners => {
        if (Array.isArray(scanners.data)) {
          const scannersForPanini = updateScannersForPanini(scanners.data);
          dispatch(loadScannersSuccess(scannersForPanini));
        } else {
          dispatch(loadScannersFailure());
        }
      })
      .catch(err => {
        dispatch(loadScannersFailure());
      });
  };
}

export function loadUserSettingsRequest() {
  return { type: LOAD_USER_SETTINGS_REQUEST };
}

export function loadUserSettingsSuccess(userSettings) {
  return { type: LOAD_USER_SETTINGS_SUCCESS, userSettings };
}

export function loadUserSettingsFailure(error) {
  return { type: LOAD_SCANNERS_FAILURE, error };
}

export function loadUserSettings(custId, userId) {
  return dispatch => {
    return api
      .fetch(`usersvc/v0/cust/${custId}/user/${userId}/user/settings`)
      .then(userSettings => {
        dispatch(loadUserSettingsSuccess(userSettings));
      })
      .then(() => {
        dispatch(loadScannersRequest());
        dispatch(loadScanners(custId, userId));
      })
      .catch(error => {
        dispatch(loadUserSettingsFailure(error));
      });
  };
}

export function saveSelectedScannerRequest() {
  return { type: SAVE_SELECTED_SCANNER_REQUEST };
}

export function saveSelectedScannerSuccess() {
  return { type: SAVE_SELECTED_SCANNER_SUCCESS };
}
export const saveSelectedScanner = (custId, userId, scannerCode) => dispatch => {
  return api
    .save(`usersvc/v0/cust/${custId}/user/${userId}/user/settings/update`, scannerCode)
    .then(scanners => {
      dispatch(saveSelectedScannerSuccess());
      return scanners;
    })
    .catch(error => {
      return error;
    });
};
