import i18n from 'i18n';

export const statusCodeToMessage = ({ data: { message = '' } = {}, status }) =>
  message && status === 404
    ? message
    : message === 'GENERAL' || status === 503 || status === 500 || status === 403 || status === 401
    ? i18n.t('callApiMiddleWare.defaultErrorMessage')
    : null;

export const errorActions = {
  RELOAD_PAGE: 'RELOAD_PAGE',
  TRY_AGAIN: 'TRY_AGAIN',
  GO_BACK: 'GO_BACK'
};

export const httpErrorTypes = {
  DEFAULT: errorActions.TRY_AGAIN,
  400: errorActions.TRY_AGAIN,
  403: errorActions.TRY_AGAIN,
  404: errorActions.TRY_AGAIN
};
