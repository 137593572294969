import { createContext, useContext, useRef } from 'react';

export const useSkipLinkContextErrorMessage = 'useSkipLinkContext must be inside a Provider';
export const SkipLinkContext = createContext();

const createUseSkipLinkContext = SkipLinkContext => () => {
  const context = useContext(SkipLinkContext);

  if (context === undefined) throw new Error(useSkipLinkContextErrorMessage);

  return context;
};

export const SkipLinkContextProvider = ({ children }) => {
  const mainRef = useRef(null);

  return <SkipLinkContext.Provider value={[mainRef]}>{children}</SkipLinkContext.Provider>;
};

export const useSkipLinkContext = createUseSkipLinkContext(SkipLinkContext);
