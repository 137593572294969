import React from 'react';
import i18n from 'i18n';

import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';

export const DELIMITERS = {
  COMMA: ',',
  DECIMAL: '.',
  SPACE: ' ',
  TICK: "'"
};

export const formatDecimals = decimals => {
  if (isEmpty(decimals)) {
    return '00';
  } else if (decimals.length >= 2) {
    return decimals.substring(0, 2);
  } else {
    return decimals;
  }
};

export const stripDelimiters = radices => (isString(radices) ? radices.replace(/[ ,.']/g, '') : radices);
export const normalizeDelimiters = radices => (isString(radices) ? radices.replace(/[ ,.']/g, '.') : radices);

export const getDelimiters = userCurrencyFormat => {
  let thousandDelimiter;
  let radixDecimalDelimeter;
  switch (userCurrencyFormat?.id) {
    case 1:
      thousandDelimiter = DELIMITERS.COMMA;
      radixDecimalDelimeter = DELIMITERS.DECIMAL;
      break;
    case 2:
      thousandDelimiter = DELIMITERS.DECIMAL;
      radixDecimalDelimeter = DELIMITERS.COMMA;
      break;
    case 3:
      thousandDelimiter = DELIMITERS.TICK;
      radixDecimalDelimeter = DELIMITERS.DECIMAL;
      break;
    case 4:
      thousandDelimiter = DELIMITERS.SPACE;
      radixDecimalDelimeter = DELIMITERS.DECIMAL;
      break;
    case 5:
      thousandDelimiter = DELIMITERS.SPACE;
      radixDecimalDelimeter = DELIMITERS.COMMA;
      break;
    default:
      thousandDelimiter = DELIMITERS.COMMA;
      radixDecimalDelimeter = DELIMITERS.DECIMAL;
      break;
  }
  return [thousandDelimiter, radixDecimalDelimeter];
};

export const amountFormatter = (amount, { userCurrencyFormat }) => {
  const asNum = isNaN(amount) ? amountUnformatter(amount, { userCurrencyFormat }) : amount;
  const [thousandSeparator, decimalSeparator] = getDelimiters(userCurrencyFormat);
  const formatter = new Intl.NumberFormat('en', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  });
  return formatter
    .formatToParts(asNum)
    .map(({ type, value }) => {
      switch (type) {
        case 'group':
          return thousandSeparator;
        case 'decimal':
          return decimalSeparator;
        default:
          return value;
      }
    })
    .reduce((string, part) => string + part);
};

export const amountUnformatter = (amount = 0, { userCurrencyFormat }, params = {}) => {
  let amt = Number(amount);
  if (typeof amt === 'number' && !isNaN(amt)) {
    return amt;
  }
  let stringifiedAmount = typeof amount !== 'string' ? amount.toString() : amount;
  if (params.allowSign) {
    stringifiedAmount = stringifiedAmount.replace('+', '');
  }
  const [thousandDelimiter, radixDecimalDelimeter] = getDelimiters(userCurrencyFormat);
  const [radices, decimals] = stringifiedAmount.split(radixDecimalDelimeter);
  const strippedRadices = stripDelimiters(radices);
  const formattedDecimals = formatDecimals(decimals);
  const combined = DELIMITERS.DECIMAL + formattedDecimals;
  const dec = !isNil(decimals) ? combined : '';
  amt = strippedRadices + dec;
  return isNaN(amt) ? amount : Number(amt);
};

export const amountValidations = ({ userCurrencyFormat }, params = {}) => {
  const [thousandDelimiter, radixDecimalDelimeter] = getDelimiters(userCurrencyFormat);
  const msg = ADA_LABEL(thousandDelimiter, radixDecimalDelimeter, params);
  const regex = params.allowSign
    ? new RegExp(
        `(?=.*?\\d)^\\$?[-+]?(([1-9]\\d{0,2}(${thousandDelimiter}\\d{3})*)|\\d+)?(\\${radixDecimalDelimeter}\\d{1,2})?$`
      )
    : new RegExp(
        `(?=.*?\\d)^\\$?(([1-9]\\d{0,2}(${thousandDelimiter}\\d{3})*)|\\d+)?(\\${radixDecimalDelimeter}\\d{1,2})?$`
      );
  return params.type === 'react-hook-form' ? [regex, { message: msg, excludeEmptyString: true }] : { regex, msg };
};

export const delimiterToEnglish = t => ({
  [DELIMITERS.COMMA]: t('validation.comma'),
  [DELIMITERS.DECIMAL]: t('validation.decimal'),
  [DELIMITERS.SPACE]: t('validation.space'),
  [DELIMITERS.TICK]: t('validation.tick')
});

const ADA_LABEL = (thousandDelimiter, radicesDelimiter, params = {}) =>
  i18n.t('validation.amountLabel', {
    thousand: delimiterToEnglish(i18n.t)[thousandDelimiter],
    radices: delimiterToEnglish(i18n.t)[radicesDelimiter]
  });

export const amountLabels = (defaultLabel, { userCurrencyFormat }) => {
  const [thousandDelimiter, radixDecimalDelimeter] = getDelimiters(userCurrencyFormat);
  const hiddenAccessibleText = ADA_LABEL(thousandDelimiter, radixDecimalDelimeter);
  const label = (
    <span>
      {defaultLabel}
      {hiddenAccessibleText && <span className="visually-hidden">{', ' + hiddenAccessibleText} </span>}
      <span aria-hidden="true">{` (###${thousandDelimiter}###${radixDecimalDelimeter}##)`} </span>
    </span>
  );
  return {
    hiddenAccessibleText,
    label
  };
};

export default amountFormatter;
