import api from 'api';
import isEmpty from 'lodash/isEmpty';
import { FILENAME_REGEX } from 'Constants/regex';
import { handleDownloadFile } from 'utility/downloadUtility';
import i18n from 'i18n';

export const LOAD_DOWNLOAD_CENTER_REQUEST = 'LOAD_DOWNLOAD_CENTER_REQUEST';
export const LOAD_DOWNLOAD_CENTER_SUCCESS = 'LOAD_DOWNLOAD_CENTER_SUCCESS';
export const LOAD_DOWNLOAD_CENTER_FAILURE = 'LOAD_DOWNLOAD_CENTER_FAILURE';
export const CLEAR_DOWNLOAD_CENTER = 'CLEAR_DOWNLOAD_CENTER';
export const LOAD_DOWNLOAD_REPORT_REQUEST = 'LOAD_DOWNLOAD_REPORT_REQUEST';
export const LOAD_DOWNLOAD_REPORT_SUCCESS = 'LOAD_DOWNLOAD_REPORT_SUCCESS';
export const LOAD_DOWNLOAD_REPORT_FAILURE = 'LOAD_DOWNLOAD_REPORT_FAILURE';
export const CLEAR_DOWNLOAD_REPORT = 'CLEAR_DOWNLOAD_REPORT';

export const DOWNLOAD_CENTER_DOWNLOAD_PDF = 7;
export const DOWNLOAD_CENTER_DOWNLOAD_LTB = 2;

export const downloadCenterErrorTypes = {
  DEFAULT_ERROR: 'DEFAULT_ERROR',
  NO_DATA: 'NO_DATA',
  DOWNLOAD_REPORT_ERROR: 'DOWNLOAD_REPORT_ERROR',
  DOWNLOAD_FILTER_ERROR: 'DOWNLOAD_FILTER_ERROR'
};

export const loadDownloadCenter = payload => ({
  types: [LOAD_DOWNLOAD_CENTER_REQUEST, LOAD_DOWNLOAD_CENTER_SUCCESS, LOAD_DOWNLOAD_CENTER_FAILURE],
  successCase: response => !isEmpty(response.data.receivablesInboxDetails),
  successCaseNotMet: {
    errorType: downloadCenterErrorTypes.DEFAULT_ERROR,
    message: i18n.t('downloadCenter.messages.notFound')
  },
  callAPI: (state, { customerId, userId }) => {
    return api.save(
      `reportexportersvc/v0/reports/exporter/cust/${customerId}/user/${userId}/receivables-inbox`,
      payload
    );
  },
  httpErrorTypes: {
    DEFAULT: downloadCenterErrorTypes.DEFAULT_ERROR,
    404: {
      type: downloadCenterErrorTypes.NO_DATA,
      message:  i18n.t('downloadCenter.messages.refine')
    }
  }
});

export function clearDownloadCenter() {
  return { type: CLEAR_DOWNLOAD_CENTER };
}

const EXPORT_FILENAME = 'report.csv';
const downloadExport = () => (dispatch, getState) => {
  const state = getState();
  const downloadReportResult = state.downloadCenter.downloadReportResult;
  if (!downloadReportResult.data) {
    return;
  }
  const contentDisposition = downloadReportResult.contentDisposition;
  const contentType = downloadReportResult.contentType;
  const matches = FILENAME_REGEX.exec(contentDisposition);
  const filename = matches !== null && matches[1] ? matches[1].replace(/['"]/g, '') : EXPORT_FILENAME;
  handleDownloadFile(downloadReportResult.data, filename, contentType);
};

export function loadDownloadReport(payload, receivablesId) {
  if (payload.reportStatus === 'Error') {
    return {
      type: LOAD_DOWNLOAD_REPORT_FAILURE,
      error: { message:  i18n.t('downloadCenter.errors.retry') }
    };
  }
  if (payload.reportStatus === 'No Entries Found') {
    return {
      type: LOAD_DOWNLOAD_REPORT_FAILURE,
      error: {
        message:i18n.t('downloadCenter.errors.criteria')
      }
    };
  }
  return {
    types: [LOAD_DOWNLOAD_REPORT_REQUEST, LOAD_DOWNLOAD_REPORT_SUCCESS, LOAD_DOWNLOAD_REPORT_FAILURE],
    successCase: response => response.data !== null,
    successCaseNotMet: {
      errorType: downloadCenterErrorTypes.DEFAULT_ERROR,
      message: i18n.t('downloadCenter.errors.retry')
    },
    callAPI: (state, { customerId, userId }) => {
      if (payload.fileTypeId === DOWNLOAD_CENTER_DOWNLOAD_PDF || DOWNLOAD_CENTER_DOWNLOAD_LTB) {
        return api.fetchPdf(
          `reportexportersvc/v0/reports/exporter/cust/${customerId}/user/${userId}/receivables/${receivablesId}`,
          payload
        );
      } else {
        return api.save(
          `reportexportersvc/v0/reports/exporter/cust/${customerId}/user/${userId}/receivables/${receivablesId}`,
          payload
        );
      }
    },
    dispatchOnSuccess: [() => downloadExport()],
    httpErrorTypes: {
      DEFAULT: downloadCenterErrorTypes.DOWNLOAD_REPORT_ERROR
    }
  };
}

export function clearDownloadReport() {
  return { type: CLEAR_DOWNLOAD_REPORT };
}
