import api from 'api';
import sessionHelper, { sessionUser } from 'utility/sessionStorageHelper';
import {
  CAPTURE_HISTORY,
  TRANSACTIONS,
  ONLINE_REPORTS,
  ONLINE_REPORT_RESULTS,
  DOWNLOAD_CENTER,
  DECISION_MANAGER_HISTORY
} from 'Constants/routes';
import isEmpty from 'lodash/isEmpty';
import i18n from 'i18n';
import { mapValuesToPayload, payloadToPathParams } from 'routes/utils/payload';

const CLEAR_ALERT_INBOX = 'CLEAR_ALERT_INBOX';

const LOAD_ALERT_COUNT_REQUEST = 'LOAD_ALERT_COUNT_REQUEST';
const LOAD_ALERT_COUNT_SUCCESS = 'LOAD_ALERT_COUNT_SUCCESS';
const LOAD_ALERT_COUNT_FAILURE = 'LOAD_ALERT_COUNT_FAILURE';

const LOAD_ALERT_INBOX_REQUEST = 'LOAD_ALERT_INBOX_REQUEST';
const LOAD_ALERT_INBOX_SUCCESS = 'LOAD_ALERT_INBOX_SUCCESS';
const LOAD_ALERT_INBOX_FAILURE = 'LOAD_ALERT_INBOX_FAILURE';

const LOAD_TRANSACTION_IDS_REQUEST = 'LOAD_TRANSACTION_IDS_REQUEST';
const LOAD_TRANSACTION_IDS_SUCCESS = 'LOAD_TRANSACTION_IDS_SUCCESS';
const LOAD_TRANSACTION_IDS_FAILURE = 'LOAD_TRANSACTION_IDS_FAILURE';

const MARK_AS_UNREAD_READ_REQUEST = 'MARK_AS_UNREAD_READ_REQUEST';
const MARK_AS_UNREAD_READ_SUCCESS = 'MARK_AS_UNREAD_READ_SUCCESS';
const MARK_AS_UNREAD_READ_FAILURE = 'MARK_AS_UNREAD_READ_FAILURE';
const CLEAR_TRANSACTION_IDS = 'CLEAR_TRANSACTION_IDS';

const LOAD_ALERT_FILTER_FAILURE = 'LOAD_ALERT_FILTER_FAILURE';
const LOAD_ALERT_FILTER_REQUEST = 'LOAD_ALERT_FILTER_REQUEST';
const LOAD_ALERT_FILTER_SUCCESS = 'LOAD_ALERT_FILTER_SUCCESS';

const LOAD_ALERT_SETTING_FAILURE = 'LOAD_ALERT_SETTING_FAILURE';
const LOAD_ALERT_SETTING_REQUEST = 'LOAD_ALERT_SETTING_REQUEST';
const LOAD_ALERT_SETTING_SUCCESS = 'LOAD_ALERT_SETTING_SUCCESS';

import { httpErrorTypes } from 'utility/error';
const alertsInboxError = {
  LOAD_ALERT_COUNT: 'LOAD_ALERT_COUNT',
  LOAD_ALERT_INBOX: 'LOAD_ALERT_INBOX',
  LOAD_ALERT_INBOX_NO_DATA: 'LOAD_ALERT_INBOX_NO_DATA',
  LOAD_TRANSACTION_IDS: 'LOAD_TRANSACTION_IDS',
  LOAD_TRANSACTION_IDS_NO_DATA: 'LOAD_TRANSACTION_IDS_NO_DATA',
  LOAD_ALERT_FILTER: 'LOAD_ALERT_FILTER',
  LOAD_ALERT_SETTING: 'LOAD_ALERT_SETTING'
};
const UNREAD = 'unread';

const clearAlertInbox = () => ({ type: CLEAR_ALERT_INBOX });

const loadAlertCount = () => {
  return {
    types: [LOAD_ALERT_COUNT_REQUEST, LOAD_ALERT_COUNT_SUCCESS, LOAD_ALERT_COUNT_FAILURE],
    callAPI: (state, { customerId, userId }) => {
      const url = `alertssvc/v0/cust/${customerId}/user/${userId}/alerts/notifications/unread-count`;
      return api.fetch(url);
    },
    httpErrorTypes: {
      DEFAULT: alertsInboxError.LOAD_ALERT_COUNT
    }
  };
};

const loadAlertInbox = (payload = '') => ({
  types: [LOAD_ALERT_INBOX_REQUEST, LOAD_ALERT_INBOX_SUCCESS, LOAD_ALERT_INBOX_FAILURE],
  callAPI: (state, { customerId, userId }) =>
    api.fetch(
      `alertssvc/v0/cust/${customerId}/user/${userId}/alerts/notifications${payloadToPathParams(
        mapValuesToPayload(payload)
      )}`
    ),
  httpErrorTypes: {
    DEFAULT: alertsInboxError.LOAD_ALERT_INBOX,
    404: {
      type: alertsInboxError.LOAD_ALERT_INBOX_NO_DATA,
      message: i18n.t('alerts.messages.noAlertsAvailable')
    }
  }
});

const valid = (transactions, errors, type) => {
  return isEmpty(errors) && sessionHelper.getItem('selectedRow') && transactions[type] && transactions[type].length > 0;
};
const validCustomerId = (transactions, errors, type) => {
  return isEmpty(errors) && sessionHelper.getItem('selectedRow') && transactions[type] && transactions[type] !== '';
};
const transistionToTransaction = history => (dispatch, getState) => {
  const state = getState();
  const alertTransactions = state.alerts.alertTransactions;

  const typeId = getState().alerts.typeId;
  const errors = state.alerts.inboxErrors;
  let pathname = '';
  let payload = {};
  if (valid(alertTransactions, errors, 'batchUniqueIds')) {
    payload = {
      applyFilter: true,
      batchUniqueIds: alertTransactions.batchUniqueIds,
      hideFilter: true
    };
    if (typeof alertTransactions.dateRange === 'object') {
      payload['captureDate'] = alertTransactions.dateRange;
    }
    sessionStorage.setItem(
      'filters',
      JSON.stringify({ filters: { captureHistoryPayload: payload, captureHistory: payload } })
    );
    pathname = CAPTURE_HISTORY;
  } else if (valid(alertTransactions, errors, 'transactionIds')) {
    payload = {
      applyFilter: true,
      hideFilter: true
    };
    if (typeof alertTransactions.type === 'string' && alertTransactions.type === 'DECISION_MANAGER_NOTIFICATION') {
      if (typeof alertTransactions.dateRange === 'object') {
        payload['processDateRange'] = alertTransactions.dateRange;
        payload['suspendIds'] = alertTransactions.transactionIds;
      }

      sessionStorage.setItem(
        'filters',
        JSON.stringify({ filters: { decisionManagerHistory: payload, decisionManagerHistoryPayload: payload } })
      );
      sessionHelper.setItem('routefrom', 'alert');
      pathname = DECISION_MANAGER_HISTORY;
    } else {
      if (typeof alertTransactions.dateRange === 'object') {
        payload['creditedDate'] = alertTransactions.dateRange;
        payload['transactionIds'] = alertTransactions.transactionIds;
      }
      sessionStorage.setItem('filters', JSON.stringify({ filters: { transactionsPayload: payload, TRAN: payload } }));
      pathname = TRANSACTIONS;
    }
  } else if (valid(alertTransactions, errors, 'returnIds')) {
    const selectedCriteria = { returnIdentifiers: alertTransactions.returnIds };
    sessionHelper.setItem('onlineReports', JSON.stringify({ selectedCriteria, hideFilter: true }));
    pathname =
      ONLINE_REPORTS +
      '/' +
      state.navigation.navigationResponse[1].menuGroups[1].menuItems.find(
        item => item.reportName === 'returnitemsummary'
      ).reportId +
      ONLINE_REPORT_RESULTS;
  } else if (validCustomerId(alertTransactions, errors, 'customReportRequestId')) {
    payload = {
      applyFilter: true,
      customReportRequestId: alertTransactions.customReportRequestId
    };

    sessionStorage.setItem(
      'filters',
      JSON.stringify({ filters: { downloadCenterPayload: payload, downloadCenter: payload } })
    );
    pathname = DOWNLOAD_CENTER;
  }
  sessionHelper.removeItem('selectedRow');
  history.push({
    pathname
  });
};

const loadTransactionIds = (inboxId, typeId, history) => {
  return {
    types: [LOAD_TRANSACTION_IDS_REQUEST, LOAD_TRANSACTION_IDS_SUCCESS, LOAD_TRANSACTION_IDS_FAILURE],
    callAPI: (state, { customerId, userId }) =>
      api.fetch(`alertssvc/v0/cust/${customerId}/user/${userId}/alerts/${typeId}/notifications/${inboxId}`),
    dispatchOnSuccess: [() => transistionToTransaction(history)],
    httpErrorTypes: {
      DEFAULT: alertsInboxError.LOAD_TRANSACTION_IDS,
      404: {
        type: alertsInboxError.LOAD_TRANSACTION_IDS_NO_DATA,
        message: i18n.t('alerts.messages.noTransactionListAvailable')
      },
      401: {
        type: alertsInboxError.LOAD_TRANSACTION_IDS_NO_DATA,
        message: i18n.t('alerts.messages.noDataFound')
      }
    }
  };
};

const markAlert = (payload, alert = UNREAD, filter = {}) => {
  return {
    types: [MARK_AS_UNREAD_READ_REQUEST, MARK_AS_UNREAD_READ_SUCCESS, MARK_AS_UNREAD_READ_FAILURE],
    callAPI: (state, { customerId, userId }) => {
      return api.save(
        alert === UNREAD
          ? `alertssvc/v0/cust/${customerId}/user/${userId}/alerts/notifications/mark-as-unread`
          : `alertssvc/v0/cust/${customerId}/user/${userId}/alerts/notifications/mark-as-read`,
        payload
      );
    },
    dispatchOnSuccess: [() => loadAlertInbox(filter), loadAlertCount],
    httpErrorTypes
  };
};

const markAlertRedirect = (payload, inboxId, alertTypeId, history) => {
  return {
    types: [MARK_AS_UNREAD_READ_REQUEST, MARK_AS_UNREAD_READ_SUCCESS, MARK_AS_UNREAD_READ_FAILURE],
    callAPI: (state, { customerId, userId }) =>
      api.save(`alertssvc/v0/cust/${customerId}/user/${userId}/alerts/notifications/mark-as-read`, payload),
    dispatchOnSuccess: [() => loadTransactionIds(inboxId, alertTypeId, history), () => loadAlertCount()],
    httpErrorTypes
  };
};
const clearTransactionIds = () => {
  return { type: CLEAR_TRANSACTION_IDS };
};
const loadAlertFilter = () => {
  return {
    types: [LOAD_ALERT_FILTER_REQUEST, LOAD_ALERT_FILTER_SUCCESS, LOAD_ALERT_FILTER_FAILURE],
    callAPI: (state, { customerId, userId }) => {
      const url = `alertssvc/v0/cust/${customerId}/user/${userId}/alerts/notification-filters`;
      return api.fetch(url);
    },
    httpErrorTypes: {
      DEFAULT: alertsInboxError.LOAD_ALERT_FILTER
    }
  };
};
const loadAlertSetting = () => {
  return {
    types: [LOAD_ALERT_SETTING_REQUEST, LOAD_ALERT_SETTING_SUCCESS, LOAD_ALERT_SETTING_FAILURE],
    callAPI: (state, { customerId, userId }) => {
      const url = `alertssvc/v0/cust/${customerId}/user/${userId}/alerts-manage-entitlement`;
      return api.fetch(url);
    },
    httpErrorTypes: {
      DEFAULT: alertsInboxError.LOAD_ALERT_SETTING
    }
  };
};
export {
  LOAD_ALERT_COUNT_FAILURE,
  LOAD_ALERT_COUNT_REQUEST,
  LOAD_ALERT_COUNT_SUCCESS,
  loadAlertCount,
  LOAD_ALERT_INBOX_FAILURE,
  LOAD_ALERT_INBOX_REQUEST,
  LOAD_ALERT_INBOX_SUCCESS,
  loadAlertInbox,
  LOAD_TRANSACTION_IDS_REQUEST,
  LOAD_TRANSACTION_IDS_SUCCESS,
  LOAD_TRANSACTION_IDS_FAILURE,
  loadTransactionIds,
  alertsInboxError,
  CLEAR_TRANSACTION_IDS,
  clearTransactionIds,
  CLEAR_ALERT_INBOX,
  clearAlertInbox,
  MARK_AS_UNREAD_READ_FAILURE,
  MARK_AS_UNREAD_READ_REQUEST,
  MARK_AS_UNREAD_READ_SUCCESS,
  markAlert,
  markAlertRedirect,
  LOAD_ALERT_FILTER_FAILURE,
  LOAD_ALERT_FILTER_REQUEST,
  LOAD_ALERT_FILTER_SUCCESS,
  loadAlertFilter,
  LOAD_ALERT_SETTING_FAILURE,
  LOAD_ALERT_SETTING_REQUEST,
  LOAD_ALERT_SETTING_SUCCESS,
  loadAlertSetting
};
