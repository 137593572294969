import { useTranslation } from 'react-i18next';
import { dateUnformatter } from 'hooks/useFieldFormatter/dateFormatter';
import { amountUnformatter } from 'hooks/useFieldFormatter/amountFormatter';
import isObject from 'lodash/isObject';
import moment from 'moment';

import { RENDERER_TYPE } from 'Components/DataGrid/utils';

export function updateColumns(columns) {
  const updatedCol = columns.map(updateColumnByName);
  return updatedCol;
}

const checkName = (name, word) => {
  return name?.toLowerCase().indexOf(word) >= 0;
};

export function viewDetailsColumns(colId) {
  return colId === 'returnDetails' || colId === 'bulkReturnRef' || colId === 'returnReason';
}

const updateColumnByName = column => {
  if (checkName(column.headerName, 'currency')) {
    return {
      field: column.field,
      headerName: column.headerName,
      width: 100,
      type: column.type
    };
  } else if (checkName(column.headerName, 'status')) {
    return {
      field: column.field,
      headerName: column.headerName,
      width: column.width || 120,
      type: column.type,
      readOnly: column.readOnly,
      csvColName: column.csvColName
    };
  } else if (
    checkName(column.field, 'paymentserialnumber') ||
    checkName(column.field, '9001') ||
    checkName(column.field, '70001')
  ) {
    return {
      ...column,
      field: column.field,
      headerName: column.headerName,
      cellRenderer: 'paymentSerialRenderer',
      cellRendererParams: {
        openImage(props) {
          props.context.openImage(props);
        }
      },
      type: [column.type, RENDERER_TYPE]
    };
  } else if (viewDetailsColumns(column.field)) {
    return {
      ...column,
      field: column.field,
      headerName: column.headerName,
      cellRenderer: 'viewDetailsRenderer',
      cellRendererParams: {
        returnDialog(props) {
          props.context.openDialog(props);
        }
      },
      type: [column.type]
    };
  } else {
    return column;
  }
};

export const updateLIMRowData = data => {
  data.map(row => {
    row.readOnly =
      row.invoiceStatus === 'MATCHED' || row.invoiceStatus === 'DEACTIVATED' || row.invoiceStatus === 'TRANSMITTED';
  });
  return data;
};

export const mergeColumns = (arrayOne, arrayTwo) => {
  if (arrayTwo) {
    arrayOne = arrayOne.concat(arrayTwo);
    arrayOne = [...new Map(arrayOne.map(item => [item['field'], item])).values()]; //removed duplicates
  }
  return arrayOne;
};

export const mergeRows = (arrayOne, arrayTwo) => {
  if (arrayTwo) {
    arrayOne = arrayOne.map(item => {
      const row = arrayTwo.find(e => e.paymentSerialNumber === item.paymentSerialNumber);
      return { ...item, ...row };
    });
  }
  return arrayOne;
};

export const createPayload = (data, columns, userSettings, refItemData) => {
  const remittancesList = [];
  data.forEach(row => {
    const rowDataList = [];
    columns.forEach(col => {
      const ref = refItemData?.find(
        refItem => refItem.refTypeId === col.field && Number(row.rowNum) === Number(refItem.rowNum)
      );
      if (row[col.field]) {
        const newCol = {
          refTypeId: Number(col.field),
          dataType: col.type === 'Numeric' ? 'N' : col.type === 'Date' ? 'D' : 'A',
          refItemId: ref?.refItemId,
          value:
            col.type === 'Date'
              ? moment(dateUnformatter(row[col.field], userSettings)).format('MM/DD/YYYY')
              : col.type === 'Numeric'
              ? '' + amountUnformatter(row[col.field], userSettings)
              : row[col.field]
        };
        rowDataList.push(newCol);
      }
    });
    const newRow = {
      edited: row.edited,
      lineNumber: Number(row.rowNum),
      internalLineNumber: Number(row.internalLineNumber),
      documentId: Number(row.documentId) ? Number(row.documentId) : 0,
      rowDataList: rowDataList,
      invoiceId: row.invoiceId && Number(row.invoiceId),
      invoiceLastUpdatedDate: row.invoiceLastUpdatedDate,
      invoiceLastUpdatedUserFullNm: row.invoiceLastUpdatedUserFullNm,
      reasonCode: isObject(row.reasonCode) ? row.reasonCode.id : Number(row.reasonCode),
      ccmpInvoiceId: row.ccmpInvoiceId,
      customerExceptionReasonId: row.customerExceptionReasonId,
      integratedReceivablesCandidateId: row.integratedReceivablesCandidateId,
      invoiceMatchRuleId: row.invoiceMatchRuleId,
      invoiceNumberText: row.invoiceNumberText,
      invoiceOpenFileId: row.invoiceOpenFileId,
      invoiceRuleName: row.invoiceRuleName,
      invoiceStatus: row.invoiceStatus,
      settlementMissionIdentifier: row.settlementMissionIdentifier,
      settlementRevision: row.settlementRevision,
      settlementVersion: row.settlementVersion,
      invoiceUserId: row.invoiceUserId,
      lastUpdatedDateForInvoice: row.lastUpdatedDateForInvoice,
      subsidiaryId: isObject(row.subsidiaryId) ? row.subsidiaryId.id : Number(row.subsidiaryId),
      deactivateRow: row.deactivateRow
    };
    remittancesList.push(newRow);
  });
  return remittancesList;
};

export const updateInvoiceRows = data => {
  const newData = data.map(row => {
    return {
      ...row.invoiceRefList,
      ...row
    };
  });
  return newData;
};
