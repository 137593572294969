import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@jpmuitk/theme';

import { pageStyles } from 'styles';

const PageFooter = props => {
  const { classes, children } = props;
  return <div className={classes.footer}>{children}</div>;
};

PageFooter.propTypes = {
  children: PropTypes.object.isRequired
};

export default withStyles(pageStyles, { name: 'PageFooter' })(PageFooter);
