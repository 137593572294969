import cssVariables from 'Constants/_variables.module.scss';
import { list } from 'styles';

export const buttonRendererStyles = {
  root: {
    '& ul': {
      ...list,
      '& li': {
        display: 'inline-block'
      }
    }
  },
  button: {
    marginRight: '8px'
  }
};

export const multilineStyles = ({ palette: { toolkit } }) => ({
  textArea: {
    width: '100%',
    '& label': {
      width: 'auto'
    }
  },
  root: {
    fontSize: '1em!important'
  },

  underTextArea: {
    display: 'flex',
    fontSize: '1em!important'
  },
  actionButtons: {
    marginTop: cssVariables.space2
  },
  characterCount: {
    marginLeft: 'auto'
  },
  cssLabel: {
    fontSize: '1em!important',
    '&$cssFocused': {
      color: `${toolkit.grey500} !important`
    }
  },
  cssOutlinedInput: {
    fontSize: '1em!important',
    '&$cssFocused $notchedOutline': {
      borderColor: `${toolkit.grey500} !important`,
      borderWidth: '1px'
    }
  },
  cssFocused: {},
  notchedOutline: {}
});
