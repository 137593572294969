import React from 'react';
import classNames from 'classnames';

import { withStyles } from '@jpmuitk/theme';
import { Icon as DefaultIcon } from '@jpmuitk/icon';

import ButtonAsLink from 'Components/Button/AsLink';

import { iconStyles } from './styles';

import i18n from 'i18n';

const Icon = withStyles(iconStyles)(DefaultIcon);

const BackButtonComponent = React.memo(({ classes, className, onClick, label }) => (
  <div className={className}>
    <ButtonAsLink onClick={onClick}>
      <>
        <Icon name="left-arrow" aria-hidden="true" />
        <span className="visually-hidden">{i18n.t('backButton.goBackTo')}</span>
        &nbsp;
        {label}
      </>
    </ButtonAsLink>
  </div>
));

export default BackButtonComponent;
