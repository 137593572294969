const baseDrawerContentWrapper = {
  width: '100%',
  height: '100%',
  display: 'flex',
  overflow: 'auto',
  flexDirection: 'column'
};

export const invoiceIdentificationPatternsStyles = {
  drawerTitle: {
    padding: '16px 24px',
    marginTop: '24px',
    paddingTop: 0
  },
  drawerContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 24px',
    overflowY: 'auto'
  },
  drawerActions: {
    margin: '16px 24px',
    '& > :last-child': {
      marginRight: 0,
      marginLeft: 0
    }
  },
  drawerContentWrapper: {
    '& > div:not([class])': {
      ...baseDrawerContentWrapper,
      '& > :nth-child(3)': baseDrawerContentWrapper
    }
  },
  inputBuilderOverride: {
    '& label': {
      width: '160px !important'
    },
    '& > div': {
      maxWidth: '500px'
    }
  },
  dialogTitle: {
    'font-size': 22
  }
};
