import React from 'react';
import { connect } from 'react-redux';

const announcementOffScreen = {
  border: 0,
  clip: 'rect(0 0 0 0)',
  height: '1px',
  margin: '-1px',
  overflow: 'hidden',
  padding: 0,
  width: '1px',
  position: 'absolute'
};

export const Announcement = ({ message, ariaLive = 'polite' }) => (
  <div style={announcementOffScreen} aria-live={ariaLive} aria-atomic="true">
    {message ? message : ''}
  </div>
);

const mapStateToProps = state => ({
  message: state.announcement.message,
  ariaLive: state.announcement.ariaLive
});

export default connect(mapStateToProps)(Announcement);
