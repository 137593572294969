import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { HashRouter } from 'react-router-dom';

import isEmpty from 'lodash/isEmpty';

import { SaltProvider } from '@salt-ds/core';
import { createToolkitTheme, ThemeProvider, withStyles } from '@jpmuitk/theme';
import { Panel as DefaultPanel } from '@jpmuitk/panel';
// ideally these css files would be loaded from a link tag
// pointing to static asset directory for caching
import '@jpmuitk/style/css/jpmuitk.css';
import '@salt-ds/theme/index.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';

import { LicenseManager } from 'ag-grid-enterprise';
import AG_GRID_LICENSE from 'Constants/license';
import sessionHelper, { sessionBroadcast, sessionUser } from 'utility/sessionStorageHelper';
import { loadNavigation } from 'actions/navigationActions';
import { loadBroadcastMessages } from 'actions/broadcastalertActions';

import MainLayout from './MainLayout';
import Login from 'containers/Login';
import ConfirmationDialog from 'Components/Dialogs/ConfirmationDialog';

import { appWrapper } from 'styles';
import './App.scss';

LicenseManager.setLicenseKey(AG_GRID_LICENSE);

let LIGHT_THEME;
if (process.env.NODE_ENV === 'production') {
  LIGHT_THEME = createToolkitTheme('light');
} else {
  LIGHT_THEME = createToolkitTheme(process.env.REACT_APP_TOOLKIT_THEME !== 'dark' ? 'light' : 'dark');
}

export const showLogin = (userCustomer, loggedIn) => {
  return (
    isEmpty(userCustomer) && !loggedIn && (window.BUILD_ENVIRONMENT === 'MOCK' || window.BUILD_ENVIRONMENT === 'DEV')
  );
};

const Panel = withStyles(appWrapper)(DefaultPanel);
const initialValues = {
  idle: false,
  loggedIn: false
};

const LanguageLookup = {
  'de-DE': 'de',
  'es-MX': 'es',
  'fr-FR': 'fr',
  'it-IT': 'it',
  'ja-JP': 'jp',
  'pt-PT': 'br',
  'pt-BR': 'br',
  'ru-RU': 'ru',
  'en-US': 'en',
  'zh-CN': 'cn',
  'zh-TW': 'tw'
};

export const App = props => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const {
    broadcastalerts,
    navigation,
    sidePanels,
    users: {
      status,
      errorMessage,
      isServiceModeError = errorMessage === 'MAINTAINMODE',
      dialogTitle = isServiceModeError ? t('login.service.outage') : t('login.service.error'),
      isFetching
    } = {},
    settings: { userCustomer, isFullPortal, userSettings = {} }
  } = useSelector(state => state);
  const { userCulture } = userSettings;
  const languageCode = userCulture?.code;
  const reducer = (currentState, newState) => ({ ...currentState, ...newState });
  const [state, setState] = useReducer(reducer, initialValues);

  useEffect(() => {
    if (status === 200) {
      const user = sessionUser.getLoggedInUser();

      if (!user.isEmpty()) {
        setState({ loggedIn: true });
        dispatch(loadNavigation(user.userCustomer.id, user.userId));
        if (!sessionBroadcast.getBroadcastFlag()) {
          dispatch(loadBroadcastMessages(user.userCustomer.id, user.userId));
        }
      }
    }
  }, [status, loadNavigation]);
  useEffect(() => {
    if (languageCode && i18n.language !== LanguageLookup[languageCode]) {
      i18n.changeLanguage(LanguageLookup[languageCode]);
    }
  }, [languageCode, i18n]);

  const closeLogin = () => {
    setState({ loggedIn: true });
  };

  const handleClose = isServiceModeError => {
    sessionHelper.removeItem('userheader');
    window.location.href = window.LOGGOFF_FULLPORTAL + '?time=' + new Date().getTime();
  };
  return (
    <SaltProvider mode="light">
      <ThemeProvider theme={LIGHT_THEME}>
        <Panel style={{ height: '100vh' }}>
          {!isFetching && errorMessage ? (
            <ConfirmationDialog
              dialogContent={{
                dialogTitle,
                openDialog: true,
                dialogState: 'error',
                btnContent: [
                  {
                    name: t('button.close'),
                    onClick: () => handleClose(isServiceModeError),
                    variant: 'cta'
                  }
                ]
              }}
              overrideHandleClose={() => handleClose(isServiceModeError)}
            >
              {!isServiceModeError ? errorMessage : <p>{t('login.service.maintenance')}</p>}
            </ConfirmationDialog>
          ) : showLogin(userCustomer, state.loggedIn) ? (
            <Login close={closeLogin} />
          ) : (
            <HashRouter>
              <MainLayout userCustomer={userCustomer} isFullPortal={isFullPortal} />
            </HashRouter>
          )}
        </Panel>
      </ThemeProvider>
    </SaltProvider>
  );
};

export default App;
