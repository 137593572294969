import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import ConfirmationDialog from 'Components/Dialogs/ConfirmationDialog';

import { isMessageNonEmpty, parseBroadcastMessages } from './broadcastParser.js';
import { sessionBroadcast } from 'utility/sessionStorageHelper';
import isEmpty from 'lodash/isEmpty';

const BroadcastDialog = props => {
  const [shownBroadcast, setShownBroadcast] = useState(false);
  const { isFetching, broadcastalerts } = useSelector(state => state.broadcastalerts);
  const { t } = useTranslation();

  if (shownBroadcast) {
    return null;
  }

  const handleClose = () => {
    sessionBroadcast.setBroadcastFlag(false);
    setShownBroadcast(true);
  };

  const parsedBroadcastMessage = parseBroadcastMessages(broadcastalerts);

  return sessionBroadcast.getBroadcastFlag() || isFetching || isEmpty(parsedBroadcastMessage) ? null : (
    <ConfirmationDialog
      dialogContent={{
        message: parsedBroadcastMessage,
        openDialog: true,
        handleClose,
        dialogTitle: t('broadcast.dialog.title'),
        btnContent: [
          {
            name: t('button.ok'),
            onClick: handleClose
          }
        ]
      }}
    />
  );
};

export { BroadcastDialog };

export default BroadcastDialog;
