import { createStyles } from '@jpmuitk/theme';

export const defaultDropdownStyle = {
  buttonLabel: {
    textTransform: 'none'
  }
};

export const listLabel = theme =>
  createStyles({
    root: {
      transform: 'none !important'
    }
  });
