import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Icon } from '@jpmuitk/icon';

import { withStyles } from '@jpmuitk/theme';
import { styles } from './styles';
import { iconAccessibleText } from './consts';

const TextWithIcon = ({ children, classes, icon, iconSize, layout, role, type, className }) => {
  return (
    <div
      className={classNames(
        className,
        classes.root,
        { [classes.page]: layout === 'page' },
        { [classes.panel]: layout === 'panel' },
        { [classes.sidepanel]: layout === 'sidepanel' },
        { [classes.error]: type === 'error' },
        { [classes.warning]: type === 'warning' },
        { [classes.info]: type === 'info' }
      )}
      role={role}
    >
      <Icon
        className={classNames(
          classes.icon,
          { [classes.error]: type === 'error' },
          { [classes.warning]: type === 'warning' },
          { [classes.info]: type === 'info' }
        )}
        name={icon}
        size={iconSize}
        accessibleText={iconAccessibleText[type]}
      />
      <span>
        <strong className={classes.strong}>{children}</strong>
      </span>
    </div>
  );
};

TextWithIcon.defaultProps = {
  iconSize: 16
};

TextWithIcon.propTypes = {
  children: PropTypes.string,
  classes: PropTypes.object,
  icon: PropTypes.string.isRequired,
  iconSize: PropTypes.number,
  layout: PropTypes.string,
  type: PropTypes.string
};

export default withStyles(styles)(TextWithIcon);
