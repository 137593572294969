// Actions
const UPDATE_FORM_STATE = 'UPDATE_FORM_STATE';
const FILTER_REQUEST = 'FILTER_REQUEST';
const FILTER_SUCCESS = 'FILTER_SUCCESS';
const FILTER_FAILURE = 'FILTER_FAILURE';
const FILTER_LOADING_SUCCESS = 'FILTER_LOADING_SUCCESS';

// Reducer
const filterRequest = (state, action) => {
  return {
    ...state,
    isFilterLoading: true
  };
};

const filterSuccess = (state, action) => {
  return {
    ...state,
    isFilterLoading: false,
    filterData: action.response.data
  };
};

const filterFailure = (state, action) => {
  return {
    ...state,
    filterError: action.error
  };
};

const filterLoadingSuccess = (state, action) => {
  return {
    ...state,
    isFilterLoading: false,
    [action.form]: { ...action.payload }
  };
};

export default function reducer(state = { isFilterLoading: true }, action = {}) {
  const handlers = {
    [FILTER_REQUEST]: filterRequest,
    [FILTER_SUCCESS]: filterSuccess,
    [FILTER_FAILURE]: filterFailure,
    [FILTER_LOADING_SUCCESS]: filterLoadingSuccess
  };

  return handlers.hasOwnProperty(action.type) ? handlers[action.type](state, action) : state;
}

// Action Creators
export const updateFormState = (form, state) => ({
  type: UPDATE_FORM_STATE,
  form,
  payload: state
});
