import { mergeByKey } from 'utility/arrayHelper';
import { statusCodeToMessage } from 'utility/error';
import {
  UPDATE_EXPIRED_TRANSACTION,
  LOCK_DECISION_DETAILS_REQUEST,
  LOCK_DECISION_DETAILS_SUCCESS,
  LOCK_DECISION_DETAILS_FAILURE,
  LOAD_DECISION_DETAILS_REQUEST,
  LOAD_DECISION_DETAILS_SUCCESS,
  LOAD_DECISION_DETAILS_FAILURE,
  FETCH_ADDITIONAL_IMAGES_REQUEST,
  FETCH_ADDITIONAL_IMAGES_SUCCESS,
  FETCH_ADDITIONAL_IMAGES_FAILURE,
  SAVE_DECISION_TRANSACTION_REQUEST,
  SAVE_DECISION_TRANSACTION_SUCCESS,
  SAVE_DECISION_TRANSACTION_FAILURE,
  SAVE_SINGLE_DOCUMENT_REQUEST,
  SAVE_SINGLE_DOCUMENT_SUCCESS,
  SAVE_SINGLE_DOCUMENT_FAILURE,
  SAVE_DECISION_DETAILS_REQUEST,
  SAVE_DECISION_DETAILS_SUCCESS,
  SAVE_DECISION_DETAILS_FAILURE,
  SUBMIT_TRANSACTION_REQUEST,
  SUBMIT_TRANSACTION_SUCCESS,
  SUBMIT_TRANSACTION_FAILURE,
  RESET_DECISION_DETAILS,
  CANCEL_EDIT_DECISION_DETAILS_REQUEST,
  CANCEL_EDIT_DECISION_DETAILS_SUCCESS,
  CANCEL_EDIT_DECISION_DETAILS_FAILURE
} from './actions';

const initialState = {
  isFetchingDetails: true
};

const lockDecisionDetailsRequest = (state, action) => {
  return {
    ...state,
    editable: false
  };
};

const lockDecisionDetailsSuccess = (state, action) => {
  return {
    ...state,
    editable: true
  };
};

const lockDecisionDetailsFailure = (state, action) => {
  return {
    ...state,
    editable: false
  };
};

const loadDecisionDetailsRequest = (state, action) => {
  return {
    ...state,
    editable: false,
    isFetchingDetails: true
  };
};

const loadDecisionDetailsSuccess = (state, action) => {
  return {
    ...state,
    data: { ...action.response.data },
    isFetchingDetails: false,
    editable: action.response.data.suspendedTransactionDetails.editMode
  };
};

const loadDecisionDetailsFailure = (state, action) => {
  return {
    ...state,
    ...action,
    isFetchingDetails: false
  };
};

const fetchAdditionalImagesRequest = (state, action) => {
  return {
    ...state,
    isFetchingAdditionalImages: true
  };
};

const fetchAdditionalImagesSuccess = (state, action) => {
  return {
    ...state,
    data: {
      ...state.data,
      suspendedTransactionDetails: {
        ...state.data.suspendedTransactionDetails,
        fullImages: [...state.data.suspendedTransactionDetails.fullImages, ...action.response.data.fullImages]
      }
    },
    isFetchingAdditionalImages: false
  };
};

const fetchAdditionalImagesFailure = (state, action) => {
  return {
    ...state,
    ...action,
    isFetchingAdditionalImages: false
  };
};

// Single Document
const saveSingleDocumentRequest = (state, action) => {
  return {
    ...state
  };
};
const saveSingleDocumentSuccess = (state, action) => {
  const {
    data: {
      suspendedTransactionDetails,
      suspendedTransactionDetails: {
        referenceDetails,
        referenceDetails: { documents },
        transactionDecision: { decisions }
      }
    }
  } = state;
  const {
    response: {
      data: { documentStatuses, decision: decisionId, ...rest }
    },
    decisionDescription = decisions.find(({ id }) => id === decisionId)
  } = action;

  return {
    ...state,
    data: {
      ...state.data,
      suspendedTransactionDetails: {
        ...suspendedTransactionDetails,
        decisionDescription,
        ...rest,
        referenceDetails: { ...referenceDetails, documents: mergeByKey(documents, documentStatuses, 'documentId') }
      }
    },
    rowCounts: mergeByKey(suspendedTransactionDetails.referenceDetails.documents, documentStatuses, 'documentId').map(
      ({ rowCount }) => rowCount
    )
  };
};
const saveSingleDocumentFailure = (state, action) => {
  return {
    ...state
  };
};

// Save transaction
const saveDecisionTransactionRequest = (state, action) => {
  return {
    ...state,
    isProcessing: true
  };
};
const saveDecisionTransactionSuccess = (state, action) => {
  const {
    data: {
      suspendedTransactionDetails,
      suspendedTransactionDetails: {
        referenceDetails,
        referenceDetails: { documents },
        transactionDecision: { decisions }
      }
    }
  } = state;
  const {
    response: {
      data: { documentStatuses, decisionSavedStatus, decision: decisionId, ...rest }
    },
    params: { updatedDocuments },
    decisionDescription = decisions.find(({ id }) => id === decisionId)
  } = action;

  return {
    ...state,
    isProcessing: false,
    editable:
      (!documentStatuses && !decisionSavedStatus) || documentStatuses?.some(document => !document.saved) ? true : false,
    data: {
      ...state.data,
      suspendedTransactionDetails: {
        ...suspendedTransactionDetails,
        decisionDescription,
        ...rest,
        referenceDetails: {
          ...referenceDetails,
          documents: mergeByKey(
            documents,
            mergeByKey(documentStatuses, decisionId > 1 ? documents : updatedDocuments, 'documentId'),
            'documentId'
          )
        }
      }
    },
    rowCounts: mergeByKey(
      state.data.suspendedTransactionDetails.referenceDetails.documents,
      action.response.data.documentStatuses,
      'documentId'
    ).map(({ rowCount }) => rowCount)
  };
};
const saveDecisionTransactionFailure = (state, action) => {
  return {
    ...state,
    isProcessing: false
  };
};

// Submit Transaction
const submitDecisionTransactionRequest = (state, action) => {
  return {
    ...state,
    isProcessing: true
  };
};
const submitDecisionTransactionSuccess = (state, action) => {
  const {
    data: {
      suspendedTransactionDetails,
      suspendedTransactionDetails: {
        referenceDetails,
        referenceDetails: { documents },
        transactionDecision: { decisions }
      }
    }
  } = state;
  const {
    response: {
      data: { documentStatuses, decisionSubmittedStatus, decision: decisionId, ...rest }
    },
    params: { updatedDocuments },
    decisionDescription = decisions.find(({ id }) => id === decisionId)
  } = action;

  return {
    ...state,
    isProcessing: false,
    editable:
      (!documentStatuses && !decisionSubmittedStatus) || documentStatuses?.some(document => !document.saved)
        ? true
        : false,
    data: {
      ...state.data,
      suspendedTransactionDetails: {
        ...suspendedTransactionDetails,
        decisionDescription,
        ...rest,
        referenceDetails: {
          ...referenceDetails,
          documents: mergeByKey(
            documents,
            mergeByKey(documentStatuses, decisionId > 1 ? documents : updatedDocuments, 'documentId'),
            'documentId'
          )
        }
      }
    },
    rowCounts: mergeByKey(
      state.data.suspendedTransactionDetails.referenceDetails.documents,
      action.response.data.documentStatuses,
      'documentId'
    ).map(({ rowCount }) => rowCount)
  };
};
const submitDecisionTransactionFailure = (state, action) => {
  return {
    ...state,
    isProcessing: false
  };
};
const saveDecisionDetailsRequest = (state, action) => {
  return {
    ...state,
    savingDetails: true
  };
};

const saveDecisionDetailsSuccess = (state, action) => {
  return {
    ...state,
    ...action.response,
    savingDetails: false
  };
};

const saveDecisionDetailsFailure = (state, action) => {
  return {
    ...state,
    ...action,
    savingDetails: false
  };
};

const resetDecisionDetails = (state, action) => {
  return {
    ...initialState
  };
};

const cancelEditDecisionDetailsRequest = (state, action) => {
  return {
    ...state,
    editable: false,
    dataSaveFlag: false,
    isFetchingDetails: true,
    isProcessing: true
  };
};

const cancelEditDecisionDetailsSuccess = (state, action) => {
  return {
    ...state,
    isProcessing: false,
    dataSaveFlag: false
  };
};

const cancelEditDecisionDetailsFailure = (state, action) => {
  return {
    ...state,
    dataSaveFlag: false,
    isProcessing: false
  };
};

const updateExpiredTransaction = (state, action) => {
  return {
    ...state,
    isDecisionExpired: action.params.params.decisionExpiredDetails?.isExpired === 2 ? true : false,
    editable: action.params.params.decisionExpiredDetails?.isExpired === 2 ? false : state.editable
  };
};

export default function decisionManagerDetailsReducer(state = initialState, action) {
  const handlers = {
    [UPDATE_EXPIRED_TRANSACTION]: updateExpiredTransaction,
    [LOCK_DECISION_DETAILS_REQUEST]: lockDecisionDetailsRequest,
    [LOCK_DECISION_DETAILS_SUCCESS]: lockDecisionDetailsSuccess,
    [LOCK_DECISION_DETAILS_FAILURE]: lockDecisionDetailsFailure,
    [LOAD_DECISION_DETAILS_REQUEST]: loadDecisionDetailsRequest,
    [LOAD_DECISION_DETAILS_SUCCESS]: loadDecisionDetailsSuccess,
    [LOAD_DECISION_DETAILS_FAILURE]: loadDecisionDetailsFailure,
    [FETCH_ADDITIONAL_IMAGES_REQUEST]: fetchAdditionalImagesRequest,
    [FETCH_ADDITIONAL_IMAGES_SUCCESS]: fetchAdditionalImagesSuccess,
    [FETCH_ADDITIONAL_IMAGES_FAILURE]: fetchAdditionalImagesFailure,
    [SAVE_SINGLE_DOCUMENT_REQUEST]: saveSingleDocumentRequest,
    [SAVE_SINGLE_DOCUMENT_SUCCESS]: saveSingleDocumentSuccess,
    [SAVE_SINGLE_DOCUMENT_FAILURE]: saveSingleDocumentFailure,
    [SAVE_DECISION_DETAILS_REQUEST]: saveDecisionDetailsRequest,
    [SAVE_DECISION_DETAILS_SUCCESS]: saveDecisionDetailsSuccess,
    [SAVE_DECISION_DETAILS_FAILURE]: saveDecisionDetailsFailure,
    [SUBMIT_TRANSACTION_REQUEST]: submitDecisionTransactionRequest,
    [SUBMIT_TRANSACTION_SUCCESS]: submitDecisionTransactionSuccess,
    [SUBMIT_TRANSACTION_FAILURE]: submitDecisionTransactionFailure,
    [RESET_DECISION_DETAILS]: resetDecisionDetails,
    [SAVE_DECISION_TRANSACTION_REQUEST]: saveDecisionTransactionRequest,
    [SAVE_DECISION_TRANSACTION_SUCCESS]: saveDecisionTransactionSuccess,
    [SAVE_DECISION_TRANSACTION_FAILURE]: saveDecisionTransactionFailure,
    [CANCEL_EDIT_DECISION_DETAILS_REQUEST]: cancelEditDecisionDetailsRequest,
    [CANCEL_EDIT_DECISION_DETAILS_SUCCESS]: cancelEditDecisionDetailsSuccess,
    [CANCEL_EDIT_DECISION_DETAILS_FAILURE]: cancelEditDecisionDetailsFailure
  };
  return handlers.hasOwnProperty(action.type) ? handlers[action.type](state, action) : state;
}
