const programItemToString = item => {
  const valueSite = item?.site?.name ? item.site.name : '';
  const valueNumber = item?.number ? item.number + ' - ' : '';
  const value = valueNumber + valueSite;
  return value;
};
const booleanItemToString = item => {
  return item ? 'Yes' : 'No';
};

const ViewPatternDetailsFields = {
  PROGRAM: {
    id: 'program',
    label: ['invoicePatterns.columnHeaderNames.program'],
    labelPlacement: 'left',
    itemToString: programItemToString,
    translate: true,
    readOnly: true
  },
  MIN_LENGTH: {
    id: 'minLength',
    label: ['invoicePatterns.columnHeaderNames.minLength'],
    labelPlacement: 'left',
    translate: true,
    readOnly: true
  },
  MAX_LENGTH: {
    id: 'maxLength',
    label: ['invoicePatterns.columnHeaderNames.maxLength'],
    labelPlacement: 'left',
    translate: true,
    readOnly: true
  },
  INVOICE_NUMBER_FORMAT: {
    id: 'invoiceRefFieldFormat',
    label: ['invoicePatterns.columnHeaderNames.invoiceRefFieldFormat'],
    labelPlacement: 'left',
    translate: true,
    readOnly: true
  },
  REFERENCE_FIELD: {
    id: 'invoiceRefTxt',
    label: ['invoicePatterns.columnHeaderNames.invoiceRefTxt'],
    labelPlacement: 'left',
    translate: true,
    readOnly: true
  },
  SEARCH_PAYMENT_NUMBER: {
    id: 'searchPaymentNumIn',
    label: ['invoicePatterns.columnHeaderNames.searchPaymentNumIn'],
    labelPlacement: 'left',
    itemToString: booleanItemToString,
    translate: true,
    readOnly: true
  },
  REMOVE_SPACES: {
    id: 'removeSpacesIn',
    label: ['invoicePatterns.columnHeaderNames.removeSpacesIn'],
    labelPlacement: 'left',
    itemToString: booleanItemToString,
    translate: true,
    readOnly: true
  },
  REMOVE_SPECIAL_CHARACTERS: {
    id: 'removeSpecialCharactersIn',
    label: ['invoicePatterns.columnHeaderNames.removeSpecialCharactersIn'],
    labelPlacement: 'left',
    itemToString: booleanItemToString,
    translate: true,
    readOnly: true
  },
  TRUNCATE_REF_LEADING: {
    id: 'truncateReferenceLeading',
    label: ['invoicePatterns.columnHeaderNames.truncateReferenceLeading'],
    labelPlacement: 'left',
    translate: true,
    readOnly: true
  },
  TRUNCATE_REF_TRAILING: {
    id: 'truncateReferenceTrailing',
    label: ['invoicePatterns.columnHeaderNames.truncateReferenceTrailing'],
    labelPlacement: 'left',
    translate: true,
    readOnly: true
  }
};

export default ViewPatternDetailsFields;
