import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@jpmuitk/theme';
import { useAriaAnnouncer } from '@jpmuitk/aria-announcer';
import { RANGE } from 'config/forms/fields';
import { useField, useFormState } from 'react-final-form';
import { useInteractiveProps } from 'Components/Forms/Hooks';
import { useFieldFormatter, fieldUnformatter, fieldFormatter } from 'hooks/useFieldFormatter';
import { ColumnLayout } from '@jpmuitk/column-layout';
import Fieldset from 'Components/Forms/Fieldset';
import { InputBuilder } from 'Components/Forms/Input';
import ConditionalWrapper from 'Components/ConditionalWrapper';
import * as V from 'utility/validation';
import { useTranslation } from 'react-i18next';
import { errorStyles } from './styles';

const InputRangeBuilder = props => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { announce } = useAriaAnnouncer();
  const [showMainError, updateMainError] = useState(false);
  const userSettings = useSelector(state => state.settings.userSettings);
  const { id, label, rangeType, hide, useColumnLayout, columnLayoutProps, rangeRequired } = useInteractiveProps(props);
  const [formattedFrom, fromValidationProps, fromLabel] = useFieldFormatter('', rangeType, RANGE(t).FROM.label, {
    allowSign: rangeType === 'amount'
  });
  const [formattedTo, toValidationProps, toLabel] = useFieldFormatter('', rangeType, RANGE(t).TO.label, {
    allowSign: rangeType === 'amount'
  });
  const formatter = value => fieldFormatter(value, rangeType || '', userSettings);
  const unformatter = value => fieldUnformatter(value, rangeType || '', userSettings);
  const { input, meta } = useField(`${id}.disableSubmit`, {
    validate: V.composeValidators([...RANGE(t).validation({ formatter, unformatter, label: props.field.label })])
  });
  const formState = useFormState();
  const errorDescId = `${id}.error.desc`;

  useEffect(() => {
    if (meta.error?.msg) {
      announce(meta.error.msg);
    }
  }, [dispatch, meta.error]);

  return hide ? null : (
    <ConditionalWrapper
      condition={useColumnLayout}
      wrapper={children => (
        <ColumnLayout item {...columnLayoutProps}>
          {children}
        </ColumnLayout>
      )}
    >
      <Fieldset legend={label()}>
        <ColumnLayout container spacing={1}>
          <ColumnLayout item xs={6}>
            <InputBuilder
              noSuffix
              errorOverride={
                showMainError && meta.error && !formState.errors[id]?.to && !formState.errors[id]?.from
                  ? { ...meta.error, ...{ msg: '' } }
                  : null
              }
              field={{
                inputProps:
                  showMainError && meta.error && !formState.errors[id]?.to && !formState.errors[id]?.from
                    ? { 'aria-describedby': errorDescId }
                    : {},
                ...RANGE(t).FROM,
                id: `${id}.${RANGE(t).FROM.id}`,
                ...fromLabel,
                validation: () => [
                  ...RANGE(t).FROM.validation(
                    { formatter, unformatter, userSettings, label: label({ t }) },
                    props.onlyAllowIntegers,
                    props.onlyAllowPositive,
                    rangeType === 'amount',
                    rangeRequired
                  ),
                  fromValidationProps?.regex
                    ? V.isValidWithRegex(
                        t('validation.invalidFormat', { message: fromValidationProps.msg }),
                        fromValidationProps.regex?.source
                      )
                    : V.isNumeric
                ]
              }}
            />
          </ColumnLayout>
          <ColumnLayout item xs={6}>
            <InputBuilder
              noSuffix
              errorOverride={
                showMainError && meta.error && !formState.errors[id]?.to && !formState.errors[id]?.from
                  ? { ...meta.error, ...{ msg: '' } }
                  : null
              }
              field={{
                inputProps:
                  showMainError && meta.error && !formState.errors[id]?.to && !formState.errors[id]?.from
                    ? { 'aria-describedby': errorDescId }
                    : {},
                ...RANGE(t).TO,
                id: `${id}.${RANGE(t).TO.id}`,
                ...toLabel,
                onBlur: () => updateMainError(true),
                validation: () => [
                  ...RANGE(t).TO.validation(
                    { formatter, unformatter, userSettings },
                    props.onlyAllowIntegers,
                    props.onlyAllowPositive,
                    rangeType === 'amount',
                    rangeRequired
                  ),
                  toValidationProps?.regex
                    ? V.isValidWithRegex(
                        t('validation.invalidFormat', { message: toValidationProps.msg }),
                        toValidationProps.regex?.source
                      )
                    : V.isNumeric
                ]
              }}
            />
          </ColumnLayout>

          {showMainError && meta.error && !formState.errors[id]?.to && !formState.errors[id]?.from && (
            <ColumnLayout className={props.classes.errorContainer} item xs={12}>
              <p id={errorDescId} className={props.classes.error}>
                {meta.error?.msg}
              </p>
            </ColumnLayout>
          )}
        </ColumnLayout>
      </Fieldset>
    </ConditionalWrapper>
  );
};

export default withStyles(errorStyles)(InputRangeBuilder);
